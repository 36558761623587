import React from 'react';

import { Typography, Box, Avatar, Link, Rating, Card, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Moment from 'moment';
import _ from 'lodash';

const ReviewCard = (props) => {
    const { review, detail } = props;
    const theme = useTheme();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { defaultCurrency } = useSelector(state => state.general);

    var datetime1 = Moment(new Date());
    var datetime2 = new Date();
    var diff = 0;
    var displayTimeDay = '';

    const calculateTimeDay = (value) => {

        datetime2 = Moment(value);
        diff = Moment.duration(datetime1.diff(datetime2));

        if (diff.days() > 0) {
            displayTimeDay = diff.days() + " days ago";
        } else if (diff.hours() >= 1) {
            displayTimeDay = diff.hours() + " hours ago";
        } else if (diff.minutes() >= 1) {
            displayTimeDay = diff.minutes() + " minutes ago";
        } else {
            displayTimeDay = diff.seconds() + " seconds ago";
        }

        return displayTimeDay;
    }

    const reviewSummary = () => {
        return (
            <>
                <Grid style={{ display: "flex", position: "absolute", width: "100%", justifyContent: "center" }} className={classes.avatarPosition}>
                    <Avatar
                        alt="profile_picture"
                        src={review.profile_photo ? review.profile_photo : null}
                        className={classes.avatarStyle}
                    >
                        {review.name.substring(0, 1)}
                    </Avatar>
                </Grid>
                <Grid style={{ padding: "6% 9% 0% 9%", marginTop: 30 }}>
                    <Typography variant="body1" color="primary" align="center" style={{ fontFamily: "FuturaPTDemi", marginBottom: "20px" }}>
                        {review.name}
                    </Typography> 
                    <Typography variant="body1" color="primary" align="center" sx={{ WebkitLineClamp: {xs: 7, sm: 5, md: 10} }} style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis', overflowWrap: "anywhere" }}>
                        {review.content}
                    </Typography>
                </Grid>
                <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Link component={RouterLink} to={`/reviews/${review.ref_id}`} underline='hover' style={{ fontSize: "1rem", padding: "20px 19px" }}>
                        {t('general.readMore')}
                    </Link>
                </Grid>
            </>
        )
    }

    const reviewAll = () => {
        return (
            <>
                <Grid style={{ padding: "2% 2%", display: 'flex' }}>
                    <Box style={{ paddingRight: 10 }}>
                        <Avatar
                            alt="profile_picture"
                            src={review.profile_photo ? review.profile_photo : null}
                            className={classes.avatarStyle}
                        >
                            {review.name.substring(0, 1)}
                        </Avatar>
                    </Box>
                    <Box>
                        <Typography variant="h5" color="primary" style={{ fontFamily: "FuturaPTDemi" }}>{review.name}</Typography>
                        <Typography variant="body2" color="primary">{ calculateTimeDay(review.created_at) }</Typography>
                        <Typography variant="body1" color="primary" style={{ paddingTop: 10, overflowWrap: "anywhere" }}>{review.content}</Typography>
                    </Box>
                </Grid>
            </>
        )
    }

    return (
        <Grid container 
            direction='column'
            sx={{ minHeight: { xs: 200, md: 260 }, height: {xs: detail ? "100%" : 250, md: detail ? "100%" : 400 } }}
            style={{ 
                display: detail ? 'flex' : '', 
                flexDirection: detail ? 'column' : '', 
                justifyContent: 'space-between', 
                boxShadow: '0px 0px 12px 10px #0001', 
                border: "2px solid transparent" 
            }}
        >
            { detail ? reviewAll() : reviewSummary() }
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    avatarStyle: {
        lineHeight: '1.0 !important',
        textTransform: 'uppercase',
        margin: 0,
        backgroundColor: "#fff !important",
        color: theme.palette.secondary.main + '!important',
        border: "3px solid " + theme.palette.secondary.main + '!important',
        [theme.breakpoints.up('xs')]: {
            width: '40px !important',
            height: '40px !important',
            fontSize: '20px !important',
        },
        [theme.breakpoints.up('sm')]: {
            width: '70px !important',
            height: '70px !important',
            fontSize: '30px !important',
        },
    },
    avatarPosition: {
        [theme.breakpoints.up('xs')]: {
            top: '-20px !important',
        },
        [theme.breakpoints.up('sm')]: {
            top: '-35px !important',
        },
    },
    packageNameStyle: {
        lineHeight: "1 !important",
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 2,
        overflow: "hidden",
        // margin: '8px 0 !important',
        fontFamily: "FuturaPTBold !important",
        fontSize: "1.1rem !important",
    },
}))

export default ReviewCard