import React from 'react';
import { Typography, Link } from '@mui/material';
import { DEFAULT_EMAIL, LOCAL_URL } from '@configs/config';

const privacy_url = LOCAL_URL+"/policy?type=privacypolicy";

const TermCondition = () => {
    return (
        <div style={{ textAlign: 'justify' }}>
            <Typography style={{ textDecoration: "underline", fontFamily: 'FuturaPTBold' }}>Terms & Conditions</Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            We are thrilled to welcome you to the platform, which encompasses our website and mobile app. By accessing or utilizing the services provided on our platform, you acknowledge and agree to be bound by the following terms and conditions, referred to as the "Terms of Use". If you do not agree with any aspect of these Terms of Use, we ask that you refrain from using the services offered on the platform. We reserve the right to modify these Terms of Use at any time without prior notice and your continued use of the platform following any changes constitutes acceptance of the updated Terms of Use.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            By using our services, you are entering into a legal agreement with ReLuxe De Vacation Sdn Bhd ("we", "us", or "our") (Company No.: 201801006674/1268688-X), a company incorporated in Malaysia and having its registered address at B-1-22, Block B, Riverson Walks, Coastal Highway, 88100 Kota Kinabalu, Sabah, Malaysia. The Terms of Use outlined herein serve as the binding agreement between you and us. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Reviewing Our Terms of Use Policy</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            You may review the latest version of our Terms of Use at any time by visiting this page. We reserve the right to modify, change or replace any portion of these Terms of Use without prior notice and it is considered that you are aware and bound by any changes to these terms once they are posted on the platform. Your continued use or access to the platform after the publication of any changes indicates your acceptance of those changes.
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Eligibility</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            The minimum age required to use the platform is 13 years old. If you are below the age of majority in your state, your parent or guardian must agree to the Terms of Use and give permission for you to use the platform. To purchase through the platform, you must be 18 years old or above, have reached the age of majority in your state, and possess a valid credit card or another acceptable form of electronic payment. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Products and Services Use</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            The offerings on the platform, including any samples, are meant for personal use only and may not be sold or resold in any manner, either directly or indirectly. Utilizing our products for illegal or unauthorized purposes or violating laws while using our services is strictly prohibited (including, but not limited to, copyright laws). We hold the right to cancel or reduce the number of orders, with or without prior notice, if we believe, at our discretion, that they may violate our Terms of Use. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            In accordance with the restrictions outlined in the Terms of Use, it is strictly prohibited to utilize the Website or its content for any illegal, immoral, or obscene purposes, including but not limited to:             </Typography>
            <br />
            <ol style={{ listStyleType: 'lower-alpha', paddingLeft: 20, lineHeight: 1.5 }}>
                <li>Encouraging others to participate in any illegal activities;</li>
                <li>Breaking any international, federal, provincial, state, or local laws or regulations;</li>
                <li>Infringing on others' intellectual property, privacy, publicity, or other legal rights;</li>
                <li>Engaging in harassing, abusive, insulting, harmful, defamatory, slanderous, derogatory, intimidating behavior, or discrimination based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability, or posting or sharing any objectionable content;</li>
                <li>Providing false or misleading information;</li>
                <li>Uploading or transmitting viruses or malicious code that can affect the services, related websites, or the internet;</li>
                <li>Collecting or tracking the personal information of others;</li>
                <li>Phishing, pharming, pretexting, scraping, crawling, or otherwise collecting information from the Website;</li>
                <li>Interfering with the security features of the services or any related websites or the internet;</li>
                <li>Sending unsolicited or unauthorized advertising or commercial communications; or</li>
                <li>Assisting any third party in doing any of the above actions.</li>
            </ol>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            Any violation of the Terms of Use or engaging in the prohibited actions may result in the termination of your use of the services or any related platform. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Information Accuracy</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            We strive to provide accurate information about our products on the platform. However, we cannot guarantee that product descriptions, colors, images, or any other content on the platform are completely accurate, current, error-free, or reliable. The information provided on the platform is intended for general purposes only and should not be solely relied upon in making decisions. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            The information on the platform may contain historical data for reference purposes only and is not guaranteed to be current. We reserve the right to modify the contents of the platform at any time, but we are not obligated to continuously monitor changes. We are not obligated to update or clarify information on the platform, except as required by law. Any updates or refreshes made to the platform do not indicate that all information has been modified or updated. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Payment and Purchasing Information</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            You are required to provide up-to-date, accurate, and complete information when making purchases through the platform. This includes promptly updating your account details such as your email address and credit card information, including numbers and expiration dates. This ensures that your transactions can be completed efficiently and that we can contact you if needed. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            You grant us permission to handle, receive, and disburse funds as per your payment instructions. This authorization allows us to either (a) debit or credit your accepted debit card, credit card, or other payment methods, or (b) initiate recurring charges from your Cards if you 
            have subscribed to a service that requires automatic billing. By placing an order, you give us permission to secure payment on your behalf. Your authorizations will remain in full force and effect while you maintain your account with us. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Prices</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            Prices will be those in effect at the time of purchase (in Malaysia Ringgit) and unless otherwise specified, exclude taxes and shipping fees. Pricing is based upon product availability and is subject to correction or change at any time without notice. You are responsible for the payment of all applicable taxes. All products are subject to availability. The prices displayed on our platform may differ from prices that are available in stores, and our pricing may differ from store to store. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            We reserve the right to correct any errors, including, without limitation, publishing, descriptive, typographical, or any other similar errors. If you do not wish to continue your purchase after pricing or other information has been corrected, please contact us right away and we will work with you to cancel or return your order accordingly. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Booking Reservations</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            We have the right to accept, reject, refuse, or cancel any booking made through our platform at any time without prior notice. We may limit the number of rooms or bookings purchased by one customer, under the same account, with the same credit card, or using the same billing and shipping address. In the event that we change or cancel a booking, we may try to notify you via the email and/or phone number provided when the booking was made. We also reserve the right to limit or prohibit bookings made by dealers, resellers, or distributors. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            Our services and products may be restricted to certain individuals, regions, or jurisdictions. This decision may be made on a case-by-case basis. We also reserve the right to limit the availability of any product or service we offer. We may discontinue a product at any time and any offer made on our website is void where prohibited. We do not guarantee that the quality of any products, services, information, or material obtained through our website will meet your expectations or that any errors on the platform will be corrected. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Personal Information</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            Your privacy is important to us, and your submission of personal information through the platform is governed by our Privacy Policy. Our Privacy Policy is incorporated into, subject to, and made part of these Terms of Use. Please review our Privacy Policy at <Link href={privacy_url} target="_blank">Privacy Policy</Link>.
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Third-Party Content and Links</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            Certain materials, products, and services available on our platform may come from third-party sources. The links found on the platform are meant to be convenient but do not imply an endorsement from us, our affiliates, or our partners for the referenced material, product, service, or provider. We are not responsible for any harm or damages resulting from the purchase or use of goods, services, resources, content, or any other dealings with third-party websites. Before 
            engaging in any transaction with a third-party, please carefully review their policies and practices to ensure you fully understand them. Any complaints, claims, issues, or inquiries about third-party products should be directed to the third party directly. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Intellectual Property</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            All the information and material found on the platform, including but not limited to its design, trademarks, logos, service marks, slogans, product names, text, original artwork, graphics, button icons, photographs, images, audio and video clips, music, data compilations, software, and the overall organization of such content (referred to as "Content"), is protected by copyright, trademark, service mark, trade dress and other intellectual property rights held by Intellectual Property Corporation of Malaysia (MyIPO) and is subject to Malaysian laws. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            You are only allowed to access and use the services and related Content for personal, non-commercial purposes. Copying, downloading, reproducing, modifying, publishing, distributing, transmitting, transferring, or creating derivative works from any part of the services or content on any media without our prior written or electronic consent is strictly prohibited. The unauthorized use of trademarks, service marks, trade dresses, and copyrighted material on the website is also strictly prohibited. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>User Content</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            You are allowed to upload, submit, store, send, or receive content and data (referred to as "User Content") through the platform or to us through specific advertising campaigns or promotions. For example, we may ask you to respond to a campaign on the social media platform with a designated hashtag or with photos/videos. These responses are considered "User Content". 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            By agreeing to our package, resort/hotels or engaging in our services, or uploading, submitting, sending, or providing your User Content through the website or to us, you hereby acknowledge and agree to the following terms and conditions: 
            </Typography>
            <br />
            <ol style={{ listStyleType: 'lower-alpha', paddingLeft: 20, lineHeight: 1.5 }}>
                <li>You confirm that you have the legal rights and ownership of your User Content, including images, videos, and text, and that it does not infringe any intellectual property rights or violate the privacy or publicity rights of any third party.</li>
                <li>You grant us and our affiliates the permission to use your User Content, including reposting, sharing, copying, displaying, distributing, reproducing, modifying, creating derivative works of, or otherwise using it on the Website, our social media feeds, printings, email, display advertisements, and other marketing channels without any expiration of the permission.</li>
                <li>You understand and agree that your User Content may appear in our advertising and promotions and be publicly viewable.</li>
                <li>We are not obligated to compensate you for using your User Content now or in the future.</li>
                <li>We reserve the right to remove your User Content from any marketing channel or decide not to use it.</li>
                <li>You release us and our respective directors, officers, employees, representatives, successors, and assigns from all liabilities relating to your permission and our use of your User Content.</li>
                <li>You retain ownership of any intellectual property rights that you hold in your User Content.</li>
            </ol>
            <br />

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Warranty Disclaimer and Limitation of Liability</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            You are solely responsible for using the services and we do not guarantee that the services will meet your needs. Except as otherwise stated in these Terms of Use, the platform and all the content and services offered there, including without limitation any materials, products, or services, are provided "as is" and "as available" without any warranties, whether expressed or implied, including without limitation any implied warranties of merchantability, fitness for a particular purpose, non-infringement of intellectual property rights of a third party, title, custom, trade, peace, the accuracy of information content, or system integration. We also reject any liability for claims resulting from misuse, incorrect product selection, incorrect installation, product modification, incorrect repair, or misapplication. We do not guarantee that the services will be available or operate without interruption, error-free, or completely secure, or that errors or defects will be corrected. By using the services, you acknowledge and agree that your use is solely at your own risk.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            In some countries, laws do not permit the exclusion or limitation of implied warranties or the exclusion or limitation of liability for incidental or consequential damages. In such cases, some or all of the disclaimers, exclusions, or limitations set forth herein may not apply, and another right may be available. In such cases, the scope and duration of such warranty and the extent of our liability will be the minimum allowed by such law. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Indemnification</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            To the fullest extent permitted by applicable law, you agree to indemnify and hold us and our Affiliates harmless from any loss, liability, claim, or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms of Use, your violation of any law or the rights of a third party or your User Content. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Termination</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            The responsibilities and liabilities of both parties established before the agreement ends will remain in effect even after the termination of the agreement for all purposes. These terms are valid unless and until either you or us end them. You can stop following these terms at any time by informing us that you no longer want to use our services or by stopping to use our platform. If we believe that you have violated any of the terms of this agreement, we reserve the right to 
            terminate the agreement at any time without prior notice and you will be held responsible for all amounts owed until the date of termination. As a result, we may also restrict your access to our services. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Miscellaneous</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            We cannot be held responsible for any performance issues that occur as a result of circumstances beyond our control, including natural disasters, labor strikes, military conflicts, terrorism, government regulations, rebellions, outbreaks, quarantines, communication or power failures, fires, accidents, explosions, inability to obtain necessary permits and licenses, inability to obtain needed supplies, extreme weather conditions, or other unexpected events. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            If any part of these Terms of Use is found to be illegal or unenforceable, it will not affect the validity of the rest of the provisions and will still be enforceable to the fullest extent allowed by law. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            Our failure to enforce any part of these Terms of Use does not mean we give up the right to do so in the future. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            These Terms of Use, together with any additional policies or rules posted on our Website, make up the entire agreement between us and you, replacing any previous agreements or communications, written or spoken, between us (including previous versions of the Terms of Use).
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            In the event of any unclear interpretations of these Terms of Use, the interpretation will not be against the party who wrote the terms. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            The failure of us to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            Please check this page regularly for the latest version of the Terms and Conditions. <br/>
            For any questions or inquiries regarding our Terms and Conditions, please contact us by email at <Link href={`mailto:${DEFAULT_EMAIL}`}>{DEFAULT_EMAIL}</Link>.
            </Typography>
        </div>
    )
}

export default TermCondition;