import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Moment from 'moment';

import { Box, Button, CardMedia, Divider, Grid, Link, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IoStorefrontSharp } from "react-icons/io5";
import { FiAlertCircle, FiCircle, FiCheckCircle } from "react-icons/fi";

import { getUrl } from '@helpers/api';

const CartItemCard = (props) => {
    const { carts, loading, setLoading, inputErrors, setActiveStep, tourType, remarks, setRemarks, placeOrder, agentDiscount, systemPublicHoliday, autoApplyDiscountCode, setAutoApplyDiscountCode, discountData, setDiscountData } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { defaultCurrency } = useSelector(state => state.general);

    const [subTotal, setSubTotal] = useState(0);
    const [sumTotal, setSumTotal] = useState(0);
    const [selectedTotal, setSelectedTotal] = useState(0);
    const [agentDiscountAmount, setAgentDiscountAmount] = useState(0);
    const [pADiscountAmount, setPADiscountAmount] = useState(0);
    const [discountCode, setDiscountCode] = useState('');
    const [discountError, setDiscountError] = useState('');
    const [discountCodeDialogOpen, setDiscountCodeDialogOpen] = useState(false);

    useEffect(() => {
        calculateTotal(carts, agentDiscount);
        // eslint-disable-next-line
    }, [carts, discountData]);

    const calculateTotal = (carts, agentDiscount) => {
        let _subTotal = 0;
        let _selectedTotal = 0;
        let agentDiscountAmount = 0;
        let priceAdjustmentMin = 0;
        let priceAdjustmentDiscount = 0;
        _.map(carts, (agencyCartList, agencyId) => {
            _.map(agencyCartList.data, (data, package_id) => {
                _.map(data, (groupItem, booking_date) => {
                    if (groupItem.selected) {
                        _.map(groupItem.items, mainOption => {
                            // main option
                            if (mainOption.status) {
                                let price = parseFloat(mainOption.quantity * getSellPrice(mainOption.booking_date, mainOption.package_option.price, agencyCartList.agency_public_holidays));
                                _subTotal += price;
                                _selectedTotal += parseInt(mainOption.quantity);
                                if (_.size(discountData.agencies_ids) === 0 || _.includes(discountData.agencies_ids, (agencyId).toString())) {
                                    priceAdjustmentMin += price;
                                }
                            }
                        })
                        _.map(groupItem.add_on, add_on => {
                            _.map(add_on.items, addOnOption => {
                                let priceArray = _.size(addOnOption.add_on_price) > 0 ? addOnOption.add_on_price : addOnOption.package_option.price;
                                // add on option
                                if (addOnOption.status) {
                                    let price = parseFloat(addOnOption.quantity * getSellPrice(addOnOption.booking_date, priceArray, agencyCartList.agency_public_holidays));
                                    _subTotal += price;
                                    _selectedTotal += parseInt(addOnOption.quantity);
                                    if (_.size(discountData.agencies_ids) === 0 || _.includes(discountData.agencies_ids, (agencyId).toString())) {
                                        priceAdjustmentMin += price;
                                    }
                                }
                            })
                        })
                    }
                })
            })
        })
        if (priceAdjustmentMin >= discountData.min_spend_value) {
            if (discountData.type === 'percent') {
                priceAdjustmentDiscount = parseFloat(priceAdjustmentMin * parseFloat(discountData.value / 100));
            } else {
                priceAdjustmentDiscount = parseFloat(discountData.value);
            }
            if (discountData.capped_value > 0) {
                priceAdjustmentDiscount = Math.min(parseFloat(discountData.capped_value), parseFloat(priceAdjustmentDiscount));
            }
            if (discountData.action === 'deduct') {
                priceAdjustmentDiscount = 0 - priceAdjustmentDiscount;
            }
        }
        if (agentDiscount?.member_agent) {
            agentDiscountAmount += parseFloat(parseFloat((_subTotal + priceAdjustmentDiscount) * parseFloat(agentDiscount.discount_rate / 100)).toFixed(2));
        }
        setSubTotal(_subTotal);
        setSumTotal(_subTotal + priceAdjustmentDiscount - agentDiscountAmount);
        setAgentDiscountAmount(agentDiscountAmount);
        setPADiscountAmount(priceAdjustmentDiscount);
        setSelectedTotal(_selectedTotal);
    }

    const displayBookingPeriod = (booking_date, duration_period) => {
        let displayText = booking_date;
        if (duration_period > 1) {
            let endDate = new Date(booking_date);
            endDate.setDate(endDate.getDate() + (duration_period - 1));
            displayText = displayText + ' - ' + Moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()]).format('YYYY-MM-DD');
        }
        return displayText;
    }

    const getSellPrice = (booking_date, price, agency_public_holidays) => {
        let selectedPrice = price['ranks'][0]['weekday'];
        if (booking_date) {
            let publicHoliday = agency_public_holidays ? agency_public_holidays : systemPublicHoliday;
            let day = parseInt((new Date(booking_date)).getDay());
            if (_.find(publicHoliday, function (date) { return date === booking_date })) {
                selectedPrice = price['ranks'][0]['holiday'];
            } else if (day === 6 || day === 0) {
                selectedPrice = price['ranks'][0]['weekend'];
            } else {
                selectedPrice = price['ranks'][0]['weekday'];
            }
        }
        return selectedPrice;
    }

    const cartItemBox = (groupItem, agency_public_holidays) => {
        let addOn = false;
        _.map(groupItem.add_on, add_on => {
            if (_.size(add_on.items) > 0) {
                addOn = true;
            }
        })
        if (groupItem.selected && _.size(groupItem.items) > 0) {
            return (
                <Grid container spacing={2} className={classes.packageBox}>
                    <Grid item xs={12} md={2} className={`head ${classes.gridItemBoxCenter} ${classes.imgAlignTop}`} >
                        <CardMedia
                            component="img"
                            image={_.size(groupItem.package.images) > 0 ? groupItem.package.images[0]?.file_name : "../images/emptyStatus/no_image.png"}
                            sx={{ flex: 1, width: "100%", padding: 0 }}
                            alt={'package\'s image'}
                            className={classes.imgStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItemBoxLeft} style={{ paddingLeft: 10 }}>
                        <Link component={RouterLink} to={`/package/${groupItem.package.id}`} underline="none" className={classes.packageLink}>
                            <Typography variant="h6" style={{ fontWeight: "bold" }}>{groupItem.package.name}</Typography>
                        </Link>
                        {
                            groupItem.booking_date
                                ?
                                <Typography className={classes.bookingDateStyle}>{displayBookingPeriod(groupItem.booking_date, 1)}</Typography>
                                :
                                null
                        }
                        {
                            _.map(groupItem.items, mainOption => {
                                return (
                                    <Box key={mainOption.id}>
                                        {optionBox(mainOption, agency_public_holidays)}
                                    </Box>
                                )
                            })
                        }
                        <Box>
                            {
                                addOn &&
                                <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20 }}>
                                    {t('checkout.addOn') + ": "}
                                </Typography>
                            }
                            {
                                _.map(groupItem.add_on, (add_on, key) => {
                                    if (_.size(add_on.items) > 0) {
                                        return (
                                            <Box key={key}>
                                                <Typography variant="body1" style={{ fontWeight: "bold" }}>{add_on.display_name}</Typography>
                                                {_.map(add_on.items, addOnOption => {
                                                    return (
                                                        <Box key={addOnOption.id}>
                                                            {optionBox(addOnOption, agency_public_holidays)}
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        )
                                    }
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            )
        }
    }

    const optionBox = (option, agency_public_holidays) => {
        let priceArray = _.size(option.add_on_price) > 0 ? option.add_on_price : option.package_option.price;
        return (
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={6}>
                    <Typography className={option.status ? '' : classes.inactiveStyle}>{option.package_option.name}</Typography>
                    {
                        inputErrors['cart.' + option.id] ?
                            <Typography variant="caption" color="error">{inputErrors['cart.' + option.id]}</Typography>
                            : null
                    }
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body2" align="center">x{option.quantity}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <div style={{ margin: "auto !important", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        {
                            option.package_option.price['retail'] > 0 && (option.package_option.price['retail'] - getSellPrice(option.booking_date, priceArray, agency_public_holidays)) > 0
                                ?
                                <Typography variant="caption" style={{ textDecoration: "line-through" }}>
                                    {
                                        " " + defaultCurrency.symbol + " "
                                        + parseFloat((option.package_option.price['retail'] * defaultCurrency.rate) * option.quantity).toFixed(2)
                                    }
                                </Typography>
                                :
                                null
                        }
                        <Typography variant="body1" style={{ marginLeft: 10 }}>
                            {
                                " " + defaultCurrency.symbol + " "
                                + parseFloat((getSellPrice(option.booking_date, priceArray, agency_public_holidays) * defaultCurrency.rate) * option.quantity).toFixed(2)
                            }
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }

    const applyCode = () => {
        if (discountCode) {
            getUrl("/price-adjustment/manual-code", { code: discountCode, site: "member" }).then(response => {
                setLoading(false);
                if (response.status) {
                    if (_.find(autoApplyDiscountCode, function (c) { return c.id === response.data.price_adjustment.id; })) {
                        setDiscountError(t('checkout.discountCodeRepeat'));
                    } else {
                        setDiscountError('');
                        setDiscountCode('');
                        if (getVoucherValid(response.data.price_adjustment, false)) {
                            setDiscountData(response.data.price_adjustment);
                        }
                        addToast(response.message, { appearance: 'success' });
                        let tempAuto = autoApplyDiscountCode;
                        tempAuto[_.size(autoApplyDiscountCode)] = response.data.price_adjustment;
                        setAutoApplyDiscountCode(tempAuto);
                    }
                } else {
                    setDiscountError(response.message);
                }
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
    }

    const totalBox = () => {
        return (
            <Box className={classes.totalBox1}>
                <Grid container spacing={2} className={classes.totalBox2}>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>{t('checkout.discountCode') + ':'}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Button size="small" color="gray" onClick={() => setDiscountCodeDialogOpen(true)}>
                            {
                                _.size(discountData) > 0
                                    ?
                                    (discountData.title).length > 20 ?
                                        ((discountData.title).substring(0, 20) + '...  > ')
                                        :
                                        (discountData.title + '  > ')
                                    :
                                    (t('checkout.selectOrEnterCode') + '  > ')
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>{t('checkout.itemPriceSum', { num: selectedTotal }) + ':'}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>
                            {
                                " " + defaultCurrency.symbol + " "
                                + (parseFloat(subTotal) * defaultCurrency.rate).toFixed(2)
                            }
                        </Typography>
                    </Grid>
                    {/* {
                        agentDiscount.member_agent &&
                        <>
                            <Grid item xs={6} className={classes.gridItemBoxRight}>
                                <Typography>{t('checkout.agentDiscountWithPercent', { discount: agentDiscount.discount_rate }) + ':'}</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.gridItemBoxRight}>
                                <Typography>
                                    -
                                    {
                                        " " + defaultCurrency.symbol + " " 
                                            + (parseFloat(agentDiscountAmount) * defaultCurrency.rate).toFixed(2)
                                    }
                                </Typography>
                            </Grid>
                        </>
                    } */}
                    {
                        _.size(discountData) ?
                            <>
                                <Grid item xs={6} className={classes.gridItemBoxRight}>
                                    <Typography>{t('checkout.discountCodeAmount') + ':'}</Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.gridItemBoxRight}>
                                    <Typography>
                                        {
                                            (pADiscountAmount > 0 ? '' : '-')
                                            +
                                            " " + defaultCurrency.symbol + " "
                                            + (parseFloat(pADiscountAmount > 0 ? pADiscountAmount : -pADiscountAmount) * defaultCurrency.rate).toFixed(2)
                                        }
                                    </Typography>
                                </Grid>
                            </>
                            :
                            null
                    }
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>{t('checkout.totalSum') + ':'}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography className={classes.finalPriceStyle}>
                            {
                                " " + defaultCurrency.symbol + " "
                                + (parseFloat(sumTotal) * defaultCurrency.rate).toFixed(2)
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const bottomActionRow = () => {
        return (
            <Box className={classes.bottomActionStyle}>
                <Grid container spacing={2} className={classes.bottomActionBox}>
                    <Grid item xs={12} className={classes.gridItemBoxRight}>
                        {/* {
                            tourType
                                ?
                                <Button variant="contained" onClick={() => setActiveStep(2)} disabled={(!loading && _.size(carts) > 0) ? false : true}>
                                    {t('general.nextStep')}
                                </Button>
                                :
                                <Button variant="contained" onClick={placeOrder} disabled={(!loading && _.size(carts) > 0) ? false : true}>
                                    {t('checkout.placeOrder')}
                                </Button>
                        } */}
                        <Button variant="contained" onClick={() => setActiveStep(2)} disabled={(!loading && _.size(carts) > 0) ? false : true}>
                            {t('general.nextStep')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const handleDiscountCodeDialogClose = (clearDiscountData) => {
        if (clearDiscountData) {
            setDiscountData({});
        }
        setDiscountCode('');
        setDiscountError('');
        setDiscountCodeDialogOpen(false);
    }

    const getVoucherValid = (discount_code, getText) => {
        let _selectedTotal = 0;
        let selectedMerchant = false;
        let priceAdjustmentMin = 0;
        _.map(carts, (agencyCartList, agencyId) => {
            _.map(agencyCartList.data, (data, package_id) => {
                _.map(data, (groupItem, booking_date) => {
                    if (groupItem.selected) {
                        _.map(groupItem.items, mainOption => {
                            // main option
                            if (mainOption.status) {
                                let price = parseFloat(mainOption.quantity * getSellPrice(mainOption.booking_date, mainOption.package_option.price, agencyCartList.agency_public_holidays));
                                _selectedTotal += parseInt(mainOption.quantity);

                                if (_.size(discount_code.agencies_ids) === 0 || _.includes(discount_code.agencies_ids, (mainOption.package.agency_id).toString())) {
                                    selectedMerchant = true;
                                    priceAdjustmentMin += price;
                                }
                            }
                        })
                        _.map(groupItem.add_on, add_on => {
                            _.map(add_on.items, addOnOption => {
                                let priceArray = _.size(addOnOption.add_on_price) > 0 ? addOnOption.add_on_price : addOnOption.package_option.price;
                                // add on option
                                if (addOnOption.status) {
                                    let price = parseFloat(addOnOption.quantity * getSellPrice(addOnOption.booking_date, priceArray, agencyCartList.agency_public_holidays));
                                    _selectedTotal += parseInt(addOnOption.quantity);

                                    if (_.size(discount_code.agencies_ids) === 0 || _.includes(discount_code.agencies_ids, (addOnOption.package.agency_id).toString())) {
                                        selectedMerchant = true;
                                        priceAdjustmentMin += price;
                                    }
                                }
                            })
                        })
                    }
                })
            })
        })
        if (!_selectedTotal) {
            return getText ? t('checkout.errorSelectQuantity') : false;
        }
        if (!selectedMerchant) {
            return getText ? t('checkout.errorSelectMerchant') : false;
        }
        if (priceAdjustmentMin < discount_code.min_spend_value) {
            return getText ? t('checkout.errorSpendMin', { min: defaultCurrency.symbol + ' ' + parseFloat((discount_code.min_spend_value - priceAdjustmentMin) * defaultCurrency.rate).toFixed(2) }) : false;
        }
        return getText ? '' : true;
    }

    const discountCodeDialog = () => {
        return (
            <Dialog open={discountCodeDialogOpen} onClose={() => handleDiscountCodeDialogClose(true)} fullWidth={true} maxWidth="sm">
                <DialogTitle>{t('checkout.selectOrEnterCode')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box style={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
                                <Box style={{ width: "70%" }}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        name="code"
                                        value={discountCode}
                                        onChange={(event) => setDiscountCode(event.target.value)}
                                    />
                                    {
                                        discountError
                                            ?
                                            <Typography variant="caption" color="error">{discountError}</Typography>
                                            :
                                            null
                                    }
                                </Box>
                                <Box style={{ width: "30%" }}>
                                    <Button variant="outlined" size="medium" onClick={() => applyCode()} disabled={discountCode ? false : true} style={{ margin: "0px 5px" }}>
                                        {t('checkout.applyCode')}
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{t('checkout.discountVoucher')}</Typography>
                        </Grid>
                        {
                            _.map(autoApplyDiscountCode, dcode => {
                                return (
                                    <Grid item xs={12} key={dcode.id}>
                                        <Box className={getVoucherValid(dcode, false) ? (discountData.id === dcode.id ? classes.selectedDiscountVoucherBox : classes.discountVoucherBox) : classes.invalidDiscountVoucherBox} onClick={getVoucherValid(dcode, false) ? () => setDiscountData(dcode) : null} style={{ wdith: "100%", display: "flex", alignItems: "center" }}>
                                            <Box style={{ width: "85%" }}>
                                                <Typography variant="body1" style={{ fontWeight: "bold" }}>{dcode.title}</Typography>
                                                {
                                                    dcode.min_spend_value > 0
                                                        ?
                                                        <Typography variant="caption" style={{ display: "block", lineHeight: 1.2 }}>{t('checkout.minSpend') + ' ' + defaultCurrency.symbol + ' ' + parseFloat(dcode.min_spend_value * defaultCurrency.rate).toFixed(2)}</Typography>
                                                        :
                                                        null
                                                }
                                                {
                                                    dcode.end_date
                                                        ?
                                                        <Typography variant="caption" style={{ display: "block", lineHeight: 1.2 }}>{t('checkout.validTill') + ': ' + dcode.end_date}</Typography>
                                                        :
                                                        null
                                                }
                                            </Box>
                                            <Box style={{ width: "15%", display: "flex", justifyContent: "center" }}>
                                                <Checkbox
                                                    checked={discountData.id === dcode.id ? true : false}
                                                    icon={<FiCircle size={20} />}
                                                    checkedIcon={<FiCheckCircle size={20} color="white" />}
                                                    disabled={getVoucherValid(dcode, false) ? false : true}
                                                    onChange={getVoucherValid(dcode, false) ? () => setDiscountData(dcode) : null}
                                                />
                                            </Box>
                                        </Box>
                                        {
                                            getVoucherValid(dcode, true)
                                                ?
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    <FiAlertCircle color="red" style={{ marginRight: "5px" }} />
                                                    <Typography variant="caption" color="error">{getVoucherValid(dcode, true)}</Typography>
                                                </Box>
                                                :
                                                null
                                        }
                                    </Grid>
                                )
                            })
                        }
                        {
                            _.size(discountData) ?
                                <Grid item xs={12} className={classes.gridItemBoxRight}>
                                    <Typography variant="h6">
                                        {t('checkout.discountCodeAmount') + ': ' + (pADiscountAmount > 0 ? '' : '-') + " " + defaultCurrency.symbol + " " + (parseFloat(pADiscountAmount > 0 ? pADiscountAmount : -pADiscountAmount) * defaultCurrency.rate).toFixed(2)}
                                    </Typography>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => handleDiscountCodeDialogClose(false)}>{t('general.ok')}</Button>
                    <Button variant="outlined" color="primary" onClick={() => handleDiscountCodeDialogClose(true)}>{t('general.cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Box className={classes.outlineBox}>
            <Typography variant="h5" style={{ marginBottom: 10 }}>{t('checkout.checkoutItem')}</Typography>
            {
                _.size(carts) > 0
                    ?
                    <>
                        {
                            _.map(carts, (agencyCartList, agencyId) => {
                                return (
                                    <Box className={classes.cartContainer} key={agencyId}>
                                        <Box className={classes.cartAgencyBox}>
                                            <Typography className={classes.cartAgencyTitle}><IoStorefrontSharp style={{ marginRight: 10 }} /> {agencyCartList.name}</Typography>
                                        </Box>
                                        <Box>
                                            {
                                                _.map(agencyCartList.data, (data1, package_id) => (
                                                    _.map(data1, (groupItem, booking_date) => (
                                                        <Box key={booking_date}>
                                                            {cartItemBox(groupItem, agencyCartList.public_holidays)}
                                                        </Box>
                                                    ))
                                                ))
                                            }
                                        </Box>
                                        <Box style={{ padding: "5px 16px" }}>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                label={t('checkout.remark')}
                                                placeholder={t('checkout.remarkNote')}
                                                name="remark"
                                                value={remarks[agencyId] ? remarks[agencyId] : ''}
                                                onChange={(event) => setRemarks({ ...remarks, [agencyId]: event.target.value })}
                                                error={inputErrors?.remarks?.[agencyId] ? true : false}
                                                helperText={inputErrors?.remarks?.[agencyId] || ''}
                                            />
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </>
                    :
                    <Box style={{ marginTop: 20, textAlign: "center" }}>
                        <Typography variant="body2" style={{ marginBottom: 10 }}>{t('checkout.noSelectedCart')}</Typography>
                        <Link component={RouterLink} to={`/cart`} underline="none">
                            <Button variant="contained">{t('checkout.goToCart')}</Button>
                        </Link>
                    </Box>
            }
            {totalBox()}
            {/* {bottomActionRow()} */}
            {discountCodeDialog()}
        </Box>
    )
}

export default CartItemCard;

const useStyles = makeStyles((theme) => ({
    cartContainer: {
        border: 'none',
        borderRadius: 20,
        borderColor: theme.palette.gray.secondary,
        overflow: 'hidden',
        boxShadow: '0px 5px 6px 0 #0000001f',
        marginBottom: 15
    },
    cartAgencyBox: {
        backgroundColor: theme.palette.gray.superLight + ' !important',
    },
    cartAgencyTitle: {
        // color: theme.palette.primary.main + ' !important',
        fontWeight: "bold !important",
        padding: "16px"
    },
    packageBox: {
        padding: "16px",
        borderBottom: "dashed 1px " + theme.palette.gray.light,
    },
    imgStyle: {
        objectFit: "contain !important",
        maxHeight: "150px",
    },
    imgAlignTop: {
        padding: '0 !important',
        margin: 'unset !important',
        marginTop: '20px!important',
    },
    gridItemBoxCenter: {
        margin: "auto !important",
        textAlign: 'center',
    },
    gridItemBoxLeft: {
        margin: "auto !important",
        textAlign: 'left',
    },
    gridItemBoxRight: {
        margin: "auto !important",
        textAlign: 'right',
    },
    bottomActionStyle: {
        // backgroundColor: theme.palette.primary.secondary + ' !important',
        margin: "50px 0px 20px 0px !important",
        borderRadius: 5,
    },
    bottomActionBox: {
        padding: "0px 16px 16px 16px",
        color: theme.palette.primary.main + ' !important',
        fontWeight: "bold !important",
    },
    totalBox1: {
        marginTop: "20px !important",
        border: 'none',
        borderRadius: 20,
        overflow: 'hidden',
        boxShadow: 'rgb(223 223 223) 0px 0px 14px 0px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    totalBox2: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "450px !important",
        maxWidth: '100%',
        padding: 16
    },
    finalPriceStyle: {
        color: theme.palette.primary.main,
        fontWeight: "bold !important",
        fontSize: "1.2rem !important"
    },
    outlineBox: {
        margin: "20px 0px 20px 0px !important"
    },
    optionStyle: {
        backgroundColor: theme.palette.primary.background + ' !important',
        color: theme.palette.primary.secondary + ' !important',
        borderRadius: 20,
        padding: "5px 10px",
        width: "fit-content",
        fontSize: "0.8rem !important",
    },
    bookingDateStyle: {
        color: theme.palette.primary.secondary + ' !important',
        padding: "5px 10px 5px 0px",
        fontSize: "1.0rem !important",
        margin: "5px 5px 5px 0px !important",
    },
    discountVoucherBox: {
        backgroundColor: theme.palette.primary.background + ' !important',
        border: "1px solid " + theme.palette.primary.main,
        padding: "3%",
        cursor: "pointer",
    },
    selectedDiscountVoucherBox: {
        backgroundColor: theme.palette.primary.main + ' !important',
        border: "1px solid " + theme.palette.primary.main,
        padding: "3%",
        cursor: "pointer",
        color: "white !important",
    },
    invalidDiscountVoucherBox: {
        backgroundColor: theme.palette.primary.background + ' !important',
        border: "1px solid " + theme.palette.primary.main,
        padding: "3%",
        opacity: "0.5"
    },
}))