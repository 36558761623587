import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Moment from 'moment';

import { Box, Button, Container, Divider, Grid, Link, Paper, LinearProgress, Rating, TextField, Typography, IconButton, Hidden, ImageList, ImageListItem, useMediaQuery, Accordion, AccordionSummary, AccordionDetails, Fab, Dialog, DialogTitle, DialogContent, Collapse, Menu, MenuItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiMinus, FiPlus, FiX } from "react-icons/fi";
import { BsFlagFill } from "react-icons/bs";
import { Star, ExpandMore } from '@mui/icons-material';
import { MdFavoriteBorder, MdFavorite } from 'react-icons/md';
import { HiArrowNarrowRight } from "react-icons/hi";
import { BiCalendar } from 'react-icons/bi';
import { MdExpandLess, MdExpandMore } from "react-icons/md";

import { updateCartTotal } from '@actions/generalActions';
import { getUrl, postUrl, deleteUrl } from '@helpers/api';
import BackToPrevious from '@components/BackToPrevious';
import PackageList from '@components/PackageList';
import ReviewCard from '@components/ReviewCard';
import CustomizeCalendar from '@components/CustomizeCalendar';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// Import required modules
import { Pagination as SwiperPagination, EffectCoverflow, FreeMode, Navigation, Thumbs } from "swiper";

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const Detail = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const { uuid, accessToken, defaultCurrency, country } = useSelector(state => state.general);
    const userState = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    let { id } = useParams();
    const [packageItem, setPackageItem] = useState({});
    const [suggestPackageList, setSuggestPackageList] = useState({});
    const [systemPublicHoliday, setSystemPublicHoliday] = useState({});
    const [dates, setDates] = useState({
        // start: new Date((new Date()).valueOf() + 86400000),
        start: '',
        end: '',
    });
    const [state, setState] = useState({
        options: [],
        booking_date: '',
        like_package: false,
        add_on_package: [],
    });
    const [gallery, setGallery] = useState({});
    const [displayPrice, setDisplayPrice] = useState(0);
    const [optionDisabledDates, setOptionDisabledDates] = useState([]);
    const [packageDisabledDates, setPackageDisabledDates] = useState([]);
    const [packageDisabledSetting, setPackageDisabledSetting] = useState({
        from_date: '',
        to_date: '',
    });
    const [calanderPopup, setCalanderPopup] = useState(false);

    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setLoading(true);
        getUrl(`/member-package/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setPackageItem(response.data.package);
                setGallery(response.data.gallery);
                setSystemPublicHoliday(response.data.system_public_holidays);
                let options = [];
                if (_.size(response.data.package.package_options) > 0) {
                    _.map(response.data.package.package_options, option => {
                        if (parseInt(option.status) === 1) {
                            options.push({ id: option.id, quantity: 0, name: option.name });
                        }
                    })
                }
                let packageLiked = false;
                if (_.size(response.data.wishlist) > 0) {
                    packageLiked = true;
                }
                // set packageDisabledSetting
                let disabledFrom = new Date();
                if (response.data.package.valid_date_from) {
                    let compare_date = new Date(response.data.package.valid_date_from);
                    if (compare_date.getTime() > disabledFrom.getTime()) {
                        disabledFrom = compare_date;
                    }
                }
                let disabledTo = new Date((new Date()).valueOf() + 86400000 * 365);
                if (response.data.package.valid_date_to) {
                    let compare_date = new Date(response.data.package.valid_date_to);
                    if (compare_date.getTime() < disabledTo.getTime()) {
                        disabledTo = compare_date;
                    }
                }
                setPackageDisabledSetting({
                    from_date: disabledFrom ? disabledFrom : '',
                    to_date: disabledTo ? disabledTo : '',
                });
                // set add on quantity
                let add_on_package = {};
                if (_.size(response.data.package.add_on_packages) > 0) {
                    _.map(response.data.package.add_on_packages, add_on => {
                        let add_on_option = [];
                        _.map(add_on.package.package_options, option => {
                            if (option.status === 1) {
                                add_on_option.push({ id: option.id, quantity: 0, name: option.name });
                            }
                        })
                        add_on_package[add_on.id] = add_on_option;
                    })
                }
                setState({
                    booking_date: Moment(new Date().setDate(new Date(disabledFrom).getDate() + 1)).format('YYYY-MM-DD'),
                    options: options,
                    like_package: packageLiked,
                    add_on_package: add_on_package,
                });
                setDates({
                    start: new Date().setDate(new Date(disabledFrom).getDate() + 1),
                    end: '',
                });
                // get disabled date
                if (response.data.package.id && disabledFrom && disabledTo) {
                    let data = {
                        package_id: response.data.package.id,
                        start_date: Moment(disabledFrom).format('YYYY-MM-DD'),
                        end_date: Moment(disabledTo).format('YYYY-MM-DD'),
                    };
                    getUrl(`/package-disabled`, data).then(response => {
                        if (response.status) {
                            setOptionDisabledDates(response.data.listing);
                            calculateDisabledDates(options, add_on_package, response.data.listing);
                        }
                    }).catch(err => {
                        addToast(JSON.stringify(err), { appearance: 'error' });
                    })
                }
            } else {
                addToast(response.message, { appearance: 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })

        getUrl(`/suggest_package`, { limit: 10, except_id: [id] }).then(response => {
            if (response.status) {
                setSuggestPackageList(response.data.package_list);
            }
        }).catch(err => {
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        calculateTotalPrice(state.options, state.add_on_package);
        // eslint-disable-next-line
    }, [state.booking_date, packageItem, systemPublicHoliday]);

    useEffect(() => {
        if ((state.booking_date !== '') && _.find(packageDisabledDates, new Date(state.booking_date))) {
            setState({ ...state, booking_date: '' });
            setDates({
                start: '',
                end: '',
            });
        }
        // eslint-disable-next-line
    }, [packageDisabledDates]);

    const calculateTotalPrice = (selectedOptions, selectedAddOnOptions) => {
        let selectedTotalPrice = 0;
        let agency_public_holidays = packageItem.agency ? packageItem.agency.public_holidays : [];
        if (state.booking_date) {
            // calculate package option price
            _.map(packageItem.package_options, option => {
                _.map(selectedOptions, stateOption => {
                    if (stateOption.quantity > 0 && parseInt(option.id) === parseInt(stateOption.id)) {
                        selectedTotalPrice += stateOption.quantity * getSellPrice(state.booking_date, option.price, agency_public_holidays);
                    }
                })
            })
            // calculate add on price
            _.map(selectedAddOnOptions, (options, add_on_id) => {
                let add_on = _.find(packageItem.add_on_packages, { id: parseInt(add_on_id) });
                _.map(options, option => {
                    if (option.quantity > 0) {
                        let add_on_option = _.find(add_on.package.package_options, { id: parseInt(option.id) });
                        let addOnPrice = add_on.option_prices[option.id] ? add_on.option_prices[option.id] : add_on_option.price;
                        selectedTotalPrice += option.quantity * getSellPrice(state.booking_date, addOnPrice, agency_public_holidays);
                    }
                })
            })
        }
        setDisplayPrice(selectedTotalPrice);
    }

    const getSellPrice = (booking_date, price, agency_public_holidays) => {
        let selectedPrice = price['ranks'][0]['weekday'];
        if (booking_date) {
            let publicHoliday = agency_public_holidays ? agency_public_holidays : systemPublicHoliday;
            let day = parseInt((new Date(booking_date)).getDay());
            if (_.find(publicHoliday, function (date) { return date === booking_date })) {
                selectedPrice = price['ranks'][0]['holiday'];
            } else if (day === 6 || day === 0) {
                selectedPrice = price['ranks'][0]['weekend'];
            } else {
                selectedPrice = price['ranks'][0]['weekday'];
            }
        }
        return selectedPrice;
    }

    const getAddOnPrice = (booking_date, add_on_package, agency_public_holidays) => {
        let addOnSum = 0;
        _.map(add_on_package, (options, add_on_id) => {
            let add_on = _.find(packageItem.add_on_packages, { id: parseInt(add_on_id) });
            _.map(options, option => {
                let add_on_option = _.find(add_on.package.package_options, { id: parseInt(option.id) });
                let addOnPrice = add_on.option_prices[option.id] ? add_on.option_prices[option.id] : add_on_option.price;
                addOnSum += option.quantity * getSellPrice(booking_date, addOnPrice, agency_public_holidays);
            })
        })
        return addOnSum;
    }

    const calculateDisabledDates = (stateOption, addOnPackage, optionDisabled) => {
        let tempDisabled = [];
        let disabledDate = [];
        if (packageItem.v_except_date) {
            disabledDate = packageItem.v_except_date;
        }
        if (optionDisabled) {
            _.map(optionDisabled, optionDates => {
                let quantity = 0;
                _.map(stateOption, option => {
                    if (option.id === optionDates.id) {
                        quantity = option.quantity;
                    }
                })
                _.map(addOnPackage, options => {
                    _.map(options, option => {
                        if (option.id === optionDates.id) {
                            quantity = option.quantity;
                        }
                    })
                })
                if (quantity > 0 && optionDates.disabled_date) {
                    disabledDate = _.union(disabledDate, optionDates.disabled_date);
                }
            })
        }
        if (_.size(disabledDate) > 0) {
            _.map(disabledDate, date => {
                tempDisabled.push(new Date(date));
            })
        }
        setPackageDisabledDates(tempDisabled);
    }

    const handleQuantityChange = (type, quantity, option_id, packageType) => {
        let newQuantity = parseInt(quantity);
        if (packageType === 'add-on') {
            let addOnOption = state.add_on_package;
            _.map(addOnOption, (options, add_on_id) => {
                _.map(options, (option, key) => {
                    if (option.id === option_id) {
                        if (type === 'quantity') {
                            addOnOption[add_on_id][key]['quantity'] = newQuantity;
                        } else if (type === 'add') {
                            addOnOption[add_on_id][key]['quantity'] = option.quantity + newQuantity;
                        } else if (type === 'deduct') {
                            addOnOption[add_on_id][key]['quantity'] = (option.quantity - newQuantity) > 0 ? (option.quantity - newQuantity) : 0;
                        }
                    }
                })
            })
            setState({ ...state, add_on_package: addOnOption });
            calculateTotalPrice(state.options, addOnOption);
            calculateDisabledDates(state.options, addOnOption, optionDisabledDates);
        } else {
            let stateOption = state.options;
            _.map(stateOption, (option, key) => {
                if (option.id === option_id) {
                    if (type === 'quantity') {
                        stateOption[key]['quantity'] = newQuantity;
                    } else if (type === 'add') {
                        stateOption[key]['quantity'] = option.quantity + newQuantity;
                    } else if (type === 'deduct') {
                        stateOption[key]['quantity'] = (option.quantity - newQuantity) > 0 ? (option.quantity - newQuantity) : 0;
                    }
                }
            })
            setState({ ...state, options: stateOption });
            calculateTotalPrice(stateOption, state.add_on_package);
            calculateDisabledDates(stateOption, state.add_on_package, optionDisabledDates);
        }
    }

    const handleToLoginPage = () => {
        navigate("/sign-in");
    }

    const handleSaveToWishlist = () => {
        setLoading(true);

        let postData = {
            package_id: id,
        };

        postUrl('/wishlist/package', postData).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, like_package: true });
                addToast(response.message, { appearance: 'success' });
            } else {
                let msg = response.message;
                if (response.errors) {
                    _.map(response.errors, error => {
                        msg = msg + ' ' + error;
                    })
                }
                addToast(msg, { appearance: 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const handleRemoveFromWishlist = () => {
        setLoading(true);

        deleteUrl(`/wishlist/package/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                addToast(response.message, { appearance: 'success' });
            } else {
                let msg = response.message;
                if (response.errors) {
                    _.map(response.errors, error => {
                        msg = msg + ' ' + error;
                    })
                }
                addToast(msg, { appearance: 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })

        setState({ ...state, like_package: false });
    }

    const addToCart = (buyNow) => {
        setLoading(true);

        let postData = {
            package_id: id,
            // option_id: state.option_id,
            // quantity: state.quantity,
            options: state.options,
            guest_token: uuid ? uuid : null,
            buy_now: buyNow,
            booking_date: packageItem.type === 'tour' ? state.booking_date : null,
            add_on_package: state.add_on_package,
        };

        postUrl(`/carts`, postData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(updateCartTotal(response.cart_count));
                if (buyNow) {
                    navigate(`/checkout`, { replace: true });
                } else {
                    addToast(response.message, { appearance: 'success' });
                }
            } else {
                let msg = response.message;
                if (response.errors) {
                    _.map(response.errors, error => {
                        msg = msg + ' ' + error;
                    })
                }
                addToast(msg, { appearance: 'error' });
                backToSelectPackage();
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const checkTime = (buyNow) => {
        let currTime = new Date();
        let nextTime = new Date();
        nextTime.setDate(currTime.getDate() + 1);
        let displayDate = Moment(nextTime).format('YYYY-MM-DD');

        if (currTime.getHours() < 20) { addToCart(buyNow); }
        else if (currTime.getHours() >= 20) {
            if (displayDate !== state.booking_date) { addToCart(buyNow); }
            else { addToast(t('voucher.timeout'), { appearance: 'error' }); }
        }
        else { addToast(t('voucher.timeout'), { appearance: 'error' }); }
    }

    const getOptionsText = () => {
        let optionsText = '-';
        _.map(state.options, option => {
            if (option.quantity > 0) {
                let thisOptionName = option.quantity + ' ' + option.name;
                optionsText = optionsText !== '-' ? optionsText + '\n' + thisOptionName : thisOptionName;
            }
        })
        return optionsText;
    }

    const getAddOnText = () => {
        let addOnsText = '-';
        _.map(state.add_on_package, (options, add_on_id) => {
            let add_on_selected = false;
            _.map(options, option => {
                if (option.quantity > 0) {
                    add_on_selected = true;
                }
            })
            if (add_on_selected) {
                let add_on = _.find(packageItem.add_on_packages, { id: parseInt(add_on_id) });
                let addOnName = add_on.display_package_name ? add_on.display_package_name : add_on.package.name;
                addOnsText = addOnsText !== '-' ? addOnsText + ', ' + addOnName : addOnName;
            }
        })
        return addOnsText;
    }

    const clearData = () => {
        let tempState = state;
        tempState.booking_date = '';
        _.map(tempState.options, (option, key) => {
            tempState.options[key].quantity = 0;
        })
        _.map(tempState.add_on_package, (options, key) => {
            _.map(options, (option, key2) => {
                tempState.add_on_package[key][key2].quantity = 0;
            })
        })
        setState({ ...tempState });
    }

    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [imageSliderOpen, setImageSliderOpen] = useState(false);
    const [imageSliderNumber, setImageSliderNumber] = useState(1);
    const handleImageSliderOpen = () => {
        setImageSliderOpen(true);
        setImageSliderNumber(1);
    }
    const imageSlider = () => {
        let video_url = "";
        if (_.size(packageItem.images) > 0 || packageItem.youtube_video_url !== null || packageItem.youku_video_url !== null) {
            const itemData = [];
            // detect show youtube or youku video
            if (packageItem.youtube_video_url !== null || packageItem.youku_video_url !== null) {
                if (packageItem.youtube_video_url === null || country === 'CN') {
                    video_url = (packageItem.youku_video_url)?.split('/');
                    video_url = (video_url[video_url.length - 1])?.split(/(id_)|(.html)/);
                    video_url = 'https://player.youku.com/embed/' + video_url[3];
                } else {
                    video_url = (packageItem.youtube_video_url)?.split('/');
                    video_url = 'https://www.youtube.com/embed/' + video_url[video_url.length - 1];
                }
            }
            // set image data
            if (_.size(packageItem.images) > 0) {
                packageItem.images.map((item, key) => {
                    if ((video_url === '' && key < 5) || key < 4) {
                        itemData.push({
                            img: item.file_name,
                            rows: null,
                            cols: null
                        })
                    }
                });
            }
            return (
                <Box>
                    <Hidden mdUp>
                        <Box style={{ position: "relative", margin: "0% 0% 3%" }}>
                            <Swiper
                                className={`mySwiper3`}
                                slidesPerGroup={1}
                                spaceBetween={10}
                                loop={false}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                }}
                                navigation
                                modules={[Navigation]}
                                style={{ height: 300 }}
                            >
                                {
                                    video_url !== ''
                                        ?
                                        <SwiperSlide>
                                            <iframe
                                                src={video_url}
                                                width="100%"
                                                height="100%"
                                                style={{ paddingRight: matchDownSm ? 0 : 5, paddingBottom: matchDownSm ? 5 : 0 }}
                                                title="Package"
                                                allowFullScreen
                                            />
                                        </SwiperSlide>
                                        :
                                        null
                                }
                                {
                                    _.map(itemData, (image, key) => {
                                        return (
                                            <SwiperSlide key={key}>
                                                <img alt="package image" src={image.img} className="center" />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                            <Box style={{ display: 'flex', justifyContent: "flex-end", position: "absolute", right: "10px", bottom: "10px", zIndex: "1" }}>
                                <Button variant="outlined" color="primary" onClick={() => handleImageSliderOpen()} style={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                                    {t("package.viewImages", { count: _.size(packageItem.images) })}
                                </Button>
                            </Box>
                        </Box>
                    </Hidden>
                    <Hidden mdDown>
                        <Container maxWidth="xl" style={{ padding: "0% 0% 3%" }}>
                            <Box style={{ position: "relative" }}>
                                <Grid container style={{ display: 'flex', flexDirection: matchDownSm ? 'column' : 'row' }}>
                                    {
                                        video_url !== '' &&
                                        <Grid item xs={12} sm={_.size(packageItem.images) < 0 ? 12 : 6} >
                                            <iframe
                                                src={video_url}
                                                width="100%"
                                                height="100%"
                                                style={{ paddingRight: matchDownSm ? 0 : 5, paddingBottom: matchDownSm ? 5 : 0 }}
                                                title="Package"
                                                allowFullScreen
                                            />
                                        </Grid>
                                    }
                                    {
                                        _.size(packageItem.images) > 0 &&
                                        <Grid item xs={12} sm={(_.size(packageItem.images) >= 1 && video_url == "") ? 12 : 6}>
                                            {
                                                _.size(packageItem.images) === 1 ?
                                                    <div style={{ display: 'flex', justifyContent: 'center', backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url('${packageItem.images[0].file_name}')`, backgroundPosition: 'center', backgroundRepeat: ' no-repeat', backgroundSize: 'cover' }}>
                                                        <img src={packageItem.images[0].file_name} style={{ width: 615, height: 405, objectFit: 'cover', aspectRatio: '1/1' }} />
                                                    </div>
                                                    :
                                                    <ImageList
                                                        // sx={{ height: 350 }}
                                                        variant="quilted"
                                                        cols={4}
                                                        rowHeight={200}
                                                    >
                                                        {itemData.map((item, key) => (
                                                            <ImageListItem
                                                                key={item.img}
                                                                rows={item.rows ? item.rows : ((key === 0 && video_url == "") ? 2 : 1)}
                                                                cols={item.cols ? item.cols : ((key === 0 && video_url == "") || video_url !== "" ? 2 : 1)}
                                                            >
                                                                <img
                                                                    {...srcset(item.img, 120, item.rows, item.cols)}
                                                                    alt="Package"
                                                                    loading="lazy"
                                                                />
                                                            </ImageListItem>
                                                        ))}
                                                    </ImageList>
                                            }
                                        </Grid>
                                    }
                                </Grid>
                                <Box style={{ display: 'flex', justifyContent: "flex-end", marginTop: 5, position: "absolute", right: "10px", bottom: "10px" }}>
                                    <Button variant="outlined" color="primary" onClick={() => handleImageSliderOpen()} style={{ backgroundColor: "rgba(0,0,0,0.7)" }}>
                                        {t("package.viewImages", { count: _.size(packageItem.images) })}
                                    </Button>
                                </Box>
                            </Box>
                        </Container>
                    </Hidden>
                </Box>
            )
        } else {
            return null;
        }

    };
    const imagePopup = () => {
        return (
            <Dialog
                open={imageSliderOpen}
                onClose={() => {
                    setImageSliderOpen(false);
                    setThumbsSwiper(null);
                }}
                fullWidth={true}
                maxWidth={'md'}
            >
                <DialogTitle style={{ display: "flex", justifyContent: "flex-end" }}>
                    <IconButton onClick={() => { setImageSliderOpen(false); setThumbsSwiper(null); }}><FiX /></IconButton>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <Swiper
                            className={`mySwiper2 ${classes.imageSwiper2}`}
                            slidesPerGroup={1}
                            navigation={true}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                            }}
                            thumbs={{ swiper: thumbsSwiper }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            onSlideChange={(swiper) => setImageSliderNumber(swiper.activeIndex + 1)}
                        >
                            {
                                _.map(packageItem.images, (image, key) => {
                                    return (
                                        <SwiperSlide key={key} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                                        >
                                            <img alt="package image" src={image.file_name} className="center" />
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                        <Box style={{ display: "flex", justifyContent: "center", margin: 5 }}>
                            <Typography variant="h6">{imageSliderNumber}/{_.size(packageItem.images)}</Typography>
                        </Box>
                        <Swiper
                            className={`mySwiper ${classes.imageSwiper}`}
                            spaceBetween={10}
                            breakpoints={{
                                0: {
                                    slidesPerView: 4,
                                },
                            }}
                            modules={[FreeMode, Navigation, Thumbs]}
                            freeMode={true}
                            watchSlidesProgress={true}
                            onSwiper={setThumbsSwiper}
                        >
                            {
                                _.map(packageItem.images, (image, key) => {
                                    return (
                                        <SwiperSlide key={key} >
                                            <img alt="package image" src={image.file_name} className="center" />
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    const packageHeader = () => {
        return (
            <Grid container style={{ marginBottom: "3%" }}>
                <Grid item xs={10}>
                    <Typography variant="h4" style={{ fontFamily: "FuturaPTDemi" }}>
                        {packageItem.name}
                    </Typography>
                    {
                        packageItem.average_rating && packageItem.total_reviews
                            ?
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Star color="primary" />
                                <Typography variant="body" style={{ color: theme.palette.primary.main, paddingLeft: 8, paddingTop: 3, lineHeight: 1 }}>{(packageItem.average_rating).toFixed(1)}</Typography>
                                <Typography variant="body2" style={{ color: theme.palette.primary.main, paddingLeft: 8, paddingTop: 3, lineHeight: 1 }}>({t('package.reviewsCount', { count: packageItem.total_reviews })})</Typography>
                            </Box>
                            :
                            null
                    }
                </Grid>
                {
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'right' }}>
                        <IconButton onClick={userState.id ? (state.like_package ? handleRemoveFromWishlist : handleSaveToWishlist) : handleToLoginPage}>
                            {
                                state.like_package
                                    ? <MdFavorite color="red" size={30} />
                                    :
                                    <>
                                        <MdFavoriteBorder size={30} className={classes.wishIconStyle} />
                                        <MdFavorite size={30} className={classes.wishIconHoverStyle} />
                                    </>
                            }
                        </IconButton>
                    </Grid>
                }
            </Grid>
        )
    }

    const overview = () => {
        return (
            <Box className={classes.packageInfoBox}>
                <Box style={{ paddingBottom: 10 }}>
                    <Typography variant="h4" style={{ fontFamily: "FuturaPTDemi" }}>
                        {t('package.orverview')}
                    </Typography>
                </Box>
                <Box>
                    {
                        packageItem.description && _.map(packageItem.description.points, (pointText, key) => {
                            if (pointText) {
                                return (
                                    <Box className={classes.tnc} key={key}>
                                        <img alt='ReLuxe Logo' src="../images/logo.png" style={{ width: "15px", paddingTop: 5 }} />
                                        <Typography variant="subtitle1" className={classes.tncText}>{pointText}</Typography>
                                    </Box>
                                )
                            }
                        })
                    }
                </Box>
            </Box>
        )
    }

    const summaryDetail = () => {
        return (
            <Box style={{ border: "1px solid" }}>
                <Box style={{ padding: "10px 30px" }}>
                    <Typography variant="h6">{t('package.bookingDetails')}</Typography>
                </Box>
                <Divider style={{ borderColor: theme.palette.secondary.main }} />
                <Box style={{ padding: "20px 30px", whiteSpace: 'break-spaces' }}>
                    {
                        _.map([
                            // { title: (t('package.checkIn') + ":"), content: dates.start !== '' ? (Moment(dates.start).format('ddd DD MMM YYYY')) : '-' },
                            // { title: (t('package.checkOut') + ":"), content: dates.end !== '' ? (Moment(dates.end).format('ddd DD MMM YYYY')) : '-' },
                            // { title: (t('package.nights') + ":"), content: (calculateNumOfNights(dates) + " Nights") },
                            { visible: _.size(packageItem.add_on_packages) > 0 ? true : false, title: (t('package.addOn') + ":"), content: getAddOnText() },
                            { visible: true, title: (t('package.date') + ":"), content: dates.start !== '' ? (Moment(dates.start).format('ddd DD MMM YYYY')) : '-' },
                            { visible: true, title: (t('package.quantity') + ":"), content: getOptionsText() },
                        ], (info, key) => {
                            if (info.visible) {
                                return (
                                    <Box style={{ display: "block", paddingTop: key === 0 ? 0 : 20 }} key={key}>
                                        <Typography variant="body" style={{ fontFamily: "FuturaPTMedium", display: "block", fontWeight: "bold" }}>{info.title}</Typography>
                                        <Typography variant="body">{info.content}</Typography>
                                    </Box>
                                )
                            }
                        })
                    }
                </Box>
                <Divider style={{ borderColor: theme.palette.secondary.main }} />
                <Box style={{ padding: "25px 30px" }}>
                    <Box>
                        <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>{t('package.priceBreakdown')}</Typography>
                    </Box>
                    <Box>
                        {
                            state.booking_date
                                ?
                                <Box>
                                    {
                                        _.map(packageItem.package_options, option => {
                                            return (
                                                _.map(state.options, stateOption => {
                                                    if (stateOption.quantity > 0 && parseInt(option.id) === parseInt(stateOption.id)) {
                                                        let thisOptionName = stateOption.quantity + ' ' + option.name;
                                                        let agency_public_holidays = packageItem.agency ? packageItem.agency.public_holidays : [];
                                                        let selectedPrice = getSellPrice(state.booking_date, option.price, agency_public_holidays);
                                                        let displayPrice = stateOption.quantity * selectedPrice;
                                                        return (
                                                            <Box key={stateOption.id} style={{ display: "flex", justifyContent: "space-between" }}>
                                                                <Typography variant="body2" style={{ fontFamily: "FuturaPTHeavy" }}>{thisOptionName}</Typography>
                                                                <Typography variant="body2" style={{ fontFamily: "FuturaPTHeavy" }}>{" " + defaultCurrency.symbol + " " + parseFloat(displayPrice * defaultCurrency.rate).toFixed(2)}</Typography>
                                                            </Box>
                                                        )
                                                    }
                                                })
                                            )
                                        })
                                    }
                                    {
                                        _.size(packageItem.add_on_packages) > 0
                                            ?
                                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                                <Typography variant="body2" style={{ fontFamily: "FuturaPTHeavy" }}>{t('package.addOn')}</Typography>
                                                <Typography variant="body2" style={{ fontFamily: "FuturaPTHeavy" }}>{" " + defaultCurrency.symbol + " " + parseFloat(getAddOnPrice(state.booking_date, state.add_on_package, packageItem.agency ? packageItem.agency.public_holidays : []) * defaultCurrency.rate).toFixed(2)}</Typography>
                                            </Box>
                                            : null
                                    }
                                </Box>
                                :
                                null
                        }
                    </Box>
                    <Box style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <Typography variant="body2">{t('package.taxesAndFeesNote')}</Typography>
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy", color: theme.palette.primary.main }}>{t('package.totalPrice') + ":"}</Typography>
                        <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>
                            {" " + defaultCurrency.symbol + " " + parseFloat(displayPrice * defaultCurrency.rate).toFixed(2)}
                        </Typography>
                    </Box>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", paddingBottom: 20 }}>
                    <Button variant="contained" style={{ padding: "6px 50px" }} onClick={backToSelectPackage}>{t('package.selectOption')}</Button>
                </Box>
            </Box>
        )
    }

    const selectPackage = () => {
        return (
            <Box className={classes.selectDateBox} id="selectPackage">
                <Typography variant="h4" style={{ fontFamily: "FuturaPTDemi" }}>
                    {t('package.packageOption')}
                </Typography>
                <Box style={{ backgroundColor: "#eeeeee", margin: "3% 0%", padding: "3%" }}>
                    {/* BOOKING DATE */}
                    {
                        packageItem.type === 'tour'
                            ?
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography variant="h6" style={{ marginBottom: 10 }}>{t('package.selectDateTitle')}</Typography>
                                    <Button variant="contained" color="secondary" onClick={() => setCalanderPopup(true)}><BiCalendar style={{ marginRight: 10 }} />{state.booking_date ? state.booking_date : t('package.checkAvailability')}</Button>
                                </Grid>
                                {
                                    matchDownMd ? null :
                                        <Grid item xs={2} align="right">
                                            <Button variant="contained" style={{ padding: "6px 50px" }} onClick={clearData}>{t('general.clear')}</Button>
                                        </Grid>
                                }
                                <Dialog
                                    open={calanderPopup}
                                    onClose={() => setCalanderPopup(false)}
                                    maxWidth={'md'}
                                >
                                    <DialogContent>
                                        <Box style={{ display: "flex", justifyContent: "center", paddingBottom: 15 }}>
                                            <Typography variant="h6" style={{ color: theme.palette.primary.main }}>{t('package.selectDate')}</Typography>
                                        </Box>
                                        <CustomizeCalendar dates={dates} setDates={setDates} state={state} setState={setState} singleDate={true} packageDisabledDates={packageDisabledDates} packageDisabledFrom={packageDisabledSetting.from_date} packageDisabledTo={packageDisabledSetting.to_date} setPopupOpen={setCalanderPopup} />
                                    </DialogContent>
                                </Dialog>
                                {/* <Hidden mdUp>
                                <Grid container>
                                    <Grid item xs={12} md={7}>
                                        <Box style={{ border: "2px solid " + theme.palette.primary.main, padding: "5px 15px", marginBottom: 10 }} className={classes.flexSbM}>
                                            <Box>
                                                <Typography variant="h6" style={{ color: theme.palette.primary.main }}>{t('package.bookingDate')}</Typography>
                                            </Box>
                                            <Box className={classes.quantityStyle} style={{ padding: '5px 0' }}>
                                                <TextField
                                                    sx={{ input: { textAlign: "center", color: theme.palette.primary.main } }}
                                                    variant="standard"
                                                    name="start_date"
                                                    type="date"
                                                    autoComplete="false"
                                                    value={state.booking_date}
                                                    fullWidth
                                                    inputProps={{
                                                        min: Moment(new Date(packageDisabledSetting.from_date).setDate(new Date(packageDisabledSetting.from_date).getDate() + 1)).format('YYYY-MM-DD'),
                                                        max: Moment(packageDisabledSetting.to_date).format('YYYY-MM-DD'),
                                                    }}
                                                    onChange={(event) => setState({ ...state, booking_date: event.target.value })}
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Hidden> */}
                            </Grid>
                            : null
                    }
                    {/* OPTION QUANTITY & BUTTON */}
                    <Grid container columns={10} style={{ marginTop: 20 }}>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ marginBottom: 10 }}>{t('package.quantity')}</Typography>
                            {
                                _.map(packageItem.package_options, option => {
                                    return (
                                        _.map(state.options, stateOption => {
                                            if (parseInt(option.id) === parseInt(stateOption.id)) {
                                                let agency_public_holidays = packageItem.agency ? packageItem.agency.public_holidays : [];
                                                let selectedPrice = getSellPrice(state.booking_date, option.price, agency_public_holidays);
                                                return (
                                                    <Box style={{ border: "2px solid " + theme.palette.primary.main, padding: "0px 15px", marginBottom: "10px", width: "100%", display: "flex", backgroundColor: "#fff", alignItems: "center" }} key={stateOption.id}>
                                                        <Box style={{ width: "60%" }}>
                                                            <Typography variant="h6" style={{ color: theme.palette.primary.main }}>{stateOption.name}</Typography>
                                                        </Box>
                                                        <Box style={{ width: "40%" }} className={classes.flexSbM}>
                                                            <Typography>{" " + defaultCurrency.symbol + " " + parseFloat(selectedPrice * defaultCurrency.rate).toFixed(2)}</Typography>
                                                            <Box className={classes.quantityStyle} style={{ padding: '5px 0' }}>
                                                                <IconButton onClick={() => handleQuantityChange('deduct', 1, stateOption.id, 'package')}>
                                                                    <FiMinus className={classes.minusPlusTextStyle} />
                                                                </IconButton>
                                                                <TextField
                                                                    sx={{ input: { textAlign: "center", color: theme.palette.primary.main } }}
                                                                    variant="standard"
                                                                    name="quantity"
                                                                    value={stateOption.quantity}
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                    }}
                                                                    fullWidth
                                                                    onChange={(event) => handleQuantityChange('quantity', event.target.value, stateOption.id, 'package')}
                                                                />
                                                                <IconButton onClick={() => handleQuantityChange('add', 1, stateOption.id, 'package')}>
                                                                    <FiPlus className={classes.minusPlusTextStyle} />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            }
                                        })
                                    )
                                })
                            }
                        </Grid>
                        {
                            _.size(packageItem.add_on_packages) > 0
                                ?
                                <Grid item xs={12}>
                                    {addOnPackagePart()}
                                </Grid>
                                :
                                null
                        }
                        <Hidden mdDown>
                            <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap", paddingTop: 10 }}>
                                <Box>
                                    <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>
                                        {" " + defaultCurrency.symbol + " " + parseFloat(displayPrice * defaultCurrency.rate).toFixed(2)}
                                    </Typography>
                                    <Typography variant="h6" color="primary">
                                        {t('package.fillAllFields')}
                                    </Typography>
                                </Box>
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Button variant="outlined" color="primary" onClick={() => checkTime(false)} disabled={loading ? true : false} style={{ minWidth: "160px", marginBottom: 10, marginRight: 10 }}>
                                        <Typography variant="h6" color="inherit" style={{ fontFamily: "FuturaPTMedium" }}>{t('package.addToCart')}</Typography>
                                    </Button>
                                    {/* {
                                        accessToken
                                            ? */}
                                    <Button variant="outlined" color="primary" onClick={() => checkTime(true)} disabled={loading ? true : false} style={{ minWidth: "160px", marginBottom: 10 }}>
                                        <Typography variant="h6" color="inherit" style={{ fontFamily: "FuturaPTMedium" }}>{t('package.checkout')}</Typography>
                                    </Button>
                                    {/* : null
                                    } */}
                                </Box>
                                {/* <Box className={classes.flexM} style={{ color: theme.palette.primary.main, padding: 20 }}>
                                <BsFlagFill size={10} />
                                <Typography variant="caption" color="inherit" style={{ paddingLeft: 10 }}>{t('package.reportListing')}</Typography>
                            </Box> */}
                            </Grid>
                        </Hidden>
                    </Grid>
                </Box>
            </Box>
        )
    }

    const suggestPackage = () => (
        <Box style={{ marginTop: 50 }}>
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>
                <Typography variant="h2" className={classes.titleStyle} style={{ fontFamily: "PlayfairDisplayItalic" }}>{t('package.moreForYou') + ":"}</Typography>
                {/* <Typography variant="h4" style={{ fontFamily: "PlayfairDisplayItalic" }}>{t('package.relax')}</Typography> */}
            </Box>
            <Box className={classes.packageList}>
                <PackageList packageList={suggestPackageList} pagination={false} swiper={true} showPrice={true} showLink={true} />
            </Box>
        </Box>
    )

    const rowDataExpand = (rowData, label) => (
        <Box style={{ marginTop: "2%" }}>
            <Accordion style={{ boxShadow: "none" }} >
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    style={{ padding: 0, borderBottom: "1px solid", marginBottom: 10 }}
                >
                    <Typography variant="h4" style={{ fontFamily: "FuturaPTDemi" }}>{label}</Typography>
                    {/* <Hidden smDown>
                                <Divider style={{ borderBottom: "1px solid", margin: "1% -100%", width: '120%', position: "relative", top: "40px" }} />
                                </Hidden> */}
                </AccordionSummary>
                <AccordionDetails>
                    {
                        _.map(rowData, (data, key) => {
                            return (
                                <Box className={classes.tnc} key={key}>
                                    <img alt='ReLuxe Logo' src="../images/logo.png" style={{ width: "15px", paddingTop: 5 }} />
                                    <Typography variant="subtitle1" className={classes.tncText}>{data}</Typography>
                                </Box>
                            )
                        })
                    }
                </AccordionDetails>
            </Accordion>
        </Box>
    )

    const reviewsPart = () => {
        if (packageItem && packageItem.average_rating && packageItem.total_reviews) {
            return (
                <Box style={{ marginTop: 40 }}>
                    <Box className={classes.reviewBox} style={{ justifyContent: 'space-between' }}>
                        <div className={classes.reviewBox}>
                            <Typography component="h1" variant="h4" sx={{ fontFamily: "FuturaPTDemi", paddingRight: "20px" }}>{t('package.reviews')}</Typography>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Rating
                                    value={packageItem.average_rating}
                                    precision={0.1}
                                    size="large"
                                    readOnly
                                    style={{ color: theme.palette.primary.main }}
                                />
                                {
                                    packageItem.average_rating
                                        ?
                                        <Typography style={{ color: theme.palette.primary.main, fontSize: "150%", paddingLeft: 12, paddingTop: 3, lineHeight: 1 }}>{packageItem.average_rating.toFixed(1)}</Typography>
                                        :
                                        null
                                }
                                <Typography style={{ color: theme.palette.primary.main, fontSize: "150%", paddingLeft: 8, paddingTop: 3, lineHeight: 1 }}>({t('package.reviewsCount', { count: packageItem.total_reviews })})</Typography>
                            </Box>
                        </div>
                        {/* <Hidden mdDown>
                            <Link component={RouterLink} to={`/reviews/${packageItem.id}`} underline="hover">
                                <Box style={{ display: "flex", alignItems: "center", margin: "3px 10px" }}>
                                    <Typography variant="h6" className={classes.seeAll}>{t('reviews.viewReviewMore')}</Typography>
                                    <HiArrowNarrowRight size={25} />
                                </Box>
                            </Link>
                        </Hidden> */}
                    </Box>
                    {/* <Grid container spacing={2} style={{ padding: "10px 0px" }}>
                        {
                            _.map(packageItem.avg_rating, (sectionReviews, key) => {
                                return (
                                    <Grid item xs={6} sm={3} key={key} style={{ lineHeight: "0.9" }}>
                                        <Typography variant="h6">{i18n.language === 'cn' ? sectionReviews.translate.cn : sectionReviews.translate.en}</Typography>
                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                            <LinearProgress variant="determinate" value={(sectionReviews.value / 5) * 100} className={classes.reviewLinear} color="secondary" />
                                            <Typography variant="body" style={{ color: theme.palette.primary.main }}>{sectionReviews.value.toFixed(1)}</Typography>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid> */}
                    <Hidden mdDown>
                        <Swiper
                            className={classes.reviewSwiperSpacing}
                            spaceBetween={20}
                            slidesPerGroup={1}
                            loop={false}
                            pagination
                            modules={[EffectCoverflow, SwiperPagination]}
                            effect={"coverflow"}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 20,
                                modifier: 1,
                                slideShadows: false,
                                scale: 0.85
                            }}
                            breakpoints={{
                                300: {
                                    slidesPerView: 2.5,
                                },
                                600: {
                                    slidesPerView: 3,
                                },
                                900: {
                                    slidesPerView: 3,
                                },
                            }}
                        >
                            {
                                _.map(packageItem.reviews, review => {
                                    return (
                                        <SwiperSlide style={{ lineHeight: 0 }} key={review.id}>
                                            <ReviewCard review={review} detail={false} />
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </Hidden>
                    <Hidden mdUp>
                        <Swiper
                            className={classes.reviewSwiperSpacing}
                            spaceBetween={10}
                            slidesPerGroup={1}
                            loop={false}
                            pagination
                            modules={[SwiperPagination]}
                            breakpoints={{
                                300: {
                                    slidesPerView: 2.5,
                                },
                                600: {
                                    slidesPerView: 3,
                                },
                                900: {
                                    slidesPerView: 3,
                                },
                            }}
                        >
                            {
                                _.map(packageItem.reviews, review => {
                                    return (
                                        <SwiperSlide style={{ lineHeight: 0 }} key={review.id}>
                                            <ReviewCard review={review} detail={false} />
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </Hidden>
                    <Grid container>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant="outlined" color="primary" component={RouterLink} to={`/reviews/${packageItem.id}`} style={{ minWidth: 250, marginBottom: 10 }}>
                                <Typography variant="h6" color="inherit" style={{ fontFamily: "FuturaPTMedium" }}>{t('package.reviewMoreCount', { count: packageItem.total_reviews })}</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            )
        } else {
            return (
                <Box style={{ marginTop: 50 }}>
                    <Box className={classes.reviewBox}>
                        <Typography component="h1" variant="h4" sx={{ fontFamily: "FuturaPTDemi", paddingRight: "20px" }}>{t('package.reviews')}</Typography>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Rating
                                value={0}
                                precision={0.1}
                                size="large"
                                readOnly
                                style={{ color: theme.palette.primary.main }}
                            />
                            <Typography style={{ color: theme.palette.primary.main, fontSize: 17, paddingLeft: 8, paddingTop: 3, lineHeight: 1 }}>({t('package.reviewsCount', { count: 0 })})</Typography>
                        </Box>
                    </Box>
                </Box>
            )
        }
    }

    const bottomNavigationBookNow = () => {
        return (
            <Paper style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                background: '#fff',
                zIndex: 3,
                width: '100%',
                transition: 'all 0.5s',
            }}
                elevation={isScrolled ? 5 : 0}
            >
                <Grid container style={{ padding: "2% 6%", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Grid item xs={4}>
                        <Typography variant="h4" style={{ fontFamily: "FuturaPTBold" }}>{t('package.totalPrice') + ":"}</Typography>
                        <Typography variant="h4" style={{ fontFamily: "FuturaPTBold" }}>
                            {" " + defaultCurrency.symbol + " " + parseFloat(displayPrice * defaultCurrency.rate).toFixed(2)}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        {/* {
                            accessToken ? */}
                        <Button variant="outlined" color="primary" onClick={() => checkTime(true)} disabled={loading ? true : false} style={{ width: "100%", maxWidth: "150px", margin: 10 }}>
                            <Typography variant="h6" color="inherit" style={{ fontFamily: "FuturaPTMedium" }}>{t('package.checkout')}</Typography>
                        </Button>
                        {/* : null
                        } */}
                        <Button variant="outlined" color="primary" onClick={() => checkTime(false)} disabled={loading ? true : false} style={{ width: "100%", maxWidth: "150px", margin: 10 }}>
                            <Typography variant="h6" color="inherit" style={{ fontFamily: "FuturaPTMedium" }}>{t('package.addToCart')}</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        );
    }

    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 1500) {
            setVisible(true);
        }
        else if (scrolled <= 1500) {
            setVisible(false);
        }
    };
    window.addEventListener('scroll', toggleVisible);
    const backToSelectPackage = () => {
        window.location.replace(window.location.href.split('#')[0] + '#selectPackage');
    }

    const [isScrolled, setIsScrolled] = useState(true);
    const toggleScroll = () => {
        if (Math.round(window.innerHeight + window.scrollY) >= document.documentElement.offsetHeight) {
            setIsScrolled(false);
        } else {
            setIsScrolled(true);
        }
    };
    window.addEventListener('scroll', toggleScroll);

    const [collapseOpen, setCollapseOpen] = useState(false);
    const [addOnId, setAddOnId] = useState('');
    const handleAddOnPackageClick = (add_on_id) => {
        if (addOnId === '') {
            setAddOnId(add_on_id);
            setCollapseOpen(true);
        } else if (addOnId !== add_on_id) {
            setAddOnId(add_on_id);
        } else if (addOnId === add_on_id) {
            setAddOnId('');
            setCollapseOpen(false);
        }
    }
    const [addOnDialog, setAddOnDialog] = useState({
        open: false,
        image: null,
        name: '',
        descr_body: '',
    });
    const handleAddOnPackageDialogOpen = (add_on_package) => {
        if (add_on_package.package.description.body || (_.size(add_on_package.package.images) > 0)) {
            setAddOnDialog({
                open: true,
                image: _.size(add_on_package.package.images) > 0 ? add_on_package.package.images[0] : null,
                name: add_on_package.display_package_name ? add_on_package.display_package_name: add_on_package.package.name,
                descr_body: add_on_package.package.description.body,
            });
        }
    }
    const handleAddOnPackageDialogClose = () => {
        setAddOnDialog({
            open: false,
            image: null,
            name: '',
            descr_body: '',
        });
    }
    const addOnPackagePart = () => {
        return (
            <Box>
                <Typography variant="h6" style={{ marginBottom: 10 }}>{t('package.addOn')}</Typography>
                <Box style={{ display: matchDownMd ? "block" : "flex" }}>
                    {
                        _.map(packageItem.add_on_packages, add_on_package => {
                            return (
                                <Box key={add_on_package.id}>
                                    <Box className={add_on_package.id === addOnId ? classes.selectedAddOnPackageName : classes.addOnPackageName}>
                                        <Typography variant="h6" style={{ color: add_on_package.id === addOnId ? '#fff' : theme.palette.primary.main, paddingRight: 10 }} onClick={() => handleAddOnPackageDialogOpen(add_on_package)}>{add_on_package.display_package_name ? add_on_package.display_package_name: add_on_package.package.name}</Typography>
                                        <IconButton onClick={() => handleAddOnPackageClick(add_on_package.id)}>
                                            {
                                                collapseOpen && (add_on_package.id === addOnId) ?
                                                    <MdExpandLess color={add_on_package.id === addOnId ? '#fff' : theme.palette.primary.main} size={20} />
                                                    : <MdExpandMore color={add_on_package.id === addOnId ? '#fff' : theme.palette.primary.main} size={20} />
                                            }
                                        </IconButton>
                                    </Box>
                                    {
                                        matchDownMd && (add_on_package.id === addOnId)
                                            ?
                                            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
                                                {addOnPackageOptionPart()}
                                            </Collapse>
                                            :
                                            null
                                    }
                                </Box>
                            )
                        })
                    }
                </Box>
                {
                    matchDownMd ? null :
                        <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
                            {addOnPackageOptionPart()}
                        </Collapse>
                }
                <Dialog
                    open={addOnDialog.open}
                    onClose={handleAddOnPackageDialogClose}
                    fullWidth={true}
                    maxWidth={'md'}
                >
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12} md={4.5}>
                                <Box style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {
                                        addOnDialog.image
                                            ?
                                            <img alt="package image" src={addOnDialog.image.file_name} className="center" height="300px" style={{ aspectRatio: '1/1' }} />
                                            :
                                            <img alt='ReLuxe' src="/images/logo.png" className={classes.classMediaStyle} height="300px" style={{ aspectRatio: '1/1' }} />
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={7.5}>
                                <Box style={{ padding: 20 }}>
                                    <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                        <IconButton onClick={handleAddOnPackageDialogClose}><FiX /></IconButton>
                                    </Box>
                                    <Typography variant="h6" style={{ textDecoration: "underline", fontWeight: "bold" }}>
                                        {addOnDialog.name}
                                    </Typography>
                                    <Typography variant="body1">
                                        {addOnDialog.descr_body}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </Box>
        )
    }
    const addOnPackageOptionPart = () => {
        let selectedAddOn = _.find(packageItem.add_on_packages, { id: addOnId });
        let addOnPackageOption = state.add_on_package[addOnId];
        return (
            <Box>
                {
                    _.map(addOnPackageOption, stateOption => {
                        let agency_public_holidays = packageItem.agency ? packageItem.agency.public_holidays : [];
                        let add_on_option = _.find(selectedAddOn.package.package_options, { id: parseInt(stateOption.id) });
                        let addOnPrice = selectedAddOn.option_prices[stateOption.id] ? selectedAddOn.option_prices[stateOption.id] : add_on_option.price;
                        let selectedPrice = getSellPrice(state.booking_date, addOnPrice, agency_public_holidays);
                        return (
                            <Box className={classes.addOnQuantity} key={stateOption.id}>
                                <Box style={{ width: "60%" }}>
                                    <Typography variant="h6" style={{ color: theme.palette.primary.main }}>{stateOption.name}</Typography>
                                </Box>
                                <Box style={{ width: "40%" }} className={classes.flexSbM}>
                                    <Typography>{" " + defaultCurrency.symbol + " " + parseFloat(selectedPrice * defaultCurrency.rate).toFixed(2)}</Typography>
                                    <Box className={classes.quantityStyle} style={{ padding: '5px 0' }}>
                                        <IconButton onClick={() => handleQuantityChange('deduct', 1, stateOption.id, 'add-on')}>
                                            <FiMinus className={classes.minusPlusTextStyle} />
                                        </IconButton>
                                        <TextField
                                            sx={{ input: { textAlign: "center", color: theme.palette.primary.main } }}
                                            variant="standard"
                                            name="quantity"
                                            value={stateOption.quantity}
                                            InputProps={{
                                                disableUnderline: true,
                                            }}
                                            fullWidth
                                            onChange={(event) => handleQuantityChange('quantity', event.target.value, stateOption.id, 'add-on')}
                                        />
                                        <IconButton onClick={() => handleQuantityChange('add', 1, stateOption.id, 'add-on')}>
                                            <FiPlus className={classes.minusPlusTextStyle} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    return (
        <Box className={classes.bgImageWhiteLeft}>
            <Container maxWidth="xl" style={{ padding: "2% 9%" }}>
                <Box>
                    <BackToPrevious />
                    {
                        _.size(packageItem) > 0
                            ?
                            <Box>
                                {!matchDownMd ? packageHeader() : null}
                                {imageSlider()}
                                {imagePopup()}
                                {matchDownMd ? packageHeader() : null}
                                {/* {packageItem.description ? packageDescription() : null} */}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={7} lg={8}>
                                        {overview()}
                                    </Grid>
                                    <Hidden mdDown>
                                        <Grid item xs={12} md={5} lg={4}>
                                            {summaryDetail()}
                                        </Grid>
                                    </Hidden>
                                    <Grid item xs={12}>
                                        {selectPackage()}
                                    </Grid>
                                </Grid>
                                {
                                    packageItem.description
                                        ?
                                        <>
                                            {_.size(packageItem.description.itinerary) > 0 ? rowDataExpand(packageItem.description.itinerary, t('package.itinerary')) : null}
                                            {_.size(packageItem.description.note) > 0 ? rowDataExpand(packageItem.description.note, t('package.note')) : null}
                                            {_.size(packageItem.description.tnc) > 0 ? rowDataExpand(packageItem.description.tnc, t('ourResorts.tnc')) : null}
                                        </>
                                        :
                                        null
                                }
                                {reviewsPart()}
                                {suggestPackage()}
                                <Hidden mdUp>
                                    {bottomNavigationBookNow()}
                                </Hidden>
                                {/* jump to select package part */}
                                <Hidden mdDown>
                                    <Fab variant="extended" onClick={backToSelectPackage} style={{ position: "fixed", right: "5%", bottom: "5%", display: visible ? 'inline' : 'none' }}>
                                        <Typography variant="body1" color="inherit" style={{ fontFamily: "FuturaPTMedium" }}>{t('package.bookNow')}</Typography>
                                    </Fab>
                                </Hidden>
                            </Box>
                            :
                            <Box style={{ marginTop: 20 }}>
                                <LinearProgress size={20} />
                            </Box>
                    }
                </Box>
            </Container>
        </Box>
    )
}

export default Detail;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    minusPlusTextStyle: {
        fontSize: "1rem",
        color: theme.palette.primary.main
    },
    quantityStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 100
    },
    flexSbM: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    flexM: {
        display: 'flex',
        alignItems: 'center'
    },
    titleStyle: {
        lineHeight: "1 !important",
        [theme.breakpoints.up('xs')]: {
            fontSize: "2.0rem !important",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "3.0rem !important",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "3.5rem !important",
        },
    },
    bgImageWhiteLeft: {
        backgroundImage: `url('../images/logo-light.png')`,
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'left -40% top 30%',
            backgroundSize: "40%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'left -10% top 30%',
            backgroundSize: "25%",
        },
    },
    // package info
    packageInfoBox: {
        [theme.breakpoints.up('xs')]: {
            paddingRight: 0,
        },
        [theme.breakpoints.up('md')]: {
            paddingRight: 50,
        },
    },
    selectDateBox: {
        // [theme.breakpoints.up('xs')]: {
        //     paddingTop: '10px',
        //     margin: "0px 35px",
        // },
        // [theme.breakpoints.up('sm')]: {
        //     paddingTop: '30px',
        //     margin: "0px 75px",
        // },
        // [theme.breakpoints.up('md')]: {
        //     padding: '30px 50px 0',
        //     margin: "0px",
        // },
    },
    // recommend package
    packageList: {
        // [theme.breakpoints.up('xs')]: {
        //     marginTop: "20px !important",
        // },
        // [theme.breakpoints.up('md')]: {
        //     marginTop: "40px !important",
        // },
    },
    // tnc
    tnc: {
        display: "flex",
        alignItems: "start",
        paddingBottom: "10px !important",
    },
    tncText: {
        paddingLeft: '10px',
        paddingBottom: '10px',
    },
    // review
    reviewLinear: {
        width: "70%",
        marginRight: "5px !important",
        borderRadius: "5px",
        height: "6px !important"
    },
    reviewSwiperSpacing: {
        [theme.breakpoints.up('xs')]: {
            padding: '35px 10px 20px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '60px 10px 50px',
        },
    },
    reviewBox: {
        marginBottom: 5,
        display: "flex",
        alignItems: "center",
        // [theme.breakpoints.up('xs')]: {
        //     display: "flex",
        //     alignItems: "center",
        // },
        // [theme.breakpoints.up('sm')]: {
        //     display: "flex",
        //     alignItems: "center",
        // },
    },
    seeAll: {
        color: theme.palette.primary.main + " !important",
        marginRight: "20px !important",
    },
    // wishlist icon
    wishIconStyle: {
        objectFit: 'contain',
        color: "#363636",
    },
    wishIconHoverStyle: {
        position: 'absolute',
        objectFit: 'contain',
        opacity: 0,
        transition: 'opacity .2s',
        color: '#363636',
        "&:hover": {
            opacity: 1,
        },
    },
    // image swiper
    imageSwiper2: {
        [theme.breakpoints.up('xs')]: {
            height: "340px !important",
        },
        [theme.breakpoints.up('md')]: {
            height: "440px !important",
        },
    },
    imageSwiper: {
        [theme.breakpoints.up('xs')]: {
            height: "80px !important",
        },
        [theme.breakpoints.up('md')]: {
            height: "100px !important",
        },
    },
    // add on
    addOnPackageName: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "2px solid " + theme.palette.primary.main,
        backgroundColor: "#fff",
        padding: "5px 15px",
        marginTop: "5px",
        marginRight: "5px",
        "&:hover": {
            cursor: "pointer",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    selectedAddOnPackageName: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "2px solid " + theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        color: "#fff !important",
        padding: "5px 15px",
        marginTop: "5px",
        marginRight: "5px",
        "&:hover": {
            cursor: "pointer",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    addOnQuantity: {
        display: "flex",
        alignItems: "center",
        border: "2px solid " + theme.palette.primary.main,
        backgroundColor: "#fff",
        padding: "0px 15px",
        width: "100%",
        marginBottom: "0px",
    },
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important",
        padding: "0px !important",
        margin: "0px !important",
    },
}))