import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Box, Button, Dialog, DialogActions, CardMedia, DialogContent, DialogTitle, Grid, Typography, Link, IconButton, Tab, Tabs } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { ADMIN_URL } from '@configs/config';
import ReviewForm from "@components/ReviewForm";

import { MdClose } from "react-icons/md";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const VoucherItemCard = (props) => {
    const { voucher, dayList, systemPublicHoliday, setRefreshData } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const [viewDetailDialog, setViewDetailDialog] = useState(false);
    const [reviewDialog, setReviewDialog] = useState(false);
    const { defaultCurrency } = useSelector(state => state.general);
    const [qrcodeTabValue, setQrcodeTabValue] = useState(0);

    const displayValidDate = () => {
        let text = '';
        if (voucher.valid_date_from && voucher.valid_date_to) {
            text = voucher.valid_date_from + ' - ' + voucher.valid_date_to;
        } else if (voucher.valid_date_from) {
            text = t('package.validFrom', { date: voucher.valid_date_from });
        } else if (voucher.valid_date_to) {
            text = t('package.validTo', { date: voucher.valid_date_from });
        }
        return text ? text : '-';
    }
    const displayDayText = () => {
        let dayText = '';
        if (_.size(dayList) > 0) {
            _.map(dayList, day => {
                if (_.includes(voucher.v_available_days, day.code)) {
                    let text = day[i18n.language] ? day[i18n.language] : day.en;
                    dayText = dayText ? dayText + ', ' + text : text;
                }
            })
        }
        return dayText ? dayText : '-';
    }
    const displayPublicHoliday = (agency_public_holidays) => {
        let phText = '';
        let publicHoliday = agency_public_holidays ? agency_public_holidays : systemPublicHoliday;
        if (_.size(publicHoliday) > 0) {
            _.map(publicHoliday, ph => {
                phText = phText ? phText + ', ' + ph : ph;
            })
        }
        return phText ? phText : '-';
    }
    const displayExceptDate = () => {
        let text = '';
        if (_.size(voucher.v_except_date) > 0) {
            _.map(voucher.v_except_date, date => {
                text = text ? text + ', ' + date : date;
            })
        }
        return text ? text : '-';
    }

    return (
        <>
            {/* ITEM CARD */}
            <Grid container className={classes.itemContainer} style={{ marginBottom: 20 }}>
                <Grid item xs={3} md={3} lg={2}>
                    <CardMedia
                        component="img"
                        image={_.size(voucher.package.images) > 0 ? voucher.package.images[0].file_name : "../images/emptyStatus/no_image.png"}
                        sx={{ flex: 1, width: "80%", maxHeight: "150px" }}
                        alt={'package\'s image'}
                        className={classes.classMediaStyle}
                    />
                </Grid>
                <Grid item xs={7} md={8} lg={9}>
                    <Box style={{ padding: "5%" }}>
                        <Box style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}>
                            <Typography style={{ fontSize: "1.1rem" }}>{t('voucher.code') + ": " + voucher.code}</Typography>
                            <Typography className={classes.statusStyle}>{voucher.status_display[i18n.language]}</Typography>
                        </Box>
                        <Typography variant="h6" style={{ lineHeight: 1.0, fontFamily: "FuturaPTDemi", padding: "5px 0px" }}>{voucher.voucher_name}</Typography>
                        {
                            voucher.start_date
                                ?
                                <Box>
                                    <Typography className={classes.descText}>{t('voucher.bookingDate') + ": " + voucher.start_date}</Typography>
                                    <Typography className={classes.descText}>{t('user.name') + ": " + voucher.user_name}</Typography>
                                </Box>
                                :
                                null
                        }
                        <div style={{ paddingTop: 25 }}>
                            <Typography className={classes.subInfoStyle}>{t('package.voucherExpiryDate') + ": " + displayValidDate()}</Typography>
                        </div>
                        {
                            _.size(voucher.review) > 0 || voucher.status === 50
                                ?
                                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button variant="outlined" color="primary" onClick={() => setReviewDialog(true)}>
                                        {_.size(voucher.review) > 0 ? t('reviews.viewReview') : t('reviews.leaveReview')}
                                    </Button>
                                </Box>
                                :
                                null
                        }
                    </Box>
                </Grid>
                <Grid item xs={2} md={1} className={classes.viewDetailStyle}>
                    <Link onClick={() => setViewDetailDialog(true)}>
                        <div style={{ width: "100%", height: "100%", position: "relative" }}>
                            <div className={classes.viewDetailText}>
                                <Typography color="#fff">{t('order.viewDetails')}</Typography>
                            </div>
                        </div>
                    </Link>
                </Grid>
            </Grid>
            {/* VOUCHER DETAIL DIALOG */}
            <Dialog open={viewDetailDialog} onClose={() => setViewDetailDialog(false)}>
                <DialogTitle>{t('voucher.code') + ": " + voucher.code}</DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography variant="body1">{t('voucher.price') + ": " + defaultCurrency.symbol + " " + (voucher.voucher_price * defaultCurrency.rate).toFixed(2)}</Typography>
                        {
                            voucher.voucher_type === 'entrance'
                                ?
                                <>
                                    <Typography variant="body1" style={{ marginTop: 10 }}>{t('package.voucherAvailableDay') + ': ' + displayDayText()}</Typography>
                                    {
                                        !_.includes(voucher.v_available_days, 'ph')
                                            ?
                                            <Typography variant="body1">{t('package.voucherAvailableDayNote')}</Typography>
                                            :
                                            null
                                    }
                                    <Typography variant="body1" style={{ marginTop: 10 }}>{t('package.publicHoliday') + ': '}</Typography>
                                    <Typography variant="body1">{displayPublicHoliday(voucher.agency ? voucher.agency.public_holidays : [])}</Typography>
                                </>
                                :
                                null
                        }
                        {
                            voucher.voucher_type === 'tour'
                                ?
                                <>
                                    {/* BOOKING INFO */}
                                    <Typography variant="body1" style={{ marginTop: 10, fontWeight: "bold" }}>{t('voucher.bookingDate') + ": " + voucher.start_date}</Typography>
                                    <Typography variant="body1">{t('user.name') + ": " + voucher.user_name}</Typography>
                                    <Typography variant="body1">{t('user.ic') + ": " + voucher.user_ic}</Typography>
                                    <Typography variant="body1">{t('user.dob') + ": " + (voucher.user_dob ? voucher.user_dob : '-')}</Typography>
                                    <Typography variant="body1">{t('user.email') + ": " + (voucher.user_email ? voucher.user_email : '-')}</Typography>
                                    <Typography variant="body1">{t('user.mobile') + ": " + (voucher.user_mobile ? voucher.user_mobile : '-')}</Typography>
                                    <Typography variant="body1">{t('user.gender') + ": " + (voucher.user_gender ? t('user.'+voucher.user_gender) : '-')}</Typography>
                                    {/* <Typography variant="body1">{t('package.voucherExceptDate') + ': ' + displayExceptDate()}</Typography>
                                    <Typography variant="body1">{t('package.durationPeriod') + ': ' + voucher.duration_period}</Typography> */}
                                </>
                                :
                                null
                        }
                    </Box>
                    {
                        voucher.status === 20 || voucher.status === 40
                            ?
                            <Box style={{ marginTop: 10 }}>
                                <Tabs
                                    value={qrcodeTabValue}
                                    onChange={(event, newValue) => setQrcodeTabValue(newValue)}
                                    centered
                                >
                                    <Tab style={{ backgroundColor: qrcodeTabValue == 0 ? theme.palette.primary.main : "#fff", color: qrcodeTabValue == 0 ? "#fff" : theme.palette.primary.main }} label={t('voucher.code')} {...a11yProps(0)} />
                                    <Tab style={{ backgroundColor: qrcodeTabValue == 1 ? theme.palette.primary.main : "#fff", color: qrcodeTabValue == 1 ? "#fff" : theme.palette.primary.main }} label={t('order.orderNo')} {...a11yProps(1)} />
                                </Tabs>
                                <Box style={{ textAlign: "center", marginTop: 10 }}>
                                    <TabPanel value={qrcodeTabValue} index={0}>
                                        <QRCodeSVG value={`${ADMIN_URL}/voucher-redeem?code=${voucher.code}`} />
                                    </TabPanel>
                                    <TabPanel value={qrcodeTabValue} index={1}>
                                        <QRCodeSVG value={`${ADMIN_URL}/voucher-redeem?order_id=${voucher.order_id}`} />
                                    </TabPanel>
                                    <Typography>{t('voucher.redeemNote')}</Typography>
                                </Box>
                            </Box>
                            :
                            null
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setViewDetailDialog(false)}>{t('general.ok')}</Button>
                </DialogActions>
            </Dialog>
            {/* REVIEW DIALOG */}
            <Dialog open={reviewDialog} onClose={() => setReviewDialog(false)}>
                <DialogTitle>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        {t('voucher.code') + ": " + voucher.code}
                        <IconButton onClick={() => setReviewDialog(false)}><MdClose /></IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box style={{ padding: 10 }}>{voucher.voucher_name}</Box>
                    <ReviewForm voucher={voucher} setReviewDialog={setReviewDialog} setRefreshData={setRefreshData} />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default VoucherItemCard;

const useStyles = makeStyles((theme) => ({
    itemContainer: {
        border: 'none',
        borderRadius: 10,
        borderColor: theme.palette.gray.secondary,
        overflow: 'hidden',
        boxShadow: '0px 1px 6px 3px #0000001f',
    },
    classMediaStyle: {
        borderRadius: 5,
        objectFit: "contain !important",
        margin: "10%",
    },
    descText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis !important',
        fontSize: '1.1rem !important'
    },
    subInfoStyle: {
        color: theme.palette.gray.ltext,
        fontSize: '1.1rem !important'
    },
    statusStyle: {
        backgroundColor: theme.palette.primary.main + ' !important',
        color: '#fff !important',
        borderRadius: 20,
        padding: "5px 10px",
        width: "fit-content",
        fontSize: "0.8rem !important",
        marginLeft: "10px !important"
    },
    viewDetailStyle: {
        backgroundColor: theme.palette.button.main + ' !important',
        cursor: 'pointer',
    },
    viewDetailText: {
        position: "absolute",
        textAlign: "center",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) rotate(-90deg)",
        whiteSpace: "nowrap",
    },
}))