import Moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Hidden, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

// https://uselessdev-datepicker.netlify.app/
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import {
    Calendar, CalendarControls, CalendarDays, CalendarDefaultTheme, CalendarMonth, CalendarMonthName, CalendarMonths, CalendarNextButton, CalendarPrevButton, CalendarWeek
} from '@uselessdev/datepicker';

const CustomizeCalendar = (props) => {
    const { dates, setDates, state, setState, singleDate, packageDisabledDates, setPopupOpen, packageDisabledFrom, packageDisabledTo, stateSetting=true } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    const handleSelectDate = (date) => {
        let newDates = dates;
        if(singleDate){
            newDates.start = date;
            newDates.end = '';
            setPopupOpen(false);
        }else{
            if (dates.start && !dates.end) {
                if (date > dates.start) {
                    newDates.end = date;
                    setPopupOpen(false);
                } else {
                    newDates.start = date;
                    newDates.end = '';
                }
            } else {
                newDates.start = date;
                newDates.end = '';
            }
        }
        if(stateSetting){
            setState({ ...state, booking_date: Moment(newDates.start).format('YYYY-MM-DD') });
        }
        setDates({ ...newDates});
    };

    const getDisablePastDates = () => {
        let return_date = new Date();
        if(packageDisabledFrom){
            let compare_date = new Date(packageDisabledFrom);
            if(compare_date.getTime() > return_date.getTime()){
                return_date = compare_date;
            } else if(return_date.getHours() >= 20) {
                return_date.setDate(return_date.getDate() + 1);
            }
        }
        return return_date;
    }

    const getDisableFutureDates = () => {
        let return_date = new Date((new Date()).valueOf() + 86400000*365);
        if(packageDisabledTo){
            let compare_date = new Date(packageDisabledTo);
            if(compare_date.getTime() < return_date.getTime()){
                return_date = compare_date;
            }
        }
        return return_date;
    }

    return (
        <Box>
            <ChakraProvider theme={calendarTheme}>
                <Calendar
                    value={dates}
                    months={2}
                    onSelectDate={handleSelectDate}
                    singleDateSelection
                    disablePastDates={getDisablePastDates()}
                    disableFutureDates={getDisableFutureDates()}
                    disableDates={packageDisabledDates?packageDisabledDates:[]}
                >
                    <CalendarControls>
                        <CalendarPrevButton />
                        <CalendarNextButton />
                    </CalendarControls>
                    <CalendarMonths>
                        <Hidden mdDown>
                            {
                                [...Array(2).keys()].map(month => (
                                    <CalendarMonth month={month} key={month}>
                                        <CalendarMonthName />
                                        <CalendarWeek />
                                        <CalendarDays />
                                    </CalendarMonth>
                                ))
                            }
                        </Hidden>
                        <Hidden mdUp>
                            {
                                [...Array(1).keys()].map(month => (
                                    <CalendarMonth month={month} key={month}>
                                        <CalendarMonthName />
                                        <CalendarWeek />
                                        <CalendarDays />
                                    </CalendarMonth>
                                ))
                            }
                        </Hidden>
                    </CalendarMonths>
                </Calendar>
            </ChakraProvider>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={() => {setDates({ start: '', end: '' }); if(stateSetting){setState({...state, booking_date: '',})}}}>
                    <Typography variant="h6" style={{ color: theme.palette.primary.main }}><u>{t('general.clearDate')}</u></Typography>
                </Button>
            </Box>
        </Box>
    )
}

export default CustomizeCalendar;

const calendarTheme = extendTheme(CalendarDefaultTheme, {
    components: {
        Calendar: {
            parts: ['calendar'],

            baseStyle: {
                calendar: {
                    border: 'none',
                    rounded: 'none',
                    shadow: 'none',
                },
            },
        },

        CalendarMonth: {
            parts: ['month', 'weekday', 'days'],

            baseStyle: {
                month: {
                    p: 4
                },
                weekday: {
                    color: 'black',
                    bgColor: '#D6CFBF',
                },
                days: { color: '#574f41' },
            },
        },

        CalendarDay: {
            variants: {
                selected: {
                    bgColor: 'black',
                    rounded: 'xl',
                    _hover: { bgColor: '#574f41' },
                },
                range: {
                    bgColor: '#574f41',
                    _hover: { bgColor: 'black' },
                },
            }
        },

        CalendarControl: {
            parts: ['button'],

            baseStyle: {
                button: {
                    h: 6,
                    px: 2,
                    rounded: 'none',
                    fontSize: 'md',
                    color: 'black',
                    bgColor: 'white',
                    _hover: { bgColor: 'grey.100' },
                    _focus: { outline: 'none' },
                },
            },
        }
    },
});