import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import theme from "../../theme";
import axios from "axios";

import { AppBar, Divider, IconButton, Link, Menu, MenuItem, Toolbar, Typography, Select, Container, InputBase, Hidden, Badge, Grid, Box, Paper, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LanguageRounded, Square } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { HiOutlineShoppingCart, HiOutlineUserCircle, HiOutlineHeart } from 'react-icons/hi';

import { changeDefaultLanguage, changeDefaultCurrency, logoutUser, removeUuid, storeUuid, updateCartTotal, getIpAddressCountry } from '@actions/generalActions';
import { updateUserState, resetProfile } from '@actions/userActions';
import { getUrl, postUrl } from '@helpers/api';
import { v4 as uuidv4 } from 'uuid';

export default memo(function AppHeader() {
    const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const userState = useSelector(state => state.user);

    const { t, i18n } = useTranslation();
    const { defaultLanguage, defaultCurrency, isLogin, accessToken, uuid, cartTotal, country } = useSelector(state => state.general);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const { pathname } = useLocation();

    const [currencyRate, setCurrencyRate] = useState({});
    const [currentCurrency, setCurrentCurrency] = useState({ "code": "MYR", "name": {'en': "Ringgit Malaysia", 'cn': "马币"}, "symbol": "RM", "rate": "1.00", "status": "1" });
    const currencyList = [];

    useEffect(() => {
        if (accessToken) {
            if (uuid) {
                postUrl('carts/merge', {
                    guest_token: uuid
                }).catch(error => {
                    addToast(JSON.stringify(error.message), { appearance: 'error' });
                });
            }
            getUrl('/user').then(response => {
                if (uuid) {
                    dispatch(removeUuid());
                }
                if (response.status) {
                    dispatch(updateUserState(response.data));
                } else {
                    addToast(t('error.contactSupport'), { appearance: 'error' });
                }
            })
        }
        getUrl("/carts", {guest_token: uuid ? uuid : null}).then(response => {
            if (response.status) {
                dispatch(updateCartTotal(response.data.cart_count));
            }
        })
        if (!uuid && !accessToken) {
            const createdUuid = uuidv4();
            dispatch(storeUuid(createdUuid));
        }

        getUrl(`/currency-rates`).then(response => {
            if (response.status) {
                for (let i = 0; i < _.size(response.data); i++) {
                    if (response.data[i].status !== "0") {
                        currencyList.push(response.data[i]);
                    }
                }
                setCurrencyRate(currencyList);
                let resetCurrecy = true;
                if (defaultCurrency && defaultCurrency.code) {
                    _.map(currencyList, (currRate, key) => {
                        if (currRate.code === defaultCurrency.code) {
                            if (currRate.status === "1") {
                                resetCurrecy = false;
                            }
                        }
                    })
                }
                if (resetCurrecy) {
                    changeCurrency(currentCurrency);
                }
            }
        }).catch(err => {
            addToast(JSON.stringify(err), { appearance: 'error' });
        })

        if (!defaultCurrency || !defaultCurrency.code || !defaultCurrency.name || !defaultCurrency.symbol || !defaultCurrency.rate || !defaultCurrency.status) {
            changeCurrency(currentCurrency);
        }
        // eslint-disable-next-line
    }, [uuid, accessToken]);

    useEffect(() => {
        if (i18n.language !== defaultLanguage) {
            changeLanguage(defaultLanguage || 'en');
        }

        axios.get("https://api.country.is/").then((response) => {
            if (response.status) {
                dispatch(getIpAddressCountry(response.data['country']));
            }
        })
            .catch((err) => {
                addToast(JSON.stringify(err), { appearance: 'error' });
            });
        // eslint-disable-next-line
    }, []);

    const signOutUser = () => {
        dispatch(logoutUser());
        dispatch(resetProfile());
        handleMenuClose();
        handleCloseNavMenu();
    }

    const handleLanguageMenuOpen = (event) => {
        setLanguageAnchorEl(event.currentTarget);
        setProfileAnchorEl(null);
    };
    const handleProfileMenuOpen = (event) => {
        setProfileAnchorEl(event.currentTarget);
        setLanguageAnchorEl(null);
    };
    const handleMenuClose = () => {
        setProfileAnchorEl(null);
        setLanguageAnchorEl(null);
    };

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeDefaultLanguage(lang));
        handleMenuClose();
    }

    const changeCurrency = currency => {
        setCurrentCurrency(currency);
        dispatch(changeDefaultCurrency(currency));
        handleMenuClose();
    }

    const renderLanguageMenu = (
        <Menu
            anchorEl={languageAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(languageAnchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => changeLanguage('en')}>
                <Typography variant="button">English</Typography>
            </MenuItem>
            <MenuItem onClick={() => changeLanguage('cn')}>
                <Typography variant="button">中文</Typography>
            </MenuItem>
        </Menu>
    );

    const renderProfileMenu = (
        <Menu
            anchorEl={profileAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(profileAnchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem>
                <Link component={RouterLink} to="/dashboard" style={{ textDecoration: "none", color: "inherit" }} onClick={handleMenuClose}>
                    <Typography variant="button">{t('title.profile')}</Typography>
                </Link>
            </MenuItem>
            <MenuItem>
                <Link component={RouterLink} to="/orders" style={{ textDecoration: "none", color: "inherit" }} onClick={handleMenuClose}>
                    <Typography variant="button">{t('title.orders')}</Typography>
                </Link>
            </MenuItem>
            <Divider />
            <MenuItem onClick={signOutUser}>
                <Typography variant="button">{t('auth.signOut')}</Typography>
            </MenuItem>
        </Menu>
    );

    const selectCurrency = (
        <Select
            labelId="demo-simple-select-label"
            id="currency"
            disableUnderline
            variant='standard'
            value={defaultCurrency ? defaultCurrency.code : currentCurrency.code}
            onChange={(event) => {
                if (_.size(currencyRate) > 0) {
                    for (let i = 0; i < _.size(currencyRate); i++) {
                        if (currencyRate[i].code === event.target.value) {
                            changeCurrency(currencyRate[i]);
                        }
                    }
                } else {
                    changeCurrency(defaultCurrency);
                }
            }}
            style={{ fontFamily: "PlayfairDisplayRegular", color: theme.palette.secondary.main, padding: "2%" }}
        >
            {
                _.map(currencyRate, (currRate, key) => {
                    return (
                        <MenuItem key={key} value={currRate.code} style={{ fontFamily: "PlayfairDisplayRegular" }}>{currRate.code}</MenuItem>
                    )
                })
            }
        </Select>
    );

    const iconBar = (
        <Box sx={{ marginLeft: {xs: "0%", sm: "3%"} }} style={{ display: "flex", alignItems: "center" }}>
            {/* <IconButton onClick={handleLanguageMenuOpen} style={{ margin: "5px" }} >
                <LanguageRounded size={20} className={classes.navbarIcon} />
                <Typography style={{ paddingLeft: 10, color: '#020202' }}>{t('footer.language')}</Typography>
            </IconButton> */}
            {/* <IconButton style={{ margin: "5px" }} >
                <LanguageRounded size={20} className={classes.navbarIcon} />
                <>
                    {
                        defaultLanguage === 'en'
                            ?
                            <Typography variant="button" style={{ paddingLeft: 10, color: '#020202' }} onClick={() => changeLanguage('cn')}>
                                中文
                            </Typography>
                            :
                            <Typography variant="button" style={{ paddingLeft: 10, color: '#020202' }} onClick={() => changeLanguage('en')}>
                                English
                            </Typography>
                    }
                </>
            </IconButton> */}
            <Hidden mdDown>
                {_.size(currencyRate) > 0 && selectCurrency}
            </Hidden>
            <IconButton sx={{ padding: {xs: "2%", sm: "8px"} }}>
                <Link component={RouterLink} to="/cart">
                    <Box style={{ display: "flex", alignItems: "center"}}>
                        <Badge badgeContent={cartTotal} invisible={!cartTotal} classes={{ badge: classes.badgeStyle }}>
                            <HiOutlineShoppingCart className={classes.navbarIcon} />
                        </Badge>
                    </Box>
                </Link>
            </IconButton>
            <IconButton sx={{ padding: {xs: "2%", sm: "8px"} }}>
                <Link component={RouterLink} to={isLogin ? "/wishlist" : "sign-in"}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <HiOutlineHeart className={classes.navbarIcon} />
                    </Box>
                </Link>
            </IconButton>
            <IconButton sx={{ padding: {xs: "2%", sm: "8px"} }}>
                <Link component={RouterLink} to={isLogin ? "/dashboard" : "sign-in"}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <HiOutlineUserCircle className={classes.navbarIcon} />
                    </Box>
                </Link>
            </IconButton>
            <Hidden mdUp>
                {_.size(currencyRate) > 0 && selectCurrency}
            </Hidden>
        </Box>
    );

    const loginSignUpContainer = (pathname) => {
        switch (pathname) {
            case '/sign-up':
                return <Paper square className={classes.loginHeader} elevation={0} style={{ backgroundColor: `${theme.palette.primary.background}`, boxShadow: 'none !important' }}>
                    <Grid item xs={4}>
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ paddingBottom: '25px' }}>
                            <div style={{ width: '25%' }}>
                                <Link component={RouterLink} to={"/"}>
                                    <img alt='ReLuxe' src="/images/logo-text.png" style={{ height: '10%', width: '100%', marginTop: '20px' }} />
                                </Link>
                            </div>
                        </Box>
                    </Grid>
                </Paper>;
            case '/sign-in':
                return <Container maxWidth="xl" className={classes.loginHeader}>
                    <Grid item xs={4}>
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <div style={{ width: '30%' }}>
                                <Link component={RouterLink} to={"/"}>
                                    <img alt='ReLuxe' src="/images/logo-text.png" style={{ height: '10%', width: '100%', marginTop: '20px' }} />
                                </Link>
                            </div>
                        </Box>
                    </Grid>
                </Container>
            case '/dashboard':
            case '/dashboard/profile':
                return <Paper square className={classes.loginHeader} elevation={0} style={{ backgroundColor: `${theme.palette.primary.background}`, boxShadow: 'none !important' }}>
                    <Container maxWidth="xl" style={{ padding: '1% 5%' }}>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <Link component={RouterLink} to={"/"}>
                                    <img alt='ReLuxe' src="/images/logo-text.png" className={classes.logoStyle} />
                                </Link>
                            </Grid>
                            <Grid item display="flex" alignItems="center" to="/dashboard" component={RouterLink} style={{ textDecoration: 'none' }}>
                                <Typography variant="h6" style={{ padding: '5px 10px 0px 0px' }} >{userState.name}</Typography>
                                <div style={{ padding: 6, borderRadius: 48, margin: '20 auto', width: 'fit-content', height: 'fit-content' }}>
                                    <Avatar
                                        alt="profile_picture"
                                        src={userState.profile_picture ? userState.profile_picture.file_name : null}
                                        className={classes.avatarStyle}
                                    >
                                        {userState.name.substring(0, 1)}
                                    </Avatar>
                                </div>
                            </Grid>
                        </Box>
                    </Container>
                </Paper>;
            default:
                return null;
        }
    };

    const linkList = [
        { url: "/our-resorts", label: t('title.ourResorts'), show: true },
        { url: "/activities", label: t('title.activities'), show: true },
        // { url: "/golf-courses", label: t('title.golfCourses'), show: true },
        { url: "/about", label: t('title.about'), show: true },
        { url: "/news", label: t('title.news'), show: true },
    ];

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [isScrolled, setIsScrolled] = useState(false);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 0) {
            setIsScrolled(true);
        }
        else if (scrolled <= 0) {
            setIsScrolled(false);
        }
    };

    window.addEventListener('scroll', toggleVisible)

    return (
        <>
            {
                _.includes(['/sign-in', '/sign-up', '/dashboard', '/dashboard/profile'], pathname) ?
                    <AppBar
                        color={"white"}
                        elevation={0}
                        position={"static"}
                    >
                        {loginSignUpContainer(pathname)}
                    </AppBar>
                    :
                    <>
                        <AppBar
                            color={isScrolled ? "white" : "transparent"}
                            elevation={isScrolled ? 4 : 0}
                            position={isScrolled ? "fixed" : "absolute"}
                            style={{ transition: 'all .5s' }}
                        >
                            <Container maxWidth="xl">
                                <Toolbar style={{ margin: "1% 3%", paddingLeft: 0, paddingRight: 0 }}>
                                    <Hidden mdUp>
                                        <Box style={{ backgroundColor: 'transparent', paddingRight: 10, margin: 0 }}>
                                            <IconButton
                                                aria-label="account of current user"
                                                aria-controls="menu-appbar"
                                                aria-haspopup="true"
                                                onClick={handleOpenNavMenu}
                                                color="inherit"
                                                sx={{ padding: {xs: "0px", sm: "8px"} }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                            <Menu
                                                id="menu-appbar"
                                                anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
                                                //keepMounted
                                                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                                                open={Boolean(anchorElNav)}
                                                onClose={handleCloseNavMenu}
                                                sx={{ display: { xs: 'block', md: 'none' }, }}
                                                marginThreshold={0}
                                                elevation={0}
                                                PaperProps={{ square: true }}
                                                transitionDuration={0}
                                                autoFocus={false}
                                            //anchorEl={anchorElNav}
                                            >
                                                <Box style={{ paddingLeft: 20, paddingRight: 50, paddingTop: 10 }}>
                                                    <Box style={{ display: 'flex', paddingBottom: 10 }}>
                                                        <IconButton
                                                            aria-label="account of current user"
                                                            aria-controls="menu-appbar"
                                                            aria-haspopup="true"
                                                            onClick={handleCloseNavMenu}
                                                            color="inherit"
                                                            style={{ marginRight: 20 }}
                                                        >
                                                            <MenuIcon />
                                                        </IconButton>
                                                        <div onClick={handleCloseNavMenu}>
                                                            <Link component={RouterLink} to={"/"} style={{ marginRight: "5%" }}>
                                                                <img className={classes.logoStyle} alt='ReLuxe' src="/images/logo-text.png" />
                                                            </Link>
                                                        </div>
                                                    </Box>
                                                    <hr style={{ width: '90%', marginLeft: 30 }}></hr>
                                                    {
                                                        _.map(linkList, list => {
                                                            return (
                                                                <Link key={list.url} component={RouterLink} underline="none" to={list.url}>
                                                                    <MenuItem onClick={handleCloseNavMenu} style={{ marginLeft: 10 }}>
                                                                        <Typography className={classes.headerLinkText} style={{ fontFamily: (pathname === list.url) ? "PlayfairDisplayItalic" : "PlayfairDisplayRegular" }}>{list.label}</Typography>
                                                                    </MenuItem>
                                                                </Link>
                                                            )
                                                        })
                                                    }
                                                    <hr style={{ width: '90%', marginLeft: 30 }}></hr>
                                                    {
                                                        isLogin ?
                                                            <MenuItem onClick={signOutUser} style={{ marginLeft: 10 }}>
                                                                <Box>
                                                                    <Typography className={classes.headerLinkText} style={{ fontFamily: "PlayfairDisplayRegular" }}>{t('title.logout')}</Typography>
                                                                </Box>
                                                            </MenuItem>
                                                            :
                                                            <MenuItem onClick={handleCloseNavMenu} component={RouterLink} to={"sign-in"} style={{ marginLeft: 10 }}>
                                                                <Box>
                                                                    <Typography className={classes.headerLinkText} style={{ fontFamily: "PlayfairDisplayRegular" }}>{t('title.login')}</Typography>
                                                                </Box>
                                                            </MenuItem>
                                                    }
                                                </Box>
                                            </Menu>
                                        </Box>
                                    </Hidden>
                                    <Link component={RouterLink} to={"/"}>
                                        <img className={classes.logoStyle} alt='ReLuxe' sx={{ padding: {xs : 0, md: isScrolled ? 10 : 0}, transition: "all 0.3s" }} src="/images/logo-text.png" onClick={handleCloseNavMenu} />
                                    </Link>
                                    <Box className={classes.centerContent}>
                                        <Hidden mdDown>
                                            <Box display="flex" justifyContent="space-around">
                                                {
                                                    _.map(linkList, list => {
                                                        return (
                                                            <Link component={RouterLink} underline="none" to={list.url} key={list.url}>
                                                                <Typography className={classes.headerLinkText} style={{ fontFamily: (pathname === list.url) ? "PlayfairDisplayItalic" : "PlayfairDisplayRegular" }}>{list.label}</Typography>
                                                            </Link>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Hidden>
                                    </Box>
                                    {/* <div className={classes.centerContent} /> */}
                                    {iconBar}
                                </Toolbar>
                            </Container>
                        </AppBar>
                    </>
            }
            {
                _.includes(['/', '/sign-in', '/sign-up', '/news', '/about', '/activities', '/our-resorts', '/dashboard', '/dashboard/profile'], pathname)
                    ?
                    null
                    :
                    <Box className={isScrolled ? null : classes.headerTopBox} />
            }
        </>
    )
})

const useStyles = makeStyles((theme) => ({
    footerLinkText: {
        color: "#fff !important",
        lineHeight: '1.4rem !important',
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main + ' !important',
            fontWeight: 'bold',
        },
    },
    logoStyle: {
        objectFit: 'contain',
        [theme.breakpoints.up('xs')]: {
            height: 35,
        },
        [theme.breakpoints.up('sm')]: {
            height: 50,
        },
        [theme.breakpoints.up('md')]: {
            height: 65,
        },
    },
    navbarIcon: {
        color: theme.palette.secondary.main,
        [theme.breakpoints.up('xs')]: {
            fontSize: "20px !important",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "30px !important",
        },
    },
    centerContent: {
        flexGrow: 1
    },
    badgeStyle: {
        backgroundColor: theme.palette.red.main,
        color: "#fff",
    },
    headerLinkText: {
        color: theme.palette.secondary.main,
        "&:hover": {
            fontStyle: "italic !important",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1rem !important",
            margin: "0px 5px !important",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.25rem !important",
            margin: "0px 10px !important",
        },
    },
    loginHeader: {
        paddingTop: '20px',
        // [theme.breakpoints.up('sm')]: {
        paddingBottom: '20px'
        // },
    },
    headerTopBox: {
        boxShadow: "#d6d6d6 0px 5px 15px -2px",
        [theme.breakpoints.up('xs')]: {
            minHeight: "70px !important",
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: "100px !important",
        },
        // [theme.breakpoints.up('md')]: {
        //     minHeight: "120px !important",
        // },
    },
    avatarStyle: {
        width: '50px !important',
        height: '50px !important',
        textTransform: 'uppercase',
        margin: 0,
        backgroundColor: theme.palette.primary.main + '!important',
    }
}));