import { getUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Box, Button, FormControl, Grid, InputLabel, Container, LinearProgress, MenuItem, Select, TextField, Typography, CircularProgress } from '@mui/material';

import PackageCard from '@components/PackageCard';
import ReviewCard from '@components/ReviewCard';
import BackToPrevious from '@components/BackToPrevious';

const Review = () => {
    let { id } = useParams();
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState({});
    const [reviewPackage, setReviewPackage] = useState({
        name: ''
    });

    useEffect(() => {
        setLoading(true);
        getUrl(`/reviews-list/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setReviews(response.data);
                setReviewPackage({ name: response.package_name});
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, []);

    return (
        <Box className={classes.paper}>
            <Container maxWidth="xl" style={{ padding: '3%' }}>
            <BackToPrevious />
            <Typography variant="h5" className={classes.titleStyle}>{reviewPackage.name}</Typography>
            <Typography variant="h5" className={classes.titleStyle}>{t('title.reviews')}</Typography>
            {/* DATA */}
            {
                _.size(reviews) > 0
                    ?
                    <Grid container spacing={3} style={{ padding: "3%" }}>
                        {
                            _.map(reviews, (reviewItem, key) => (
                                <Grid item xs={12} sm={6} key={key}>
                                    <ReviewCard review={reviewItem} detail={true} />
                                </Grid>
                            ))
                        }
                    </Grid>
                    :
                    loading
                        ?
                        <Box style={{ marginTop: 20 }}>
                            <LinearProgress size={20} />
                        </Box>
                        :
                        <Box style={{ marginTop: 20, textAlign: "center" }}>
                            {t('general.noData')}
                        </Box>
            }
            </Container>
        </Box>
    )
}

export default Review;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    titleStyle: {
        fontWeight: "bold !important",
        color: theme.palette.primary.main,
        marginLeft: "20px !important",
        marginBottom: "20px !important"
    },
}))