
import { getUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Box, Button, Grid, LinearProgress, Link, Container, Typography } from '@mui/material';
import { FiAlertCircle } from "react-icons/fi";

import BackToPrevious from '@components/BackToPrevious';
import CartStepper from '@components/CartStepper';
import CartItemCard from '@guestpages/Cart/CartItemCard';

const Cart = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { uuid, accessToken } = useSelector(state => state.general);
    const [loading, setLoading] = useState(false);

    const [carts, setCarts] = useState({});
    const [systemPublicHoliday, setSystemPublicHoliday] = useState({});

    useEffect(() => {
        setLoading(true);
        getUrl("/carts", { guest_token: uuid ? uuid : null }).then(response => {
            setLoading(false);
            if (response.status) {
                setCarts(response.data.listing);
                setSystemPublicHoliday(response.data.system_public_holidays);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [uuid, accessToken]);

    const guestLoginReminder = () => {
        return (
            <Box className={classes.guestNoteStyle}>
                <Grid container spacing={2} className={classes.guestNoteBox}>
                    <Grid item xs={2} sm={1} className={classes.gridItemBox}>
                        <FiAlertCircle size={20} />
                    </Grid>
                    <Grid item xs={10} sm={7} className={classes.gridItemBox}>
                        {t('checkout.guestNote')}
                    </Grid>
                    <Grid item xs={6} sm={2} className={classes.gridItemBox}>
                        <Link underline='none' component={RouterLink} to={`/sign-in`}>
                            <Button variant="contained">
                                {t('auth.signIn')}
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6} sm={2} className={classes.gridItemBox}>
                        <Link underline='none' component={RouterLink} to={`/sign-up`}>
                            <Button variant="contained">
                                {t('auth.signUp')}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box className={classes.paper}>
            <Container maxWidth="xl">
            <BackToPrevious />
            {/* CART STEPPER */}
            <CartStepper activeStep={0} />
            {/* GUEST NOTE */}
            {(_.size(carts) > 0 && !accessToken) ? guestLoginReminder() : null}
            {/* DATA */}
            {
                _.size(carts) > 0
                    ?
                    <CartItemCard carts={carts} setCarts={setCarts} systemPublicHoliday={systemPublicHoliday} />
                    :
                    loading
                        ?
                        <Box style={{ marginTop: 20 }}>
                            <LinearProgress size={20} />
                        </Box>
                        :
                        <Box style={{ margin: "10% 0%", textAlign: "center" }}>
                            <Typography variant="h5" style={{ fontFamily: "FuturaPTDemi" }}>
                                {t('general.noData')}
                            </Typography>
                        </Box>
            }
            </Container>
        </Box>
    )
}

export default Cart;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    guestNoteStyle: {
        backgroundColor: theme.palette.primary.main + ' !important',
        margin: "auto",
        marginTop: "50px",
        borderRadius: 5,
        maxWidth: 700
    },
    guestNoteBox: {
        padding: "0px 16px 16px 16px",
        color: theme.palette.primary.secondary + ' !important',
    },
    gridItemBox: {
        margin: "auto",
        textAlign: 'center',
    },
}))