import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const PRIMARY_MAIN = "#ad9e80";
const SECONDARY_MAIN = "#183f3d";

const theme = createTheme({
    palette: {
        primary: {
            main: PRIMARY_MAIN,
            secondary: '#574f41',
            background: "#D6CFBF",
            lightMain: '#D5D3CE',
        },
        secondary: {
            main: SECONDARY_MAIN,
            secondary: '#0f2d2b',
        },
        green: {
            main: '#34bf91',
        },
        red: {
            main: '#e83a3a',
        },
        black: {
            main: '#020202'
        },
        white: {
            main: '#fff'
        },
        gray: {
            main: '#848484',
            secondary: '#b5b5b5',
            ltext: '#999999',
            dtext: '#606060',
            light: '#c1c1c1',
            superLight: '#eaeaea',
            tableHeader: '#dbdbdb',
            tableHeaderText: '#606060',
            disable: '#cecece',
        },
        indicator: {
            green: '#47ba80',
            red: '#f46464',
            yellow: '#f4c04e',
            disable: '#c1c1c1',
        },
        button: {
            main: PRIMARY_MAIN,
            borderMain: '2px solid ' + PRIMARY_MAIN,
            darkRed: '#cd4949',
            yellow: '#ffcb58',
            green: '#47ba80',
            orange: '#ff5b00',
        },
        shadow: {
            main: '#ffe6aa'
        },
        text:{
            main:'#6c6d6a'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'capitalize',
                    borderRadius: "0px",
                },
                outlinedPrimary: {
                    color: PRIMARY_MAIN,
                    backgroundColor: '#fff',
                    border: `solid 2px ${PRIMARY_MAIN}`,
                    '&:hover': {
                        color: "#fff",
                        backgroundColor: PRIMARY_MAIN,
                        border: `solid 2px ${PRIMARY_MAIN}`,
                    },
                },
                outlinedSecondary: {
                    color: PRIMARY_MAIN,
                    backgroundColor: '#fff',
                    border: `solid 2px ${SECONDARY_MAIN}`,
                    '&:hover': {
                        color: "#fff",
                        backgroundColor: SECONDARY_MAIN,
                        border: `solid 2px ${SECONDARY_MAIN}`,
                    },
                },
                outlinedWhite: {
                    color: '#fff',
                    backgroundColor: PRIMARY_MAIN,
                    border: `solid 2px ${'#fff'}`,
                    '&:hover': {
                        color: PRIMARY_MAIN,
                        backgroundColor: "#fff",
                    },
                },
                containedPrimary: {
                    color: "#fff",
                    backgroundColor: PRIMARY_MAIN,
                    border: `solid 2px ${PRIMARY_MAIN}`,
                    '&:hover': {
                        color: PRIMARY_MAIN,
                        backgroundColor: '#fff',
                        border: `solid 2px ${PRIMARY_MAIN}`,
                    },
                },
                containedSecondary: {
                    color: "#fff",
                    backgroundColor: SECONDARY_MAIN,
                    border: `solid 2px ${SECONDARY_MAIN}`,
                    '&:hover': {
                        color: SECONDARY_MAIN,
                        backgroundColor: '#fff',
                        border: `solid 2px ${SECONDARY_MAIN}`,
                    },
                },
                containedWhite: {
                    color: PRIMARY_MAIN,
                    backgroundColor: "#fff",
                    border: 'solid 2px #fff',
                    '&:hover': {
                        color: '#fff',
                        backgroundColor: PRIMARY_MAIN,
                    },
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(255,255,255,0.4)",
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                underlineHover: {
                    border: `solid 1.5px transparent`,
                    '&:hover': {
                        textDecoration: "none",
                        border: `solid 1.5px ${PRIMARY_MAIN}`,
                    },
                },
            },
        },
    },
    typography: {
        allVariants: {
            fontFamily: 'FuturaPTBook',
            color: SECONDARY_MAIN,
        },
        h1: {
            fontFamily: 'PlayfairDisplayRegular',
        },
        h2: {
            fontFamily: 'PlayfairDisplayRegular',
        },
        h3: {
            fontFamily: 'PlayfairDisplayRegular',
        },
    },
    root: {
        fontSize: "20px"
    }
});

export default responsiveFontSizes(theme);