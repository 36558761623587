import _ from 'lodash';
import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { getUrl } from '@helpers/api';
import { Box, Button, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography, Container, Pagination, PaginationItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import BackToPrevious from '@components/BackToPrevious';
import VoucherItemCard from './VoucherItemCard';
import AccountSidebar from '@components/AccountSidebar';

const Voucher = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();
    const [vouchers, setVouchers] = useState({});
    const [statusList, setStatusList] = useState({});
    const [dayList, setDayList] = useState({});
    const [systemPublicHoliday, setSystemPublicHoliday] = useState({});
    const [refreshData, setRefreshData] = useState(false);

    let code = searchParams.get('code');
    let voucher_name = searchParams.get('voucher_name');
    let status = searchParams.get('status');
    let page = searchParams.get('page');
    const [searchField, setSearchField] = useState({
        code: code ? code : '',
        voucher_name: voucher_name ? voucher_name : '',
        status: status ? status : 0,
        page: page ? page : 1,
    });

    useEffect(() => {
        setLoading(true);
        getUrl("/voucher", searchField).then(response => {
            setLoading(false);
            if (response.status) {
                setTotal(response.data.listing.last_page);
                setVouchers(response.data.listing.data);
                setStatusList(response.data.status_list);
                setDayList(response.data.available_days_list);
                setSystemPublicHoliday(response.data.system_public_holidays);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [code, voucher_name, status, page, refreshData]);

    const handlePaginationChange = (event, value) => {
        searchData(value);
    }

    const searchData = (page) => {
        setSearchParams({
            code: searchField.code ? searchField.code : '',
            voucher_name: searchField.voucher_name ? searchField.voucher_name : '',
            status: searchField.status ? searchField.status : 0,
            page: page,
        });
        setSearchField({ ...searchField, page: page });
    }
    const clearSearchData = () => {
        setSearchParams({ page: 1 });
        setSearchField({
            code: '',
            voucher_name: '',
            status: 0,
            page: 1,
        });
    }

    return (
        <Box className={classes.paper}>
            <Container maxWidth="xl">
                <AccountSidebar>
                    <Typography variant="h5" className={classes.titleStyle}>{t('title.vouchers')}</Typography>
                    {/* SEARCH */}
                    <Grid container columnSpacing={2} style={{ paddingBottom: 10 }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={t('voucher.code')}
                                    value={searchField.code}
                                    name="code"
                                    onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={t('voucher.voucherName')}
                                    value={searchField.voucher_name}
                                    name="voucher_name"
                                    onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t('voucher.status')}</InputLabel>
                                    <Select
                                        value={searchField.status}
                                        label={t('voucher.status')}
                                        name="status"
                                        onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                    >
                                        <MenuItem value={0}>{t('general.pleaseSelect') + ' ' + t('voucher.status')}</MenuItem>
                                        {_.map(statusList, (resultItem, code) => {
                                            return <MenuItem key={code} value={code}>{resultItem[i18n.language] ? resultItem[i18n.language] : resultItem.en}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={3} md={1}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, marginBottom: 1, width: '100%' }, }} >
                                <Button variant="contained" color="primary" className={classes.buttonStyle} onClick={() => searchData(1)}>{t('general.search')}</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={3} md={1}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, marginBottom: 1, width: '100%' }, }} >
                                <Button variant="contained" color="primary" className={classes.buttonStyle} onClick={clearSearchData}>{t('general.clear')}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* DATA */}
                    {
                        _.size(vouchers) > 0
                            ?
                            <Grid>
                                {
                                    _.map(vouchers, voucher => {
                                        return (
                                            <Grid item xs={12} key={voucher.id}>
                                                <VoucherItemCard voucher={voucher} dayList={dayList} systemPublicHoliday={systemPublicHoliday} setRefreshData={setRefreshData} />
                                            </Grid>
                                        )
                                    })
                                }
                                <Box style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                                    <Pagination count={total} page={parseInt(searchField.page)} onChange={handlePaginationChange} color="secondary" variant="text"
                                        renderItem={(item) => (
                                            <PaginationItem
                                                sx={{ color: theme.palette.secondary.main }}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>
                            :
                            loading
                                ?
                                <Box style={{ marginTop: 20 }}>
                                    <LinearProgress size={20} />
                                </Box>
                                :
                                <Box style={{ marginTop: 20, textAlign: "center" }}>
                                    {t('general.noData')}
                                </Box>
                    }
                </AccountSidebar>
            </Container>
        </Box>
    )
}

export default Voucher;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    titleStyle: {
        fontWeight: "bold !important",
        color: theme.palette.primary.main,
        marginTop: "10px !important",
    },
    paginationStyle: {
        "& > *": {
            marginTop: theme.spacing(2),
            justifyContent: "center",
            display: 'flex'
        }
    }
}))