import { postUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import { Button, Card, CardContent, CircularProgress, Container, Grid, Box, TextField, Typography, Link, FormControlLabel, Checkbox, Tabs, Tab } from '@mui/material';
import theme from "../../theme";

import BackToPrevious from '@components/BackToPrevious';

import { FaFacebook } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";

const INITIAL_STATE = {
    name: '',
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    mobile: '',
    // ic: '',
    password: '',
    agent_username: '',
    // password_confirmation: '',
    type: '',
    subscribe_status: false,
};

const SignUp = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [registerField, setRegisterField] = useState(INITIAL_STATE);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const [searchParams/** , setSearchParams */] = useSearchParams();
    const [tab, setTab] = React.useState(false);
    const [agreeToPrivacy, setAgreeToPrivacy] = useState(false);
    const [agreeToSubscribe, setAgreeToSubscribe] = useState(false);

    useEffect(() => {
        if (searchParams.get('type')) {
            setRegisterField({ ...registerField, type: searchParams.get('type') });
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (event) => {
        setRegisterField({ ...registerField, [event.target.name]: event.target.value });
    };

    const handleChangeChk = (chkValue) => {
        setAgreeToPrivacy(chkValue.target.checked);
    }

    const handleChangeChkSubscribe = (chkValue) => {
        setRegisterField({ ...registerField, subscribe_status: !agreeToSubscribe });
        setAgreeToSubscribe(chkValue.target.checked);
    }

    const registerBtnClicked = () => {
        setLoading(true);
        if (agreeToPrivacy === false) {
            setLoading(false);
            addToast(t('auth.privacyChecked'), { appearance: 'error' });
            return;
        }

        postUrl('register', registerField).then(response => {
            setLoading(false);
            let message = response.message;
            if (!response.status) {
                setInputErrors(response.errors);
            } else {
                setInputErrors({});
                setRegisterField(INITIAL_STATE);
                setTimeout(() => {
                    if(searchParams.get('type') === 'member'){
                        navigate(`/sign-in`, { replace: true });
                    }else{
                        navigate(`/`, { replace: true });
                    }
                }, 1000);
            }

            if (message) {
                addToast(message, { appearance: response.status ? 'success' : 'error' });
            }

        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        });
    }

    return (
        <Box className={classes.bgImageWhiteRight}>
            <Container component="main" maxWidth="md" style={{ paddingBottom: "70px", paddingTop: '35px' }} >
                <div align='center'>
                    <Typography component="h1" variant="h3" style={{ textTransform: 'uppercase' }} >
                        {t('auth.createAccount')}
                    </Typography>
                </div>
                <Tabs value={tab} centered style={{ paddingTop: '30px' }}>
                    {
                        searchParams.get('type') ?
                            <div>
                                {
                                    searchParams.get('type') === 'member' ?
                                        <>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                className={classes.submit}
                                                sx={{ borderBottom: '0 !important' }}
                                            >
                                                <Typography component="h1" variant="h6" style={{ paddingLeft: '40px', paddingRight: '40px' }}>{t('auth.member')}</Typography>
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                component={RouterLink}
                                                to="/sign-up?type=agent"
                                                color="primary"
                                                size="small"
                                                className={classes.submit}
                                                sx={{ border: '0 !important', marginTop: '2px' }}
                                            >
                                                <Typography component="h1" variant="h6" style={{ paddingLeft: '20px', paddingRight: '20px' }}>{t('auth.agent')}</Typography>
                                            </Button>
                                        </>
                                        :
                                        <>
                                            <Button
                                                variant="outlined"
                                                component={RouterLink}
                                                to="/sign-up?type=member"
                                                color="primary"
                                                size="small"
                                                className={classes.submit}
                                                sx={{ border: '0 !important', marginTop: '2px' }}
                                            >
                                                <Typography component="h1" variant="h6" style={{ paddingLeft: '20px', paddingRight: '20px' }}>{t('auth.member')}</Typography>
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                className={classes.submit}
                                                sx={{ borderBottom: '0 !important' }}
                                            >
                                                <Typography component="h1" variant="h6" style={{ paddingLeft: '40px', paddingRight: '40px' }}>{t('auth.agent')}</Typography>
                                            </Button>
                                        </>
                                }
                            </div>
                            :
                            <div>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    className={classes.submit}
                                    sx={{ borderBottom: '0 !important' }}
                                >
                                    <Typography component="h1" variant="h6" style={{ paddingLeft: '40px', paddingRight: '40px' }}>{t('auth.member')}</Typography>
                                </Button>
                                <Button
                                    variant="outlined"
                                    component={RouterLink}
                                    to="/sign-up?type=agent"
                                    color="primary"
                                    size="small"
                                    className={classes.submit}
                                    sx={{ border: '0 !important', marginTop: '2px' }}
                                >
                                    <Typography component="h1" variant="h6" style={{ paddingLeft: '20px', paddingRight: '20px' }}>{t('auth.agent')}</Typography>
                                </Button>
                            </div>
                    }
                </Tabs>
                <Card className={classes.root} elevation={0} square>
                    <CardContent className={classes.cardContentRoot}>
                        {/* <BackToPrevious /> */}
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px', paddingBottom: '25px' }}>
                            <Typography component="h1" variant="h3" style={{ fontFamily: "PlayfairDisplayItalic" }} >
                                {t('auth.signUpCapt')}
                            </Typography>
                        </div>
                        <div className="paper-container">
                            <div className={classes.form} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="none"
                                            fullWidth
                                            label={t('user.firstName')}
                                            name="firstName"
                                            autoComplete="firstName"
                                            autoFocus
                                            value={registerField.firstName}
                                            onChange={handleChange}
                                            error={inputErrors?.firstName ? true : false}
                                            helperText={inputErrors?.firstName || ''}
                                            sx={{
                                                fieldset: {
                                                    borderRadius: '0px',
                                                    border: `2px solid ${theme.palette.primary.main}`,
                                                },
                                                input: { color: theme.palette.primary.main }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="none"
                                            fullWidth
                                            label={t('user.lastName')}
                                            name="lastName"
                                            autoComplete="lastName"
                                            value={registerField.lastName}
                                            onChange={handleChange}
                                            error={inputErrors?.lastName ? true : false}
                                            helperText={inputErrors?.lastName || ''}
                                            sx={{
                                                fieldset: {
                                                    borderRadius: '0px',
                                                    border: `2px solid ${theme.palette.primary.main}`,
                                                },
                                                input: { color: theme.palette.primary.main }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="none"
                                            fullWidth
                                            label={t('user.username')}
                                            name="username"
                                            autoComplete="username"
                                            value={registerField.username}
                                            onChange={handleChange}
                                            error={inputErrors?.username ? true : false}
                                            helperText={inputErrors?.username || ''}
                                            sx={{
                                                fieldset: {
                                                    borderRadius: '0px',
                                                    border: `2px solid ${theme.palette.primary.main}`,
                                                },
                                                input: { color: theme.palette.primary.main }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="none"
                                            fullWidth
                                            label={t('user.email')}
                                            name="email"
                                            autoComplete="email"
                                            value={registerField.email}
                                            onChange={handleChange}
                                            error={inputErrors?.email ? true : false}
                                            helperText={inputErrors?.email || ''}
                                            sx={{
                                                fieldset: {
                                                    borderRadius: '0px',
                                                    border: `2px solid ${theme.palette.primary.main}`,
                                                },
                                                input: { color: theme.palette.primary.main }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="none"
                                            fullWidth
                                            label={t('user.mobile')}
                                            name="mobile"
                                            autoComplete="tel-national"
                                            value={registerField.mobile}
                                            onChange={handleChange}
                                            error={inputErrors?.mobile ? true : false}
                                            helperText={inputErrors?.mobile || ''}
                                            sx={{
                                                fieldset: {
                                                    borderRadius: '0px',
                                                    border: `2px solid ${theme.palette.primary.main}`,
                                                },
                                                input: { color: theme.palette.primary.main }
                                            }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={t('user.ic')}
                                            name="ic"
                                            autoComplete="ic"
                                            autoFocus
                                            value={registerField.ic}
                                            onChange={handleChange}
                                            error={inputErrors?.ic ? true : false}
                                            helperText={inputErrors?.ic || ''}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="none"
                                            type="password"
                                            fullWidth
                                            label={t('auth.password')}
                                            name="password"
                                            autoComplete="new-password"
                                            value={registerField.password}
                                            onChange={handleChange}
                                            error={inputErrors?.password ? true : false}
                                            helperText={
                                                inputErrors?.password || 
                                                <Typography component="h1" variant="subtitle2" style={{ fontFamily: "FuturaPTBook", width: '100%', lineHeight: '14px' }} >
                                                    {t('auth.passwordCapt')}
                                                </Typography>
                                            }
                                            sx={{
                                                fieldset: {
                                                    borderRadius: '0px',
                                                    border: `2px solid ${theme.palette.primary.main}`,
                                                },
                                                input: { color: theme.palette.primary.main }
                                            }}
                                        />
                                        {/* <Typography component="h1" variant="subtitle2" style={{ fontFamily: "FuturaPTBook", width: '76%', lineHeight: '14px', paddingTop: '10px', paddingLeft: '12px' }} >
                                            {t('auth.passwordCapt')}
                                        </Typography> */}
                                    </Grid>
                                    {/* {
                                        searchParams.get('type') === 'agent' ?
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="none"
                                                    fullWidth
                                                    label={t('auth.agentUsername')}
                                                    name="agent_username"
                                                    autoComplete="agent_username"
                                                    value={registerField.agent_username}
                                                    onChange={handleChange}
                                                    error={inputErrors?.agent_username ? true : false}
                                                    helperText={inputErrors?.agent_username || ''}
                                                    sx={{
                                                        fieldset: {
                                                            borderRadius: '0px',
                                                            border: `2px solid ${theme.palette.primary.main}`,
                                                        },
                                                        input: { color: theme.palette.primary.main }
                                                    }}
                                                />
                                                <Typography component="h1" variant="subtitle2" style={{ fontFamily: "FuturaPTBook", width: '76%', lineHeight: '14px', paddingTop: '10px', paddingLeft: '12px' }} >
                                                    {t('auth.passwordCapt')}
                                                </Typography>
                                            </Grid>
                                            : null
                                    } */}
                                    {/* <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            type="password"
                                            fullWidth
                                            label={t('auth.password_confirmation')}
                                            name="password_confirmation"
                                            autoComplete="new-password"
                                            autoFocus
                                            value={registerField.password_confirmation}
                                            onChange={handleChange}
                                            error={inputErrors?.password_confirmation ? true : false}
                                            helperText={inputErrors?.password_confirmation || ''}
                                        />
                                    </Grid> */}
                                    {/* <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label={ t('auth.rememberMe') }
                                /> */}
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox name="privacy" value="privacy" color="primary" checked={agreeToPrivacy} onChange={(event) => { handleChangeChk(event) }} />}
                                            label={
                                                <>
                                                    <Typography component="h1" variant="subtitle1" style={{ lineHeight: '20px' }}>{t('auth.privacyBegin')}
                                                        <Link component={RouterLink} to="/policy?type=privacypolicy" target="_blank" underline="none" style={{ fontFamily: "FuturaPTBold", color: theme.palette.secondary.secondary }}>
                                                            {t('auth.privacyStatement')}
                                                        </Link>{t('auth.privacyAnd')}
                                                        <Link component={RouterLink} to="/policy?type=privacypolicy" target="_blank" underline="none" style={{ fontFamily: "FuturaPTBold", color: theme.palette.secondary.secondary }}>
                                                            {t('auth.privacyPolicy')}
                                                        </Link>.
                                                    </Typography>
                                                </>
                                            }
                                        />
                                        <FormControlLabel
                                            control={<Checkbox value="remember" color="primary" checked={agreeToSubscribe} onChange={(event) => { handleChangeChkSubscribe(event) }} />}
                                            label={
                                                <>
                                                    <Typography component="h1" variant="subtitle1" style={{ lineHeight: '20px' }}>{t('auth.promotions')}</Typography>
                                                </>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography component="h1" variant="subtitle2" style={{ lineHeight: '20px', paddingLeft: '20px', paddingRight: '20px' }}>{t('auth.acknowledgementFront')}
                                            <Link component={RouterLink} to="/policy?type=termcondition" target="_blank" underline="none" style={{ fontFamily: "FuturaPTBold", color: theme.palette.secondary.secondary }}>
                                                {t('auth.terms')}
                                            </Link>
                                            {t('auth.acknowledgementBack')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid display="flex" justifyContent="center" style={{ paddingTop: '30px', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            className={classes.submit}
                                            onClick={registerBtnClicked}
                                            disabled={loading ? true : false}
                                            sx={{ paddingLeft: { sm: '50px', md: '50px', lg: '50px', xl: '50px' }, paddingRight: { sm: '50px', md: '50px', lg: '50px', xl: '50px' } }}
                                        >
                                            {
                                                loading ?
                                                    <CircularProgress size={20} />
                                                    : <Typography component="h1" variant="h6">{t('auth.signUp')}</Typography>
                                            }
                                        </Button>
                                    </Grid>
                                    {/* <div style={{ flex: 1, height: '1px', backgroundColor: 'black', marginLeft: '20px', marginRight: '20px' }} />
                                    <Grid><Typography component="h1" variant="h5" style={{ marginBottom: '5px' }}>{t('auth.or')}</Typography></Grid>
                                    <div style={{ flex: 1, height: '1px', backgroundColor: 'black', marginLeft: '20px', marginRight: '20px' }} />
                                    <Grid>
                                        <FaFacebook size="40" className={classes.icon} />
                                    </Grid>
                                    <Grid>
                                        <FaGooglePlus size="40" className={classes.icon} />
                                    </Grid> */}
                                </Grid>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Container>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    cardContentRoot: {
        padding: theme.spacing(4)
    },
    paper: {
        // marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    submit: {
        // margin: theme.spacing(4, 0, 4),
        textTransform: 'uppercase !important',
        fontFamily: 'FuturaPTMedium !important',
        color: `${theme.palette.secondary.secondary} !important`,
    },
    root: {
        boxShadow: 'none',
        // marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1),
        border: `2px solid ${theme.palette.primary.main}`,
    },
    icon: {
        // color: `${theme.palette.primary.main}`,
        cursor: 'pointer',
        paddingLeft: '5px',
    },
    bgImageWhiteRight: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat', 
        backgroundBlendMode: 'multiply',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -55% top -10%',
            backgroundSize: "50%", 
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right -8% top 10%',
            backgroundSize: "20%",
        },
    },
}));

export default SignUp