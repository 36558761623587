import { putUrl, postUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import _ from 'lodash';

import { Box, Button, Card, CardContent, CircularProgress, Grid, TextField, Typography, Container, IconButton, Avatar } from '@mui/material';
import { BsPlusCircle } from 'react-icons/bs';

import { updateAddresses, updateProfile } from '@actions/userActions';
import AddressAccordion from './AddressAccordion';
import AccountSidebar from '@components/AccountSidebar';
import Document from '@userpages/Profile/Document';

const INITIAL_ADDRESS_STATE = {
    name: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    country: '',
};

const Profile = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [profileField, setProfileField] = useState({
        email: '',
        name: '',
        first_name: '',
        last_name: '',
        username: '',
        mobile: '',
        ic: '',
        profile_picture: '',
    });
    const [addAddressFormShow, setAddAddressFormShow] = useState(false);
    const [addressField, setAddressField] = useState(INITIAL_ADDRESS_STATE);
    const [passwordField, setPasswordField] = useState({
        current_password: '',
        password: '',
        password_confirmation: '',
    });
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const userState = useSelector(state => state.user);
    const { addresses } = useSelector(state => state.user);
    const userRole  =useSelector(state => state.general.role);

    useEffect(() => {
        if (userState) {
            setProfileField({
                // name: userState.name || '',
                first_name: userState.first_name || '',
                last_name: userState.last_name || '',
                username: userState.username || '',
                mobile: userState.mobile || '',
                ic: userState.ic || '',
            });
        }
    }, [userState]);

    const updateProfileBtnClicked = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("_method", "put");
        _.map(profileField, (value, name) => {
            formData.append(name, value);
        })
        postUrl("/user", formData).then(response => {
            if (response.status) {
                setInputErrors({});
                dispatch(updateProfile(response.data));
            } else {
                setInputErrors(response.errors);
            }

            if (response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const handleChange = ({ target }) => {
        setProfileField({ ...profileField, [target.name]: target.value });
    }

    const toggleAddAddress = () => {
        setAddAddressFormShow(!addAddressFormShow);
    }

    const addAddressBtnClicked = () => {
        setLoading(true);
        postUrl(`/address`, addressField).then(response => {
            if (response.status) {
                setInputErrors({});
                dispatch(updateAddresses(response.data));
                toggleAddAddress();
                setAddressField(INITIAL_ADDRESS_STATE)
            } else {
                setInputErrors(response.errors);
            }

            if (response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const handleAddressChange = ({ target }) => {
        setAddressField({ ...addressField, [target.name]: target.value });
    }

    const updatePasswordBtnClicked = () => {
        setLoading(true);
        putUrl("/user/password", passwordField).then(response => {
            if (response.status) {
                setInputErrors({});
                setPasswordField({
                    current_password: '',
                    password: '',
                    password_confirmation: '',
                });
            } else {
                setInputErrors(response.errors);
            }
            if (response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const handleSecurityChange = ({ target }) => {
        setPasswordField({ ...passwordField, [target.name]: target.value });
    }

    return (
        <Box style={{ padding: 20 }}>
            <Container maxWidth="xl">
                <AccountSidebar>
                    <Card className={classes.cardContainerStyle} style={{ borderRadius: 15, boxShadow: 'rgb(0 0 0 / 13%) 0px 0px 10px 0px' }}>
                        <CardContent className={classes.cardContentRoot}  >
                            <div className={classes.paper}>
                                <Typography component="p" variant="h5">
                                    {t('user.profile')}
                                </Typography>
                                <div className={classes.formContainer}>
                                    <div className={classes.form} noValidate>
                                        <Grid container columnSpacing={2}>
                                            {/* <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('user.name')}
                                                    value={profileField.name}
                                                    autoComplete="name"
                                                    name="name"
                                                    onChange={handleChange}
                                                    error={inputErrors?.name ? true : false}
                                                    helperText={inputErrors?.name || ''}
                                                />
                                            </Grid> */}
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={t('user.firstName')}
                                                    name="first_name"
                                                    variant="outlined"
                                                    type="text"
                                                    margin="normal"
                                                    fullWidth
                                                    value={profileField.first_name}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={inputErrors && inputErrors.first_name ? inputErrors.first_name : ''}
                                                    error={inputErrors && inputErrors.first_name ? true : false}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={t('user.lastName')}
                                                    name="last_name"
                                                    variant="outlined"
                                                    type="text"
                                                    margin="normal"
                                                    fullWidth
                                                    value={profileField.last_name}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={inputErrors && inputErrors.last_name ? inputErrors.last_name : ''}
                                                    error={inputErrors && inputErrors.last_name ? true : false}
                                                    onChange={handleChange}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={t('user.username')}
                                                    name="username"
                                                    variant="outlined"
                                                    type="text"
                                                    margin="normal"
                                                    fullWidth
                                                    value={profileField.username}
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                                    error={inputErrors && inputErrors.username ? true : false}
                                                    onChange={handleChange}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('user.email')}
                                                    value={userState.email || ''}
                                                    disabled
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('user.mobile')}
                                                    value={profileField.mobile}
                                                    autoComplete="tel-national"
                                                    name="mobile"
                                                    onChange={handleChange}
                                                    error={inputErrors?.mobile ? true : false}
                                                    helperText={inputErrors?.mobile || ''}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('user.ic')}
                                                    value={profileField.ic}
                                                    autoComplete="ic"
                                                    name="ic"
                                                    onChange={handleChange}
                                                    error={inputErrors?.ic ? true : false}
                                                    helperText={inputErrors?.ic || ''}
                                                />
                                            </Grid>
                                            <Grid item xs={3} md={1} style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ borderRadius: 24 }} align="center">
                                                    <Avatar
                                                        alt="profile_picture"
                                                        src={userState.profile_picture ? userState.profile_picture.file_name : null}
                                                        className={classes.avatarStyle}
                                                    >
                                                        {userState.name.substring(0, 1)}
                                                    </Avatar>
                                                </div>
                                            </Grid>
                                            <Grid item xs={9} md={5}>
                                                <TextField
                                                    label={t('user.profilePicture')}
                                                    type="file"
                                                    InputLabelProps={{ shrink: true }}
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    error={inputErrors?.profile_picture ? true : false}
                                                    helperText={inputErrors?.profile_picture || ''}
                                                    onChange={({ target }) => setProfileField({ ...profileField, profile_picture: target.files[0] })}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        className={classes.submit}
                                                        onClick={updateProfileBtnClicked}
                                                        disabled={loading ? true : false}
                                                    >
                                                        {
                                                            loading ?
                                                                <CircularProgress size={20} />
                                                                : t('general.update')
                                                        }
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    <Card className={classes.cardContainerStyle} style={{ borderRadius: 15, boxShadow: 'rgb(0 0 0 / 13%) 0px 0px 10px 0px' }}>
                        <CardContent className={classes.cardContentRoot}>
                            <div className={classes.paper}>
                                <Typography component="p" variant="h5">
                                    {t('user.password')}
                                </Typography>
                                <div className={classes.formContainer}>
                                    <div className={classes.form} noValidate>
                                        <Grid container columnSpacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    type="password"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('user.old', { field: t('user.password') })}
                                                    value={passwordField.current_password}
                                                    name="current_password"
                                                    onChange={handleSecurityChange}
                                                    error={inputErrors?.current_password ? true : false}
                                                    helperText={inputErrors?.current_password || ''}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    type="password"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('user.new', { field: t('user.password') })}
                                                    value={passwordField.password}
                                                    name="password"
                                                    onChange={handleSecurityChange}
                                                    error={inputErrors?.password ? true : false}
                                                    helperText={inputErrors?.password || ''}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    type="password"
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('user.confirm', { field: t('user.password') })}
                                                    value={passwordField.password_confirmation}
                                                    name="password_confirmation"
                                                    onChange={handleSecurityChange}
                                                    error={inputErrors?.password_confirmation ? true : false}
                                                    helperText={inputErrors?.password_confirmation || ''}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <Button
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        className={classes.submit}
                                                        onClick={updatePasswordBtnClicked}
                                                        disabled={loading ? true : false}
                                                    >
                                                        {
                                                            loading ?
                                                                <CircularProgress size={20} />
                                                                : t('general.update')
                                                        }
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    {/* Document for Member-Agent */}
                    {
                        // _.includes( userRole , 'member-agent') &&
                        // <Card className={classes.cardContainerStyle} style={{ borderRadius: 15, boxShadow: 'rgb(0 0 0 / 13%) 0px 0px 10px 0px' }}>
                        //     <CardContent className={classes.cardContentRoot}>
                        //         <Document />
                        //     </CardContent>
                        // </Card>
                    }
                    <Card className={classes.cardContainerStyle} style={{ borderRadius: 15, boxShadow: 'rgb(0 0 0 / 13%) 0px 0px 10px 0px' }}>
                        <CardContent className={classes.cardContentRoot}>
                            <div className={classes.paper}>
                                <Box display="flex" flexDirection="row" justifyContent="space-between">
                                    <Typography component="p" variant="h5">
                                        {t('user.addresses')}
                                    </Typography>
                                    <IconButton onClick={toggleAddAddress} color="primary" aria-label="add address" component="span">
                                        <BsPlusCircle />
                                    </IconButton>
                                </Box>
                                {addAddressFormShow ?
                                    <Box sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }}>
                                        <div className={classes.formContainer} noValidate>
                                            <Grid container>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        label={t('address.name')}
                                                        value={addressField.name}
                                                        autoComplete="name"
                                                        name="name"
                                                        onChange={handleAddressChange}
                                                        error={inputErrors?.name ? true : false}
                                                        helperText={inputErrors?.name || ''}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container columnSpacing={2}>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        rows={2}
                                                        multiline
                                                        label={t('address.address1')}
                                                        value={addressField.address1}
                                                        autoComplete="address-line1"
                                                        name="address1"
                                                        onChange={handleAddressChange}
                                                        error={inputErrors?.address1 ? true : false}
                                                        helperText={inputErrors?.address1 || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        rows={2}
                                                        multiline
                                                        label={t('address.address2')}
                                                        value={addressField.address2}
                                                        autoComplete="address-line2"
                                                        name="address2"
                                                        onChange={handleAddressChange}
                                                        error={inputErrors?.address2 ? true : false}
                                                        helperText={inputErrors?.address2 || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        label={t('address.zip')}
                                                        value={addressField.zip}
                                                        autoComplete="postal-code"
                                                        name="zip"
                                                        onChange={handleAddressChange}
                                                        error={inputErrors?.zip ? true : false}
                                                        helperText={inputErrors?.zip || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        label={t('address.city')}
                                                        value={addressField.city}
                                                        autoComplete="address-level2"
                                                        name="city"
                                                        onChange={handleAddressChange}
                                                        error={inputErrors?.city ? true : false}
                                                        helperText={inputErrors?.city || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        label={t('address.state')}
                                                        value={addressField.state}
                                                        autoComplete="address-level1"
                                                        name="state"
                                                        onChange={handleAddressChange}
                                                        error={inputErrors?.state ? true : false}
                                                        helperText={inputErrors?.state || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        margin="normal"
                                                        fullWidth
                                                        label={t('address.country')}
                                                        value={addressField.country}
                                                        autoComplete="country-name"
                                                        name="country"
                                                        onChange={handleAddressChange}
                                                        error={inputErrors?.country ? true : false}
                                                        helperText={inputErrors?.country || ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="flex-end">
                                                        <Button
                                                            type="submit"
                                                            variant="contained"
                                                            color="primary"
                                                            size="large"
                                                            className={classes.submit}
                                                            onClick={addAddressBtnClicked}
                                                            disabled={loading ? true : false}
                                                        >
                                                            {loading ?
                                                                <CircularProgress size={20} />
                                                                : t('general.create')}
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Box>
                                    : null}
                                <Box paddingTop={1}>
                                    {_.map(addresses, addressItem => <AddressAccordion key={addressItem.id} {...addressItem} />)}
                                </Box>
                            </div>
                        </CardContent>
                    </Card>
                </AccountSidebar>
            </Container>
        </Box>
    )
}

export default Profile;

const useStyles = makeStyles((theme) => ({
    cardContainerStyle: {
        marginBottom: 10,
        borderRadius: 15,
        boxShadow: 'none'

    },
    formContainer: {
        // paddingTop: theme.spacing(3),
        // paddingBottom: theme.spacing(3),
        width: '100%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },
    cardContentRoot: {
        padding: theme.spacing(6),
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        borderRadius: 15
    },
    avatarStyle: {
        width: '50px !important',
        height: '50px !important',
        textTransform: 'uppercase',
        margin: 0,
        backgroundColor: theme.palette.primary.main + '!important',
    },
}))