import React from 'react';
import { Typography, Link } from '@mui/material';
import { DEFAULT_EMAIL } from '@configs/config';

const CookiePolicy = () => {
    return (
        <div style={{ textAlign: 'justify' }}>
            <Typography style={{ textDecoration: "underline", fontFamily: 'FuturaPTBold' }}>Cookie Policy</Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            At ReLuxe, we use cookies to enhance your browsing experience and to personalize the content and advertisements we show you. This policy provides information on what cookies are, how we use them, and how you can manage them. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>What Are Cookies?</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            Cookies are small text files that are stored on your device (computer, mobile phone, tablet, etc.) when you visit a website. They are used to remember your preferences and to keep track of your activity on the website. Cookies can also be used to collect information about your browsing habits, such as the pages you visit, the links you click on, and the time you spend on the site. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>How Do We Use Cookies?</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            We use cookies to provide a better user experience and to analyze our website traffic. This information helps us to improve the content and functionality of our website and to deliver more relevant advertisements to you. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            We use the following types of cookies on our website:
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            <b>Essential Cookies</b>: These cookies allow us to manage this Website and ensure it functions properly. They are essential to help you navigate around this Website and provide you with an improved surfing experience. If these cookies are disabled, we will not be in a position to offer you our services. We also use these cookies for fraud detection and prevention purposes. You do not need to log in repeatedly to access different pages once you have logged into this Website. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            <b>Performance Cookies</b>: These cookies collect information on how you use our website, such as which pages you visit and which links you click on. This information is used to analyze website performance and to make improvements to the site. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            <b>Functional Cookies</b>: These cookies store your preferences, such as language and font size, so that you don't have to re-enter them every time you visit our website. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            <b>Advertising Cookies</b>: Advertising cookies enable us to limit the number of times you see the same ad and to ensure that the ad content is relevant to your interests. These cookies, together with an identifier linked to your IP address, can track your activity across various websites and thus display ads in accordance with your preference. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>How Can I Manage Cookies?</Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            You can accept or decline our use of cookies at any time by managing and/or disabling all or some of the cookies from your browser settings. However, by doing so, you may not be able to enjoy the full functionality of this Website. Cookies settings can normally be found in the preference or settings menu from your browser. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            If you have any questions or concerns about our use of cookies, please contact us by email at <Link href={`mailto:${DEFAULT_EMAIL}`}>{DEFAULT_EMAIL}</Link>.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            By using our website, you consent to our use of cookies in accordance with this policy. If you do not agree with all the terms and conditions or any subsequent updates, modifications, or amendments, you must stop using the ReLuxe platform and its services. 
            </Typography>
        </div>
    )
}

export default CookiePolicy;