import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { postUrl } from '@helpers/api';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router-dom';

import { BottomNavigation, BottomNavigationAction, Box, Container, Grid, Hidden, Link, Paper, Typography, Divider, TextField, Button, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { BiCart } from "react-icons/bi";
import { FiHome, FiUser } from "react-icons/fi";
import { HiOutlineTicket } from "react-icons/hi";
import { GiPalmTree } from "react-icons/gi";
import { TbSwimming } from "react-icons/tb";
import { IoCallOutline, IoLogoWhatsapp, IoMail } from "react-icons/io5";
import { FaFacebookF, FaInstagram, FaYoutube, FaTwitter, FaTicketAlt } from "react-icons/fa";
import { MdHome, MdLocationPin, MdHomeWork } from "react-icons/md";

import { changeDefaultLanguage } from '@actions/generalActions';
import { COMPANY_FULLNAME, APP_NAME } from '@configs/config';

const COMPANY_INFO = {
    "company_no": "201801006674 / 1268688-X",
    "previously_company_name": "Previously Borneo Eastern Travel Sdn Bhd",
    "lesen": "No. Lesen KPK/LN : 9681",
    "address": ["B-1-22, Block B, Riverson Walks,", "Coastal Highway, 88100 Kota Kinabalu,", "Sabah, Malaysia."],
    "phone": "+6 088 210 069",
    "whatsapp": "+6 019 808 6933",
    "email": "booking@reluxeofficial.com",
}

const INITIAL_STATE = {
    name: '',
    email: '',
};

const AppFooter = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const location = useLocation();

    const { t, i18n } = useTranslation();
    const { isLogin } = useSelector(state => state.general);
    const { addToast } = useToasts();

    const [value, setValue] = React.useState(location.pathname);
    const [loading, setLoading] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const [subscribe, setSubscribe] = useState(INITIAL_STATE);

    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const changeLanguage = lang => {
        i18n.changeLanguage(lang);
        dispatch(changeDefaultLanguage(lang));
    }

    const handleChange = (event) => {
        setSubscribe({ ...subscribe, [event.target.name]: event.target.value });
    };

    const registerBtnClicked = () => {
        setLoading(true);
        postUrl('/subscribes', subscribe).then(response => {
            setLoading(false);
            let message = response.message;
            if (!response.status) {
                setInputErrors(response.errors);
            } else {
                setInputErrors({});
                setSubscribe(INITIAL_STATE)
            }

            if (message) {
                addToast(message, { appearance: response.status ? 'success' : 'error' });
            }

        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        });
    }

    const quickLinkList = [
        { url: "/our-resorts", label: t('title.ourResorts'), show: true },
        { url: "/activities", label: t('title.activities'), show: true },
        { url: "/about", label: t('title.aboutUs'), show: true },
        { url: "/news", label: t('title.news'), show: true },
        // { url: "/", label: t('title.gallery'), show: true },
        // { url: "/", label: t('title.blog'), show: true },
        // { url: "/", label: t('title.contactUs'), show: true },
        { url: "/policy?type=termcondition", label: t('title.tnc'), show: true },
        { url: "/policy?type=returnrefundpolicy", label: t('title.rnr'), show: true },
        { url: "/policy?type=privacypolicy", label: t('title.pp'), show: true },
        { url: "/policy?type=cookiepolicy", label: t('title.cp'), show: true },
    ];

    const externalLinkList = [
        { url: "/", logo: <FaFacebookF size={matchDownMd ? (matchDownSm ? 14 : 23) : 30} className={classes.externalLogo} /> },
        { url: "/", logo: <FaInstagram size={matchDownMd ? (matchDownSm ? 14 : 23) : 30} className={classes.externalLogo} /> },
        { url: "/", logo: <FaYoutube size={matchDownMd ? (matchDownSm ? 14 : 23) : 30} className={classes.externalLogo} /> },
        { url: "/", logo: <FaTwitter size={matchDownMd ? (matchDownSm ? 14 : 23) : 30} className={classes.externalLogo} /> },
    ];

    const reluxeSubLogo = [
        { color: '/images/footerSubLogo/Mantanani_Colour.png', white: '/images/footerSubLogo/Mantanani_White.png', link: '/' },
        // { color: '/images/footerSubLogo/Dinawan_Colour.png', white: '/images/footerSubLogo/Dinawan_White.png', link: '/' },
        // { color: '/images/footerSubLogo/ThreeIsland_Colour.png', white: '/images/footerSubLogo/ThreeIsland_White.png', link: '/' },
        // { color: '/images/footerSubLogo/BentongFarm_Colour.png', white: '/images/footerSubLogo/BentongFarm_White.png', link: '/' },
        { color: '/images/footerSubLogo/PalmBar_Colour.png', white: '/images/footerSubLogo/PalmBar_White.png', link: '/' },
    ];

    const linkList = (dataList) => {
        return (
            <>
                {
                    _.map(dataList, (list, key) => {
                        if (list.show) {
                            if (list.url) {
                                return (
                                    <Link component={RouterLink} underline="none" to={list.url} key={key}>
                                        <Typography variant="h6" sx={{ fontSize: { xs: 12, sm: 14, md: 18 } }} className={classes.footerLinkText}>{list.label}</Typography>
                                    </Link>
                                )
                            } else {
                                return (
                                    <Typography variant="h6" sx={{ fontSize: { xs: 12, sm: 14, md: 18 } }} className={classes.footerText} key={key}>{list.label}</Typography>
                                )
                            }
                        }
                    })
                }
            </>
        )
    }

    const logoDisplay = (
        <Grid item xs={9} sm={3}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Link component={RouterLink} to={"/"}>
                    <img alt='ReLuxe Logo' src="/images/logo-text-white.png" className={classes.reluxeLogo} />
                </Link>
            </Box>
        </Grid>
    );

    const lesenDisplay = (
        <Grid item xs={12} md={3} sx={{ justifyContent: { xs: "flex-start", md: "flex-end" } }} style={{ display: "flex", flexDirection: "column" }}>
            <Grid sx={{ display: {xs: 'flex', md: 'inline'} }}>
                <Typography variant="h6" sx={{ fontSize: { xs: 12, sm: 16, md: 20 }, fontFamily: {xs: "FuturaPTBook", md: "FuturaPTBold"}, paddingRight: {xs: 1, md: 0} }} style={{ lineHeight: "1rem", color: "#fff" }}>{COMPANY_FULLNAME}</Typography>
                <Typography variant="caption" sx={{ fontSize: { xs: 7, sm: 11, md: 12 } }} style={{ lineHeight: "1rem", color: "#fff" }}>({COMPANY_INFO['company_no']})</Typography>
            </Grid>
            <Typography variant="caption" sx={{ fontSize: { xs: 7, sm: 11, md: 12 } }} style={{ fontStyle: "italic", display: "block", lineHeight: "1rem", color: "#fff" }}>({COMPANY_INFO['previously_company_name']})</Typography>
            <Typography variant="body2" sx={{ fontSize: { xs: 10, sm: 15, md: 15 } }} style={{ color: "#fff" }}>{COMPANY_INFO['lesen']}</Typography>
        </Grid>
    );

    const subscribeDisplay = (
        <Grid item xs={12} md={3}>
            <div noValidate>
                <Grid container>
                    <Hidden mdUp>
                        <Grid item xs={12}>
                            <Typography style={{ fontFamily: "FuturaPTBook", lineHeight: "1rem", color: theme.palette.primary.main }}>{t('footer.subscribeTitle')}</Typography>
                        </Grid>
                    </Hidden>
                    <Grid item xs={6} style={{ paddingRight: 5 }}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            color="white"
                            focused
                            placeholder={t('user.name')}
                            name="name"
                            value={subscribe.name}
                            onChange={handleChange}
                            sx={{ input: { color: "#fff" } }}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: 5 }}>
                        <TextField
                            variant="standard"
                            margin="normal"
                            fullWidth
                            color="white"
                            focused
                            placeholder={t('user.email')}
                            name="email"
                            value={subscribe.email}
                            onChange={handleChange}
                            sx={{ input: { color: "#fff" } }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" onClick={registerBtnClicked} variant="outlined" color="primary" fullWidth style={{ fontSize: 16, padding: 0 }}>{t('footer.subscribe')}</Button>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    );

    const addressDisplay = (
        <Grid item xs={8} md={4.5}>
        {
            _.map(COMPANY_INFO['address'], addr => {
                return (
                    <Typography variant="h6" sx={{ fontSize: { xs: 10, sm: 14, md: 22 }, lineHeight: { xs: "1.1rem", md: "1.4rem" } }} style={{ color: "#fff" }} key={addr}>{addr}</Typography>
                )
            })
        }
        </Grid>
    );

    const linkDisplayList = (
        <Grid item xs={12} md={4.5}>
            <Grid container>
                <Grid item xs={6}>
                    {linkList(_.slice(quickLinkList, 0, 4))}
                </Grid>
                <Grid item xs={6}>
                    {linkList(_.slice(quickLinkList, 4))}
                </Grid>
            </Grid>
        </Grid>
    );

    const externalLink = (
        <Grid item xs={4} md={3} sx={{ marginBottom: {xs: 3, sm: 0} }} style={{ display: "flex", justifyContent: "flex-end" }}>
        {
            _.map(externalLinkList, (external, key) => {
                return (
                    <Link component={RouterLink} underline="none" to={external.url} key={key}>
                        {external.logo}
                    </Link>
                )
            })
        }
        </Grid>
    );

    const contactUsLink = (
        <Grid item xs={12} md={4.5} sx={{ justifyContent: { xs: "space-between", md: "flex-start" }, flexDirection: {xs: "row", md: "column"} }} style={{ display: "flex" }}>
            <Typography variant="h6" sx={{ fontSize: { xs: 10, sm: 12, md: 20 }, lineHeight: { xs: "1.2rem", md: "1.6rem" } }} style={{ color: "#fff" }}><IoCallOutline className={classes.logo} />{COMPANY_INFO['phone']}</Typography>
            <Typography variant="h6" sx={{ fontSize: { xs: 10, sm: 12, md: 20 }, lineHeight: { xs: "1.2rem", md: "1.6rem" } }} style={{ color: "#fff" }}><IoLogoWhatsapp className={classes.logo} />{COMPANY_INFO['whatsapp']}</Typography>
            <Typography variant="h6" sx={{ fontSize: { xs: 10, sm: 12, md: 20 }, lineHeight: { xs: "1.2rem", md: "1.6rem" } }} style={{ color: "#fff" }}><IoMail className={classes.logo} style={{ verticalAlign: "middle" }} />{COMPANY_INFO['email']}</Typography>
        </Grid>
    );

    const copyrightDisplay = (
        <Box sx={{ marginBottom: {xs: 4, sm: 0} }} style={{ display: "flex", justifyContent: "space-between", alignSelf: "center", padding: "20px 0px" }}>
            <Typography variant="body2" color="#fff">
                {`${t('footer.copyright')} © ${new Date().getFullYear()} ${COMPANY_FULLNAME} | ${t('general.allRightReserved')}.`}
            </Typography>
            <Hidden mdDown>
                <Link component={RouterLink} to="/policy" underline='none'>
                    <Typography variant="body2" className={classes.footerLinkText}>{t('footer.tnc')}</Typography>
                </Link>
            </Hidden>
        </Box>
    );

    const mobileCopyright = (
        <Box style={{ padding: "6% 0%" }}>
            <Typography variant="body2" color="#fff" align="center">
                {`© ${new Date().getFullYear()} ${APP_NAME}. ${t('general.allRightReserved')}.`}
            </Typography>
        </Box>
    );

    const bottomNavItems = [
        { id: 0, url: '/', label: <Typography style={{ fontSize: '1.0rem', color: location.pathname === '/' ? '#ad9e80' : 'black' }}>{t('title.home')}</Typography>, icon: <MdHome size={25} />},
        { id: 2, url: '/our-resorts', label: <Typography style={{ fontSize: '1.0rem', color: location.pathname === '/our-resorts' ? '#ad9e80' : 'black' }}>{t('title.resorts')}</Typography>, icon: <GiPalmTree size={25} />},
        { id: 3, url: '/activities', label: <Typography style={{ fontSize: '1.0rem', color: location.pathname === '/activities' ? '#ad9e80' : 'black' }}>{t('title.activities')}</Typography>, icon: <TbSwimming size={25} />},
        { id: 1, url: '/cart', label: <Typography style={{ fontSize: '1.0rem', color: location.pathname === '/cart' ? '#ad9e80' : 'black' }}>{t('title.carts')}</Typography>, icon: <BiCart size={25} />},
    ];

    const [isScrolled, setIsScrolled] = useState(true);
    const toggleVisible = () => {
        if (Math.round(window.innerHeight + window.scrollY) >= document.documentElement.offsetHeight) {
            setIsScrolled(false);
        } else {
            setIsScrolled(true);
        }
    };
    
    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <Box style={{ color: "#fff", backgroundColor: theme.palette.secondary.main }} className={classes.bgImageWhiteRight}>
                <Container maxWidth="xl" sx={{ padding: {xs: "6% 15% 0% 15%", md: "4% 10%"} }}>
                    {/* <Hidden only="xs"> */}
                    {/* logo, company info, subscribe */}
                    <Grid container spacing={1} columns={9} style={{ marginTop: 5 }}>
                        <Hidden mdDown>
                            {logoDisplay}
                            {lesenDisplay}
                        </Hidden>
                        {subscribeDisplay}
                    </Grid>
                    {/* company address/phone/email, internal link, external link */}
                    <Grid container spacing={3} style={{ marginTop: 5 }}>
                        <Hidden mdUp>
                            {linkDisplayList}
                            {lesenDisplay}
                            {addressDisplay}
                            {externalLink}
                            {contactUsLink}
                        </Hidden>
                        <Hidden mdDown>
                            {addressDisplay}
                            {linkDisplayList}
                            {externalLink}
                            {contactUsLink}
                        </Hidden>
                    </Grid>
                    <Hidden mdUp>
                        {mobileCopyright}
                    </Hidden>
                    {/* LANGUAGE */}
                    {/* <Box style={{ marginTop: 30, width: "fit-content" }}>
                        <Typography variant="h6" style={{ lineHeight: "1.6rem", color: "#fff", fontFamily: "FuturaPTDemi" }}>
                            {t('footer.language')}
                        </Typography>
                        <Typography className={classes.footerLinkText} onClick={() => changeLanguage('en')}>English</Typography>
                        <Typography className={classes.footerLinkText} onClick={() => changeLanguage('cn')}>中文</Typography>
                    </Box> */}
                    {/* </Hidden> */}
                </Container>
            </Box>
            <Box style={{ color: "#fff", backgroundColor: theme.palette.secondary.secondary }}>
                <Container maxWidth="xl" sx={{ padding: {xs: "1% 1% 1% 1%", md: "1% 10% 1% 10%"} }}>
                    {/* sub logo */}
                    <Grid container spacing={1} columns={10} alignItems='center' justifyContent='center' >
                        {
                            _.map(reluxeSubLogo, subLogo => {
                                return (
                                    <Grid item xs={3} sm={2} key={subLogo.white} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Box style={{ position: "relative", margin: 5 }}>
                                            <Link component={RouterLink} to={subLogo.link} underline='none'>
                                                <img alt='ReLuxe Logo' src={subLogo.white} style={{ width: "100%" }} className={classes.footerLogoWhite} />
                                                <img alt='ReLuxe Logo' src={subLogo.color} style={{ width: "100%" }} className={classes.footerLogoColor} />
                                            </Link>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Hidden mdDown>
                        <Divider style={{ borderColor: "#fff" }} />
                        {/* copyright, tnc */}
                        {copyrightDisplay}
                    </Hidden>
                </Container>
            </Box>
            {/* MOBILE FOOTER */}
            <Hidden mdUp>
                {
                    location.pathname.slice(0, 9) !== '/package/' &&
                        <Paper 
                            elevation={isScrolled ? 3 : 0} 
                            style={{ 
                                position: isScrolled ? 'fixed' : 'absolute', 
                                bottom: 0, 
                                left: 0, 
                                right: 0, 
                                background: '#fff', 
                                zIndex: 2,
                                width: '100%',
                                opacity: isScrolled ? 1 : 0,
                                transition: 'all 0.5s',
                            }}
                        >
                            <BottomNavigation
                                showLabels
                                value={location.pathname}
                                onChange={(event, newValue) => { setValue(newValue); }}
                                style={{ padding: "0% 10%" }}
                            >
                                {
                                    _.map(bottomNavItems, (items, key) => {
                                        return (
                                            <BottomNavigationAction key={key} value={items.url} component={RouterLink} to={items.url} label={items.label} icon={items.icon} />
                                        )
                                    })
                                }
                            </BottomNavigation>
                        </Paper>
                }
                <Paper style={{ 
                    position: 'absolute',  
                    background: '#fff', 
                    zIndex: 2,
                    width: '100%',
                    opacity: isScrolled ? 0 : 1,
                    transition: 'all 0.5s',
                }}>
                    {
                        location.pathname.slice(0, 9) !== '/package/' ?
                        <BottomNavigation
                            showLabels
                            value={location.pathname}
                            onChange={(event, newValue) => { setValue(newValue); }}
                            style={{ padding: "0% 10%" }}
                        >
                            {
                                _.map(bottomNavItems, (items, key) => {
                                    return (
                                        <BottomNavigationAction key={key} value={items.url} component={RouterLink} to={items.url} label={items.label} icon={items.icon} />
                                    )
                                })
                            }
                        </BottomNavigation>
                        :
                        <Grid container style={{ padding: "2% 6%", display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Grid item xs={4}>
                                <Typography variant="h4" style={{ fontFamily: "FuturaPTBold", color: '#fff' }}>{t('package.totalPrice') + ":"}</Typography>
                                <Typography variant="h4" style={{ fontFamily: "FuturaPTBold", color: '#fff' }}>
                                    0.00
                                </Typography>
                            </Grid>
                            <Grid item xs={8} style={{ display:'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                                <Typography style={{ width: "100%", maxWidth: "150px", margin: 10, color: '#fff' }}>0.00</Typography>
                            </Grid>
                        </Grid>
                    }
                </Paper>
            </Hidden>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    footerLinkText: {
        color: "#fff !important",
        lineHeight: '1.9rem !important',
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main + ' !important',
            fontWeight: 'bold',
        },
    },
    footerText: {
        color: "#fff !important",
        lineHeight: '1.9rem !important',
    },
    logo: {
        color: theme.palette.primary.main,
        [theme.breakpoints.up('xs')]: {
            marginRight: 3,
        },
        [theme.breakpoints.up('md')]: {
            marginRight: 7,
        },
        verticalAlign: "text-top",
        display: "inline-block"
    },
    externalLogo: {
        color: "#fff",
        cursor: "pointer",
        [theme.breakpoints.up('xs')]: {
            marginLeft: 8,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 16,
        },
        "&:hover": {
            color: theme.palette.primary.main,
        },
    },
    reluxeLogo: {
        [theme.breakpoints.up('xs')]: {
            width: '58%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '88%',
        },
    },
    bgImageWhiteRight: {
        backgroundImage: `url('../images/logo-super-light.png')`, 
        backgroundRepeat: 'no-repeat', 
        backgroundSize: "450px",
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -350% bottom', 
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right -8% center', 
        },
    },
    footerLogoWhite: {
        objectFit: 'contain',
    },
    footerLogoColor: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        objectFit: 'contain',
        opacity: 0,
        transition: 'opacity .2s',
        "&:hover": {
            opacity: 1,
        },
    }
}));

export default AppFooter;