import axios from 'axios';
import { API_URL, TIMEOUT } from '@configs/config';
import { store } from '@configs/store';
import { logoutUser } from '@actions/generalActions';
import { resetProfile } from '@actions/userActions';

const instance = axios.create({
    baseURL: API_URL,
    timeout: TIMEOUT,
    headers: {},
    // withCredentials: true
});

instance.interceptors.request.use(
    async (config) => {
        const token = await store.getState().general.accessToken;
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        const lang = await store.getState().general.defaultLanguage;
        config.headers.Language = lang ? lang : 'en';
        config.headers.Accept = "application/json";
        // console.log("config",config)
        // store.dispatch({ type : START_LOADING })
        return config;
    },
    (err) => {
        // console.log("error", err);
        return Promise.reject(err);
    },
)

instance.interceptors.response.use(
    (res) => {
        console.log("response", res);
        // store.dispatch({ type : STOP_LOADING });
        return res.data
    },
    (err) => {
        console.log("err", err);
        if(err && err.response) {
            switch(err.response.status) {
                case 400:
                    console.log("Request error")
                    break;
                case 401:
                    console.log("Unauthorized access")
                    store.dispatch(logoutUser());
                    store.dispatch(resetProfile());
                    break;
                default:
                    console.log('Other error messages');
            }
        }
        // throw err.response;
        // throw new Error(err);
        return Promise.reject(err);
    }
)

export default instance;