import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import Moment from 'moment';

import { CardMedia, Link, Typography, Divider, Box, Button } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

const NewsCard = (props) => {
    const { news, readMore, newsDetails } = props;
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();

    const card = () => (
        <Box style={{ boxShadow: '0 0 6px 0 #0002', backgroundColor: '#ffffff'}}>
            <CardMedia
                component="img"
                image={news.image ? news.image.file_name : '/images/emptyStatus/no_ticket.png'}
                sx={{ flex: 1, width: "100%" }}
                alt={'news\'s image'}
                className={classes.classMediaStyle}
            />
            <Box style={{ padding: "6% 9%" }} className={newsDetails?classes.newsDetailsStyle:classes.newsStyle}>
                <Box style={{ height: newsDetails?"40%":"100%" }}>
                <Typography className={classes.titleStyle}>
                    {news.title[i18n.language] ? news.title[i18n.language] : news.title.en}
                </Typography>
                <Typography variant="body2" style={{ margin: "3% 0% 6%" }}>{Moment(news.created_at).format('LL')}</Typography>
                </Box>
                {
                    newsDetails
                        ?
                        <Box style={{ maxHeight: "60%" }}>
                            <Divider style={{ borderBottomWidth: 2, borderColor: "black" }} />
                            <Box style={{ marginTop: "6%", overflow: "hidden", lineHeight: 1.5, fontSize: 14, color: theme.palette.secondary.main }} className={classes.contentHeight}>
                                <div dangerouslySetInnerHTML={{ __html: (news.content[i18n.language] ? news.content[i18n.language] : news.content.en).slice(0, 200) }} />
                            </Box>
                        </Box>
                        :
                        null
                }
            </Box>
        </Box>
    )

    return (
        <>
            {
                readMore
                    ?
                    <>
                        {card()}
                        <Link component={RouterLink} to={`/news/${news.id}`} style={{ textDecoration: "none", color: "inherit" }}>
                            <Button variant="contained" style={{ position: "relative", left: 28, bottom: 17, fontSize: 13, padding: "4px 19px" }}>{t('general.readMore')}</Button>
                        </Link>
                    </>
                    :
                    <Link component={RouterLink} to={`/news/${news.id}`} style={{ textDecoration: "none", color: "inherit" }}>
                        {card()}
                    </Link>
            }
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important",
        [theme.breakpoints.up('xs')]: {
            height: "140px !important",
        },
        [theme.breakpoints.up('sm')]: {
            height: "190px !important",
        },
        [theme.breakpoints.up('lg')]: {
            height: "220px !important",
        },
    },
    titleStyle: {
        lineHeight: "1 !important",
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 3,
        overflow: "hidden",
        fontFamily: "FuturaPTBold !important",
        fontSize: "1.2rem !important",
    },
    newsDetailsStyle: {
        [theme.breakpoints.up('xs')]: {
            height: "200px !important",
        },
        [theme.breakpoints.up('sm')]: {
            height: "250px !important",
        },
        [theme.breakpoints.up('md')]: {
            height: "280px !important",
        },
    },
    newsStyle: {
        [theme.breakpoints.up('xs')]: {
            height: "60px !important",
        },
        [theme.breakpoints.up('sm')]: {
            height: "80px !important",
        },
        [theme.breakpoints.up('md')]: {
            height: "100px !important",
        },
    },
    contentHeight: {
        [theme.breakpoints.up('xs')]: {
            maxHeight: "75px !important",
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: "100px !important",
        },
    }
}))

export default NewsCard