const chinese = {
    footer: {
        quickLink: "快速链接",
        aboutTikcat: "关于Tikcat",
        language: "语言",
        register: "注册账号",
        tnc: "条款和条件",
        contactUs: "联络我们",
        sellerCenter: "卖家中心",
        searchPlaceholder: "搜索……",
        copyright: "版权所有",
        subscribe: "订阅",
        subscribeTitle: "订阅以便得到更新资讯：",
    },
    auth: {
        signIn: "登入",
        signInMember: "用户登入",
        signInAgent: "代理登入",
        signInDesc: "或使用您的帐户",
        signOut: "登出",
        signUp: "注册",
        signUpDesc: "输入您的详细信息并与我们一起开始旅程！",
        signUpCapt: "与我们一起体验",
        member: "用户",
        agent: "代理",
        createAccount: "注册账号",
        username: "用户名",
        email: "电子邮箱",
        name: "名字",
        password: "密码",
        password_confirmation: "确认密码",
        securityPassword: "安全密码",
        confirmSecurityPassword: "确认安全密码",
        passwordCapt: "至少 8 个字符，至少包含一个数字和至少一个符号",
        rememberMe: "持续登入",
        forgotPassword: "忘记密码",
        noAccountSignUp: "没有账号？注册",
        privacyBegin: "我已阅读并接受 ReLuxe Resorts",
        privacyAnd: "和",
        privacyStatement: "隐私声明",
        privacyPolicy: "隐私政策",
        promotions: "我愿意接收有关 ReLuxe Resorts 和活动促销和特别优惠的电子邮件。*",
        acknowledgementFront: "通过提交此表格，我同意 ReLuxe ",
        terms: "条款和条件 ",
        acknowledgementBack: "以及使用本网站、进行预订和住宿。",
        or: "或",
        agentUsername: "代理用户名",
        privacyChecked: "请接受 ReLuxe Resorts 隐私声明和隐私政策",
        required: {
            email: "请输入电子邮箱",
            username: "请输入用户名",
            password: "请输入密码",
        },
        noEmailAccess: "请允许系统访问您的电子邮件。"
    },
    forgotPassword: {
        placeholder: "请输入您的电子邮箱",
        email: "电子邮箱",
        required: {
            email: "请输入您的电子邮箱"
        },
        sendSuccess: "我们已将您的重置密码链接发送给您，请检查您的收件箱并在60分钟内重置您的密码。"
    },
    resetPassword: {
        email: "电子邮箱",
        password: "新密码",
        password_confirmation: "确认密码",
        required: {
            password: "请输入您的新密码",
            password_confirmation: "请输入您的确认密码",
        },
        errors: {
            notSame: "您的密码和确认密码不匹配"
        },
        loginNow: "现在登入",
        notValid: "您的重置密码链接不符合我们的服务器凭据或已过期，请尝试再次请求重置密码链接。",
        resetSuccess: "您的密码已成功更改，您现在可以使用新密码继续登录。"
    },
    title: {
        home: "主页",
        profile: "我的账户",
        forgotPassword: "忘记密码",
        resetPassword: "重设密码",
        search: "搜索",
        agencies: "商家",
        packages: "配套",
        reviews: "评论",
        carts: "我的购物车",
        orders: "订单记录",
        vouchers: "优惠券",
        news: "消息",
        ourResorts: "我们的度假村",
        activities: "活动",
        aboutUs: "关于我们",
        about: "关于",
        gallery: "画廊",
        blog: "博客",
        contactUs: "联系我们",
        golfCourses: "高尔夫课程",
        dashboard: "仪表板",
        subscription: "订阅",
        unsubscribe: "取消订阅",
        ourNewsletter: "我们的时事通讯",
        paymentHistory: "付款记录",
        destination: "目的地",
        resorts: "度假村",
        logout: "登出",
        login: "登录",
        tnc: "条款和条件",
        rnr: "退货/退款政策",
        pp: "隐私政策",
        cp: "缓存政策",
    },
    user: {
        profile: "账户资料",
        username: "用户名",
        name: "姓名",
        email: "电子邮箱",
        mobile: "电话号码",
        ic: "身份证号码/护照号码",
        password: "密码",
        security_password: "安全密码",
        old: "当前{{field}}",
        new: "新{{field}}",
        confirm: "重复新{{field}}",
        addresses: "地址",
        firstName: "名",
        lastName: "姓",
        profilePicture: "头像",
        preview:"预览",
        dob: "出生日期",
        gender: "性别",
        male: "男",
        female: "女",
    },
    address: {
        name: "姓名",
        address1: "地址 1",
        address2: "地址 2",
        zip: "邮政编码",
        state: "州",
        city: "城市",
        country: "国家",
    },
    general: {
        goHome: "返回主页",
        notFound: "404 - 网页不存在！",
        copiedToClipboard: "复制到剪贴板",
        pleaseSelect: "请选择",
        error: "过程出现错误。",
        itemNotFound: "暂无商品",
        allRightReserved: "版权所有",
        followUs: "关注我们",
        noData: "没有相关资料。",
        purchase: "购买",
        submit: "提交",
        update: "更新",
        edit: "编辑",
        cancel: "取消",
        create: "新增",
        delete: "删除",
        search: "搜索",
        clear: "清除",
        ok: "好的",
        loading: "等待中……",
        yes: "是",
        no: "否",
        backToPrevious: "返回上一页",
        previousStep: "上一步",
        nextStep: "下一步",
        readMore: "阅读更多",
        clearDate: "清除日期",
        weekday:{
            1:"星期一",
            2:"星期二",
            3:"星期三",
            4:"星期四",
            5:"星期五",
            6:"星期六",
            7:"星期日"
        },
        upload: "上传",
        print:"打印",
        backendLink: "代理网站"
    },
    profile: {
        changeProfile: "更新账号",
        changePassword: "更新密码",
        changeSecurityPassword: "更新安全密码",
        myAddress: "我的地址",
        security: "安全设置",
        myDocument:"我的文件"
    },
    datatable: {
        noMatch: "对不起，暂无记录",
        next: "下一页",
        previous: "上一页",
        rowsPerPage: "每页行数",
        displayRows: "的",
    },
    package: {
        category: "类别",
        location: "目的地",
        voucherCount: "优惠券数量(每一份量)",
        voucherAvailableDay: "优惠券可使用日",
        voucherExpiryDate: "优惠券使用期限",
        description: "配套描述",
        addToCart: "加入购物车",
        name: "配套名称",
        quantity: "数量",
        buyNow: "立即购买",
        voucherAvailableDayNote: "*** 此配套的可使用日不含公共假期。",
        publicHoliday: "此配套的公共假期包含",
        discount: "折扣",
        voucherExceptDate: "优惠券不可在这些日期使用",
        durationPeriod: "持续时间(天)",
        validFrom: "自{{date}}起有效",
        validTo: "有效期至{{date}}为止",
        selectPackage: "选择选项",
        packageOption: "配套选项",
        total: "总金额",
        startingFrom: "低至",
        operationHour: "营业时间（当地时间）",
        bookingDate: "旅游日期",
        loadMorePackage: "浏览更多",
        noMorePackage: "已经没有更多商品啦",
        moreForYou: "为您推荐",
        relax: "#放松",
        reviews: "评论",
        reviewsCount: "{{count}}则评论",
        reviewMoreCount: "显示所有{{count}}则评论",
        bookingDetails: "您的预订信息",
        checkIn: "入住",
        checkOut: "退房",
        nights: "入住天数",
        for: "详情",
        room: "房间",
        taxesAndFees: "税费",
        taxesAndFeesNote: "以您的货币表示的总价是近似值，并基于今天的汇率。 实际收取的价格将以酒店的货币计算。",
        totalPrice: "总价格",
        selectDate: "请选择参加日期",
        selectDateTitle: "请选择日期",
        bookNow: "立即预定",
        reportListing: "举报此房源",
        sellingOutSoon: "即将售罄",
        mustVisit: "必须参观",
        checkout: "结账",
        startDate: "开始日期",
        endDate: "结束日期",
        date: "日期",
        activity: "活动",
        priceBreakdown: "价格细项",
        viewImages: "查看{{count}}张照片",
        dayTrip: "一日游",
        mustHaveAdult: "孩童必须在成人的陪同下参与。",
        orverview: "概述",
        itinerary: "行程",
        note: "注意事项",
        fillAllFields: "完成所有必填选项以继续",
        checkAvailability: "检查可用性",
        selectOption: "选择选项",
        searchActivities: "选择活动",
        addOn: "附加配套",
    },
    checkout: {
        all: "全选",
        subtotal: "小计",
        selectedItems: "已选商品",
        checkout: "前往结账",
        stepper: {
            cart: "购物车",
            checkout: "提交订单",
            submit: "付款",
            bookingInfo: "预约详情",
        },
        guestNote: "您尚未登入！登入后您的商品将会自动加入购物车。",
        noSelectedItem: "请先选择要结账的商品。",
        placeOrder: "确认订单",
        itemPriceSum: "{{num}}件商品，总商品金额",
        totalSum: "总金额",
        buyerInfo: "买家详情",
        remark: "备注",
        remarkNote: "给卖家的备注",
        bilingAddress: "帐单地址",
        checkoutItem: "结账商品",
        editBuyerInfo: "编辑买家资料",
        addAddress: "新增地址",
        editAddress: "编辑地址",
        noSelectedCart: "您未选择结账商品，请先在您的购物车中进行选择。",
        goToCart: "前往购物车",
        noAddress: "暂无地址。",
        invalidPayment: "付款资讯有误。",
        payment: "付款方式",
        option: "选项",
        bookingInfo: "顾客资料",
        sameWithAcc: "与账户信息相同",
        noTourPackagenoBookingDetail: "所购买的配套无需填写预约详情。",
        payNow: "立即付款",
        agentDiscountWithPercent: "代理折扣 ( {{discount}} % )",
        agentDiscount: "代理折扣",
        orderTotal: "订单总额",
        buyerInfoRequired: "买家详情必填。",
        buyerAddressRequired: "地址必填。",
        discountCode: "折扣码",
        applyCode: "应用折扣码",
        discountCodeAmount: "折扣金额",
        selectOrEnterCode: "选择或输入折扣码",
        discountVoucher: "折扣优惠券",
        discountCodeRepeat: "优惠券已存在。",
        errorSelectBookingDate: "先选择旅游日期才能使用此折扣优惠券。",
        errorSelectQuantity: "先选择商品才能使用此折扣优惠券。",
        errorSelectMerchant: "仅适用于特定商品。",
        errorSpendMin: "再添加 {{min}} 即可使用此折扣优惠券。",
        minSpend: "最低消费",
        validTill: "有效期至",
        uploadReceipt: "上传付款证明",
        bankName: "银行名称",
        accountName: "账户名称",
        accountNo: "账户号码",
        cutOffTitle: "提示",
        cutOffContent: "抱歉，晚上8:00后无法预订明日的配套。您最早只能预定后天的配套。请联络客服人员以了解更多关于订购的详情。客服电邮：{{email}}",
        contactDetails: "联络资料",
        emergencyContact: "紧急联络资料",
        relationship: "关系",
        agreeToBeMember: "据此，我同意成为 ReLuxe 会员。",
        addOn: "Add-On",
    },
    payment: {
        success: "付款成功",
        pending: "付款审核中",
        fail: "付款失败",
        successContent: "您的优惠券已经新增到列表中啦~",
        pendingContent: "系统会在审核完成的第一时间内将优惠券新增至列表中。",
        failContent: "付款出现问题，请与客服联系了解更多详情。",
    },
    order: {
        createdAt: "订单日期",
        orderNo: "订单编号",
        totalAmount: "总金额",
        remark: "订单备注",
        viewDetails: "查看详情",
        status: "订单状态",
        packageName: "配套名称",
        orderItems: "订单配套",
        payNow: "立即付款",
        cancelOrder: "取消订单",
        cancelOrderReminder: "您确定要取消此订单吗？",
        cancelRemark: "取消备注"
    },
    homepage: {
        categories: "分类",
        locations: "地点",
        locationNote: "令人难忘的目的地",
        agencies: "本月最喜欢的商店",
        agencieNote: "领取本月最受欢迎品牌的最新优惠券",
        packages: "今日最佳优惠券",
        packageNote: "立即使用这些促销代码节省更多",
        viewMorePackage: "查看更多配套",
        viewMoreVoucher: "查看更多优惠券",
        viewAll: "查看全部",
        title1: "来这里重新定义旅行",
        title1Break: "来这里<br/>重新定义<br/>旅行。",
        searchBarPlaceHolder: "美人鱼岛浮潜配套...",
        startPlanningBreak: "开始<br/>规划",
        startPlanning: "开始规划",
        topPackages: "轮船派对",
        golfCourses: "事件",
        activities: "活动",
        destinations: "度假村",
        browse: "浏览",
        discover: "发现",
        join: "加入",
        explore: "探索",
        jumpstart: "立即开启",
        yourDaytrip: "您的旅程",
        sleep: "海上",
        onTheSea: "度假村。",
        resortsText: "在面向大海的亭子里向太阳致敬。 在您的水上别墅中放松身心，河豚和小礁鲨在下方清澈的海水中游泳。",
        seeOurResorts: "浏览我们的度假村",
        review: "评论",
        reviewCaption: "我们的客户如何评价他们在我们这里的体验！",
        slideToSeeMore: "滑动查看更多",
        comingSoon: "即将推出！",
        galleryTitle: "#LetsReLuxe",
        gallerySubtitle: "在我们的 Instagram 上使用标签与我们分享您的时刻！",
        loadMore: "加载更多结果",
    },
    voucher: {
        guestDetail: "客人信息",
        code: "优惠券代码",
        voucherName: "优惠券名称",
        status: "优惠券状态",
        price: "优惠券价格",
        viewDetails: "查看详情",
        showQr: "显示二维码",
        bookingDate: "旅游日期",
        redeemNote: "请向工作人员出示二维码。",
        agreementTitle: "在完成订单之前，请查看并同意以下内容：",
        agreement1: "1) 通过下订单，我同意 ReLuxe 的",
        agreement2: " 和 ",
        termscondition: "条款和条件",
        privacypolicy: "隐私政策",
        agreement3: "2) 下订单后，您将自动成为 ReLuxe 会员并解锁专属福利。 作为会员，您可以轻松管理订单并享受条款和条件中列出的福利。",
        agreement4: "3) 下订单即表示您同意接收条款和条件中规定的任何形式的营销促销材料。 及时了解我们的最新产品和特别促销活动。",
        acceptPolicy: "请在下订单前接受条款和条件以及隐私政策。",
        timeout: "抱歉，晚上8:00后无法预订明日的配套。您最早只能预定后天的配套。"
    },
    news: {
        title1: "最新资讯",
        title2: "新闻与故事",
        noNews: "暂无新闻。",
        recommended: "为您推荐",
        seeAll: "查看所有新闻",
        topArticle: "热门文章",
        leaveComment: "发表评论",
        commentDesc: "您的电子邮件地址不会被公开。必需的地方已做标记 *",
        message: "在此输入...",
        name: "姓名 *",
        email: "电子邮件 *",
        website: "网站",
        subscribe: "在此浏览器中保存我的姓名和电子邮件，以便我下次发表评论时使用。",
        submit: "提交",
        comments: "评论",
        noComments: "暂无评论。",
    },
    abouts: {
        title: "关于",
        reluxe: "ReLuxe",
        content1: "ReLuxe Vacay 运营着一个专注于酒店、度假租赁和一日游套餐的在线网站。我们还专注于管理度假村套房和住宿、专属酒吧和休息室、娱乐、休闲、运动和物业管理。我们的酒店和度假村 Mantanami Island 和 Three Island 正在进行和正在建设中。<br/><br/>我们认识到富裕是一个过时的概念，在当前竞争激烈的市场中已不再足够，因为现在的奢侈品是关于联系，人们通过体验和努力寻求满足感 与他们所爱的人建立与其他文化的深厚联系。",
        gone: '与 ReLuxe',
        withReluxe: "同行",
        hastTag: "#LetsExperienceTheDifference",
        content2: "现在的旅行都是关于沉浸感和体验，与快节奏的世界脱节，具有讽刺意味的是，连接到更深刻的体验和人际互动。<br/><br/>因此，我们将重塑奢侈品。<br /><br/>凭借我们在数字营销和旅游业的多年经验，我们认识到实施数字效率将为我们的利益相关者带来价值的重要性。<br/><br/>我们将提供无忧、真实的服务 、丰富和个性化的旅行体验，将增强第一次互动，并通过结合大数据和客户服务使我们所有人脱颖而出。",
    },
    mantanani: {
        title: {
            0 : "美人鱼岛",
            // 1 : "三岛",
            1 : "迪纳万岛"
        },
        content: {
            0 : "一组三个孤立的岛屿，拥有充满无数海洋生物的清澈湛蓝的海水和椰树环绕的白色沙滩。",
            // 1 : "在著名的幸存者岛上的僻静天堂体验冒险与放松的完美结合。",
            1 : "发现一个隐藏的天堂，拥有柔软的沙滩和蔚蓝的海水，令人惊叹的日落将天空变成橙色的画布。"
        }
    },
    activities: {
        content1: "冒险与发现之旅",
        content2: "向东西方冒险",
        content3: "让 ReLuxe 带您去世界上最迷人的目的地探险。 在婆罗洲水域与蝠鲼一起潜入深海。 沉浸在马来西亚半岛森林的大自然中。 在仙本那的小圣托里尼放松一下。<br/><br/>从发现之旅到宁静之旅，Reluxe 诚邀您通过 ReLuxe Experience 沉浸在我们的每个目的地。",
        content4: "#experience the difference.",
        chooseDestination: "选择您的目的地",
        from: "低至",
        perPax: " /人",
    },
    ourResorts: {
        title: "在这里开始您难忘的旅程。",
        content: "在跨越世界各地的城市、岛屿、海滩、茂密的荒野和森林的集合中，近距离接触每个目的地的真实特征。当您探索酒店和度假村时，我们打造了一段罕见、独特和独特的旅程，让您沉浸在野外美景和土著遗产中，很少有人能接触到。一旦你介入，你就不会想离开。",
        sleep: "享受",
        caption1: "在海上。",
        hastTag1: "#Relax",
        caption2: "奢华地<br/>放松",
        hashTag2: "#Luxury",
        chooseDestination: "选择您的目的地",
        bookNow: "现在预订",
        explore: "探索更多",
        tnc: "条款和条件",
        tnc1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        tnc2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        tnc3: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        tnc4: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        tnc5: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    dashboard: {
        editProfile: "编辑个人资料",
        publicView: "公众视图",
        adsTitle: "Kayaking 在当地马尔代夫",
        adsContent: "在 ReLuxe De Mantanani Island Resort 沉浸在岛上的终极乐趣中。通过我们的独家优惠，在海边品尝特色美食。探索越野的真实体验，让您的旅程更加难忘。",
        wishlist: "我的收藏",
        booking: "最近预订",
        document: "上传文件",
        unsubscribe: "取消订阅",
        subscribe: "现在订阅",
        emptyValue: "请输入您的电子邮件。",
        emailNotFound: "您的电子邮件不存在我们的系统中。",
        subscribeDesc: "要订阅我们的 ReLuxe 网站，请在此处输入您的电子邮件地址。我们会不时发送更新。",
        unsubscribeDesc: "要取消订阅我们的 ReLuxe 网站，请在此处输入您的电子邮件地址。",
        successUnsubscribe: "您已成功取消订阅我们的 ReLuxe 网站。您将无法收到我们的任何信息。",
        noWishlist: "您还没有收藏愿望清单哦~",
    },
    reviews: {
        leaveReview: "发表评论",
        content: "内容",
        rating: "评分",
        viewReview: "查看评论",
        viewReviewMore: "查看更多",
    },
    policy: {
        tnc: "条款和条件",
        rnr: "退货和退款政策",
        pp: "隐私政策",
        cp: "缓存政策",
    },
    pdf:{
        booking:"预订",
        confirmation:"确认",
        presentTicketDetail:"请在入住时出示您的预订确认信的电子版或纸质版。",
        orderNo:"预订号",
        guest:"来宾",
        countryOfResidence:"居住国家",
        package:"配套",
        reluxeContact:"Reluxe 联系号码",
        packageType:"配套类型",
        confirmationEmail:"有关完整促销的详细信息和条件，请参阅确认电子邮件。",
        cancelPolicy:"取消政策：无风险预订！您可以在 {{cancel_date}} 之前取消并且无需支付任何费用！在旅游日期前 1 天内收到的任何取消将收取整个住宿费用。如果您未能到达或取消预订，将不予退款。",
        bookingDate:"旅游日期",
        paymentDetail:"付款详情",
        pleaseNote:"请注意",
        pleaseNoteDetail:"ReLuxe 尚未收取此预订的付款。此预订的付款必须由 ReLuxe 收取",
        noteForPackage:"配套注意事项",
        bookingDetail:"预订已通过于此 ID ",
        stampAndSignature:"授权印章和签名",
        remark:"备注",
        notIncluded:"不包含",
        taxAndFee:"税费 {{$prefix}} {{price}}（支付配套）",
        otherGuest:"其他来宾",
        detailText:"所有特殊要求均需视抵达时的供应情况而定。",
        note:"注意",
        important:"重要",
        firstNote:"登记入住时，您必须出示附有照片的有效身份证件，确认您的地址与预订的领队来宾的姓名相同。对于使用信用卡支付的预订，您可能还需要出示用于付款的卡。否则可能会导致酒店要求额外付款或不接受您的预订。",
        secondNote:" 所有客房均在抵达当天保证。如果未出现，您的房间将被释放，您将遵守预订时指定的取消/未出现政策的条款和条件以及确认中注明的电子邮件。",
        thirdNote:"此预订的总价不包括迷你吧项目、电话使用费、洗衣服务等。酒店将直接向您收费。",
        forthNote:"如果房价包含早餐，请注意，某些酒店可能会对与父母同行的儿童收取额外费用。如果适用，酒店将直接向您收费。抵达后，如果您有任何疑问，请与酒店核实。"
    }
};

export default chinese;