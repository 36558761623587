import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Link as RouterLink } from 'react-router-dom';

import StarIcon from '@mui/icons-material/Star';
import { Avatar, Box, Tab, Tabs, Button, Container, Divider, Grid, LinearProgress, Rating, TextField, InputAdornment, Typography, Link, Hidden, ImageList, ImageListItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import { IoBusiness } from "react-icons/io5";
import { FiClock, FiMinus, FiPlus } from "react-icons/fi";
import { FiChevronRight, FiShare2 } from "react-icons/fi";
import { MdFavoriteBorder, MdWifi, MdBeachAccess, MdPool, MdWineBar, MdArrowDropDown } from 'react-icons/md';

import { updateCartTotal } from '@actions/generalActions';
import BackToPrevious from '@components/BackToPrevious';
import PackageList from '@components/PackageList';
import { getUrl, postUrl } from '@helpers/api';

import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import PropTypes from 'prop-types';

const Detail = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const { uuid, accessToken } = useSelector(state => state.general);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [packages, setPackages] = useState([]);

    const perPage = 4;

    useEffect(() => {
        setLoading(true);
        getUrl("/package-pagination", { page: page, per_page: perPage }).then(response => {
            setLoading(false);
            if (response.status) {
                setPackages(response.data.listing.data);
                setTotal(response.data.listing.last_page);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [page]);

    const handlePaginationChange = (event, value) => {
        setPage(value);
    }

    const itemData = [
        {
          img: '../images/rooms_offers/room2.jpg',
          title: 'Room 2',
        },
        {
          img: '../images/rooms_offers/room3.jpg',
          title: 'Room 3',
        },
        {
          img: '../images/rooms_offers/room4.jpg',
          title: 'Room 4',
        },
    ];

    return(
        <>
            {/* Room Display Photo */}
            <div className={classes.bgImageSmall}>
                <Container maxWidth="xl" style={{ display: 'flex', direction: 'row', padding: "2% 10%" }}>
                    <div>
                        <img src={'../images/rooms_offers/room1.jpg'} width="100%" style={{ borderRadius: 10, paddingTop: 40 }} />
                        <div style={{ position: 'relative', bottom: '140px', left: '40px' }}>
                            <Typography variant='h3' style={{ color: 'white' }}>Standard</Typography>
                            <Typography variant='h3' style={{ color: 'white' }} sx={{ fontFamily: 'PlayfairDisplayItalic' }}>DeLuxe Room</Typography>
                        </div>
                    </div>
                    <Hidden lgDown>
                        <img src={'../images/rooms_offers/activity3.jpg'} width="30%" height="370px" style={{ objectFit: 'cover', position: 'relative', top: 350, padding: 20 }} />
                    </Hidden>
                </Container>
            </div>

            <Container maxWidth="xl" style={{ padding: "2% 10%" }} className={classes.bgImageWhite}>
                <Box className={classes.paper}>
                    {/* Selected Room */}
                    <div style={{ backgroundColor: '#ffffff', paddingTop: 30 }}>
                        <Typography variant='h3' style={{ paddingBottom: 30 }}>Selected Rooms</Typography>
                        <ImageList cols={3} rowHeight={400} gap={10}>
                            {itemData.map((item) => (
                                <ImageListItem key={item.img}>
                                <img
                                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </div>

                    {/* Description */}
                    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <Typography variant='h4' sx={{ fontFamily: 'PlayfairDisplayRegular' }}>Standard Suites Room</Typography>
                                <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                                <Typography variant='h6'>
                                    Immerse yourself in the ultimate in-island pleasures at ReLuxe De 
                                    Mantanami Island Resort. Savor special treats by the ocean with our
                                    exclusive offers. Discover off-the-trail authentic experiences to
                                    make your journey even more memorable.
                                </Typography>

                                <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />

                                <Typography variant='h6'>Cancellation policy:</Typography>
                                <Typography variant='h6'>Reservation may not be cancelled. Full payment will be forfeited.</Typography>

                                <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />

                                <Typography variant='h6'>Reservation policy:</Typography>
                                <Typography variant='h6'>
                                    A deposit of MYR 3,419.80 will be charged to your credit card at the time of reservation.
                                </Typography>

                                <Divider style={{ margin: "50px 0px", borderBottomWidth: 0 }} />
                                <Divider style={{ borderBottom: "1px solid", margin: "3% -100%", width: '200%' }} />

                                <Typography variant='h6' sx={{ fontFamily: 'FuturaPTHeavy' }}>Special Deals:</Typography>
                                <Typography variant='h6'>New Year Special (Add to cart / see more)</Typography>
                                <Typography variant='h6'>Long-Stay Exclusive (Add to cart / see more)</Typography>
                                <Typography variant='h6'>Beyond the Trail (Add to cart / see more)</Typography>
                                <Typography variant='h6'>And more packages</Typography>

                                <Divider style={{ margin: "50px 0px", borderBottomWidth: 0 }} />

                                <Button variant="contained" color="primary" className={ classes.buttonStyle }>
                                    <b>Book Now</b>
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box style={{ border: "1px solid" }}>
                                    <Box style={{ padding: "10px 30px" }}>
                                        <Typography variant="h6">{t('package.bookingDetails')}</Typography>
                                    </Box>
                                    <Divider style={{ borderColor: theme.palette.secondary.main }} />
                                    <Box style={{ padding: "20px 30px" }}>
                                        {
                                            _.map([
                                                { title: (t('package.checkIn') + ":"), content: "Sat 05 Nov 2022" },
                                                { title: (t('package.checkOut') + ":"), content: "Sat 05 Nov 2022" },
                                                { title: (t('package.nights') + ":"), content: "2 Nights" },
                                                { title: (t('package.for') + ":"), content: "2 Adults, 1 Room" },
                                            ], (info, key) => (
                                                <Box style={{ display: "block", paddingTop: key === 0 ? 0 : 20 }} key={key}>
                                                    <Typography variant="body" style={{ fontFamily: "FuturaPTMedium", textTransform: 'uppercase', display: "block" }}>{info.title}</Typography>
                                                    <Typography variant="h6">{info.content}</Typography>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                    <Divider style={{ borderColor: theme.palette.secondary.main }} />
                                    <Box style={{ padding: "25px 30px" }}>
                                        <Box style={{ display: "block", paddingBottom: 20 }}>
                                            <Typography variant="h6">Price Breakdown</Typography>
                                            <Typography variant="h6">2 Nights, 1 Room, 2 Adults</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>{t('package.room') + ":"}</Typography>
                                            <Typography variant="h6">MYR 2,932.94</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>{t('package.taxesAndFees') + ":"}</Typography>
                                            <Typography variant="h6">MYR 450.94</Typography>
                                        </Box>
                                        <Box style={{ lineHeight: "1", padding: "10px 0px 20px" }}>
                                            <Typography variant="caption">{t('package.taxesAndFeesNote')}</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy", color: theme.palette.primary.main }}>{t('package.totalPrice') + ":"}</Typography>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>{"MYR 2,932.94"}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Container>

            {/* Activity List */}
            <div className={classes.bgImageBig}>
                <Container maxWidth="xl" style={{ padding: "2% 9%" }}>
                    <Box>
                        <Typography variant='h2' sx={{ fontFamily: 'PlayfairDisplayItalic' }}>Jumpstart</Typography>
                        <Typography variant='h2'>your daytrip</Typography>

                        <Box className={classes.packageList}>
                            {
                                _.size(packages) > 0
                                    ?
                                    <Box style={{ marginTop: 40 }}>
                                        <PackageList 
                                            packageList={packages} 
                                            pagination={false} 
                                            swiper={true} 
                                            handlePaginationChange={handlePaginationChange} 
                                            page={page} 
                                            total={total}  
                                            showPrice={true} 
                                            showLink={true} 
                                        />
                                    </Box>
                                    :
                                    loading
                                        ?
                                        <Box style={{ marginTop: 20 }}>
                                            <LinearProgress size={20} />
                                        </Box>
                                        :
                                        <Box style={{ marginTop: 20, textAlign: "center" }}>
                                            <Typography variant="h6" style={{ padding: 50 }}>{t('general.noData')}</Typography>
                                        </Box>
                            }
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    );
}
export default Detail;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    swiperStyle: {
        width: '100%',
        height: 400
    },
    bgImageBig: {
        backgroundColor: theme.palette.primary.lightMain, 
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat', 
        backgroundBlendMode: 'multiply',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -30% top 10%', 
            backgroundSize: "50%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right 10% top 10%', 
            backgroundSize: "30%",
        },
    },
    bgImageSmall: {
        backgroundColor: theme.palette.primary.lightMain, 
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'multiply',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -60% top -40%', 
            backgroundSize: "60%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right 10% top -5%', 
            backgroundSize: "25%",
        },
    },
    bgImageWhite: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'left -50% top 20%', 
            backgroundSize: "50%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'left top 20%', 
            backgroundSize: "25%",
        },
    },
    titleText: {
        color: theme.palette.gray.dtext,
        fontSize: '28px!important',
        fontWeight: 'bold!important',
    },
    priceText: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold!important',
        fontSize: '26px!important',
    },
    contentText: {
        color: theme.palette.gray.dtext,
        fontSize: '16px!important',
    },
    contentNote: {
        color: theme.palette.gray.dtext,
        fontSize: '16px!important',
        fontStyle: "italic",
        paddingLeft: 20
    },
    phNote: {
        color: theme.palette.gray.dtext,
        fontSize: '16px!important',
    },
    buttonOrange: {
        backgroundColor: theme.palette.button.orange + " !important",
        boxShadow: "1px 2px 7px 0 #ffa34c !important",
    },
    buttonPrimary: {
        backgroundColor: theme.palette.button.main + " !important",
        boxShadow: "1px 2px 5px 0 #ff7f4c57  !important",
    },
    buttonStyle: {
        textAlign: 'center',
        padding: "6px 10px !important",
        fontSize: '60px',
        minWidth: '220px !important',
    },
    minusPlusIconStyle: {
        minWidth: 'unset !important',
        padding: 8
    },
    minusPlusTextStyle: {
        fontSize: 20,
        color: '#ad9e80'
    },
    quantityStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 300,
        height: 60,
        border: 'solid 3px #ad9e80'
    },
    quantityTitle: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    quantityGroup: {
        border: '1px solid',
        borderRadius: 8,
        padding: '8px 15px',
        borderColor: theme.palette.gray.light,
        marginTop: 8,
    },
    titleFont: {
        fontWeight: 'bold',
        [theme.breakpoints.up('xs')]: {
            fontSize: '16px!important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px!important',
        },
    },
    flexSbM: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    flexM: {
        display: 'flex',
        alignItems: 'center'
    },
    packageList: {
        marginTop: "10px !important",
    },
}))