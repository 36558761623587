const english = {
    footer: {
        quickLink: "Quick Link",
        aboutTikcat: "About Tikcat",
        language: "Language",
        register: "Register Account",
        tnc: "Terms and Conditions",
        contactUs: "Contact Us",
        sellerCenter: "Seller Center",
        searchPlaceholder: "Search...",
        copyright: "Copyright",
        subscribe: "Subscribe",
        subscribeTitle: "Subscribe for Updates:",
    },
    auth: {
        signIn: "Log In",
        signInMember: "Log in as member",
        signInAgent: "Log in as agent",
        signInDesc: "or use your account",
        signOut: "Sign Out",
        signUp: "Sign Up",
        signUpDesc: "Enter your details and start the<br/>journey with us!",
        signUpCapt: "Experience With Us",
        member: "Member",
        agent: "Agent",
        createAccount: "Create Account",
        username: "Username",
        email: "Email",
        name: "Name",
        password: "Password",
        password_confirmation: "Password Confirmation",
        securityPassword: "Security Password",
        confirmSecurityPassword: "Re-type Security Password",
        passwordCapt: "Minimum 8 characters with at least one number and at least one symbol",
        rememberMe: "Remember Me",
        forgotPassword: "Forgot Your Password",
        noAccountSignUp: "Don't have an account? Sign Up",
        privacyBegin: "I have read and accepted the ReLuxe Resorts",
        privacyAnd: "and",
        privacyStatement: " Privacy Statement ",
        privacyPolicy: " Privacy Policy",
        promotions: "I would like to receive emails on ReLuxe Resorts & Activities promotions and special offers.*",
        acknowledgementFront: "By submitting this form, I agree to the ReLuxe ",
        terms: "Terms and Conditions ",
        acknowledgementBack: "and for the use of this site, making reservations and stays.",
        or: "or",
        agentUsername: "Agent Username",
        privacyChecked: "Please accept the ReLuxe Resorts Privacy Statement and Privacy Policy",
        required: {
            email: "Please enter your email",
            username: "Please enter your username",
            password: "Please enter your password",
        },
        noEmailAccess: "Please allow the system to access your email"
    },
    forgotPassword: {
        placeholder: "Please insert your email",
        email: "Email",
        required: {
            email: "Please insert your email"
        },
        sendSuccess: "We've sent you your reset password link, please check your inbox and reset your password within 60 minutes."
    },
    resetPassword: {
        email: "Email",
        password: "New Password",
        password_confirmation: "Re-type New Password",
        required: {
            password: "Please insert your new password",
            password_confirmation: "Please insert your confirm new password",
        },
        errors: {
            notSame: "Your password does not match with your confirmation password"
        },
        loginNow: "Login Now",
        notValid: "Your reset password link doesn't meet our server credential or already expired, please try to request a reset password link again.",
        resetSuccess: "Your password is successfully changed, you may now proceed login with your new password."
    },
    title: {
        home: "Home",
        profile: "Profile",
        forgotPassword: "Forgot Password",
        resetPassword: "Reset Password",
        search: "Search",
        agencies: "Merchants",
        packages: "Packages",
        reviews: "Testimonials",
        carts: "My Carts",
        orders: "Orders",
        vouchers: "Vouchers",
        news: "News",
        ourResorts: "Our Resorts",
        activities: "Activities",
        aboutUs: "About Us",
        about: "About",
        gallery: "Gallery",
        blog: "Blog",
        contactUs: "Contact Us",
        golfCourses: "Golf Courses",
        dashboard: "Dashboard",
        subscription: "Subscription",
        unsubscribe: "Unsubscribe",
        ourNewsletter: "Our Newsletters",
        paymentHistory: "Payment History",
        destination: "Destination",
        resorts: "Resorts",
        logout: "Log Out",
        login: "Log In",
        tnc: "Terms & Conditions",
        rnr: "Return / Refund Policy",
        pp: "Privacy Policy",
        cp: "Cookie Policy",
    },
    user: {
        profile: "Profile",
        username: "Username",
        name: "Name",
        email: "Email",
        mobile: "Mobile",
        ic: "IC/Passport No.",
        password: "Password",
        security_password: "Security Password",
        old: "Old {{field}}",
        new: "New {{field}}",
        confirm: "Re-type new {{field}}",
        addresses: "Addresses",
        firstName: "First Name",
        lastName: "Last Name",
        profilePicture: "Profile Picture",
        preview:"Preview",
        dob: "Date of birth",
        gender: "Gender",
        male: "Male",
        female: "Female",
    },
    address: {
        name: "Name",
        address1: "Address 1",
        address2: "Address 2",
        zip: "Zip",
        state: "State",
        city: "City",
        country: "Country",
    },
    general: {
        goHome: "Back to Home",
        notFound: "404 - Not Found!",
        copiedToClipboard: "Copied to clipboard",
        pleaseSelect: "Please Select",
        error: "There's and error in the process.",
        itemNotFound: "Item Not Found",
        allRightReserved: "All right reserved",
        followUs: "Follow Us",
        noData: "No Related Data.",
        purchase: "Purchase",
        submit: "Submit",
        update: "Update",
        edit: "Edit",
        cancel: "Cancel",
        create: "Create",
        delete: "Delete",
        search: "Search",
        clear: "Clear",
        ok: "OK",
        loading: "Loading...",
        yes: "Yes",
        no: "No",
        backToPrevious: "Back To Previous Page",
        previousStep: "Previous Step",
        nextStep: "Next Step",
        readMore: "Read More",
        clearDate: "Clear Date",
        weekday:{
            1:"Mon",
            2:"Tue",
            3:"Wed",
            4:"Thurs",
            5:"Fri",
            6:"Sat",
            7:"Sun"
        },
        upload: "Upload",
        print:"Print",
        backendLink: "Agent Website"
    },
    profile: {
        changeProfile: "Change Profile",
        changePassword: "Change Password",
        changeSecurityPassword: "Change Security Password",
        myAddress: "My Address",
        security: "Security",
        myDocument:"My Document"
    },
    datatable: {
        noMatch: "Sorry, no matching records found",
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows per page",
        displayRows: "of",
    },
    package: {
        category: "Category",
        location: "Destinations (optional)",
        voucherCount: "Number of Voucher (per quantity)",
        voucherAvailableDay: "Voucher Available Used Day",
        voucherExpiryDate: "Voucher Valid Date",
        description: "Package Description",
        addToCart: "Add To Cart",
        name: "Package Name",
        quantity: "Quantity",
        buyNow: "Buy Now",
        voucherAvailableDayNote: "*** Available days for this package do not include public holidays.",
        publicHoliday: "Public Holiday for this package includes",
        discount: "OFF",
        voucherExceptDate: "Vouchers cannot be used on these dates",
        durationPeriod: "Duration Period (Day)",
        validFrom: "Valid From {{date}}",
        validTo: "Valid Until {{date}}",
        selectPackage: "Select Package",
        packageOption: "Package Option",
        total: "Total",
        startingFrom: "Starting From",
        operationHour: "Operation Hours (Local Time)",
        bookingDate: "Tour Date",
        loadMorePackage: "Show more",
        noMorePackage: "No More Package",
        moreForYou: "More for you",
        relax: "#Relax",
        reviews: "Testimonials",
        reviewsCount: "{{count}} reviews",
        reviewMoreCount: "Show all {{count}} reviews",
        bookingDetails: "Your booking details",
        checkIn: "Check-In",
        checkOut: "Check-Out",
        nights: "Night (s)",
        for: "For",
        room: "Room",
        taxesAndFees: "Taxes & Fees",
        taxesAndFeesNote: "Total price in your currency is approximate and based on today's exchange rates. The actual price charged will be in the hotel's currency.",
        totalPrice: "Total Price",
        selectDate: "Please select a participation date",
        selectDateTitle: "Please select a date",
        bookNow: "Book Now",
        reportListing: "Report this listing",
        sellingOutSoon: "Selling Out Soon",
        mustVisit: "Must Visit",
        checkout: "Check Out",
        startDate: "Start Date",
        endDate: "End Date",
        date: "Date",
        activity: "Activity",
        priceBreakdown: "Price Breakdown",
        viewImages: "View {{count}} Images",
        dayTrip: "Day Trip",
        mustHaveAdult: "Children must be accompanied by an adult to participate.",
        orverview: "Overview",
        itinerary: "Itinerary",
        note: "Things to Note",
        fillAllFields: "Complete all required fields to continue",
        checkAvailability: "Check Availability",
        selectOption: "Select Option",
        searchActivities: "Search activities",
        addOn: "Add On",
    },
    checkout: {
        all: "All",
        subtotal: "Subtotal",
        selectedItems: "Selected Items",
        checkout: "Checkout",
        stepper: {
            cart: "Cart",
            checkout: "Checkout",
            submit: "Payment",
            bookingInfo: "Booking Detail",
        },
        guestNote: "You are not logged in! After logging in, your items will be automatically added to the shopping cart.",
        noSelectedItem: "Please select the item(s) you want to checkout.",
        placeOrder: "Place Order",
        itemPriceSum: "{{num}} item(s), Subtotal",
        totalSum: "Total Payment",
        buyerInfo: "Buyer Info",
        remark: "Remark",
        remarkNote: "Remark to seller",
        bilingAddress: "Billing Address",
        checkoutItem: "Checkout Item",
        editBuyerInfo: "Edit Buyer Info",
        addAddress: "Add New Address",
        editAddress: "Edit Address",
        noSelectedCart: "No product was selected, please select an product in your cart to proceed.",
        goToCart: "Go To Cart",
        noAddress: "No Address.",
        invalidPayment: "Invalid Payment.",
        payment: "Payment Method",
        option: "Option",
        bookingInfo: "Guest Detail",
        sameWithAcc: "Same as account information",
        noTourPackagenoBookingDetail: "The purchased package does not need to fill in the booking details.",
        payNow: "Pay Now",
        agentDiscountWithPercent: "Agent Discount ( {{discount}} % )",
        agentDiscount: "Agent Discount",
        orderTotal: "Order Total",
        buyerInfoRequired: "Buyer info is required.",
        buyerAddressRequired: "Address is required.",
        discountCode: "Discount Code",
        applyCode: "Apply Code",
        discountCodeAmount: "Discount Amount",
        selectOrEnterCode: "Select Or Enter Code",
        discountVoucher: "Discount Voucher",
        discountCodeRepeat: "Discount voucher already exists.",
        errorSelectBookingDate: "Select tour date first to apply voucher.",
        errorSelectQuantity: "Select items first to apply voucher.",
        errorSelectMerchant: "Only applicable to specific product.",
        errorSpendMin: "Add {{min}} more to use this voucher.",
        minSpend: "Min. Spend",
        validTill: "Valid Till",
        uploadReceipt: "Upload Receipt",
        bankName: "Bank Name",
        accountName: "Account Name",
        accountNo: "Account No",
        cutOffTitle: "Notification",
        cutOffContent: "Sorry, it is not allow to book tomorrow\'s package after 8:00PM. You can only book packages for the day after tomorrow. Please contact our customer service to learn more about booking rules. Customer Service Email: {{email}}",
        contactDetails: "Contact Details",
        emergencyContact: "Emergency Contact",
        relationship: "Relationship",
        agreeToBeMember: "By this, I agree to be a ReLuxe member.",
        addOn: "Add-On",
    },
    payment: {
        success: "Payment Successful",
        pending: "Payment Pending",
        fail: "Payment Failed",
        successContent: "Your voucher has been added to the list~",
        pendingContent: "The system will add the voucher to the list as soon as the payment is completed.",
        failContent: "There was a problem with the payment, please contact customer service for more details.",
    },
    order: {
        createdAt: "Order Date",
        orderNo: "Order No",
        totalAmount: "Total Amount",
        remark: "Remark",
        viewDetails: "View Details",
        status: "Order Status",
        packageName: "Package Name",
        orderItems: "Order Items",
        payNow: "Pay Now",
        cancelOrder: "Cancel Order",
        cancelOrderReminder: "Are you sure want to cancel this order?",
        cancelRemark: "Cancel Remark"
    },
    homepage: {
        categories: "Categories",
        locations: "Locations",
        locationNote: "Incredible destinations",
        agencies: "This Month Favourite Shops",
        agencieNote: "Claim latest coupons from the most popular brands in this month",
        packages: "Today's Best Vouchers",
        packageNote: "Use these promo codes to save more today",
        viewMorePackage: "View More Package",
        viewMoreVoucher: "View More Voucher",
        viewAll: "View All",
        title1: "Come redefine travel.",
        title1Break: "Come<br/>redefine<br/>travel.",
        searchBarPlaceHolder: "Mantanani Snorkeling Package...",
        startPlanningBreak: "Start<br/>planning",
        startPlanning: "Start planning",
        topPackages: "Party Boat",
        golfCourses: "Events",
        activities: "Activities",
        destinations: "Resorts",
        browse: "Browse",
        discover: "Discover",
        join: "Join",
        explore: "Explore",
        jumpstart: "Jumpstart",
        yourDaytrip: "your daytrip",
        sleep: "Sleep",
        onTheSea: "on the sea.",
        resortsText: "Salute the sun in an ocean-facing pavilion. Laze in your overwater villa, puffer fish and baby reef sharks swimming in crystal-clear waters below.",
        seeOurResorts: "See our resorts",
        review: "Testimonials",
        reviewCaption: "What our customer says about their experience with us!",
        slideToSeeMore: "Slide to see more",
        comingSoon: "Coming Soon!",
        loadMore: "Load more results",
        galleryTitle: "#LetsReLuxe",
        gallerySubtitle: "Hashtag to share your moments with us on our Instagram!",
    },
    voucher: {
        guestDetail: "Guest Detail",
        code: "Voucher Code",
        voucherName: "Voucher Name",
        status: "Voucher Status",
        price: "Voucher Price",
        viewDetails: "View Details",
        showQr: "Show QR Code",
        bookingDate: "Tour Date",
        redeemNote: "Please show the QR code to the staff.",
        agreementTitle: "Before you complete your order, please review and agree to the following: ",
        agreement1: "1) By placing order, I agree to the ReLuxe's ",
        agreement2: " and ",
        termscondition: "Terms & Conditions",
        privacypolicy: "Privacy Policy",
        agreement3: "2) By placing your order, you will automatically become a ReLuxe member and unlock exclusive benefits. As a member, you'll have easy access to manage your orders and enjoy the perks outlined in the Terms & Conditions.",
        agreement4: "3) By placing your order, you agree to receive marketing promotional materials in any form(s) stated in the Terms & Conditions. Stay up-to-date with our latest offerings and special promotions.",
        acceptPolicy: "Please accept the Terms & Conditions and Privacy Policy before place order.",
        timeout: "Sorry, it is not allow to book tomorrow\'s package after 8:00PM. You can only book packages for the day after tomorrow."
    },
    news: {
        title1: "Noteworthy",
        title2: "news & stories",
        noNews: "No News.",
        recommended: "Recommended for you",
        seeAll: "See all news",
        topArticle: "Top article read",
        leaveComment: "Leave a Comment",
        commentDesc: "Your email address will not be published. Required fields are marked *",
        message: "Type here...",
        name: "Name *",
        email: "Email *",
        website: "Website",
        subscribe: "Save my name and email in this browser for the next time I comment.",
        submit: "Submit",
        comments: "Comments",
        noComments: "No Comments",
    },
    abouts: {
        title: "About",
        reluxe: "ReLuxe",
        content1: "Welcome to ReLuxe Vacay! Our passion is creating immersive travel experiences that connect you with people, cultures, and memories. We're not just another online travel website - we're a team of experts specializing in hotels, vacation rentals, day trips, and resort management.<br/><br/>Introducing our exclusive resort in Mantanani Island - a paradise that's as cutting-edge as it is beautiful. Get ready for mind-blowing day trips featuring the latest in snorkeling, sea walking, scuba diving, water sports, party boats, and more.",
        gone: "Gone",
        withReluxe: "with ReLuxe",
        hastTag: "#LetsExperienceTheDifference",
        content2: "But here's the thing: we know luxury isn't just about extravagance– it's about meaningful experiences that leave a lasting impact. That's why we're committed to reshaping the way you travel. We offer hassle-free, authentic, and personalized experiences that transport you to another world.<br/><br/>Our team of digital marketing and tourism industry experts know that the future is all about using Big Data to enhance your travel experience. We combine it with the highest level of customer service to create an unforgettable journey that will blow your mind.",
    },
    mantanani: {
        title: {
            0 : "Mantanani",
            // 1 : "Three Island",
            1 : "Dinawan"
        },
        content: {
            0 : "A group of three isolated islands that boast of crystal clear blue waters filled with a myriad of marine life and white sandy beaches fringed by coconut palm trees.",
            // 1 : "Experience the perfect blend of adventure & relaxation in a secluded paradise on the renowned Survivor Island.",
            1 : "Uncover a hidden paradise with creamy sands and azure waters, where stunning sunsets transform the sky into an orange canvas."
        }
    },
    activities: {
        content1: "WELCOME TO RELUXE",
        content2: "Adventure Awaits",
        content3: "Let ReLuxe bring you to the adventure of the world's most captivating destinations. <br/>From journeys of discovery to journeys of serenity, ReLuxe invites you to immerse yourself in each of our destinations with the ReLuxe Experience.",
        content4: "#Experience the difference today.",
        // content1: "JOURNEYS OF ADVENTURE AND DISCOVERY",
        // content2: "Adventure to the West and East",
        // content3: "Let ReLuxe bring you to the adventure of the world's most captivating destinations. Dive deep with mantas in Borneo waters. Immerse in the nature of Peninsular Malaysia's forest. Chill in Little Santorini in Semporna.<br/><br/>From journeys of discovery to journeys of serenity, Reluxe invites you to immerse yourself in each of our destinations with the ReLuxe Experience.",
        // content4: "#experience the difference.",
        chooseDestination: "Unveil Your Next Adventure",
        from: "From",
        perPax: " / pax",
    },
    ourResorts: {
        title: "Begin your memorable journey here.",
        content: "Get up close with the authentic character of each destination in a collection spanning cities, islands, beaches, lush wilderness, and forests across the world. We forge a journey that is rare, unique, and exclusive as you explore hotels and resorts immersing you in wild beauty and indigenous heritage with very few people having access to it. Once you step in you won't feel like leaving.",
        sleep: "Sleep",
        caption1: "on the sea.",
        hastTag1: "#Relax",
        caption2: "Relax<br/>Luxuriously",
        hashTag2: "#Luxury",
        chooseDestination: "Choose Your Destination",
        bookNow: "Book Now",
        explore: "Explore More",
        tnc: "Terms & Conditions",
        tnc1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        tnc2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        tnc3: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        tnc4: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        tnc5: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    },
    dashboard: {
        editProfile: "Edit Profile",
        publicView: "Public View",
        adsTitle: "Kayaking In Local Maldives",
        adsContent: "Immerse yourself in the ultimate in-island pleasures at ReLuxe De Mantanani Island Resort. Savor special treats by the ocean with our exclusive offers. Discover off-the-trail authentic experiences to make your journey even more memorable.",
        wishlist: "My Wishlist",
        booking: "Recent Booking",
        document: "Upload Document",
        unsubscribe: "Unsubscribe",
        subscribe: "Subscribe Now",
        emptyValue: "Please enter your email.",
        emailNotFound: "Your email does not exist in our system.",
        subscribeDesc: "To subscribe our ReLuxe website, please enter your email address here. We will send updates occasionally.",
        unsubscribeDesc: "To unsubscribe our ReLuxe website, please enter your email address here.",
        successUnsubscribe: "You have successfully unsubscribe our ReLuxe website. You will not be able to receive any information from us.",
        noWishlist: "No Wishlist.",
    },
    reviews: {
        leaveReview: "Leave Review",
        content: "Content",
        rating: "Rating",
        viewReview: "View Review",
        viewReviewMore: "View More",
    },
    policy: {
        tnc: "Terms & Conditions",
        rnr: "Return & Refund Policy",
        pp: "Privacy Policy",
        cp: "Cookie Policy",
    },
    pdf:{
        booking:"Booking",
        confirmation:"Confirmation",
        presentTicketDetail:"Please present either an electronic or paper copy of your booking confirmation upon check-in.",
        orderNo:"Booking No",
        guest:"Guest",
        countryOfResidence:"Country of Residence",
        package:"Package",
        reluxeContact:"Reluxe Contact",
        packageType:"Package Type",
        confirmationEmail:"For Full Promotion details & conditions see confirmation email.",
        cancelPolicy:"Cancellation Policy : Risk-freebooking! You can cancel until {{cancel_date}} & pay nothing! Any cancellation received within 1 day prior to the tour date will be charge for the entire stay. If you fail to arrive or cancel the booking, no refund will be given.",
        bookingDate:"Tour Date",
        paymentDetail:"Payment Detail",
        pleaseNote:"Please note",
        pleaseNoteDetail:"Payment for this booking has not been collected by ReLuxe. Payment for this booking must be collected by ReLuxe",
        noteForPackage:"Note for package",
        bookingDetail:"Booking is made to this ID",
        stampAndSignature:"Authorized Stamp & Signature",
        remark:"Remarks",
        notIncluded:"Not Included",
        taxAndFee:"Taxes & fees {{prefix}} {{price}} (pay the package)",
        otherGuest:"Other Guest",
        detailText:"All special requests are subject to availability upon arrival.",
        note:"Notes",
        important:"Important",
        firstNote:"At check-in, you must present a valid photo ID with your address confirming the same name as the lead guest on the booking. For bookings paid with a credit card, you may also need to present the card used to make the payment. Failure to do so may result in the hotel requesting additional payment or your reservation noraging honored.",
        secondNote:" All rooms are guaranteed on the day of arrival. In the case of a no-show, your room(s) will be released and you will be subject to the terms and conditions of the Cancellation/No-Show Policy specified at the time you made the booking as well as noted in the Confirmation Email.",
        thirdNote:"The total price for this booking does not include mini-bar items, telephone usage, laundry service, etc. The property will bill you directly.",
        forthNote:"In cases where Breakfast is included with the room rate, please note that certain properties may charge extra for children travelling with their parents. If applicable, the property will bill you directly. Upon arrival, if you have any questions, please verify with the property."
    }
};

export default english;