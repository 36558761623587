import React from 'react';

import { Box, Grid, Hidden } from '@mui/material/';

import AccountComponent from '@components/AccountComponent';
import BackToPrevious from '@components/BackToPrevious';

const AccountSidebar = ({ children }) => {
    return (
        <Box>
            {/* <BackToPrevious /> */}
            <Grid container>
                {/* <Hidden mdDown> */}
                    <Grid item xs={12} md={2.5}>
                        <AccountComponent />
                    </Grid>
                {/* </Hidden> */}
                <Grid item xs={12} md={9.5}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
};

export default AccountSidebar;