
import { getUrl } from '@helpers/api';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import _ from 'lodash';

import { Box, Container } from '@mui/material';

import CartStepper from '@components/CartStepper';
import CartItemCard from '@userpages/Payment/CartItemCard';
import CustomerInfo from '@userpages/Payment/CustomerInfo';
import BackdropLoading from '@components/BackdropLoading';

const Payment = () => {
    const classes = useStyles();
    const { addToast } = useToasts();
    const { accessToken } = useSelector(state => state.general);
    const [loading, setLoading] = useState(false);
    let { purchase_no } = useParams();
    const theme = useTheme();

    const [orders, setOrders] = useState({});
    const [agentDiscount, setAgentDiscount] = useState({});

    useEffect(() => {
        if (accessToken) {
            setLoading(true);
            getUrl(`payments/${encodeURIComponent(purchase_no)}`).then(response => {
                setLoading(false);
                if (response.status) {
                    setOrders(response.data);
                    setAgentDiscount(response.agent_discount);
                }
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
        // eslint-disable-next-line
    }, [accessToken]);

    return (
        <Box className={classes.paper}>
            <Container maxWidth="xl">
                {/* CART STEPPER */}
                <CartStepper activeStep={2} />
                {/* BUYER INFO & ADDRESS */}
                {_.size(orders) > 0 && orders[0].order_customer ? <CustomerInfo orders={orders} /> : null}
                {/* CART ITEM */}
                <CartItemCard purchase_no={purchase_no} orders={orders} loading={loading} setLoading={setLoading} agentDiscount={agentDiscount} />
            </Container>
            <BackdropLoading loading={loading} />
        </Box>
    )
}

export default Payment;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    }
}))