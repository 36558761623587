import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { getUrl, postUrl } from '@helpers/api';
import _ from 'lodash';
import Moment from 'moment';

import { Box, CircularProgress, Typography, Container, Grid, Divider, Avatar, Link, TextField, FormControlLabel, Checkbox, Button, Card, CardMedia, CardContent, Pagination, PaginationItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { HiArrowNarrowLeft } from "react-icons/hi";

const Detail = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const [news, setNews] = useState([]);
    const [comments, setComments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    let { id } = useParams();
    const theme = useTheme();
    const [recommend, setRecommend] = useState([]);
    const [topArticle, setTopArticle] = useState([]);
    const userState = useSelector(state => state.user);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);

    const [state, setState] = useState({
        comment: '',
        name: userState.id ? userState.name : '',
        email: userState.id ? userState.email : '',
    });

    useEffect(() => {
        setLoading(true);
        getUrl(`/member-news/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setNews(response.data.news);
            }
        }).catch(err => {
            setLoading(false);
            addToast(t('general.error'), { appearance: 'error' });
        })
        getUrl("/member-news", { page: 1 }).then(response => {
            setLoading(false);
            if (response.status) {
                setRecommend(_.slice(response.data.listing.data, 0, 3));
                setTopArticle(_.slice(response.data.listing.data, 0, 5));
            }
        }).catch(err => {
            setLoading(false);
            addToast(t('general.error'), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
        setLoading(true);
        getDisplayComments();
        // eslint-disable-next-line
    }, [id, page]);

    const getDisplayComments = () => {
        getUrl(`/member-display-comments/${id}`, { status: 1, page: page }).then(response => {
            setLoading(false);
            if (response.status) {
                setComments(response.data.listing.data);
                setTotal(response.data.listing.last_page);
            }
        }).catch(err => {
            setLoading(false);
            addToast(t('general.error'), { appearance: 'error' });
        })
    }

    const handlePaginationChange = (event, value) => {
        setPage(value);
    }

    const handleChange = ({ target }) => {
        setState({ ...state, [target.name]: target.value });
    };

    const addComment = () => {
        setInputErrors([]);
        let postData = {
            ...state,
            news_id: id
        }
        postUrl("/member-comments", postData).then(response => {
            setLoading(false);
            if (response.status) {
                setState({
                    comment: '',
                    name: userState.id ? userState.name : '',
                    email: userState.id ? userState.email : '',
                });
                addToast(response.message, { appearance: 'success' });
                getDisplayComments();
            } else {
                addToast(response.message, { appearance: 'error' });
                setInputErrors(response.errors);
            }
        }).catch(err => {
            setLoading(false);
            addToast(t('general.error'), { appearance: 'error' });
        })
    }

    const recommendForYou = () => {
        return (
            <Box style={{ marginBottom: "27%" }}>
                <Typography variant="h4" style={{ fontFamily: "FuturaPTBold" }}>{t('news.recommended')}</Typography>
                <Divider style={{ borderBottom: "2px solid", margin: "6% 0%" }} />
                {
                    _.map(recommend, rNews => {
                        return (
                            <Box style={{ margin: "10% 2%" }} key={rNews.id}>
                                <Link component={RouterLink} to={`/news/${rNews.id}`} className={classes.newsLink}>
                                    <CardMedia
                                        component="img"
                                        image={rNews.image ? rNews.image.file_name : '/images/emptyStatus/no_ticket.png'}
                                        sx={{ flex: 1, width: "100%", height: 180, margin: "2% 0%" }}
                                        alt={'news\'s image'}
                                        className={classes.classMediaStyle}
                                    />
                                    <Typography className={classes.recommendStyle} style={{ minHeight: 60 }}>{rNews.title[i18n.language] ? rNews.title[i18n.language] : rNews.title.en}</Typography>
                                </Link>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    const topArticleRead = () => {
        return (
            <Box style={{ marginBottom: "27%" }}>
                <Typography variant="h4" style={{ fontFamily: "FuturaPTBold" }}>{t('news.topArticle')}</Typography>
                <Divider style={{ borderBottom: "2px solid", margin: "6% 0%" }} />
                {
                    _.map(topArticle, tNews => {
                        return (
                            <Box style={{ padding: "3% 2%" }} key={tNews.id}>
                                <Link component={RouterLink} to={`/news/${tNews.id}`} className={classes.newsLink}>
                                    <Typography className={classes.topArticleStyle}>{tNews.title[i18n.language] ? tNews.title[i18n.language] : tNews.title.en}</Typography>
                                </Link>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    var datetime1 = Moment(new Date());
    var datetime2 = new Date();
    var diff = 0;
    var displayTimeDay = '';

    const calculateTimeDay = (value) => {

        datetime2 = Moment(value);
        diff = Moment.duration(datetime1.diff(datetime2));

        if (diff.days() > 0) {
            displayTimeDay = diff.days() + " days ago";
        } else if (diff.hours() >= 1) {
            displayTimeDay = diff.hours() + " hours ago";
        } else if (diff.minutes() >= 1) {
            displayTimeDay = diff.minutes() + " minutes ago";
        } else {
            displayTimeDay = diff.seconds() + " seconds ago";
        }

        return displayTimeDay;
    }

    const commentDisplay = () => {
        return (
            <Box style={{ padding: "2% 30% 2% 10%" }}>
                <Typography variant="h5" style={{ fontFamily: "FuturaPTBold" }}>{t('news.comments')}</Typography>
                <Divider style={{ borderBottom: "2px solid", margin: "2% 0%" }} />
                {
                    _.size(comments) > 0
                    ?
                    _.map(comments, tComments => {
                        console.log(tComments)
                        return (
                            <Box style={{ padding: "2% 2%", display: 'flex' }} key={tComments.id}>
                                <Box style={{ paddingRight: 10 }}>
                                    <Avatar
                                        alt="profile_picture"
                                        src={userState.profile_picture ? userState.profile_picture.file_name : null}
                                        className={classes.avatarStyle}
                                    >
                                        {tComments.name.substring(0, 1)}
                                    </Avatar>
                                </Box>
                                <Box>
                                    <Typography variant="body1" style={{ fontFamily: 'FuturaPTBold', color: theme.palette.primary.main }}>{tComments.name}</Typography>
                                    <Typography variant="body2" style={{ color: theme.palette.primary.main }}>{ calculateTimeDay(tComments.created_at) }</Typography>
                                    <Typography variant="body2" style={{ color: theme.palette.primary.main }}>{tComments.comment}</Typography>
                                </Box>
                            </Box>
                        )
                    })
                    :
                    <Box>
                        <Typography variant="h6">{t('news.noComments')}</Typography>
                    </Box>
                }
                {
                    total ?
                        <Box style={{ display: "flex", justifyContent: "center", alignSelf: "center", margin: "3%" }}>
                            <Pagination count={total} page={page} onChange={handlePaginationChange} color="secondary" size="large" variant="text"
                                renderItem={(item) => (
                                    <PaginationItem
                                        sx={{ color: theme.palette.secondary.main }}
                                        {...item}
                                    />
                                )}
                            />
                        </Box>
                        : null
                }
            </Box>
        )
    }

    return (
        <>
            <Box className={classes.bgImageWhiteRight}>
                <Container maxWidth="xl" style={{ padding: "2% 9%" }}>
                    {
                        !loading && _.size(news) > 0
                            ?
                            <Grid container columns={7} style={{ marginTop: "3%" }}>
                                <Grid item xs={7} sm={5}>
                                    <Box display="flex" flexDirection="column" style={{ margin: "0% 7%" }}>
                                        <Box>
                                            <Typography variant="h4" className={classes.titleStyle}>{news.title[i18n.language] ? news.title[i18n.language] : news.title.en}</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", justifyContent: "space-between", margin: "20px 0px", alignItems: "center" }}>
                                            <Box style={{ display: "flex", alignItems: "center" }}>
                                                {
                                                    news.creator
                                                        ?
                                                        news.creator.profile_picture
                                                            ?
                                                            <Avatar alt="profile_picture" src={news.creator.profile_picture} sx={{ width: 28, height: 28 }} />
                                                            :
                                                            <Avatar sx={{ bgcolor: theme.palette.secondary.main, width: 28, height: 28 }}>{_.slice(news.creator.name, 0, 1)}</Avatar>
                                                        :
                                                        null
                                                }
                                                <Typography variant="body2" style={{ marginLeft: "10px" }}>{news.creator ? news.creator.name : ''}</Typography>
                                            </Box>
                                            <Typography variant="body2">{Moment(news.created_at).format('LL')}</Typography>
                                        </Box>
                                        {
                                            news.image
                                                ?
                                                <Box>
                                                    <img alt='news banner' src={news.image.file_name} style={{ width: "100%" }} />
                                                </Box>
                                                :
                                                null
                                        }
                                        <Box style={{ marginTop: 20, lineHeight: 1.5 }}>
                                            <Card style={{ width: "100%", boxShadow: 'none' }}>
                                                <CardContent style={{ padding: 0 }}>
                                                    <div dangerouslySetInnerHTML={{ __html: news.content[i18n.language] ? news.content[i18n.language] : news.content.en }} />
                                                </CardContent>
                                            </Card>
                                        </Box>
                                        <Box style={{ margin: "8% 0%", display: "flex" }}>
                                            <Link component={RouterLink} to="/news" underline="hover">
                                                <Box style={{ display: "flex", alignItems: "center", margin: "3px 10px" }}>
                                                    <HiArrowNarrowLeft size={20} />
                                                    <Typography variant="h6" className={classes.seeAll}>{t('news.seeAll')}</Typography>
                                                </Box>
                                            </Link>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={7} sm={2}>
                                    {recommendForYou()}
                                    {topArticleRead()}
                                </Grid>
                            </Grid>
                            :
                            <Box style={{ margin: 250, display: "flex", justifyContent: "center" }}>
                                <CircularProgress color="primary" />
                            </Box>
                    }
                </Container>
            </Box>

            <Box>
                {/* Leave a Comment */}
                <div style={{ backgroundColor: theme.palette.gray.superLight }}>
                    {commentDisplay()}
                    <Container maxWidth="xl" sx={{ padding: {xs: "2% 10% 10% 10%", md: "2% 30% 10% 10%"} }}>
                        <Typography variant='h5'>{t('news.leaveComment')}</Typography>
                        <Typography variant='body1'>{t('news.commentDesc')}</Typography>
                        <Divider style={{ margin: "30px 0px", borderBottomWidth: 0 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div>
                                    <TextField
                                        variant="filled"
                                        name="comment"
                                        multiline
                                        placeholder={t('news.message')}
                                        rows={7}
                                        fullWidth
                                        value={state.comment}
                                        InputProps={{ disableUnderline: true, }}
                                        onChange={handleChange}
                                        error={inputErrors?.comment ? true : false}
                                        helperText={inputErrors?.comment || ''}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <div>
                                    <TextField
                                        variant="filled"
                                        name="name"
                                        placeholder={t('news.name')}
                                        fullWidth
                                        value={state.name}
                                        InputProps={{ disableUnderline: true, }}
                                        onChange={handleChange}
                                        error={inputErrors?.name ? true : false}
                                        helperText={inputErrors?.name || ''}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div>
                                    <TextField
                                        variant="filled"
                                        name="email"
                                        placeholder={t('news.email')}
                                        fullWidth
                                        value={state.email}
                                        InputProps={{ disableUnderline: true, }}
                                        onChange={handleChange}
                                        error={inputErrors?.email ? true : false}
                                        helperText={inputErrors?.email || ''}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: "5%", borderBottomWidth: 0 }} />
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox />} label={t('news.subscribe')} />
                            </Grid> */}
                            <Grid item xs={12}>
                                <Button variant="contained" color="primary" className={classes.buttonStyle} onClick={addComment}>
                                    <b>{t('news.submit')}</b>
                                </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </Box>
        </>

    )
}

const useStyles = makeStyles((theme) => ({
    avatarStyle: {
        width: '50px !important',
        height: '50px !important',
        fontSize: '20px !important',
        lineHeight: '1.0 !important',
        textTransform: 'uppercase',
        margin: 0,
        backgroundColor: "#fff !important",
        color: theme.palette.secondary.main + '!important',
        border: "3px solid " + theme.palette.secondary.main + '!important',
    },
    titleStyle: {
        fontFamily: "FuturaPTBold !important",
        [theme.breakpoints.up('xs')]: {
            fontSize: "1.75rem !important",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "2.3rem !important",
        },
    },
    recommendStyle: {
        lineHeight: "1.1 !important",
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 3,
        overflow: "hidden",
        fontFamily: "FuturaPTBold !important",
        fontSize: "1.2rem !important",
    },
    topArticleStyle: {
        lineHeight: "1.1 !important",
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 3,
        overflow: "hidden",
        fontFamily: "FuturaPTBold !important",
        fontSize: "0.8rem !important",
    },
    newsLink: {
        textDecoration: "none !important",
        "&:hover": {
            color: theme.palette.secondary.main,
            textDecoration: "underline !important",
        },
    },
    seeAll: {
        color: theme.palette.primary.main + " !important",
        marginLeft: "30px !important",
    },
    buttonStyle: {
        textAlign: 'center',
        padding: "6px 10px !important",
        fontSize: '80px',
        minWidth: '200px !important',
    },
    bgImageWhiteRight: {
        backgroundImage: `url('../images/logo-light.png')`,
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -80% center',
            backgroundSize: "60%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right -20% center',
            backgroundSize: "40%",
        },
    },
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important"
    },
}))

export default Detail;