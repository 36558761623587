
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Box, Typography, Container, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Moment from 'moment';

import { updateCartTotal, changeLoginState } from '@actions/generalActions';
import { getUrl, postUrl } from '@helpers/api';

import BackToPrevious from '@components/BackToPrevious';
import BackdropLoading from '@components/BackdropLoading';
import CartStepper from '@components/CartStepper';
import BookingInfo from '@userpages/Checkout/BookingInfo';
import CartItemCard from '@userpages/Checkout/CartItemCard';
import CustomerInfo from '@userpages/Checkout/CustomerInfo';

import { DEFAULT_EMAIL } from '@configs/config';

const Checkout = () => {
    const classes = useStyles();
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const { uuid, accessToken } = useSelector(state => state.general);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { name, email, mobile, addresses, first_name, last_name } = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [inputErrors, setInputErrors] = useState({});

    const [carts, setCarts] = useState({});
    const [activeStep, setActiveStep] = useState(1);
    const [tourType, setTourType] = useState(false);
    const [remarks, setRemarks] = useState({});
    const [buyerInfo, setBuyerInfo] = useState({
        name: name ? name : '',
        email: email ? email : '',
        mobile: mobile ? mobile : '',
        address_id: _.size(addresses) > 0 ? addresses[0].id : '',
    });
    const [bookingInfo, setBookingInfo] = useState({});
    const [agentDiscount, setAgentDiscount] = useState({});
    const [systemPublicHoliday, setSystemPublicHoliday] = useState({});
    const [policyChecked, setPolicyChecked] = useState(false);
    const [buyerInfoError, setBuyerInfoError] = useState({
        info: '',
        address: ''
    });
    const [discountData, setDiscountData] = useState({});
    const [autoApplyDiscountCode, setAutoApplyDiscountCode] = useState({});
    const [cutOffPopup, setCutOffPopup] = useState(false);
    const [registerInfo, setRegisterInfo] = useState({
        register_first_name: first_name ? first_name : '',
        register_last_name: last_name ? last_name : '',
        register_username: '',
        register_email: email ? email : '',
        register_mobile: mobile ? mobile : '',
        register_ic: '',
        register_dob: '',
    });
    const [emergencyContact, setEmergencyContact] = useState({
        emergency_name: '',
        emergency_relationship: '',
        emergency_mobile: '',
    });
    const [guestAddOnInfo, setGuestAddOnInfo] = useState({});

    useEffect(() => {
        // if (accessToken) {
        setLoading(true);
        getUrl("/carts", { guest_token: uuid ? uuid : null }).then(response => {
            setLoading(false);
            if (response.status) {
                let newTourType = false;
                let newBookingInfo = {};
                let newGuestAddOnInfo = {};
                let newCart = response.data.listing;
                _.map(newCart, (agencyCartList, agencyId) => {
                    let agencyShow = false;
                    _.map(agencyCartList.data, (data, package_id) => {
                        _.map(data, (groupItem, booking_date) => {
                            if (groupItem.selected) {
                                _.map(groupItem.items, mainOption => {
                                    // main option
                                    if (mainOption.status) {
                                        agencyShow = true;
                                        if (mainOption.package.type === "tour") {
                                            newTourType = true;
                                            let totalCount = mainOption.package.voucher_count * mainOption.quantity;
                                            let cartBooking = [];
                                            _.times(totalCount, (count) => {
                                                cartBooking.push({
                                                    checked: false,
                                                    user_name: '',
                                                    user_email: '',
                                                    user_mobile: '',
                                                    user_ic: '',
                                                    user_dob: '',
                                                    user_gender: '',
                                                });
                                            });
                                            newBookingInfo[mainOption.id] = cartBooking;
                                        }
                                    }
                                })
                                _.map(groupItem.add_on, add_on => {
                                    _.map(add_on.items, addOnOption => {
                                        // add on option
                                        if (addOnOption.status && addOnOption.package.type === 'tour') {
                                            newTourType = true;
                                            let totalCount = addOnOption.package.voucher_count * addOnOption.quantity;
                                            let cartBooking = [];
                                            _.times(totalCount, (count) => {
                                                cartBooking.push("");
                                            });
                                            newGuestAddOnInfo[addOnOption.id] = cartBooking;
                                        }
                                    })
                                })
                            }
                        })
                    })
                })
                setCarts(newCart);
                setTourType(newTourType);
                setBookingInfo(newBookingInfo);
                setAgentDiscount(response.data.agent_discount);
                setSystemPublicHoliday(response.data.system_public_holidays);
                setAutoApplyDiscountCode(response.data.price_adjustments);
                setGuestAddOnInfo(newGuestAddOnInfo);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // }
        // eslint-disable-next-line
    }, [uuid, accessToken]);

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line
    }, [activeStep]);

    const placeOrder = () => {
        setLoading(true);
        _.map(guestAddOnInfo, (selectedMain, cart_id) => {
            let cartBooking = [];
            _.map(selectedMain, selected => {
                if (selected) {
                    let split_data = _.split(selected, '-');
                    cartBooking.push(
                        bookingInfo[split_data[0]][split_data[1]]
                    )
                } else {
                    cartBooking.push({
                        checked: false,
                        user_name: '',
                        user_email: '',
                        user_mobile: '',
                        user_ic: '',
                        user_dob: '',
                        user_gender: '',
                    })
                }
            })
            bookingInfo[cart_id] = cartBooking;
        })
        let postData = {
            ...registerInfo,
            ...emergencyContact,
            // name: registerInfo.register_first_name + (registerInfo.register_last_name ? ' ' + registerInfo.register_last_name : ''),
            // email: registerInfo.register_email,
            // mobile: registerInfo.register_mobile,
            // address_id: buyerInfo.address_id ? buyerInfo.address_id : null,
            remark: remarks,
            booking_info: bookingInfo,
            price_adjustment_id: discountData?.id ? discountData.id : '',
            discount_code_id: discountData?.discount_codes?.[0]?.id ? discountData.discount_codes[0].id : '',
            guest_token: uuid ? uuid : null
        };

        if (policyChecked) {
            postUrl("place-order", postData).then(response => {
                setLoading(false);
                if (response.status) {
                    getUrl("/carts", {guest_token: uuid ? uuid : null}).then(response => {
                        if (response.status) {
                            dispatch(updateCartTotal(response.data.cart_count));
                        }
                    })
                    if (!accessToken && response.user.access_token) {
                        dispatch(changeLoginState(response.user));
                    }
                    setInputErrors({});
                    let purchase_no = response.data[0]['purchase_no'];
                    navigate(`/payment/${encodeURIComponent(purchase_no)}`, { replace: true });
                } else {
                    setInputErrors(response.errors);
                    // let orderError = false;
                    // _.map(response.errors, (err, key) => {
                    //     if (!key.includes('booking_info') && !key.includes('register') && !key.includes('emergency')) {
                    //         orderError = true;
                    //     }
                    // })
                    // if (orderError) {
                    //     setActiveStep(1);
                    // }
                    addToast(response.message, { appearance: 'error' });
                }
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        } else {
            setLoading(false);
            addToast(t('voucher.acceptPolicy'), { appearance: 'error' });
        }
    }

    const handleActiveStep = (nextStep) => {
        let error = false;
        if (nextStep === 2) {
            // let tempData = {
            //     info: '',
            //     address: ''
            // };
            // if (!buyerInfo.name || !buyerInfo.email || !buyerInfo.mobile) {
            //     addToast(t('checkout.buyerInfoRequired'), { appearance: 'error' });
            //     tempData['info'] = t('checkout.buyerInfoRequired');
            //     error = true;
            // }
            // if (!buyerInfo.address_id) {
            //     addToast(t('checkout.buyerAddressRequired'), { appearance: 'error' });
            //     tempData['address'] = t('checkout.buyerAddressRequired');
            //     error = true;
            // }
            // setBuyerInfoError(tempData);
            let $today = new Date();
            if ($today.getHours() >= 20) {
                _.map(carts, (agencyCartList, agencyId) => {
                    _.map(agencyCartList.data, (data, package_id) => {
                        _.map(data, (groupItem, booking_date) => {
                            if (groupItem.selected) {
                                if (booking_date === Moment().add(1, 'days').format('YYYY-MM-DD')) {
                                    setCutOffPopup(true);
                                    error = true;
                                }
                            }
                        })
                    })
                })
            }
        }
        if (!error) {
            setActiveStep(nextStep);
        }
    }

    const cutOffPopupBox = () => {
        return (
            <Dialog open={cutOffPopup} onClose={() => setCutOffPopup(false)} fullWidth={true} maxWidth="sm">
                <DialogTitle>{t('checkout.cutOffTitle')}</DialogTitle>
                <DialogContent>
                    {t('checkout.cutOffContent', { email: DEFAULT_EMAIL })}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setCutOffPopup(false)}>{t('general.ok')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Box className={classes.paper}>
            <Container maxWidth="xl">
                <BackToPrevious />
                {/* CART STEPPER */}
                <CartStepper activeStep={activeStep} />
                {/* ERROR DISPLAY */}
                {
                    _.size(inputErrors) > 0
                        ?
                        <Box className={classes.inputErrorBox}>
                            {
                                _.map(inputErrors, (err, key) => {
                                    return (
                                        <Typography key={key} color="error">{err}</Typography>
                                    )
                                })
                            }
                        </Box>
                        :
                        null
                }
                {/* BUYER INFO & ADDRESS */}
                {/* <CustomerInfo buyerInfo={buyerInfo} setBuyerInfo={setBuyerInfo} addresses={addresses} loading={loading} setLoading={setLoading} buyerInfoError={buyerInfoError} /> */}
                {/* CART ITEM */}
                <CartItemCard carts={carts} setCarts={setCarts} buyerInfo={buyerInfo} bookingInfo={bookingInfo} loading={loading} setLoading={setLoading} inputErrors={inputErrors} setInputErrors={setInputErrors} setActiveStep={handleActiveStep} tourType={tourType} remarks={remarks} setRemarks={setRemarks} placeOrder={placeOrder} agentDiscount={agentDiscount} systemPublicHoliday={systemPublicHoliday} autoApplyDiscountCode={autoApplyDiscountCode} setAutoApplyDiscountCode={setAutoApplyDiscountCode} discountData={discountData} setDiscountData={setDiscountData} />
                {/* BOOKING INFO */}
                <BookingInfo carts={carts} bookingInfo={bookingInfo} setBookingInfo={setBookingInfo} loading={loading} setLoading={setLoading} inputErrors={inputErrors} setInputErrors={setInputErrors} activeStep={activeStep} setActiveStep={setActiveStep} tourType={tourType} placeOrder={placeOrder} setPolicyChecked={setPolicyChecked} policyChecked={policyChecked} registerInfo={registerInfo} setRegisterInfo={setRegisterInfo} emergencyContact={emergencyContact} setEmergencyContact={setEmergencyContact} guestAddOnInfo={guestAddOnInfo} setGuestAddOnInfo={setGuestAddOnInfo} />
                <BackdropLoading loading={loading} />
                {cutOffPopupBox()}
            </Container>
        </Box>
    )
}

export default Checkout;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    inputErrorBox: {
        margin: 30
    }
}))