import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';

import { Box, Container, Typography, Tab, Tabs, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import TermCondition from './TermCondition';
import ReturnRefundPolicy from './ReturnRefundPolicy';
import PrivacyPolicy from './PrivacyPolicy';
import CookiePolicy from './CookiePolicy';

const Policy = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isMountedRef = useRef(null);

    const [value, setValue] = useState(0);

    const [searchParams, setSearchParams] = useSearchParams();
    let type = searchParams.get('type');

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const listing = {
        0: {
            code: 'termcondition',
            display: t('policy.tnc'),
        },
        1: {
            code: 'returnrefundpolicy',
            display: t('policy.rnr'),
        },
        2: {
            code: 'privacypolicy',
            display: t('policy.pp'),
        },
        3: {
            code: 'cookiepolicy',
            display: t('policy.cp'),
        },
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setSearchParams({
            type: (listing[newValue]['code']).toLowerCase(),
        })
    };

    useEffect(() => {
        isMountedRef.current = true;
        if(type){
            let lowerCaseText = type.toLowerCase();
            let withoutSpaceText =type.replace(/\s+/g, '');
            if(lowerCaseText === listing[1]['code'] || withoutSpaceText === listing[1]['code']){
                setValue(1);
            }else if(lowerCaseText === listing[2]['code'] || withoutSpaceText === listing[2]['code']){
                setValue(2);
            }else if(lowerCaseText === listing[3]['code'] || withoutSpaceText === listing[3]['code']){
                setValue(3);
            }else{
                setValue(0);
                setSearchParams({
                    type: (listing[0]['code']).toLowerCase(),
                })
            }
            window.scrollTo(0, 0);
        }
        return () => { isMountedRef.current = false };
    }, [type]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
          </div>
        );
    }
      
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
      
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <Box padding={matchDownMd?0:5}>
            <Container maxWidth="xl" style={{ padding: "2% 6%"}}>
                <Grid container>
                    <Grid item xs={12} >
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs 
                                    value={value} 
                                    onChange={handleChange} 
                                    aria-label="basic tabs example"
                                    variant="scrollable"
                                    allowScrollButtonsMobile
                                >
                                    {
                                        _.map(listing , (item,key)=>{
                                            return  <Tab label={<Typography style={{ width: "100%", textTransform: 'capitalize', color: value === parseInt(key) ? theme.palette.primary.main : '', fontWeight: 'bold', fontFamily: 'PlayfairDisplayRegular' }}>{item.display}</Typography>} {...a11yProps(key)} key={key} />
                                        })
                                    }
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <TermCondition />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <ReturnRefundPolicy />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                <PrivacyPolicy />
                            </TabPanel>
                            <TabPanel value={value} index={3}>
                                <CookiePolicy />
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
export default Policy;