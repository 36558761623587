
import { getUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import {  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';

import BackdropLoading from '@components/BackdropLoading';

const PaymentCallback = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    let { payment_id } = useParams();
    const [dialog, setDialog] = useState({
        open: false,
        content: "",
        title: "",
        successPayment: false,
    });

    useEffect(() => {
        if (payment_id) {
            setLoading(true);
            getUrl(`payments/${payment_id}/validate`).then(response => {
                setLoading(false);
                if (response.status) {
                    let status = response.data.payment_status === 40 ? 'success' : (response.data.payment_status === 20 ? 'pending' : 'fail');
                    setDialog({
                        open: true,
                        content: t('payment.' + status + 'Content'),
                        title: t('payment.' + status),
                        successPayment: status === 'fail' ? false : true,
                    });
                } else {
                    addToast(response.message, { appearance: 'error' });
                }
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
        // eslint-disable-next-line
    }, [payment_id]);

    const handleOkButtonClick = () => {
        navigate(`/orders`, { replace: true });
    }

    return (
        <Box className={classes.paper}>
            <Typography>{t('general.loading')}</Typography>
            <BackdropLoading loading={loading} />
            <Dialog open={dialog.open}>
                <DialogTitle>{dialog.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialog.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleOkButtonClick}>
                        {t('general.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default PaymentCallback;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
        height: 500
    },
}))