import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Box, Card, CardContent, Typography } from '@mui/material';
import { FiUser, FiMail, FiHome } from "react-icons/fi";

const CustomerInfo = (props) => {
    const { orders } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const [buyerInfo, setBuyerInfo] = useState({
        name: '',
        email: '',
        mobile: '',
        full_address: '',
    });

    useEffect(() => {
        if (_.size(orders) > 0 && orders[0].order_customer) {
            setBuyerInfo({
                name: orders[0].order_customer.name,
                email: orders[0].order_customer.email,
                mobile: orders[0].order_customer.mobile ? orders[0].order_customer.mobile : '',
                full_address: orders[0].order_customer.full_address ? orders[0].order_customer.full_address : '-',
            });
        }
        // eslint-disable-next-line
    }, [orders]);

    return (
        <Box sx={{margin:{xs: "20px 0px 20px 0px", md: "20px"}}}>
            <Box className={classes.outlineBox}>
            <div className={classes.infoStyle}></div>
                <Typography variant="h6" style={{ padding:'15px 15px 0' }}><b>{t('checkout.buyerInfo')}</b></Typography>
                <Card className={classes.buyerInfoBox}>
                    <CardContent>
                        <Typography>
                            <FiUser style={{ marginRight: 5 }} />
                            {buyerInfo.name + (buyerInfo.mobile ? (" | " + buyerInfo.mobile) : '')}
                        </Typography>
                        <Typography>
                            <FiMail style={{ marginRight: 5 }} />
                            {buyerInfo.email}
                        </Typography>
                        {/* <Typography>
                            <FiHome style={{ marginRight: 5 }} />
                            {buyerInfo.full_address}
                        </Typography> */}
                    </CardContent>
                </Card>
            </Box>
        </Box>
    )
}

export default CustomerInfo;

const useStyles = makeStyles((theme) => ({
    buyerInfoBox: {
        // backgroundColor: theme.palette.secondary.secondary + ' !important',
        // color: theme.palette.secondary.main + ' !important',
        // border: "1px solid " + theme.palette.secondary.main,
        boxShadow:'none !important'
    },
    outlineBox: {
        width: "100%",
        backgroundColor:'#fff', boxShadow:'0 3px 6px 0 #0003', paddingBottom:15, borderRadius:'0 0 15px 15px', marginTop:30
    },
    infoStyle:{
        height: 5,
        width: '100%',
        backgroundPositionX: -30,
        backgroundSize: '116px 3px',
        backgroundImage: 'repeating-linear-gradient(45deg,#f7ca4d,#f7ca4d 33px,transparent 0,transparent 41px,#222 0,#222 74px,transparent 0,transparent 82px)',
    },
}))

