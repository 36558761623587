import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Card, Link, Typography, Grid } from '@mui/material/';
import { makeStyles, useTheme } from '@mui/styles';

import { logoutUser } from '@actions/generalActions';
import { resetProfile } from '@actions/userActions';
import { MdDashboard, MdSubscriptions } from 'react-icons/md';
import { HiOutlineTicket, HiOutlineShoppingCart } from 'react-icons/hi';
import { RiMoneyDollarBoxFill } from 'react-icons/ri';
import { IoIosLogOut } from 'react-icons/io';

const AccountComponent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();

    const sidebarList = [
        // { url: "/dashboard", label: t('title.dashboard'), logo: <MdDashboard size={30} style={{ color: theme.palette.primary.main }} /> },
        { url: "/vouchers?page=1", label: t('title.vouchers'), logo: <HiOutlineTicket size={30} style={{ color: theme.palette.primary.main }} /> },
        { url: "/orders?page=1", label: t('title.paymentHistory'), logo: <RiMoneyDollarBoxFill size={30} style={{ color: theme.palette.primary.main }} /> },
        { url: "/cart", label: t('title.carts'), logo: <HiOutlineShoppingCart size={30} style={{ color: theme.palette.primary.main }} /> },
        // { url: "/subscription", label: t('title.subscription'), logo: <MdSubscriptions size={30} style={{ color: theme.palette.primary.main }} /> },
    ];

    const signOutUser = () => {
        dispatch(logoutUser());
        dispatch(resetProfile());
    }

    return (
        <Grid container>
            {
                _.map(sidebarList, section => {
                    return (
                        <Grid item xs={3} md={12} key={section.url}>
                            <Link component={RouterLink} to={section.url} underline='none'>
                                <Box className={section.url.includes(window.location.pathname) ? classes.selectedLink : classes.normalLink} style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                                    {section.logo}
                                    <Typography color="inherit" style={{ paddingLeft: '2%' }}>{section.label}</Typography>
                                </Box>
                            </Link>
                        </Grid>
                    )
                })
            }
            <Grid item xs={3} md={12}>
                <Box className={classes.normalLink} onClick={signOutUser} style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    <IoIosLogOut size={30} style={{ color: theme.palette.primary.main }} />
                    <Typography color="inherit" style={{ paddingLeft: '2%' }}>{t('auth.signOut')}</Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles(theme => ({
    selectedLink: {
        margin: "10px 20px",
        cursor: 'pointer',
        color: theme.palette.secondary.main + ' !important',
    },
    normalLink: {
        margin: "10px 20px",
        cursor: 'pointer',
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.gray.main + ' !important',
            fontWeight: 'bold',
        },
    },
}))

export default AccountComponent;