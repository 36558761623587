import * as actionTypes from '@types/generalType';

export const changeLoginState = loginState => ({
    type: actionTypes.CHANGE_LOGIN_STATE,
    payload: loginState
});

export const logoutUser = () => ({
    type: actionTypes.LOGOUT_USER
});

export const changeDefaultLanguage = lang => ({
    type: actionTypes.CHANGE_DEFAULT_LANGUAGE,
    payload: lang
});

export const changeDefaultCurrency = currency => ({
    type: actionTypes.CHANGE_DEFAULT_CURRENCY,
    payload: currency
});

export const storeUuid = uuid => ({
    type: actionTypes.STORE_UUID,
    payload: uuid
});

export const removeUuid = () => ({
    type: actionTypes.REMOVE_UUID,
});

export const updateCartTotal = total => ({
    type: actionTypes.CART_TOTAL,
    payload: total
});

export const getIpAddressCountry = country => ({
    type: actionTypes.IP_ADDRESS_COUNTRY,
    payload: country
})