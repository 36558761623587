import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// import Backend from 'i18next-http-backend';

// import { BASE_URL } from '../services/config';

import en from './languages/en';
import cn from './languages/cn';

const resources = {
    en: { translation: en },
    cn: { translation: cn },
}

const i18n = i18next.use(LanguageDetector).use(initReactI18next).init({ //.use(Backend)
    lng: "en",
    fallbackLng: "en",
    resources,
    // backend: {
    //     loadPath: `${BASE_URL}/locales/{{lng}}/{{ns}}.json`
    // },
});

export default i18n;