import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Box, Container, Typography, Grid, Divider, CardMedia, LinearProgress, Pagination, PaginationItem, Dialog, Button, Hidden } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl } from '@helpers/api';

import { RiCloseFill } from "react-icons/ri";

import PackageList from '@components/PackageList';

const perPage = 4;

const OurResorts = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const classes = useStyles();
    const theme = useTheme();
    const [packages, setPackages] = useState([]);
    const [data, setData] = useState(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setLoading(true);
        getUrl("/package-pagination", { page: page }).then(response => {
            setLoading(false);
            if (response.status) {
                setPackages(response.data.listing.data);
                setTotal(response.data.listing.last_page);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [page]);

    const handlePaginationChange = (event, value) => {
        setPage(value);
    }

    const toggleItem = (packageItem = null) => {
        setData(packageItem);
        setVisible(packageItem !== null);
    };

    const PackageModal = ({ data, closeModal }) => {
        return (
            <Dialog
                open={visible}
                onClose={closeModal}
                fullWidth={true}
                maxWidth={'md'}
                PaperProps={{
                    style: {
                        backgroundColor: `${theme.palette.primary.main}`,
                    },
                }}
            >
                <Grid container display="flex" flexDirection="row" >
                    <Grid item xs={12} style={{ padding: "15px 15px" }}>
                        <RiCloseFill size={50} style={{ position: 'absolute', right: 15, cursor: 'pointer', color: 'white' }} onClick={closeModal} />
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: '30px 30px 0px 30px' }}>
                        <CardMedia
                            component="img"
                            image={(data.images && data.images[0]) ? data.images[0].file_name : '/images/emptyStatus/no_ticket.png'}
                            sx={{ flex: 1, width: "100%", height: 280 }}
                            alt={'package\'s image'}
                            className={classes.classMediaStyle}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.listContainer}>
                        <Typography variant="h6" className={classes.listTitle}>{data.name}</Typography>
                        <Typography variant="body1" className={classes.listContent}>{data.description&&data.description.body?data.description.body:""}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ padding: "15px 15px" }}>
                        {/*  rating/comment */}
                    </Grid>
                    <Grid item xs={12} sm={6} display="flex" align="center" style={{ padding: "0px 20px 25px 0px" }}>
                        <Grid item xs={12} sm={6} >
                            <Button
                                component={RouterLink}
                                to={`/package/${data.id}`}
                                variant="contained"
                                color="white"
                                size="small"
                                sx={{ boxShadow: 'none', border: 'none', '& :hover': { color: `${theme.palette.white.main} !important` } }}
                            >
                                <Typography variant="subtitle1" className={classes.buttonText} style={{ color: theme.palette.primary.main }}>{t('ourResorts.bookNow')}</Typography>
                            </Button>
                        </Grid>
                        {/* <Hidden smDown> */}
                            <Grid item xs={12} sm={6}>
                                <Button
                                    component={RouterLink}
                                    to={`/packages`}
                                    variant="outlined"
                                    color="white"
                                    size="small"
                                    sx={{ color: `white !important`, boxShadow: 'none', '& :hover': { color: `${theme.palette.primary.main} !important` }}}
                                >
                                    <Typography variant="subtitle1" className={classes.buttonText} style={{ color: theme.palette.white.main }}>{t('ourResorts.explore')}</Typography>
                                </Button>
                            </Grid>
                        {/* </Hidden> */}
                    </Grid>
                    {/* <Hidden smUp>
                        <Grid item xs={12} sm={6} align="center" style={{ padding: "0px 20px 30px 0px" }}>
                            <Button
                                component={RouterLink}
                                to={`/packages`}
                                variant="outlined"
                                color="white"
                                size="small"
                                sx={{ color: `white !important`, boxShadow: 'none', '& :hover': { color: `${theme.palette.primary.main} !important` }}}
                            >
                                <Typography variant="subtitle1" className={classes.buttonText} style={{ color: theme.palette.white.main }}>{t('ourResorts.explore')}</Typography>
                            </Button>
                        </Grid>
                    </Hidden> */}
                </Grid>
            </Dialog >
        );
    };

    return (
        <>
            {/* <img alt='ourResorts banner' src={"/images/ourResorts/banner.jpg"} style={{ width: "100%" }} /> */}
            <Box className={classes.bannerBackground} />
            <Box style={{ padding: '20px 0 40px' }} className={classes.bgImageWhiteRight}>
                <Container maxWidth="xl" className="app-container">
                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: '60px' }}>
                        <Typography align="center" component="h1" variant="h2" >{t('ourResorts.title')}</Typography>
                    </Grid>
                    <Grid sx={{ paddingTop: '30px', paddingLeft: '50px', paddingRight: '50px' }}>
                        <Typography align="center" component="h1" variant="h5" sx={{ lineHeight: '1.1' }} ><Trans>{t('ourResorts.content')}</Trans></Typography>
                    </Grid>
                    <Grid container spacing={0} sx={{ paddingTop: '70px' }}>
                        <Grid item xs={12} sm={6}>
                            <img alt='img1' align="right" src={"/images/ourResorts/image1.jpg"} className={classes.image} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.content}>
                            <Typography component="h1" variant="h2" sx={{ fontFamily: 'PlayfairDisplayMediumItalic', display: 'inline-block', paddingLeft: '30px' }}>{t('ourResorts.sleep')}</Typography>
                            <Typography component="h1" variant="h2" sx={{ fontFamily: 'PlayfairDisplayMedium', paddingLeft: '30px' }}>{t('ourResorts.caption1')}</Typography>
                            <Grid display="flex" justifyContent="center" style={{ paddingTop: '30px', alignItems: 'center' }}>
                                <Grid item xs={2} sm={3.5}>
                                    <div style={{ flex: 1, height: '1.8px', backgroundColor: '#DCD8D3' }} />
                                </Grid>
                                <Grid item xs={10} sm={10.5}>
                                    <Typography component="h1" variant="h4" sx={{ fontFamily: 'PlayfairDisplayMediumItalic', paddingLeft: '10px' }}>{t('ourResorts.hastTag1')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box style={{ padding: '20px 0 40px' }} className={classes.bgImageWhiteLeft}>
                <Container maxWidth="xl" className="app-container">
                    <Grid container spacing={0} paddingY={8}>
                        <Hidden mdUp>
                            <Grid item xs={12} sm={6}>
                                <img alt='aboourResortsuts banner' align="left" src={"/images/ourResorts/image2.jpg"} className={classes.image2} />
                            </Grid>
                        </Hidden>
                        <Grid item xs={12} sm={6} className={classes.content2}>
                            <Typography component="h1" variant="h2" sx={{ fontFamily: 'PlayfairDisplayMediumItalic', display: 'inline-block', paddingLeft: '100px' }}><Trans>{t('ourResorts.caption2')}</Trans></Typography>
                            <Grid display="flex" justifyContent="center" style={{ paddingTop: '30px', alignItems: 'center' }}>
                                <Grid item xs={6} sm={6}>
                                    <Divider style={{ borderBottom: "2px solid #DCD8D3", margin: "1% -100%", width: '200%' }} />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <Typography component="h1" variant="h4" sx={{ fontFamily: 'PlayfairDisplayMediumItalic', paddingLeft: '10px' }}>{t('ourResorts.hashTag2')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Hidden mdDown>
                            <Grid item xs={12} sm={6}>
                                <img alt='aboourResortsuts banner' align="left" src={"/images/ourResorts/image2.jpg"} className={classes.image2} />
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} className={classes.destination}>
                        <Typography component="h1" variant="h2" sx={{ fontFamily: 'PlayfairDisplayItalic' }}>{t('ourResorts.chooseDestination')}</Typography>
                    </Grid>
                    {
                        _.size(packages) > 0
                            ?
                            <Box className={classes.packageList}>
                                <PackageList packageList={packages} pagination={false} swiper={true} showPrice={true} showLink={true} bookResortNow={false} />
                                {/* <PackageList packageList={packages} pagination={true} swiper={false} handlePaginationChange={handlePaginationChange} page={page} total={total} showPrice={false} showLink={false} bookResortNow={true} toggleItem={toggleItem} /> */}
                            </Box>
                            :
                            loading
                                ?
                                <Box style={{ marginTop: 20 }}>
                                    <LinearProgress size={20} />
                                </Box>
                                :
                                <Box style={{ marginTop: 20, textAlign: "center" }}>
                                    <Typography variant="h6" style={{ padding: 50 }}>{t('general.noData')}</Typography>
                                </Box>
                    }
                    {/* {visible === true && data !== null && (
                        <PackageModal data={data} closeModal={() => toggleItem()} />
                    )} */}
                </Container>
            </Box>
            {/* <Box style={{ padding: '20px 0 40px' }} className={classes.bgImageWhiteRight}>
                <Container maxWidth="xl" className="app-container">
                    <Grid item xs={12} className={classes.tncTitle}>
                        <Typography component="h1" variant="h4" sx={{ fontFamily: "FuturaPTDemi" }}>{t('ourResorts.tnc')}</Typography>
                    </Grid>
                    <Divider style={{ borderBottom: "1px solid", margin: "1% -100%", width: '150%' }} />
                    <Grid item xs={12} paddingY={1} display="flex" alignItems="center" className={classes.tnc}>
                        <img alt='ReLuxe Logo' src="../images/logo.png" style={{ width: "2%" }} />
                        <Typography component="h1" variant="subtitle1" className={classes.tncText}>{t('ourResorts.tnc1')}</Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={1} display="flex" alignItems="center" className={classes.tnc}>
                        <img alt='ReLuxe Logo' src="../images/logo.png" style={{ width: "2%" }} />
                        <Typography component="h1" variant="subtitle1" className={classes.tncText}>{t('ourResorts.tnc2')}</Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={1} display="flex" alignItems="center" className={classes.tnc}>
                        <img alt='ReLuxe Logo' src="../images/logo.png" style={{ width: "2%" }} />
                        <Typography component="h1" variant="subtitle1" className={classes.tncText}>{t('ourResorts.tnc3')}</Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={1} display="flex" alignItems="center" className={classes.tnc}>
                        <img alt='ReLuxe Logo' src="../images/logo.png" style={{ width: "2%" }} />
                        <Typography component="h1" variant="subtitle1" className={classes.tncText}>{t('ourResorts.tnc4')}</Typography>
                    </Grid>
                    <Grid item xs={12} paddingY={1} display="flex" alignItems="center" className={classes.tnc}>
                        <img alt='ReLuxe Logo' src="../images/logo.png" style={{ width: "2%" }} />
                        <Typography component="h1" variant="subtitle1" className={classes.tncText}>{t('ourResorts.tnc5')}</Typography>
                    </Grid>
                </Container>
            </Box> */}
        </>
    )
};
export default OurResorts;

const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '80%',
        },
    },
    image2: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '90%',
        },
    },
    bgImageWhiteLeft: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat', 
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'left -15% top 5%', 
            backgroundSize: "40%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'left 15% top 40%',  
            backgroundSize: "15%",
        },
    },
    bgImageWhiteRight: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right 10% top 5%', 
            backgroundSize: "40%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right 10% top 50%',   
            backgroundSize: "15%",
        },
    },
    content: {
        paddingTop: '50px',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '180px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '240px',
        },
    },
    content2: {
        paddingTop: '50px',
        paddingBottom: '50px',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '120px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '200px',
        },
    },
    destination: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '50px',
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '100px',
        },
    },
    tncTitle: {
        paddingTop: '30px',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '50px',
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '100px',
        },
    },
    tnc: {
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '50px',
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '100px',
        },
    },
    tncText: {
        display: 'inline-block',
        paddingLeft: '10px',
    },
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important"
    },
    packageNameStyle: {
        lineHeight: "1.4 !important",
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 2,
        overflow: "hidden",
        margin: '8px 0 !important',
        fontFamily: "FuturaPTBold !important",
        fontSize: "1.1rem !important",
    },
    offerStyle: {
        fontSize: 18,
    },
    flexSbM: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    packageList: {
        marginTop: 20,
        [theme.breakpoints.up('sm')]: {
            padding: "0px 70px 0px 70px"
        },
    },
    listContainer: {
        padding: '50px 30px 0px 0px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 30px 0px 0px'
        },
    },
    listTitle: {
        fontFamily: "FuturaPTBold !important",
        minHeight: 36,
        color: `${theme.palette.white.main} !important`,
        textDecoration: 'underline',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "30px"
        },
    },
    listContent: {
        margin: "10px 0px 25px 0px",
        lineHeight: "1.1",
        color: `${theme.palette.white.main} !important`,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: "30px"
        },
    },
    buttonText: {
        [theme.breakpoints.up('sm')]: {
            padding: '0px 30px',
        },
    },
    bannerBackground: {
        backgroundImage: `url("/images/ourResorts/banner.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('xs')]: {
            minHeight: "200px",
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: "400px",
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: "600px",
        },
    },
}));