import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme, makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useReactToPrint } from 'react-to-print';
import { DEFAULT_PHONE_NUMBER } from '@configs/config';

// MUI
import { Button, Typography, Grid, Box } from '@mui/material';

const MODULE = 'pdf';

const OrderPrint = ({ label, data, defaultCurrency, orderDetail }) => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const componentRef = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Order_' + data.order_no + '-' + orderDetail.id,
    });

    const ComponentToPrint = ({ innerRef }) => {
        return (
            <div style={{ padding: 60 }} ref={innerRef} className={classes.backgroundStyle} >
                <Box style={{ marginBottom: "20%" }} />
                <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center">
                    <Grid item xs={12} style={{ marginBottom: 30 }}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} marginLeft={0.3}>
                            <Typography style={{ fontFamily: "FuturaPTHeavy", color: theme.palette.secondary.main, letterSpacing: 1.5 }} variant="h6">{t(`${MODULE}.booking`)}</Typography>&nbsp;
                            <Typography style={{ fontFamily: "FuturaPTHeavy", color: theme.palette.primary.main, letterSpacing: 1.5 }} variant="h6">{t(`${MODULE}.confirmation`)}</Typography>
                        </Grid>
                        <Typography variant="body2" style={{ color: theme.palette.text.main }}>{t(`${MODULE}.presentTicketDetail`)}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center">
                    <Grid item xs={6} style={{ paddingTop: 10 }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                            <Grid item xs={5} style={{ paddingTop: 0 }} >
                                <Typography variant="caption">{t(`${MODULE}.orderNo`)}</Typography>
                            </Grid>
                            <Grid item xs={7} style={{ paddingTop: 0 }}>
                                <Typography variant="caption">: {data?.order_no || '-'}</Typography>
                            </Grid>
                            <Grid item xs={5} style={{ paddingTop: 0 }} >
                                <Typography variant="body2" >{t(`${MODULE}.guest`)}</Typography>
                            </Grid>
                            <Grid item xs={7} style={{ paddingTop: 0 }}>
                                <Typography variant="caption" >: {data?.order_customer?.name || '-'}</Typography>
                            </Grid>
                            <Grid item xs={5} style={{ paddingTop: 0 }}>
                                <Typography variant="caption" >{t(`${MODULE}.countryOfResidence`)}</Typography>
                            </Grid>
                            <Grid item xs={7} style={{ paddingTop: 0 }}>
                                <Typography variant="caption" style={{ textTransform: 'capitalize' }} >: {data?.order_customer?.country || '-'}</Typography>
                            </Grid>
                            <Grid item xs={5} style={{ paddingTop: 0 }}>
                                <Typography variant="caption" >{t(`${MODULE}.package`)}</Typography>
                            </Grid>
                            <Grid item xs={7} style={{ paddingTop: 0 }}>
                                <Typography variant="caption" >: {orderDetail?.package_name || '-'}</Typography>
                            </Grid>
                            <Grid item xs={5} style={{ paddingTop: 0 }}>
                                <Typography variant="caption" >{t(`${MODULE}.reluxeContact`)}</Typography>
                            </Grid>
                            <Grid item xs={7} style={{ paddingTop: 0 }}>
                                <Typography variant="caption">: {DEFAULT_PHONE_NUMBER || '-'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ backgroundColor: theme.palette.primary.background }}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} padding={1}>
                            <Grid item xs={6} style={{ paddingTop: 0 }}>
                                <Typography style={{ color: theme.palette.text.main }} variant="caption">{orderDetail?.package_option_name}:</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: 0 }}>
                                <Typography style={{ textAlign: 'center', border: '1px solid black' }} variant="body2">{orderDetail?.quantity}</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: 2 }}>
                                <Typography style={{ color: theme.palette.text.main }} variant="caption">{t(`${MODULE}.packageType`)} :</Typography>
                            </Grid>
                            <Grid item xs={6} style={{ paddingTop: 2 }}>
                                {/* <Typography style={{ textAlign: 'center', border: '1px solid black' }} variant="body2">{orderDetail?.package_type}</Typography> */}
                                <Typography style={{ textAlign: 'center', border: '1px solid black' }} variant="body2">{t(`package.dayTrip`)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption" style={{ color: theme.palette.text.main }} >{t(`${MODULE}.confirmationEmail`)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <Grid item xs={12} style={{ backgroundColor: theme.palette.primary.background, paddingTop: 0, paddingX: 10 }} marginLeft={2}>
                        <Typography padding={2} style={{ color: theme.palette.text.main, textAlign: 'justify' }} variant="caption">{t(`${MODULE}.cancelPolicy`)}</Typography>
                    </Grid>
                </Grid> */}
                <Grid container spacing={2} marginTop={2} paddingLeft={2} direction="row">
                    <Grid item xs={7}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ border: '1px solid black', paddingBottom: 10 }}>
                            <Grid item xs={4}>
                                <Typography variant="body2">{t(`${MODULE}.bookingDate`)} :</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <Typography style={{ backgroundColor: theme.palette.primary.background, display: 'inline', padding: 5 }} variant="body2">{orderDetail?.vouchers[0]?.start_date || '-'}</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: 0 }}>
                                <Typography variant="body2">{t(`${MODULE}.paymentDetail`)} :</Typography>
                            </Grid>
                            <Grid item xs={12} marginLeft={3} style={{ paddingTop: 0, lineHeight: '1.0rem' }}>
                                <Typography variant="body2" display="inline" style={{ color: theme.palette.red.main }}>Harap Maklum : </Typography>
                                <Typography variant="body2" display="inline" >Bayaran untuk tempahan ini tidak dikutip oleh ReLuxe. Bayaran untuk tempahan ini harus dikutip oleh peninapan. </Typography>
                            </Grid>
                            <Grid item xs={12} marginLeft={3} style={{ paddingTop: 0, lineHeight: '1.0rem' }}>
                                <Typography variant="body2" display="inline" style={{ color: theme.palette.red.main }}>{t(`${MODULE}.pleaseNote`)} : </Typography>
                                <Typography variant="body2" display="inline" >{t(`${MODULE}.pleaseNoteDetail`)}</Typography>
                            </Grid>
                            <Grid item xs={12} marginLeft={3} style={{ paddingTop: 0, lineHeight: '1.0rem' }}>
                                <Typography variant="body2" display="inline" style={{ color: theme.palette.red.main }}>{t(`${MODULE}.noteForPackage`)} : </Typography>
                                <Typography variant="body2" display="inline" >{t(`${MODULE}.bookingDetail`)}</Typography>
                                <Typography variant="body2" display="inline" style={{ color: theme.palette.text.main }} >{data.order_no}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} style={{ display: 'flex', alignItems: 'stretch' }}>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} style={{ border: '1px solid black', marginLeft: 1, backgroundColor: '#f3f1ed' }}>
                            <Grid item xs={12} style={{ paddingTop: 0 }}>
                                <Typography>{t(`${MODULE}.stampAndSignature`)} : </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ height: 100 }}>
                                {/* <img src={'/images/signature/agoda-signature.png'} alt="signature" style={{ width: '90%', height: 100, objectFit: 'contain' }} /> */}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center" marginTop={1}>
                    <Grid item xs={12} marginLeft={2} style={{ paddingTop: 0 }}>
                        <Typography display="inline" style={{ fontFamily: 'FuturaPTMedium', color: theme.palette.text.main }} variant="subtitle2">{t(`${MODULE}.remark`)} : </Typography>
                        <Typography display="inline" style={{ fontFamily: 'FuturaPTMedium', color: theme.palette.text.main }} variant="subtitle2">{data?.remark || '-'}</Typography>
                    </Grid>
                    {/* <Grid item xs={12} marginLeft={2} style={{ paddingTop: 0 }}>
                        <Typography display="inline" style={{ fontFamily: 'FuturaPTMedium', color: theme.palette.text.main }} variant="subtitle2">{t(`${MODULE}.notIncluded`)} : </Typography>
                        <Typography display="inline" style={{ fontFamily: 'FuturaPTMedium', color: theme.palette.text.main }} variant="subtitle2">{t(`${MODULE}.taxAndFee`, { prefix: defaultCurrency?.symbol, price: (parseFloat(orderDetail.price.final) * defaultCurrency?.rate).toFixed(2) })}</Typography>
                    </Grid> */}
                    <Grid item xs={12} marginLeft={2} style={{ paddingTop: 0 }}>
                        <Typography display="inline" style={{ fontFamily: 'FuturaPTMedium', color: theme.palette.text.main }} variant="subtitle2">{t(`${MODULE}.otherGuest`)} : </Typography>
                        <Typography display="inline" style={{ fontFamily: 'FuturaPTMedium', color: theme.palette.text.main }} variant="subtitle2">{
                            _.size(orderDetail.vouchers) > 0 ?
                                <>
                                    {
                                        _.map(orderDetail.vouchers, (detail, index) => {
                                            if (index === 0) {
                                                return `${detail.user_name}`
                                            } else {
                                                return `, ${detail.user_name}`
                                            }
                                        })
                                    }
                                </>
                                : "-"
                        }</Typography>
                    </Grid>
                    <Grid item xs={12} marginLeft={2} marginTop={2} style={{ paddingTop: 0 }}>
                        <Typography display="inline" style={{ fontFamily: 'FuturaPTMedium', color: theme.palette.text.main }}>{t(`${MODULE}.detailText`)}</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" marginTop={2}>
                    <Grid item xs={12} style={{ border: '1px solid #dddddd', paddingTop: 0, paddingX: 10 }} marginLeft={2}>
                        <Grid item marginLeft={2} xs={12} style={{ paddingTop: 0, paddingLeft: 0 }}>
                            <Typography display="inline" variant="caption" >{t(`${MODULE}.note`)}</Typography>
                        </Grid>
                        <Grid item marginLeft={2} xs={12} style={{ display: 'list-item', paddingTop: 0, paddingLeft: 0, paddingRight: 5 }}>
                            <Typography display="inline" style={{ textTransform: 'uppercase', color: theme.palette.red.main, textAlign: 'justify' }} variant="caption">{t(`${MODULE}.important`)} : </Typography>
                            <Typography display="inline" variant="caption" style={{ textAlign: 'justify' }} >{t(`${MODULE}.firstNote`)}</Typography>
                        </Grid>
                        <Grid item marginLeft={2} xs={12} style={{ display: 'list-item', paddingTop: 0, paddingLeft: 0, paddingRight: 5 }}>
                            <Typography display="inline" variant="caption" style={{ textAlign: 'justify' }}>{t(`${MODULE}.secondNote`)}</Typography>
                        </Grid>
                        <Grid item marginLeft={2} xs={12} style={{ display: 'list-item', paddingTop: 0, paddingLeft: 0, paddingRight: 5 }}>
                            <Typography display="inline" variant="caption" style={{ textAlign: 'justify' }}>{t(`${MODULE}.thirdNote`)}</Typography>
                        </Grid>
                        <Grid item marginLeft={2} xs={12} style={{ display: 'list-item', paddingTop: 0, paddingLeft: 0, paddingRight: 5 }}>
                            <Typography display="inline" variant="caption" style={{ textAlign: 'justify' }} >{t(`${MODULE}.forthNote`)}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handlePrint}>{label}</Button>
            <div style={{ display: "none" }}>
                <ComponentToPrint innerRef={componentRef} />
            </div>
        </div>
    )
}

export default OrderPrint;

const useStyles = makeStyles((theme) => ({
    backgroundStyle: {
        backgroundImage: `url('../images/letterhead.png')`,
        backgroundRepeat: 'no-repeat',
        // backgroundBlendMode: 'multiply',
        backgroundSize: '100%',
        // backgroundPosition: 'center 110%',
    }
}))