import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { getUrl, deleteUrl, postUrl } from '@helpers/api';
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Link, Rating, Tooltip, Typography, Hidden, IconButton, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { MdFavorite, MdFavoriteBorder } from 'react-icons/md';

const PackageCard = (props) => {
    const { packageItem, showPrice, showLink, bookResortNow, toggleItem, popup } = props;
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const userState = useSelector(state => state.user);
    const { defaultCurrency } = useSelector(state => state.general);

    const [hovered, setHovered] = useState(false);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    let navigate = useNavigate();
    const [state, setState] = useState({
        like_package: packageItem.wishlist_id?true:false,
    });
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    useEffect(() => {
        setState({...state, like_package: packageItem.wishlist_id?true:false});
        // eslint-disable-next-line
    }, [packageItem.wishlist_id]);

    const handleMouseEnter = () => {
        // if (popup === true && _.size(packageItem.label)) {
            setHovered(true);
        // }
    }

    const handleMouseOut = () => {
        setHovered(false);
    }

    const handleToLoginPage = () => {
        navigate("/sign-in");
    }

    const handleSaveToWishlist = () => {
        setLoading(true);

        let postData = {
            package_id: packageItem.id,
        };

        postUrl('/wishlist/package', postData).then(response => {
            setLoading(false);
            if (response.status) {
                setState({ ...state, like_package: true });
                addToast(response.message, { appearance: 'success' });
            } else {
                let msg = response.message;
                if (response.errors) {
                    _.map(response.errors, error => {
                        msg = msg + ' ' + error;
                    })
                }
                addToast(msg, { appearance: 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const handleRemoveFromWishlist = () => {
        setLoading(true);

        deleteUrl(`/wishlist/package/${packageItem.id}`).then(response => {
            setLoading(false);
            if (response.status) {
                addToast(response.message, { appearance: 'success' });
            } else {
                let msg = response.message;
                if (response.errors) {
                    _.map(response.errors, error => {
                        msg = msg + ' ' + error;
                    })
                }
                addToast(msg, { appearance: 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })

        setState({ ...state, like_package: false });
    }

    const card = () => (
        <>
            <Card xs={12} style={{ boxShadow: (hovered ? '0 0 15px 0 #b3b3b3' : '0 0 6px 0 #0002'), borderRadius: 0, backgroundColor: 'white' }}>
                <CardMedia
                    sx={{ flex: 1, transition: 'all .2s' }}
                    image={(packageItem.images && packageItem.images[0]) ? packageItem.images[0].file_name : '/images/emptyStatus/no_ticket.png'}
                    alt={'package\'s image'}
                    className={`${classes.classMediaStyle} ${hovered?classes.cardMediaHovered:classes.cardMediaNormal}`}
                />
                <CardContent className={classes.cardContent}>
                    <Grid container style={{ height: "100%" }}>
                        <Grid item xs={12} style={{ height: "20%" }}>
                            <Tooltip title={packageItem.name}>
                                <Typography variant="h6" className={classes.packageNameStyle}>{packageItem.name}</Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} style={{ height: "40%" }}>
                            <Typography variant="body1" style={{ margin: "10px 0px 10px 0px", fontFamily: "FuturaPTDemi", lineHeight: "1.1" }}>
                                {(packageItem.description && packageItem.description.title) ? packageItem.description.title : null}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ height: "25%" }}>
                            <Box sx={{ display: "flex", flexDirection: {xs: 'column', sm: 'row'}, alignItems: {xs: 'flex-start', sm: 'center'} }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Rating
                                        value={packageItem.average_rating}
                                        precision={0.1}
                                        readOnly
                                        size="small"
                                        style={{ color: theme.palette.primary.main }}
                                    />
                                    {
                                        packageItem.average_rating > 0
                                            ?
                                            <Typography variant="body2" color="primary" style={{ padding: 3 }}>
                                                {parseFloat(packageItem.average_rating).toFixed(1)}
                                            </Typography>
                                            :
                                            null
                                    }
                                </div>
                                <Typography variant="caption" color="primary">
                                    ({t('package.reviewsCount', { count: packageItem.total_reviews })})
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} style={{ height: "20%" }}>
                            {
                                showPrice && packageItem.display_price.sell_price > 0 ?
                                    <Typography variant="h6" style={{lineHeight: "1.0"}}>
                                        {t('activities.from')}
                                        <b>
                                            {
                                                " " + defaultCurrency?.symbol + " " 
                                                + (parseFloat(packageItem.display_price.sell_price) * defaultCurrency?.rate).toFixed(2) 
                                                + t('activities.perPax')
                                            }
                                        </b>
                                    </Typography>
                                    : null
                            }
                        </Grid>
                    </Grid>
                </CardContent>
                {
                    bookResortNow ? 
                        <CardActions>
                            <Button onClick={() => toggleItem(packageItem)}>
                                <Typography variant="h6">{t('ourResorts.bookNow')}</Typography>
                            </Button>
                        </CardActions>
                    : null
                }
            </Card>
        </>
    )

    const cardOuter = () => (
        <>
            <Box style={{ position: "relative", width: "100%" }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseOut}>
                <Box style={{ position: 'relative', top: matchDownMd ? 35 : 50, left: -10, visibility: _.size(packageItem.label) ? 'visible' : 'hidden' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 20" className={classes.labelHeight}>
                        <polygon points={matchDownSm ? "0 0, 80 0, 70 10, 80 20, 0 20" : "0 0, 100 0, 90 10, 100 20, 0 20"} fill="#d8644a" />
                        {
                            _.size(packageItem.label) &&
                            <text fill="#ffffff" fontSize={10} x="6%" y="70%" style={{ fontFamily: "FuturaPTHeavy" }}>
                                {packageItem?.label?.detail[i18n.language]}
                            </text>
                        }
                    </svg>
                </Box>
                <Box style={{ position: 'absolute', right: 0 }}>
                    <IconButton onClick={userState.id ? (state.like_package ? handleRemoveFromWishlist : handleSaveToWishlist) : handleToLoginPage} >
                        {
                            state.like_package 
                            ? <MdFavorite color="red" size={matchDownSm ? 20 : 30}/> 
                            :
                            <>
                                <MdFavoriteBorder size={matchDownSm ? 20 : 30} className={classes.wishIconStyle} />
                                <MdFavorite size={matchDownSm ? 20 : 30} className={classes.wishIconHoverStyle} />
                            </>
                        }
                    </IconButton>
                </Box>
                {
                    showLink ? 
                        <Link component={RouterLink} to={`/package/${packageItem.id}`} style={{ textDecoration: "none", color: "inherit" }}>
                            {card()}
                        </Link>
                        : card()
                }
            </Box>
        </>
    )

    return (
        <>
            { cardOuter() }
        </>
    )
}

// onMouseEnter={handleMouseEnter} onMouseOut={handleMouseOut}

const useStyles = makeStyles((theme) => ({
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important"
    },
    packageNameStyle: {
        lineHeight: "1 !important",
        display: "-webkit-box",
        boxOrient: "vertical",
        lineClamp: 2,
        overflow: "hidden",
        // margin: '8px 0 !important',
        fontFamily: "FuturaPTBold !important",
        fontSize: "1.1rem !important",
    },
    wishIconStyle: {
        objectFit: 'contain',
        color: "white",
    },
    wishIconHoverStyle: {
        position: 'absolute',
        objectFit: 'contain',
        opacity: 0,
        transition: 'opacity .2s',
        color: 'red',
        "&:hover": {
            opacity: 1,
        },
    },
    cardMediaNormal: {
        [theme.breakpoints.up('xs')]: {
            height: "120px !important",
        },
        [theme.breakpoints.up('sm')]: {
            height: "170px !important",
        },
        [theme.breakpoints.up('lg')]: {
            height: "220px !important",
        },
    },
    cardMediaHovered: {
        [theme.breakpoints.up('xs')]: {
            height: "140px !important",
        },
        [theme.breakpoints.up('sm')]: {
            height: "190px !important",
        },
        [theme.breakpoints.up('lg')]: {
            height: "240px !important",
        },
    },
    cardContent: {
        // width: "100%",
        [theme.breakpoints.up('xs')]: {
            padding: "10px !important",
            height: "130px !important",
        },
        [theme.breakpoints.up('sm')]: {
            padding: "10px !important",
            height: "160px !important",
        },
        [theme.breakpoints.up('lg')]: {
            padding: "16px !important",
            height: "210px !important",
        },
        [theme.breakpoints.up('xl')]: {
            padding: "16px !important",
            height: "260px !important",
        },
    },
    labelHeight: {
        // width: '100%',
        [theme.breakpoints.up('xs')]: {
            height: "23px !important",
        },
        [theme.breakpoints.up('md')]: {
            height: "33px !important",
        },
    }
}))

export default PackageCard