import React from 'react';
import { Typography, Link } from '@mui/material';
import { DEFAULT_EMAIL, LOCAL_URL } from '@configs/config';

const cookie_url = LOCAL_URL+"/policy?type=cookiepolicy";

const PrivacyPolicy = () => {
    return (
        <div style={{ textAlign: 'justify' }}>
            <Typography style={{ textDecoration: "underline", fontFamily: 'FuturaPTBold' }}>Privacy Policy</Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            At ReLuxe Holdings, also referred to as "ReLuxe", "we", "us", and "our", we are dedicated to protecting and respecting your privacy and adhering to all data protection principles and laws that apply. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            When you use our website (<Link href={LOCAL_URL} target="_blank">www.reluxeofficial.com</Link>), any services offered by ReLuxe, or any third party through the ReLuxe platform, we may collect, process, use, and disclose your information. These services are referred to as "the Services" in this Privacy Policy. "You" and "your" in this policy refer to anyone who accesses our platform or uses our services. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            This Privacy Policy outlines the terms and conditions under which ReLuxe collects, processes, uses, and/or discloses the information obtained from you when you access our platform or use the Services. This information may include personal information linked to a specific individual, such as name, address, phone number, email, travel document information, or any other information that we have requested and you have provided through our platform, referred to as "Personal Information". 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            We ask that you please take the time to carefully read this Privacy Policy. By accessing the ReLuxe platform, you are consenting to the collection, processing, usage, and disclosure of your Personal Information as outlined in this policy. 
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>The Information We Gather</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
                <b>Personal Information</b>: When you create an account or make a booking through our website, we collect personal information such as your name, email address, phone number, and payment information. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
                <b>User Content</b>: Our website allows users to post reviews, comments, and other content. Any information or content you submit to our website is voluntarily provided by you. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
                <b>Log Information</b>: When you use our website, we collect log information such as your IP address, browser type, and internet service provider. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
                <b>Location Information</b>: We may collect information about your location if you have enabled location services on your device. 
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            We may collect Personal Information about you through your use of our platform, including 
            when you open a User Account, visit the platform, or make reservations for our services. 
            Providing your Personal Information to ReLuxe is always optional. However, some services may 
            not be available if you choose not to provide us with your Personal Information. For example, 
            we cannot create your User Account or make reservations for you without your name and 
            contact information. 
            </Typography>
            <br />
            <ol style={{ listStyleType: 'decimal', paddingLeft: 20, lineHeight: 1.5 }}>
                <li>Setting Up Your User Account:</li>
                <Typography>
                When you create an account with us or update your account information, we may gather 
                information such as your name, email address, username, password, and telephone number. 
                </Typography>
                <li>Using the ReLuxe Platform, Booking Services, or Services:</li>
                <ol style={{ listStyleType: 'lower-alpha', paddingLeft: 20, lineHeight: 1.5 }}>
                    <li>Whether you have a User Account or not, when you visit the ReLuxe platform, book 
                        services, or use any of our services, we may collect and process information about you, 
                        including Personal Information and non-identifiable information linked to your Personal 
                        Information, including but not limited to the following;</li>
                    <li>Any communications between you and ReLuxe, or between you and our service providers;</li>
                    <li>Records of your interactions and use of the ReLuxe services, including information on traffic, 
                        location, and duration of your sessions;</li>
                    <li>Your responses to surveys, feedback, newsletters, and any other publications by ReLuxe;</li>
                    <li>Data automatically collected and stored on our servers or those of our third-party providers, 
                        such as login information, IP address, browser type, device information, pages visited, and 
                        previous or subsequent websites visited.</li>
                </ol>
                <li>Mobile Device Access:</li>
                <Typography>When you access the ReLuxe services through your mobile device, we may collect information 
                about you and your device, including location data if you have allowed your device to share it 
                with us. This information will be used under this Privacy Policy and can be utilized to provide 
                personalized content or suggest nearby services during your travels. However, you have the 
                option to change your mobile device's privacy settings to stop sharing location information. Note 
                that some features on ReLuxe services may not be available if you deactivate this function. If 
                you have any questions about your mobile device's privacy settings, please reach out to your 
                mobile service provider or device manufacturer.
                </Typography>
                <li>Booking Services for Others:</li>
                <Typography>If you book services for other individuals through the ReLuxe services, we may request personal 
                information about these individuals. You must obtain the necessary consent of these individuals 
                and ensure they are aware of, understand, and accept this Privacy Policy before providing their 
                information to ReLuxe.</Typography>
            </ol>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Information Management</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            The Personal Information and data that you provide us with may be transferred to and 
            processed on our servers or those of our third-party service providers. This information will be 
            stored for as long as it is needed to meet the purposes outlined in this Privacy Policy, or to 
            comply with any (i) contractual requirements that are necessary for the provision of the 
            Services, and (ii) legal, tax, or accounting requirement.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            Once it is no longer necessary to retain this information, we will either delete or anonymize the 
            data. If this is not possible, the information will be securely stored.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            For analytical purposes or trend analysis, we will make an effort to anonymize or aggregate the 
            data. ReLuxe is committed to maintaining appropriate physical, electronic, and organizational 
            procedures to protect your Personal Information and data, ensuring that it is treated securely 
            and under this Privacy Policy. This also includes preventing unauthorized access, alteration, 
            disclosure, or destruction of your data.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            Once we have received your information, we will use strict procedures and security features to 
            try to prevent unauthorized access. ReLuxe does not give any representation, warranty, or 
            undertaking that the Personal Information you provide to us will be secure at all times, and to 
            the extent, ReLuxe has fulfilled its obligations under no circumstances shall ReLuxe be 
            responsible for any losses, damages, costs, and expenses which you may suffer or incur arising 
            from unauthorized access to or use of your Personal Information.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            All payment transactions processed through us or our chosen third-party payment processing 
            provider will be encrypted using secure online technology. It is your responsibility to keep your 
            password confidential and not share it with any third party.
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Usage of Information</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            We process the Personal Information collected as far as necessary for the performance of the 
            contract with and providing services to you. Besides, we process the other Personal Information 
            collected based on our legitimate interests, which are the further improvement of the services 
            and direct marketing purposes.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            For example, ReLuxe will use Personal Information and other data collected through the 
            ReLuxe platform or when making purchases for the Services to create your User Account, to 
            provide you with the Services, to continually improve the ReLuxe platform and the Services, and 
            to contact you about the Services. This includes using your Personal Information or other data 
            to achieve faster purchase requests, and better customer support and to provide you with timely 
            notice of new Services and special offers.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            From time to time, we may also make use of your Personal Information to contact you for 
            feedback on your use of the ReLuxe platform, to assist us in improving the ReLuxe platform, or 
            to offer special savings or promotions to you, where you have indicated your consent to 
            receiving such communications. If you would prefer not to receive notices of special savings or 
            promotions, you may simply opt out from receiving them by replying to us through the hyperlink 
            provided in these notices.
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Disclosure of Information</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            From time to time, we may share and make known your Personal Information and other data 
            with third parties, some of whom may be located outside your country of residence. The 
            situations in which this sharing and disclosure may occur can include, but are not limited to, the 
            following:
            </Typography>
            <ol style={{ listStyleType: 'decimal', paddingLeft: 20, lineHeight: 1.5 }}>
                <li>To complete your reservations or fulfill our Terms of Use, we may share your information with 
                    third parties who provide goods and services or act on our behalf.</li>
                <li>With our third-party service providers which we engage for specific services, such as web 
                    hosting, data analysis, marketing, market research, and customer service.</li>
                <li>If required by law, court order, or a request from a governmental authority.</li>
                <li>Within ReLuxe Holdings, in the event of a corporate transaction, such as a sale, merger, 
                    acquisition, or corporate restructuring, your Personal Information may be disclosed, shared, or 
                    transferred to the new controlling entity or its authorized third party.</li>
                <li>With our advisors, agencies, or other parties concerned to protect the rights and property of ReLuxe.</li>
                <li>In any other cases, to any third parties with your prior written consent (in which case, you will 
                    be able to withdraw your consent just as easily as you provided it).</li>
            </ol>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            We may also share aggregate or anonymous information with relevant third parties, including 
            advertisers, but this information does not contain Personal Information and will not personally 
            identify you. However, these third parties may possess information about you or obtain it from 
            other sources, and when combined with our aggregate information, they may be able to identify 
            you personally.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            Your Personal Information may be transferred to a location outside of your home country and 
            outside of the European Union for the above-mentioned purposes, but ReLuxe will make 
            reasonable efforts to ensure that appropriate safeguards are in place if the transfer takes place 
            to a country without an adequate level of protection.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            ReLuxe platform may contain links that could result in you leaving the platform and/or being 
            taken to third-party websites. Please note that any Personal Information you provide to these 
            third-party websites is not covered by this Privacy Policy, and ReLuxe is not liable for any 
            losses, damages, costs, or expenses that may result from your providing or making Personal 
            Information or other data available to such third-party websites.
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Cookies</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            Cookies are widely used to make websites work, or work more efficiently. When you visit our 
            Website, we collect some of your Personal Information transmitted to us by your browser via 
            cookies. This enables you to access the ReLuxe platform and helps us to create a better user 
            experience for you. You will find more details about cookies and similar technologies that we 
            use, in our <Link href={cookie_url} target="_blank">Cookies Policy</Link>.
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Managing Your Personal Information</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            You have the right to access, edit, or delete your personal information at any time through the 
            user portal on our website under "My Account". You can also request access, correction, or 
            deletion by contacting us by email. If needed by law, you can also request to limit the processing 
            of your personal information, object to its processing, or request a copy of the information we 
            have about you by sending an email. If you have any complaints about how we handle your 
            personal information, please reach out to us through the contact information provided. When 
            processing any of these requests, we may verify the requester's identity to make sure they are 
            entitled to make the request.
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Control Over Your Information</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            You can control certain aspects of your personal information through the following options:
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
                <b>Access and Update</b>: You can access and update your personal information through your account on our website.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
                <b>Marketing Communications</b>: You can opt out of receiving marketing communications from us by following the unsubscribe instructions in our emails.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
                <b>Tracking Technologies</b>: You can control the use of cookies through your browser settings.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            ReLuxe reserves the right to modify, update or amend this Privacy Policy at any time without 
            prior notice. Your continued use of the ReLuxe platform and its services signifies your 
            acceptance of the updated, modified, or amended Privacy Policy. If any changes result in a 
            reduction of your rights, we will seek your consent before implementing the changes. If you do 
            not agree with all the terms and conditions of this Privacy Policy or any subsequent updates, 
            modifications, or amendments, you must stop using the ReLuxe platform and its services.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
                Please check this page regularly for the latest version of this Privacy Policy.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            For any questions or inquiries regarding our Privacy Policy, please contact us by email at <Link href={`mailto:${DEFAULT_EMAIL}`}>{DEFAULT_EMAIL}</Link>.
            </Typography>
        </div>
    )
}

export default PrivacyPolicy;