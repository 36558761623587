import React, { memo } from 'react';
import { useTheme } from '@mui/styles';
import { Backdrop, CircularProgress } from '@mui/material';

const BackdropLoading = memo(({ loading , color ,zIndex }) => {
    const theme = useTheme();
    return (
        <Backdrop
            sx={{ color: color || theme.palette.primary.main, zIndex: zIndex ||((theme) => theme.zIndex.drawer + 1) }}
            open={loading}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
});

export default BackdropLoading;