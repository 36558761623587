import { postUrl, putUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { Box, Button, Card, CardActionArea, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import { FiEdit, FiPlus } from "react-icons/fi";

import { updateAddresses } from '@actions/userActions';

const CustomerInfo = (props) => {
    const { buyerInfo, setBuyerInfo, addresses, loading, setLoading, buyerInfoError } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const [inputErrors, setInputErrors] = useState({});
    const { accessToken } = useSelector(state => state.general);

    const [buyerInfoDialog, setBuyerInfoDialog] = useState({
        open: false,
        name: '',
        email: '',
        mobile: '',
    });
    const [addressDialog, setAddressDialog] = useState({
        open: false,
        id: '',
        name: '',
        address1: '',
        address2: '',
        zip: '',
        city: '',
        state: '',
        country: '',
    });

    // Buyer Info
    const handleBuyerInfoOpen = () => {
        setBuyerInfoDialog({
            open: true,
            name: buyerInfo.name,
            email: buyerInfo.email,
            mobile: buyerInfo.mobile,
        });
    }
    const handleBuyerInfoClose = () => {
        setBuyerInfoDialog({
            open: false,
            name: '',
            email: '',
            mobile: '',
        });
    }
    const handleBuyerInfoSave = () => {
        setBuyerInfo({
            ...buyerInfo,
            name: buyerInfoDialog.name,
            email: buyerInfoDialog.email,
            mobile: buyerInfoDialog.mobile,
        });
        handleBuyerInfoClose();
    }
    const buyerInfoBox = () => {
        return (
            <Box className={classes.outlineBox}>
                <Typography variant="h6" style={{ marginBottom: 10 }}>{t('checkout.buyerInfo')}</Typography>
                {
                    buyerInfoError.info
                    ?
                    <Typography variant="caption" color="error">{buyerInfoError.info}</Typography>
                    :
                    null
                }
                <Card className={classes.buyerInfoBox}>
                    <CardHeader
                        action={
                            <IconButton style={{ zIndex: 1 }} onClick={() => handleBuyerInfoOpen()} disabled={loading}>
                                <FiEdit size={15} />
                            </IconButton>
                        }
                        style={{ paddingBottom: 0 }}
                    />
                    <CardContent style={{ marginTop: -30 }}>
                        <Typography>{t('user.name') + ": " + (buyerInfo.name ? buyerInfo.name : '-')}</Typography>
                        <Typography>{t('user.email') + ": " + (buyerInfo.email ? buyerInfo.email : '-')}</Typography>
                        <Typography>{t('user.mobile') + ": " + (buyerInfo.mobile ? buyerInfo.mobile : '-')}</Typography>
                    </CardContent>
                </Card>
            </Box>
        )
    }

    // Address
    const handleAddressOpen = (addressId) => {
        if (addressId) {
            let selectedAddress = _.find(addresses, function (addr) { return addr.id === addressId; });
            setAddressDialog({
                open: true,
                id: addressId,
                name: selectedAddress.name ? selectedAddress.name : '',
                address1: selectedAddress.address1 ? selectedAddress.address1 : '',
                address2: selectedAddress.address2 ? selectedAddress.address2 : '',
                zip: selectedAddress.zip ? selectedAddress.zip : '',
                city: selectedAddress.city ? selectedAddress.city : '',
                state: selectedAddress.state ? selectedAddress.state : '',
                country: selectedAddress.country ? selectedAddress.country : '',
            });
        } else {
            setAddressDialog({
                open: true,
                id: '',
                name: '',
                address1: '',
                address2: '',
                zip: '',
                city: '',
                state: '',
                country: '',
            });
        }
    }
    const handleAddressClose = () => {
        setInputErrors({});
        setAddressDialog({
            open: false,
            id: '',
            name: '',
            address1: '',
            address2: '',
            zip: '',
            city: '',
            state: '',
            country: '',
        });
    }
    const handleAddressSave = () => {
        setLoading(true);
        let postData = {
            name: addressDialog.name,
            address1: addressDialog.address1,
            address2: addressDialog.address2,
            zip: addressDialog.zip,
            city: addressDialog.city,
            state: addressDialog.state,
            country: addressDialog.country,
        }
        if (addressDialog.id) {
            // update
            putUrl(`/address/${addressDialog.id}`, postData).then(response => {
                if (response.status) {
                    handleAddressClose();
                    dispatch(updateAddresses(response.data));
                } else {
                    setInputErrors(response.errors);
                }
                if (response.message) {
                    addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        } else {
            // add
            postUrl(`/address`, postData).then(response => {
                if (response.status) {
                    handleAddressClose();
                    dispatch(updateAddresses(response.data));
                    if (!buyerInfo.address_id) {
                        setBuyerInfo({ ...buyerInfo, address_id: _.size(response.data) > 0 ? response.data[0].id : '' });
                    }
                } else {
                    setInputErrors(response.errors);
                }
                if (response.message) {
                    addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
    }
    const buyerAddressBox = () => {
        return (
            <Box className={classes.outlineBox}>
                <Box className={classes.flexM} style={{ marginBottom: 10 }}>
                    <Typography variant="h6">{t('checkout.bilingAddress')}</Typography>
                    {/* <Box className={classes.spaceBetween}>
                        <IconButton className={classes.addNewAddress} onClick={() => handleAddressOpen(0)} disabled={loading}>
                            <FiPlusSquare size={20} />
                        </IconButton>
                        <Typography style={{ paddingTop: 5 }}>{t('checkout.addAddress')}</Typography>
                    </Box> */}
                </Box>
                {
                    buyerInfoError.address
                    ?
                    <Typography variant="caption" color="error">{buyerInfoError.address}</Typography>
                    :
                    null
                }
                <Box>
                    {
                        _.size(addresses) > 0
                            ?
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={1}>
                                    <IconButton className={classes.addNewAddress} onClick={() => handleAddressOpen(0)} disabled={loading}>
                                        <FiPlus size={40} />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={11}>
                                    <Grid container spacing={2}>
                                        {
                                            _.map(addresses, (address, key) => {
                                                return (
                                                    <Grid item xs={12} sm={4} md={3} key={key}>
                                                        <Card className={(address.id === buyerInfo.address_id) ? classes.selectedAddressItemBox : classes.addressItemBox}>
                                                            <CardHeader
                                                                action={
                                                                    <IconButton style={{ zIndex: 1 }} onClick={() => handleAddressOpen(address.id)} disabled={loading}>
                                                                        <FiEdit size={15} />
                                                                    </IconButton>
                                                                }
                                                                style={{ paddingBottom: 0 }}
                                                            />
                                                            <CardActionArea style={{ marginTop: -40 }} onClick={() => setBuyerInfo({ ...buyerInfo, address_id: address.id })} disabled={loading}>
                                                                <CardContent>
                                                                    <Typography>{address.name}</Typography>
                                                                    <Typography>{address.full_address}</Typography>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            <Box style={{ marginTop: 20, textAlign: "center" }}>
                                <Typography variant="body2" style={{ marginBottom: 10 }}>{t('checkout.noAddress')}</Typography>
                                <IconButton className={classes.addNewAddress} onClick={() => handleAddressOpen(0)} disabled={loading} style={{ width: "fit-content" }}>
                                    <FiPlus size={40} />
                                </IconButton>
                            </Box>
                    }
                </Box>
            </Box>
        )
    }

    return (
        <div>
            <div style={{ backgroundColor: '#fff', boxShadow: '0 0 6px 0 #0003', paddingBottom: 15, borderRadius: '0 0 15px 15px', marginTop: 30 }}>
                <div className={classes.infoStyle}></div>
                {buyerInfoBox()}
                {accessToken ? buyerAddressBox() : null}
            </div>
            {/* Buyer Info Dialog */}
            <Dialog open={buyerInfoDialog.open} onClose={handleBuyerInfoClose}>
                <DialogTitle>{t('checkout.editBuyerInfo')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('user.name')}
                                value={buyerInfoDialog.name}
                                name="name"
                                onChange={(event) => setBuyerInfoDialog({ ...buyerInfoDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.name ? true : false}
                                helperText={inputErrors?.name || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('user.email')}
                                value={buyerInfoDialog.email}
                                name="email"
                                onChange={(event) => setBuyerInfoDialog({ ...buyerInfoDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.email ? true : false}
                                helperText={inputErrors?.email || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('user.mobile')}
                                value={buyerInfoDialog.mobile}
                                name="mobile"
                                onChange={(event) => setBuyerInfoDialog({ ...buyerInfoDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.mobile ? true : false}
                                helperText={inputErrors?.mobile || ''}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleBuyerInfoClose}>{t('general.cancel')}</Button>
                    <Button variant="contained" color="primary" onClick={handleBuyerInfoSave}>{t('general.update')}</Button>
                </DialogActions>
            </Dialog>
            {/* Address Dialog */}
            <Dialog open={addressDialog.open} onClose={handleAddressClose}>
                <DialogTitle>{addressDialog.id ? t('checkout.editAddress') : t('checkout.addAddress')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('address.name')}
                                value={addressDialog.name}
                                onChange={(event) => setAddressDialog({ ...addressDialog, name: event.target.value })}
                                error={inputErrors?.name ? true : false}
                                helperText={inputErrors?.name || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                rows={2}
                                multiline
                                label={t('address.address1')}
                                value={addressDialog.address1}
                                autoComplete="address-line1"
                                name="address1"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.address1 ? true : false}
                                helperText={inputErrors?.address1 || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                rows={2}
                                multiline
                                label={t('address.address2')}
                                value={addressDialog.address2}
                                autoComplete="address-line2"
                                name="address2"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.address2 ? true : false}
                                helperText={inputErrors?.address2 || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('address.zip')}
                                value={addressDialog.zip}
                                autoComplete="postal-code"
                                name="zip"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.zip ? true : false}
                                helperText={inputErrors?.zip || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('address.city')}
                                value={addressDialog.city}
                                autoComplete="address-level2"
                                name="city"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.city ? true : false}
                                helperText={inputErrors?.city || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('address.state')}
                                value={addressDialog.state}
                                autoComplete="address-level1"
                                name="state"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.state ? true : false}
                                helperText={inputErrors?.state || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('address.country')}
                                value={addressDialog.country}
                                autoComplete="country-name"
                                name="country"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.country ? true : false}
                                helperText={inputErrors?.country || ''}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleAddressClose}>{t('general.cancel')}</Button>
                    <Button variant="contained" color="primary" onClick={handleAddressSave}>{addressDialog.id ? t('general.update') : t('general.create')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CustomerInfo;

const useStyles = makeStyles((theme) => ({
    infoStyle: {
        height: 5,
        width: '100%',
        backgroundPositionX: -30,
        backgroundSize: '116px 3px',
        backgroundImage: 'repeating-linear-gradient(45deg,#f7ca4d,#f7ca4d 33px,transparent 0,transparent 41px,#222 0,#222 74px,transparent 0,transparent 82px)',
    },
    buyerInfoBox: {
        backgroundColor: theme.palette.primary.main + ' !important',
        color: theme.palette.secondary.main + ' !important',
        width: "fit-content",
    },
    outlineBox: {
        margin: "20px !important"
    },
    addressBox: {
        padding: 16
    },
    addressItemBox: {
        backgroundColor: 'transparent !important',
        color: theme.palette.primary.main + ' !important',
    },
    selectedAddressItemBox: {
        backgroundColor: theme.palette.primary.main + ' !important',
    },
    spaceBetween: {
        justifyContent: "space-between",
        display: "flex",
        alignItems: 'center',
    },
    flexM: {
        display: 'flex',
        alignItems: 'center'
    },
    addNewAddress: {
        color: theme.palette.primary.main + '! important',
        backgroundColor: 'transparent !important',
        height: '100% !important', border: '2px solid '+theme.palette.primary.main+' !important', borderRadius: '5px !important', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'
    }
}))