// AUTH
import SignIn from '@authpages/SignIn';
import SignUp from '@authpages/SignUp';
import ForgotPassword from '@authpages/ForgotPassword';
import ResetPassword from '@authpages/ResetPassword';

// GUEST
import Home from '@guestpages/Home';
import Cart from '@guestpages/Cart';
// import Package from '@guestpages/Package';
import PackageDetail from '@guestpages/Package/Detail';
import PackageSummary from '@guestpages/Package/Summary';
import Review from '@guestpages/Review';
import Resort from '@guestpages/Resort';
import ResortDetail from '@guestpages/Resort/Detail';
import RoomDetail from '@guestpages/Room/Detail';
import News from '@guestpages/News'
import NewsDetail from '@guestpages/News/Detail'
import About from '@guestpages/About';
import Activities from '@guestpages/Activities';
import OurResorts from '@guestpages/OurResorts';
import Unsubscribe from '@guestpages/Subscription';
import Policy from '@guestpages/Policy';
import Checkout from '@userpages/Checkout';

// USER
// import Account from '@userpages/Profile/Account';
// import Security from '@userpages/Profile/Security';
// import Address from '@userpages/Profile/Address';
import Payment from '@userpages/Payment';
import PaymentCallback from '@userpages/Payment/PaymentCallback';
import Order from '@userpages/Order';
import OrderDetail from '@userpages/Order/Detail';
import Voucher from '@userpages/Voucher';
import MobileAccountSidebar from '@userpages/Profile/MobileAccountSidebar';
import Dashboard from '@userpages/Dashboard';
import Profile from '@userpages/Dashboard/Profile';
import Subscription from '@userpages/Subscription';
import Wishlist from '@userpages/Wishlist';

// ONLY CAN ACCESS AFTER LOGIN
export const private_routes = [
    {
        path: "/account",
        component: <MobileAccountSidebar />
    },
    // {
    //     path: "/profile",
    //     component: <Account />
    // },
    // {
    //     path: "/security",
    //     component: <Security />
    // },
    // {
    //     path: "/address",
    //     component: <Address />
    // },
    {
        path: "/payment/:purchase_no",
        component: <Payment />
    },
    {
        path: "/orders",
        component: <Order />
    },
    {
        path: "/order/:id",
        component: <OrderDetail />
    },
    {
        path: "/vouchers",
        component: <Voucher />
    },
    {
        path: "/dashboard",
        component: <Dashboard />
    },
    {
        path: "/dashboard/profile",
        component: <Profile />
    },
    // {
    //     path: "/subscription",
    //     component: <Subscription />
    // },
    {
        path: "/wishlist",
        component: <Wishlist />
    },
];

// CAN ACCESS BEFORE OR AFTER LOGIN
export const public_routes = [
    {
        path: "/",
        exact: true,
        component: <Home />
    },
    // {
    //     path: "/packages",
    //     exact: true,
    //     component: <Package />
    // },
    {
        path: "/package/:id",
        exact: true,
        component: <PackageDetail />
    },
    {
        path: "/package/summary",
        exact: true,
        component: <PackageSummary />
    },
    {
        path: "/reviews/:id",
        exact: true,
        component: <Review />
    },
    // {
    //     path: "/resort",
    //     exact: true,
    //     component: <ResortDetail />
    // },
    // {
    //     path: "/room",
    //     exact: true,
    //     component: <RoomDetail />
    // },
    {
        path: "/cart",
        exact: true,
        component: <Cart />
    },
    {
        path: "/checkout",
        component: <Checkout />
    },
    {
        path: "/revenuemonster/:payment_id/callback",
        exact: true,
        component: <PaymentCallback />
    },
    {
        path: "/news",
        exact: true,
        component: <News />
    },
    {
        path: "/news/:id",
        exact: true,
        component: <NewsDetail />
    },
    {
        path: "/about",
        exact: true,
        component: <About />
    },
    {
        path: "/activities",
        exact: true,
        component: <Activities />
    },
    {
        path: "/our-resorts",
        exact: true,
        component: <OurResorts />
    },
    {
        path: "/unsubscribe",
        exact: true,
        component: <Unsubscribe />
    },
    {
        path: "/policy",
        exact: true,
        component: <Policy />
    }
];

// ONLY CAN ACCESS BEFORE LOGIN
export const public_restricted_routes = [
    {
        path: "/sign-in",
        exact: true,
        component: <SignIn />
    },
    {
        path: "/sign-up",
        component: <SignUp />
    },
    {
        path: "/forgot-password",
        component: <ForgotPassword />
    },
    {
        path: "/reset-password",
        component: <ResetPassword />
    }
];