import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import _ from 'lodash';
import theme from "../../../theme";

import { Box, Container, Typography, Grid, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import Mantanani from '@components/Mantanani';

const About = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            {/* <img alt='abouts banner' src={"/images/abouts/banner.jpg"} style={{ width: "100%" }} /> */}
            <Box className={classes.bannerBackground} />
            <Box style={{ padding: '20px 0 40px' }} className={classes.bgImageWhiteCenter}>
                <Container maxWidth="xl">
                    <Grid item xs={12} className={classes.title}>
                        <Typography component="h1" variant="h3" >{t('abouts.title')}</Typography>&nbsp;&nbsp;&nbsp;
                        <Typography component="h1" variant="h3" sx={{ fontFamily: 'PlayfairDisplayItalic', display: 'inline-block' }}>{t('abouts.reluxe')}</Typography>
                    </Grid>
                    <Grid sx={{ paddingTop: '50px', paddingLeft: '50px', paddingRight: '50px' }}>
                        <Typography align="center" component="h1" variant="h6" sx={{ lineHeight: '1.1' }} ><Trans>{t('abouts.content1')}</Trans></Typography>
                    </Grid>
                    <Grid container spacing={0} sx={{ paddingTop: '70px' }}>
                        <Grid item xs={12} sm={6}>
                            <img alt='abouts banner' align="right" src={"/images/abouts/image1.jpg"} className={classes.image} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={`${classes.content1} ${classes.bgImageWhiteRight}`}>
                            <Typography component="h1" variant="h2" sx={{ fontFamily: 'PlayfairDisplayMediumItalic', display: 'inline-block', paddingLeft: '30px' }}>{t('abouts.gone')}</Typography>
                            <Typography component="h1" variant="h2" sx={{ fontFamily: 'PlayfairDisplayMedium', paddingLeft: '30px' }}>{t('abouts.withReluxe')}</Typography>
                            <Grid display="flex" justifyContent="center" style={{ paddingTop: '30px', alignItems: 'center' }}>
                                <Grid item xs={2} sm={3.5}>
                                    <div style={{ flex: 1, height: '1.8px', backgroundColor: '#DCD8D3' }} />
                                </Grid>
                                <Grid item xs={10} sm={8.5}>
                                    <Typography component="h1" variant="h5" sx={{ fontFamily: 'PlayfairDisplayMediumItalic', paddingLeft: '10px' }}>{t('abouts.hastTag')}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box style={{ padding: '20px 0 40px' }} className={classes.bgImageWhiteLeft}>
                <Container maxWidth="xl">
                    <Grid container spacing={0} paddingY={6}>
                        <Grid item xs={0} sm={1} md={2} className={classes.line} >
                            <Divider style={{ borderBottom: "2px solid #DCD8D3", margin: "1% -100%", width: '200%' }} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <img alt='abouts banner' align="left" src={"/images/abouts/image2.jpg"} className={classes.content2} />
                        </Grid>
                        <Grid item xs={12} sm={5} md={4}>
                            <Typography component="h1" align="left" variant="h5" className={classes.content2Text} ><Trans>{t('abouts.content2')}</Trans></Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Mantanani />
        </>
    )
};
export default About;

const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '80%',
        },
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.up('xs')]: {
            paddingTop: '30px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '100px',
        },
    },
    content1: {
        paddingTop: '50px',
        [theme.breakpoints.up('sm')]: {
            paddingTop: '200px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '240px',
        },
    },
    content2: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            // paddingLeft: '100px',
            width: '90%',
        },
    },
    content2Text: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: '50px',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '0px',
            paddingLeft: '30px',
            lineHeight: '1.1 !important',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '50px',
            paddingLeft: '0px',
            lineHeight: '1.1 !important',
        },
    },
    line: {
        [theme.breakpoints.up('sm')]: {
            paddingTop: '270px',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '380px',
        },
    },
    bgImageWhiteLeft: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'left bottom -20%', 
            backgroundSize: "65%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'left bottom -60%', 
            backgroundSize: "30%",
        },
    },
    bgImageWhiteRight: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -20% top 100%', 
            backgroundSize: "50%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right top', 
            backgroundSize: "50%",
        },
    },
    bgImageWhiteCenter: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'center top -10%', 
            backgroundSize: "40%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'center top -30%', 
            backgroundSize: "20%",
        },
    },
    bannerBackground: {
        backgroundImage: `url("/images/abouts/banner.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: '0% 27%',
        [theme.breakpoints.up('xs')]: {
            minHeight: "200px",
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: "400px",
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: "600px",
        },
    },
}));