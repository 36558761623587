import { combineReducers } from 'redux';

import generalReducer from './generalReducer';
import userReducer from './userReducer';

const combinedReducers = combineReducers({
    general: generalReducer,
    user: userReducer,
});

export default combinedReducers;