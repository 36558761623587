import { postUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import theme from "../../theme";

import { Box, Button, Container, CircularProgress, Paper, Link, TextField, Typography } from '@mui/material';

import { changeLoginState } from '@actions/generalActions';
import { FaFacebook } from "react-icons/fa";
import { FaGooglePlus } from "react-icons/fa";

import { GOOGLE_CLIENT_ID } from "../../configs/config";

const INITIAL_STATE = {
    email: '',
    password: '',
    type: 'web'
}

const SignIn = () => {
    const [loginField, setLoginField] = useState(INITIAL_STATE);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const loginBtnClicked = () => {
        if (_.size(loginField.email) <= 0) {
            emailRef.current.focus();
            addToast(t('auth.required.email'), { appearance: 'error' });
        } else if (_.size(loginField.password) <= 0) {
            passwordRef.current.focus();
            addToast(t('auth.required.password'), { appearance: 'error' });
        } else {
            setLoading(true);
            postUrl('login', loginField).then(response => {
                setLoading(false);
                let message = response.message;
                if (!response.status) {
                    if (_.hasIn(response, 'errors')) {
                        _.map(response.errors, item => {
                            addToast(item, { appearance: 'error' });
                        })
                    }
                } else {
                    dispatch(changeLoginState(response.data));
                    setLoginField(INITIAL_STATE);
                }

                if (message) {
                    addToast(message, { appearance: response.status ? 'success' : 'error' });
                }
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
    };

    const handleChange = (event) => {
        setLoginField({ ...loginField, [event.target.name]: event.target.value });
    };

    const passwordKeyPress = e => {
        if (e.keyCode === 13) {
            loginBtnClicked();
        }
    }

    const emailRef = useRef();
    const passwordRef = useRef();

    // https://www.youtube.com/watch?v=roxC8SMs7HU
    useEffect(() => {
        /* global google */
        google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: googleLoginCallbackFunction
        });

        google.accounts.id.renderButton(
            document.getElementById("googleSignIn"),
            { type: "icon", theme: "outline", size: "large", shape: "circle", }
        );
    }, []);

    function googleLoginCallbackFunction(response) {
        let postData = {
            credential: response.credential,
            type: 'member'
        }
        postUrl('/third-party-login/google', postData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(changeLoginState(response.data));
                setLoginField(INITIAL_STATE);
            } else {
                let msg = response.message;
                if (response.errors) {
                    _.map(response.errors, error => {
                        msg = msg + ' ' + error;
                    })
                }
                addToast(msg, { appearance: 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        document.body.appendChild(script);
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '890594962267297',
                cookie: true,
                xfbml: true,
                version: 'v2.5'
            });
        };
    }, []);
    const handleFBLoginClick = () => {
        window.FB.login(function (response) {
            console.log('login response', response);
            if (response.authResponse) {
                window.FB.api('/me', { fields: 'id,name,email,first_name,last_name' }, function (response2) {
                    console.log('me response', response2);
                    if (response2.email) {
                        let postData = {
                            ...response2,
                            access_token: response.authResponse.accessToken,
                            type: 'member'
                        };
                        postUrl('/third-party-login/facebook', postData).then(response => {
                            setLoading(false);
                            if (response.status) {
                                dispatch(changeLoginState(response.data));
                                setLoginField(INITIAL_STATE);
                            } else {
                                let msg = response.message;
                                if (response.errors) {
                                    _.map(response.errors, error => {
                                        msg = msg + ' ' + error;
                                    })
                                }
                                addToast(msg, { appearance: 'error' });
                            }
                        }).catch(err => {
                            setLoading(false);
                            addToast(JSON.stringify(err), { appearance: 'error' });
                        })
                    } else {
                        addToast(t('auth.noEmailAccess'), { appearance: 'error' });
                    }
                });
            }
        }, { scope: 'email', auth_type: 'reauthorize' });
    }

    return (
        <Paper className={classes.paper} square>
            <Paper className={classes.rootSignIn} elevation={0} square>
                <Container>
                    <Box style={{ paddingTop: '80px' }} >
                        <Typography align='center' style={{ fontFamily: "PlayfairDisplayBoldItalic", textTransform: 'uppercase' }} component="h1" variant="h2">
                            {t('auth.signIn')}
                        </Typography>
                        <Typography align='center' style={{ fontFamily: "FuturaPTBook" }} component="h1" variant="h5">
                            {t('auth.signInDesc')}
                        </Typography>
                        <div className={classes.form} noValidate>
                            <TextField
                                ref={emailRef}
                                variant="outlined"
                                margin="normal"
                                label={t('auth.email')}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={loginField.email}
                                onChange={handleChange}
                                style={{ width: '75%', paddingLeft: '1.5%' }}
                                InputLabelProps={{
                                    style: { paddingLeft: '2%' }
                                }}
                                sx={{
                                    fieldset: {
                                        borderRadius: '0px',
                                        border: `2px solid ${theme.palette.primary.main}`,
                                    },
                                    input: { color: theme.palette.primary.main }
                                }}
                            />
                            <TextField
                                ref={passwordRef}
                                variant="outlined"
                                margin="normal"
                                name="password"
                                label={t('auth.password')}
                                type="password"
                                autoComplete="current-password"
                                value={loginField.password}
                                onChange={handleChange}
                                onKeyDown={passwordKeyPress}
                                style={{ width: '75%', paddingLeft: '1.5%' }}
                                InputLabelProps={{
                                    style: { paddingLeft: '2%' }
                                }}
                                sx={{
                                    fieldset: {
                                        borderRadius: '0px',
                                        border: `2px solid ${theme.palette.primary.main}`,
                                    },
                                    input: { color: theme.palette.primary.main }
                                }}
                            />
                            {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label={ t('auth.rememberMe') }
                            /> */}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-around', paddingTop: '10px', paddingLeft: '10px' }}>
                            <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={loginBtnClicked}
                                disabled={loading ? true : false}
                                style={{ textTransform: 'uppercase', paddingLeft: '20px', paddingRight: '20px' }}
                            >
                                {
                                    loading ?
                                        <CircularProgress size={20} />
                                        : t('auth.signInMember')
                                }
                            </Button>
                            {/* <Button
                                type="submit"
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={loginBtnClicked}
                                disabled={loading ? true : false}
                                style={{ textTransform: 'uppercase', paddingLeft: '20px', paddingRight: '18px' }}
                            >
                                {
                                    loading ?
                                        <CircularProgress size={20} />
                                        : t('auth.signInAgent')
                                }
                            </Button> */}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                            <Link component={RouterLink} to="/forgot-password" underline="none" style={{ fontFamily: "FuturaPTBook", color: theme.palette.secondary.secondary }}>
                                {t('auth.forgotPassword')}
                            </Link>
                        </div>
                        <Box style={{ paddingTop: '10px', paddingBottom: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center', color: theme.palette.secondary.secondary }} >
                            <FaFacebook size="40" className={classes.icon} />
                            <FaGooglePlus size="40" className={classes.icon} />
                            {/* <FaFacebook size="40" className={classes.icon} onClick={()=>handleFBLoginClick()} /> */}
                            {/* <div id="googleSignIn" style={{ marginLeft: '5px' }}></div> */}
                        </Box>
                    </Box>
                </Container>
            </Paper>
            <Paper className={classes.rootSignUp} square>
                <Container className={classes.signUp} spacing={10}>
                    <Box style={{ paddingTop: '80px', paddingBottom: '120px' }} >
                        <Typography align='center' style={{ fontFamily: "PlayfairDisplayBoldItalic", textTransform: 'uppercase', color: `${theme.palette.white.main}` }} component="h1" variant="h2">
                            {t('auth.signUp')}
                        </Typography>
                        <Trans>
                            <Typography align='center' style={{ fontFamily: "FuturaPTBook", paddingTop: '10px', color: `${theme.palette.white.main}` }} component="h1" variant="h5">
                                {t('auth.signUpDesc')}
                            </Typography>
                        </Trans>
                        <Box className={classes.box} >
                            <Button
                                component={RouterLink}
                                to="/sign-up?type=member"
                                variant="outlined"
                                color="white"
                                size="small"
                                className={classes.submit}
                            >
                                {t('auth.member')}
                            </Button>
                            <Button
                                component={RouterLink}
                                to="/sign-up?type=agent"
                                variant="outlined"
                                color="white"
                                size="small"
                                className={classes.submit}
                            >
                                {t('auth.agent')}
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </Paper>
        </Paper >
    )
}

export default SignIn;

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        boxShadow: "none !important",
        paddingTop: '25px',
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    form: {
        // width: '75%', // Fix IE 11 issue.
        Color: theme.palette.primary.main,
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    submit: {
        // margin: theme.spacing(4, 0, 4),
        width: '25%',
        borderRadius: '0px !important',
        borderWidth: '2px !important',
        textTransform: 'uppercase !important',
    },
    rootSignIn: {
        background: `linear-gradient(180deg, #ebeaea 0%, #fff 5%)`,
        boxShadow: "none",
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
    },
    rootSignUp: {
        boxShadow: "none",
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
    },
    signUp: {
        background: `linear-gradient(180deg, #ababab 0%, ${theme.palette.primary.main} 5%)`,
        color: 'white',
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        [theme.breakpoints.up('xs')]: {
            paddingTop: '30px',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '135px',
        },
    },
    icon: {
        cursor: 'pointer',
        paddingLeft: '5px',
    }
}));