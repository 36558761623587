import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Link, Typography } from '@mui/material/';
import { makeStyles, useTheme } from '@mui/styles';
import { FiArrowLeft } from "react-icons/fi";

const BackToPrevious = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();

    const handleOnClick = () => {
        window.history.back();
    }

    return (
        <div className={classes.flexMiddle} style={{ paddingBottom: 30 }}>
            <Link underline="none" onClick={handleOnClick} style={{ cursor: 'pointer' }}>
                <div className={classes.flexMiddle}>
                    <FiArrowLeft className={classes.backToArrowStyle} />
                    <Typography className={classes.backToStyle}>{t('general.backToPrevious')}</Typography>
                </div>
            </Link>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
        fontSize: "1.2rem !important",
    },
    backToArrowStyle: {
        color: theme.palette.gray.main,
        // fontSize: "16px !important",
        marginRight: 10
    },
    backToStyle: {
        color: theme.palette.gray.main,
        // fontSize: "16px !important",
        textTransform: 'uppercase'
    },
}))

export default BackToPrevious;