import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _, { isEmpty, isNull } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { getUrl } from '@helpers/api';
import { useToasts } from 'react-toast-notifications';
import { LOCAL_URL, ADMIN_URL } from '@configs/config';

import { Box, Button, Grid, Typography, Container, Avatar, Hidden, LinearProgress, CardMedia, Link } from '@mui/material';

import AccountSidebar from '@components/AccountSidebar';
import PackageList from '@components/PackageList';
import { MdLocationOn,MdAutoGraph } from 'react-icons/md';
import { RiPhoneFill } from 'react-icons/ri';
import { IoIosMail } from 'react-icons/io';

const Dashboard = () => {
    const classes = useStyles();
    const { addToast } = useToasts();
    const { t, i18n } = useTranslation();
    const userState = useSelector(state => state.user);
    const theme = useTheme();
    const { addresses } = useSelector(state => state.user);
    const userRole  =useSelector(state => state.general.role);

    // const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [bookingloading, setBookingLoading] = useState(false);

    const [searchField, setSearchField] = useState({
        status: 50,
        limit: 3,
    });
    const [orders, setOrders] = useState({});
    const [wishlists, setWishlists] = useState([]);
    const [advertisement, setAdvertisement]=useState({});

    useEffect(() => {
        setBookingLoading(true);
        getUrl("/order", searchField).then(response => {
            setBookingLoading(false);
            if (response.status) {
                setOrders(response.data.listing);
            }
        }).catch(err => {
            setBookingLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(true);
        getUrl("/wishlist-list", { pagination: 0 }).then(response => {
            setLoading(false);
            if (response.status) {
                setWishlists(response.data.listing);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })

        getUrl("/profile-advertisement/profile_adv", searchField).then(response => {
            setBookingLoading(false);
            if (response.status) {
                setAdvertisement(response.data);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, []);

    return (
        <Box style={{ padding: 20 }}>
            <Container maxWidth="xl">
                <AccountSidebar>
                    {/* profile */}
                    <Grid container className={classes.cardContainerStyle}>
                        {/* image */}
                        <Grid item xs={12} sm={3} className={classes.cardContentRoot} display="flex" justifyContent="center" alignItems="center">
                            <div style={{ borderRadius: 48 }} align="center">
                                <Avatar
                                    alt="profile_picture"
                                    src={userState.profile_picture ? userState.profile_picture.file_name : null}
                                    className={classes.avatarStyle}
                                >
                                    {userState.name.substring(0, 1)}
                                </Avatar>
                            </div>
                        </Grid>
                        {/* content */}
                        <Grid item xs={12} sm={9} className={classes.cardContentRoot} display="flex" flexDirection="row" justifyContent="space-around">
                            <Grid container>
                                <Grid item xs={12} sm={9}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            {/* info */}
                                            <Typography variant="caption">Welcome</Typography>
                                            <Typography variant="h6" style={{ lineHeight: "1.0" }}>{userState.name}</Typography>
                                            {
                                                userState.rank && 
                                                <Grid item xs={12}>
                                                    <MdAutoGraph width="1.5%" style={{ color: `${theme.palette.primary.main}`, verticalAlign: '-2px', marginRight: 5 }} /><Typography variant="body1" style={{ display: 'inline-block' }}>{i18n.language ==='cn'? userState.rank['cn']:userState.rank['en']}</Typography>
                                                </Grid>
                                            }
                                            <MdLocationOn width="1.5%" style={{ color: `${theme.palette.primary.main}`, verticalAlign: '-2px', marginRight: 5 }} /><Typography variant="body1" style={{ display: 'inline-block' }}>{_.size(addresses) > 0 ? (addresses[0]['country'] + ', ' + addresses[0]['city']) : '-'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} style={{ marginTop: 20 }}>
                                            <RiPhoneFill width="2%" style={{ color: `${theme.palette.primary.main}`, verticalAlign: '-2px', marginRight: 5 }} /><Typography variant="subtitle1" style={{ display: 'inline-block' }}>{userState.mobile ? userState.mobile : '-'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={8} style={{ marginTop: 20 }}>
                                            <IoIosMail width="2%" style={{ color: `${theme.palette.primary.main}`, verticalAlign: '-2px', marginRight: 5 }} /><Typography variant="subtitle1" style={{ display: 'inline-block' }}>{userState.email ? userState.email : '-'}</Typography>
                                        </Grid>
                                        {
                                            _.includes(userRole, 'member-agent') &&
                                            <Grid item xs={12} style={{ marginTop: 10 }}>
                                                {t('general.backendLink') + ": "}
                                                <a href={ADMIN_URL}>{ADMIN_URL}</a>
                                            </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                {/* edit profile */}
                                <Grid item xs={12} sm={3}>
                                    <Box style={{ display: "flex", justifyContent: "end" }}>
                                        <Button
                                            component={RouterLink}
                                            to={`/dashboard/profile`}
                                            variant="outlined"
                                            color="black"
                                            size="small"
                                            sx={{ boxShadow: 'none', border: '2px solid !important', '& :hover': { color: `${theme.palette.black.main} !important` } }}
                                        >
                                            <Typography variant="body2" align="center" className={classes.button}>{t('dashboard.editProfile')}</Typography>
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* ads */}
                    {
                        _.size(advertisement) > 0 && advertisement.status === "1" && advertisement.file_name &&
                        <Link href={advertisement?.link ? advertisement.link : LOCAL_URL} underline="none" >
                            <div style={{ height: 163, width: 'auto', marginBottom: 25 }}>
                                <img
                                    alt='ads'
                                    src={advertisement?.file_name && isEmpty(advertisement.file_name) ? "" : advertisement.file_name}
                                    className={classes.ads}
                                />
                            </div>
                        </Link>
                    }
                    {/* wishlist */}
                    <Box style={{ display: "flex", justifyContent: "start", alignItems: "flex-end", paddingTop: '3%' }}>
                        <Typography variant="h4" style={{ fontFamily: 'PlayfairDisplayRegular' }}>{t('dashboard.wishlist')}</Typography>
                        <Link component={RouterLink} to={`/wishlist`} underline="hover" style={{ marginLeft: 20, padding: "2px 6px" }}>
                            <Typography variant="body2" style={{ color: theme.palette.primary.main }}>{t('homepage.viewAll')}</Typography>
                        </Link>
                    </Box>
                    {
                        _.size(wishlists) > 0
                            ?
                            <Box>
                                <PackageList packageList={wishlists} pagination={false} swiper={true} showPrice={true} showLink={true} />
                            </Box>
                            :
                            loading
                                ?
                                <Box style={{ marginTop: 20 }}>
                                    <LinearProgress size={20} />
                                </Box>
                                :
                                <Box style={{ marginTop: 20 }}>
                                    <Typography variant="h6">{t('dashboard.noWishlist')}</Typography>
                                </Box>
                    }
                    {/* orders */}
                    {
                        _.size(orders) > 0
                            ?
                            <Grid container paddingTop={3}>
                                <Grid item style={{ border: `1px solid ${theme.palette.primary.lightMain}` }}>
                                    <Typography variant="body1" style={{ padding: '0px 20px' }}>{t('dashboard.booking')}</Typography>
                                </Grid>
                                <Grid container padding={2} className={classes.cardContainerStyle} display="flex">
                                    {
                                        _.map(orders, list => {
                                            return (
                                                <>
                                                    <Grid item xs={4} md={3} paddingY={1}>
                                                        <CardMedia
                                                            component="img"
                                                            image={_.size(list.order[0].order_details[0].package.images) > 0 ? list.order[0].order_details[0].package.images[0].file_name : "../images/emptyStatus/no_ticket.png"}
                                                            alt={'package\'s image'}
                                                            className={classes.classMediaStyle}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={8} md={9} paddingY={1} style={{ paddingLeft: '5%' }}>
                                                        <Link component={RouterLink} to={`/order/${list.order[0].id}`}>
                                                            <Typography variant="h6" style={{ fontFamily: 'FuturaPTBold', textDecoration: 'underline' }}>{list.order[0].order_details[0].package.name}</Typography>
                                                        </Link>
                                                        <Box>
                                                            {
                                                                list.order[0].order_details[0].package.description && _.map(list.order[0].order_details[0].package.description.points, (pointText, key) => {
                                                                    if (pointText) {
                                                                        return (
                                                                            <Box className={classes.tnc} key={key}>
                                                                                <img alt='ReLuxe Logo' src="../images/logo.png" style={{ width: "15px" }} />
                                                                                <Typography variant="subtitle1" className={classes.tncText}>{pointText}</Typography>
                                                                            </Box>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </Box>
                                                        {/* <Typography variant="body1">{list.order[0].order_details[0].package.description&&list.order[0].order_details[0].package.description.body?list.order[0].order_details[0].package.description.body:''}</Typography> */}
                                                    </Grid>
                                                </>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                            :
                            // bookingloading
                            //     ?
                            //     <Box style={{ marginTop: 20 }}>
                            //         <LinearProgress size={20} />
                            //     </Box>
                            //     :
                                null
                    }
                </AccountSidebar>
            </Container>
        </Box >
    )
}

export default Dashboard;

const useStyles = makeStyles((theme) => ({
    cardContainerStyle: {
        marginBottom: 25,
        border: `1px solid ${theme.palette.primary.lightMain}`,
    },
    formContainer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    cardContentRoot: {
        padding: theme.spacing(3),
        border: `1px solid ${theme.palette.primary.lightMain}`,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        borderRadius: 15
    },
    avatarStyle: {
        width: '80px !important',
        height: '80px !important',
        textTransform: 'uppercase',
        margin: 0,
        backgroundColor: theme.palette.primary.main + '!important',
    },
    ads: {
        // [theme.breakpoints.up('xs')]: {
        //     width: '100%'
        // },
        // [theme.breakpoints.up('sm')]: {
        //     width: '120%'
        // },
        // [theme.breakpoints.up('md')]: {
        //     width: '105%'
        // },
        [theme.breakpoints.up('xs')]: {
            objectFit:'fill',
        },
        [theme.breakpoints.up('sm')]: {
            objectFit:'contain',
        },
        [theme.breakpoints.up('md')]: {
            objectFit:'fill',
        },
        width:'100%',
        height:'100%',
        objectFit:'fill',
    },
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important",
    },
    button: {
        [theme.breakpoints.up('md')]: {
            padding: '0px 7px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0px 20px'
        },
    },
    // tnc
    tnc: {
        display: "flex",
        alignItems: "center",
        paddingBottom: "10px !important",
    },
    tncText: {
        paddingLeft: '10px',
        lineHeight: "1.0 !important",
    },
}))