import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Container, Link, Typography } from '@mui/material';

const NotFound = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container maxWidth="md">
            <div className={classes.containerStyle}>
                <Typography variant="h2" className={classes.textStyle}>{t('general.notFound')}</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "5%" }}
                    to="/"
                    component={RouterLink}
                >
                    {t('general.goHome')}
                </Button>
            </div>
        </Container>
    )
};

const useStyles = makeStyles((theme) => ({
    textStyle: {
        fontWeight: 'bold !important'
    },
    containerStyle: {
        paddingTop: theme.spacing(22),
        paddingBottom: theme.spacing(22),
        margin: "0px 50px !important"
    },
}))

export default NotFound;