import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import { putUrl, getUrl, postUrl } from '@helpers/api';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Box, Button, Grid, Typography, Container, Avatar, Hidden, LinearProgress, CardMedia, Link, Divider, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import AccountSidebar from '@components/AccountSidebar';
import PackageList from '@components/PackageList';
import { MdLocationOn, MdSubscriptions } from 'react-icons/md';
import { RiPhoneFill } from 'react-icons/ri';
import { IoIosMail } from 'react-icons/io';

const Subscription = () => {
    const classes = useStyles();
    const { addToast } = useToasts();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userState = useSelector(state => state.user);
    const { info } = useSelector(state => state.user);
    const theme = useTheme();

    const [inputErrors, setInputErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [subscribeList, setSubscribeList] = useState({
        name: '',
        email: '',
        created_at: '',
    });
    const [emailInput, setEmailInput] = useState({
        email: ''
    });
    const [inputList, setInputList] = useState({
        name: '',
        email: '',
    });
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        getUrl(`/subscribe/${userState.name}`).then(response => {
            setLoading(false);
            if (response.status) {
                if (response.data.subscribe.length > 0) {
                    setSubscribeList({
                        name: response.data.subscribe[0].name,
                        email: response.data.subscribe[0].email,
                        created_at: response.data.subscribe[0].created_at,
                    });
                }
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, []);

    const handleChange = ({ target }) => {
        setEmailInput({ ...emailInput, email: target.value });
        setInputList({ name: userState.name, email: target.value })
    }

    const updateSubscription = () => {
        setLoading(true);
        putUrl(`/subscribe/${emailInput.email}`).then(response => {
            if (response.status) {
                setInputErrors({});
                setEmailInput("");
                handleClose();
            } else {
                setInputErrors(response.errors);
            }

            if (response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const addSubscription = () => {
        postUrl('subscribe-create', inputList).then(response => {
            setLoading(false);
            let message = response.message;
            console.log(inputList);
            if (!response.status) {
                setInputErrors(response.errors);
            } else {
                setInputErrors({});
                setEmailInput("");
                handleClose();
            }

            if (message) {
                addToast(message, { appearance: response.status ? 'success' : 'error' });
            }

        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        });
    }

    return (
        <Box padding={5}>
            <Container maxWidth="xl">
                <AccountSidebar>
                    <Grid container xs={12} className={classes.cardContainerStyle}>
                        <Grid item={true} xs={12} sm={3} className={classes.cardContentRoot} display="flex" justifyContent="center" alignItems="center">
                            <div style={{ borderRadius: 48 }} align="center">
                                <Avatar
                                    alt="profile_picture"
                                    src={userState.profile_picture ? userState.profile_picture.file_name : null}
                                    className={classes.avatarStyle}
                                >
                                    {userState.name.substring(0, 1)}
                                </Avatar>
                            </div>
                        </Grid>
                        <Grid container xs={12} sm={9} className={classes.cardContentRoot} display="flex" flexDirection="row" justifyContent="space-between">
                            <Grid item xs={12} sm={6} md={6} >
                                <Typography variant="h6">{userState.name}</Typography>
                                
                                <Typography variant="subtitle1" style={{ display: 'inline-block' }}>
                                    <IoIosMail width="2%" style={{ color: `${theme.palette.primary.main}`, verticalAlign: '-2px', marginRight: 5 }} />
                                    { userState.email ? userState.email : '-' }
                                </Typography>

                                <Typography variant="subtitle1" style={{ display: 'inline-block' }}>
                                    <MdSubscriptions width="2%" style={{ color: `${theme.palette.primary.main}`, verticalAlign: '-2px', marginRight: 5 }} />
                                    { subscribeList.created_at ? ("Subscribe on " + subscribeList.created_at) : 'Subscribe on -' }
                                </Typography>
                            </Grid>

                            <Hidden smDown>
                                <Grid item xs={6} align="right">
                                    <Button
                                        variant="outlined"
                                        color="black"
                                        onClick={handleClickOpen}
                                        sx={{ boxShadow: 'none', border: '2px solid !important', '& :hover': { color: `${theme.palette.black.main} !important` } }}
                                    >
                                        <Typography variant="body2" align="center" className={classes.button}>
                                            { (subscribeList.email !== '' && subscribeList.email === userState.email) ? t('dashboard.unsubscribe') : t('dashboard.subscribe') }
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Hidden>
                            <Hidden smUp>
                                <Grid item xs={12} align="center">
                                    <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                                    <Button
                                        variant="outlined"
                                        color="black"
                                        fullWidth
                                        onClick={handleClickOpen}
                                        sx={{ boxShadow: 'none', border: '2px solid !important', '& :hover': { color: `${theme.palette.black.main} !important` } }}
                                    >
                                        <Typography variant="body2" align="center" className={classes.button}>
                                            { (subscribeList.email !== '' && subscribeList.email === userState.email) ? t('dashboard.unsubscribe') : t('dashboard.subscribe') }
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Hidden>

                            <Dialog open={open} onClose={handleClose}>
                                <DialogTitle>
                                    { (subscribeList.email !== '' && subscribeList.email === userState.email) ? t('dashboard.unsubscribe') : t('dashboard.subscribe') }
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        { (subscribeList.email !== '' && subscribeList.email === userState.email) ? t('dashboard.unsubscribeDesc') : t('dashboard.subscribeDesc') }
                                    </DialogContentText>
                                    {/* Email */}
                                    <TextField
                                        margin="dense"
                                        id="email"
                                        label={t('user.email')}
                                        type="email"
                                        fullWidth
                                        variant="standard"
                                        value={emailInput.email}
                                        onChange={handleChange}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose}>{t('general.cancel')}</Button>
                                    <Button onClick={(subscribeList.email !== '' && subscribeList.email === userState.email) ? updateSubscription : addSubscription}>
                                        { (subscribeList.email !== '' && subscribeList.email === userState.email) ? t('dashboard.unsubscribe') : t('dashboard.subscribe') }
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                    </Grid>
                </AccountSidebar>
            </Container>
        </Box >
    )
}
export default Subscription;

const useStyles = makeStyles((theme) => ({
    cardContainerStyle: {
        marginBottom: 25,
        border: `1px solid ${theme.palette.primary.lightMain}`,
    },
    formContainer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    cardContentRoot: {
        padding: theme.spacing(3),
        border: `1px solid ${theme.palette.primary.lightMain}`,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        borderRadius: 15
    },
    avatarStyle: {
        width: '80px !important',
        height: '80px !important',
        textTransform: 'uppercase',
        margin: 0,
        backgroundColor: theme.palette.primary.main + '!important',
    },
    ads: {
        [theme.breakpoints.up('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '120%'
        },
        [theme.breakpoints.up('md')]: {
            width: '105%'
        },
    },
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important",
    },
    button: {
        [theme.breakpoints.up('md')]: {
            padding: '0px 7px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0px 20px'
        },
    }
}))