import { deleteUrl, putUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { updateCartTotal } from '@actions/generalActions';
import Moment from 'moment';

import { Box, Button, CardMedia, Checkbox, Hidden, FormControlLabel, Grid, Link, TextField, Typography } from '@mui/material';
import { FiCheckCircle, FiCircle, FiMinus, FiPlus, FiTrash2 } from "react-icons/fi";
import { IoStorefrontSharp } from "react-icons/io5";

const CartItemCard = (props) => {
    const { carts, setCarts, systemPublicHoliday } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { uuid, accessToken, defaultCurrency } = useSelector(state => state.general);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [sumTotal, setSumTotal] = useState(0);
    const [selectedTotal, setSelectedTotal] = useState(0);
    const [checkAll, setCheckAll] = useState(true);

    useEffect(() => {
        calculateTotal(carts);
        // eslint-disable-next-line
    }, []);

    const calculateTotal = (carts) => {
        let _sumTotal = 0;
        let _selectedTotal = 0;
        let _checkAll = true;
        _.map(carts, (agencyCartList, agencyId) => {
            _.map(agencyCartList.data, (data, package_id) => {
                _.map(data, (groupItem, booking_date) => {
                    _.map(groupItem.items, mainOption => {
                        // main option
                        if (mainOption.status) {
                            if (groupItem.selected) {
                                _sumTotal += parseFloat(mainOption.quantity * getSellPrice(mainOption.booking_date, mainOption.package_option.price, agencyCartList.public_holidays));
                                _selectedTotal += parseInt(mainOption.quantity);
                            } else {
                                _checkAll = false;
                            }
                        }
                    })
                    _.map(groupItem.add_on, add_on => {
                        _.map(add_on.items, addOnOption => {
                            let priceArray = _.size(addOnOption.add_on_price) > 0 ? addOnOption.add_on_price : addOnOption.package_option.price;
                            // add on option
                            if (addOnOption.status) {
                                if (groupItem.selected) {
                                    _sumTotal += parseFloat(addOnOption.quantity * getSellPrice(addOnOption.booking_date, priceArray, agencyCartList.public_holidays));
                                    _selectedTotal += parseInt(addOnOption.quantity);
                                } else {
                                    _checkAll = false;
                                }
                            }
                        })
                    })
                })
            })
        })
        setSumTotal(_sumTotal);
        setSelectedTotal(_selectedTotal);
        setCheckAll(_checkAll);
    }

    const updateCartItem = (cartId, quantity) => {
        let newCart = carts;
        let postData = {
            quantity: parseInt(quantity),
        };
        putUrl(`/carts/${cartId}`, postData).then(response => {
            if (response.status) {
                // addToast(response.message, { appearance: 'success' });
                _.map(newCart, (agencyCartList, agencyId) => {
                    _.map(agencyCartList.data, (data, package_id) => {
                        _.map(data, (groupItem, booking_date) => {
                            _.map(groupItem.items, (mainOption, key) => {
                                if (mainOption.id === cartId) {
                                    newCart[agencyId]['data'][package_id][booking_date]['items'][key].quantity = postData.quantity;
                                }
                            })
                            _.map(groupItem.add_on, (add_on, key) => {
                                _.map(add_on.items, (addOnOption, key2) => {
                                    if (addOnOption.id === cartId) {
                                        newCart[agencyId]['data'][package_id][booking_date]['add_on'][key]['items'][key2].quantity = postData.quantity;
                                    }
                                })
                            })
                        })
                    })
                })
                setCarts({ ...newCart });
                calculateTotal(newCart);
            } else {
                let msg = response.message;
                if (response.errors) {
                    _.map(response.errors, error => {
                        msg = msg + ' ' + error;
                    })
                }
                addToast(msg, { appearance: 'error' });
            }
        }).catch(err => {
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const deleteCartItem = (cartId) => {
        let newCart = carts;
        deleteUrl(`/carts/${cartId}`, {guest_token: uuid ? uuid : null}).then(response => {
            if (response.status) {
                // addToast(response.message, { appearance: 'success' });
                _.map(carts, (agencyCartList, agencyId) => {
                    let agencyList = false;
                    _.map(agencyCartList.data, (data, package_id) => {
                        _.map(data, (groupItem, booking_date) => {
                            _.remove(groupItem.items, function (n) { return n.id === cartId });
                            newCart[agencyId]['data'][package_id][booking_date]['items'] = groupItem.items;
                            if (_.size(groupItem.items) !== 0) {
                                agencyList = true;
                            } else {
                                newCart[agencyId]['data'][package_id][booking_date]['add_on'] = [];
                            }
                            _.map(groupItem.add_on, (add_on, key) => {
                                _.remove(add_on.items, function (n) { return n.id === cartId });
                                newCart[agencyId]['data'][package_id][booking_date]['add_on'][key]['items'] = add_on.items;
                            })
                        })
                    })
                    if (!agencyList) {
                        delete newCart[agencyId];
                    }
                })
                setCarts({ ...newCart });
                calculateTotal(newCart);
                dispatch(updateCartTotal(response.cart_count));
            }
        }).catch(err => {
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const toogleCheckAll = () => {
        let _checkAll = !checkAll;
        let newCart = carts;
        _.map(newCart, (agencyCartList, agencyId) => {
            _.map(agencyCartList.data, (data, package_id) => {
                _.map(data, (groupItem, booking_date) => {
                    // update selected
                    if (groupItem.selected !== _checkAll) {
                        putUrl(`/carts/selected`, {
                            selected: _checkAll ? 1 : 0,
                            package_id: package_id,
                            booking_date: booking_date,
                            guest_token: uuid ? uuid : null,
                        }).catch(err => {
                            addToast(JSON.stringify(err), { appearance: 'error' });
                        })
                        newCart[agencyId]['data'][package_id][booking_date]['selected'] = _checkAll;
                    }
                })
            })
        })
        setCarts(newCart);
        setCheckAll(_checkAll);
        calculateTotal(newCart);
    }

    const updateGroupSelected = (agencyId, package_id, booking_date, selected) => {
        let newCart = carts;
        putUrl(`/carts/selected`, {
            selected: selected ? 1 : 0,
            package_id: package_id,
            booking_date: booking_date,
            guest_token: uuid ? uuid : null,
        }).catch(err => {
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        newCart[agencyId]['data'][package_id][booking_date]['selected'] = selected;
        setCarts(newCart);
        calculateTotal(newCart);
    }

    const checkoutCart = () => {
        let afterTime = false;
        let currTime = new Date();
        let nextTime = new Date();
        nextTime.setDate(currTime.getDate() + 1);
        let displayDate = Moment(nextTime).format('YYYY-MM-DD');
        let adultChild = {};
        let error = false;

        _.map(carts, (agencyCartList, agencyId) => {
            _.map(agencyCartList.data, (data, package_id) => {
                _.map(data, (groupItem, booking_date) => {
                    if (groupItem.selected) {
                        if (displayDate === booking_date) {
                            afterTime = true;
                        }
                        _.map(groupItem.items, mainOption => {
                            // main option
                            if (!adultChild[package_id]) {
                                adultChild[package_id] = {};
                            }
                            if (!adultChild[package_id][booking_date]) {
                                adultChild[package_id][booking_date] = { adult: false, child: false };
                            }
                            if (mainOption.package_option.name.includes("adult") || mainOption.package_option.name.includes("Adult")) {
                                adultChild[package_id][booking_date].adult = true;
                            }
                            if (mainOption.package_option.name.includes("child") || mainOption.package_option.name.includes("Child")) {
                                adultChild[package_id][booking_date].child = true;
                            }
                        })
                    }
                })
            })
        })
        _.map(adultChild, acData => {
            _.map(acData, _package => {
                if (_package.child && !_package.adult) {
                    addToast(t('package.mustHaveAdult'), { appearance: 'error' });
                    error = true;
                }
            })
        })
        if ((currTime.getHours() >= 23) && afterTime) {
            addToast(t('voucher.timeout'), { appearance: 'error' });
            error = true;
        }
        if (!error && (selectedTotal > 0)) { navigate(`/checkout`); }
    }

    const displayBookingPeriod = (booking_date, duration_period) => {
        let displayText = Moment(booking_date).format('ddd DD MMM YYYY');
        if (duration_period > 1) {
            let endDate = new Date(booking_date);
            endDate.setDate(endDate.getDate() + (duration_period - 1));
            displayText = displayText + ' - ' + Moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()]).format('ddd DD MMM YYYY');
        }
        return displayText;
    }

    const getSellPrice = (booking_date, price, agency_public_holidays) => {
        let selectedPrice = price['ranks'][0]['weekday'];
        if (booking_date) {
            let publicHoliday = agency_public_holidays ? agency_public_holidays : systemPublicHoliday;
            let day = parseInt((new Date(booking_date)).getDay());
            if (_.find(publicHoliday, function (date) { return date === booking_date })) {
                selectedPrice = price['ranks'][0]['holiday'];
            } else if (day === 6 || day === 0) {
                selectedPrice = price['ranks'][0]['weekend'];
            } else {
                selectedPrice = price['ranks'][0]['weekday'];
            }
        }
        return selectedPrice;
    }

    const cartItemBox = (groupItem, agencyId, package_id, booking_date, agency_public_holidays) => {
        let selectActive = true;
        let addOn = false;
        _.map(groupItem.items, cart_option => {
            if (!cart_option.status) {
                selectActive = false;
            }
        })
        _.map(groupItem.add_on, add_on => {
            if (_.size(add_on.items) > 0) {
                addOn = true;
            }
            _.map(add_on.items, cart_option => {
                if (!cart_option.status) {
                    selectActive = false;
                }
            })
        })
        if (_.size(groupItem.items) > 0) {
            return (
                <Grid container spacing={2} className={classes.packageBox}>
                    <Grid item xs={2} md={0.5} className={classes.checkboxStyle}>
                        <Checkbox style={{ width: 35, padding: 0 }}
                            checked={Boolean(groupItem.selected)}
                            icon={<FiCircle size={20} />}
                            checkedIcon={<FiCheckCircle size={20} />}
                            disabled={selectActive ? false : true}
                            onChange={() => updateGroupSelected(agencyId, package_id, booking_date, !groupItem.selected)}
                        />
                    </Grid>
                    <Grid item xs={10} md={1.5} className={`head ${classes.gridItemBoxCenter} ${classes.imgAlignTop}`} >
                        <CardMedia
                            component="img"
                            image={_.size(groupItem.package.images) > 0 ? groupItem.package.images[0]?.file_name : "../images/emptyStatus/no_image.png"}
                            sx={{ flex: 1, width: '100%', padding: 0 }}
                            alt={'package\'s image'}
                            className={classes.imgStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItemBoxLeft} style={{ paddingLeft: 10 }}>
                        <Link component={RouterLink} to={`/package/${groupItem.package.id}`} underline="none" className={classes.packageLink}>
                            <Typography variant="h6" style={{ fontWeight: "bold" }}>{groupItem.package.name}</Typography>
                        </Link>
                        {
                            groupItem.booking_date
                                ?
                                <Typography className={classes.bookingDateStyle}>{displayBookingPeriod(groupItem.booking_date, 1)}</Typography>
                                :
                                null
                        }
                        {
                            _.map(groupItem.items, mainOption => {
                                return (
                                    <Box key={mainOption.id}>
                                        {optionBox(mainOption, agency_public_holidays)}
                                    </Box>
                                )
                            })
                        }
                        <Box>
                            {
                                addOn &&
                                <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20 }}>
                                    {t('checkout.addOn') + ": "}
                                </Typography>
                            }
                            {
                                _.map(groupItem.add_on, (add_on, key) => {
                                    if (_.size(add_on.items) > 0) {
                                        return (
                                            <Box key={key}>
                                                <Typography variant="body1" style={{ fontWeight: "bold" }}>{add_on.display_name}</Typography>
                                                {_.map(add_on.items, addOnOption => {
                                                    return (
                                                        <Box key={addOnOption.id}>
                                                            {optionBox(addOnOption, agency_public_holidays)}
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        )
                                    }
                                })
                            }
                        </Box>
                    </Grid>
                </Grid>
            )
        }
    }

    const optionBox = (option, agency_public_holidays) => {
        let priceArray = _.size(option.add_on_price) > 0 ? option.add_on_price : option.package_option.price;
        return (
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={6}>
                    <Typography className={option.status ? '' : classes.inactiveStyle}>{option.package_option.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                    {
                        option.status
                            ?
                            <div className={classes.quantityStyle}>
                                <Button className={classes.minusPlusIconStyle} onClick={(event) => updateCartItem(option.id, ((option.quantity - 1) > 0 ? (option.quantity - 1) : 1))}>
                                    <FiMinus className={classes.minusPlusTextStyle} />
                                </Button>
                                <TextField
                                    sx={{ input: { textAlign: "center" } }}
                                    variant="standard"
                                    name="quantity"
                                    value={option.quantity}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    onChange={(event) => updateCartItem(option.id, (event.target.value > 0 ? event.target.value : option.quantity))}
                                />
                                <Button className={classes.minusPlusIconStyle} onClick={(event) => updateCartItem(option.id, (option.quantity + 1))}>
                                    <FiPlus className={classes.minusPlusTextStyle} />
                                </Button>
                            </div>
                            :
                            <Typography variant="body2" align="center">x{option.quantity}</Typography>
                    }
                </Grid>
                <Grid item xs={2}>
                    <div style={{ margin: "auto !important", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        {
                            option.package_option.price['retail'] > 0 && (option.package_option.price['retail'] - getSellPrice(option.booking_date, priceArray, agency_public_holidays)) > 0
                                ?
                                <Typography variant="caption" style={{ textDecoration: "line-through" }}>
                                    {
                                        " " + defaultCurrency.symbol + " "
                                        + parseFloat((option.package_option.price['retail'] * defaultCurrency.rate) * option.quantity).toFixed(2)
                                    }
                                </Typography>
                                :
                                null
                        }
                        <Typography variant="body1" style={{ marginLeft: 10 }}>
                            {
                                " " + defaultCurrency.symbol + " "
                                + parseFloat((getSellPrice(option.booking_date, priceArray, agency_public_holidays) * defaultCurrency.rate) * option.quantity).toFixed(2)
                            }
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={1}>
                    <Button onClick={() => deleteCartItem(option.id)}>
                        <FiTrash2 size={20} />
                    </Button>
                </Grid>
            </Grid>
        )
    }

    const bottomActionRow = () => {
        return (
            <Box className={classes.bottomActionStyle}>
                <Grid container spacing={2} className={classes.bottomActionBox}>

                    <Grid item xs={2} sm={2} md={1} className={classes.gridItemBox}>
                        <FormControlLabel
                            style={{ marginRight: 10 }}
                            label={t('checkout.all')}
                            control={
                                <Checkbox style={{ width: 35, padding: 0, }}
                                    checked={checkAll}
                                    icon={<FiCircle size={20} />}
                                    checkedIcon={<FiCheckCircle size={20} />}
                                    onChange={() => toogleCheckAll()}
                                />
                            }
                        />
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={5} sm={3} md={4} className={classes.gridItemBox}>
                            <Typography>{t('checkout.selectedItems') + ': ' + selectedTotal}</Typography>
                        </Grid>
                    </Hidden>
                    <Grid item xs={10} sm={7} md={7} className={`head ${classes.gridItemBox} ${classes.bottomActionCheckout}`}>
                        <div className={classes.subtotalStyle}>
                            <Typography>
                                {t('checkout.subtotal') + ':'}
                                {
                                    " " + defaultCurrency.symbol + " "
                                    + parseFloat(sumTotal * defaultCurrency.rate).toFixed(2)
                                }
                            </Typography>
                            {/* <Typography style={{ lineHeight: 1 }}>{'RM ' + parseFloat(sumTotal).toFixed(2)}</Typography> */}
                        </div>
                        <Button variant="contained" onClick={checkoutCart} disabled={(selectedTotal > 0) ? false : true}>
                            {t('checkout.checkout') + ' (' + selectedTotal + ')'}
                        </Button>
                    </Grid>
                </Grid>
                {/* <Hidden smUp>
                    <div style={{paddingTop:10}}>
                        <Typography style={{textAlign:'right'}}>{t('checkout.selectedItems') + ': ' + selectedTotal}</Typography>
                    </div>
                </Hidden> */}
            </Box>
        )
    }

    return (
        <div>
            {
                _.map(carts, (agencyCartList, agencyId) => {
                    return (
                        <Box className={classes.cartContainer} key={agencyId}>
                            <Box className={classes.cartAgencyBox}>
                                <Typography className={classes.cartAgencyTitle}><IoStorefrontSharp style={{ marginRight: 10 }} />{agencyCartList.name}</Typography>
                            </Box>
                            <Box>
                                {
                                    _.map(agencyCartList.data, (data1, package_id) => (
                                        _.map(data1, (groupItem, booking_date) => (
                                            <Box key={booking_date}>
                                                {cartItemBox(groupItem, agencyId, package_id, booking_date, agencyCartList.public_holidays)}
                                            </Box>
                                        ))
                                    ))
                                }
                            </Box>
                        </Box>
                    )
                })
            }
            {bottomActionRow()}
        </div>
    )
}

export default CartItemCard;

const useStyles = makeStyles((theme) => ({
    cartContainer: {
        margin: "20px 0 20px 0px !important",
        border: 'none',
        borderRadius: 20,
        borderColor: theme.palette.gray.secondary,
        overflow: 'hidden',
        boxShadow: '0px 5px 6px 0 #0000001f',
    },
    cartAgencyBox: {
        backgroundColor: theme.palette.gray.superLight + ' !important',
    },
    cartAgencyTitle: {
        // color: theme.palette.primary.main + ' !important',
        fontWeight: "bold !important",
        padding: "16px",
        display: 'flex',
        alignItems: 'center'
    },
    packageBox: {
        padding: "16px",
        borderBottom: "dashed 1px " + theme.palette.gray.superLight,
    },
    imgStyle: {
        objectFit: "contain !important",
        maxHeight: "150px",
    },
    minusPlusIconStyle: {
        backgroundColor: theme.palette.gray.superLight + " !important",
        minWidth: 'unset !important',
        // boxShadow: '2px 2px 6px 0 ' + theme.palette.primary.secondary,
        borderRadius: '6px !important',
        [theme.breakpoints.up('xs')]: {
            padding: "2px !important",
            width: 15,
            height: 15,
        },
        [theme.breakpoints.up('sm')]: {
            padding: "5px !important",
            width: 30,
            height: 30,
        },
    },
    minusPlusTextStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: 10,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
        },
        color: '#222',
    },
    quantityStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    gridItemBoxCenter: {
        margin: "auto !important",
        textAlign: 'center',
    },
    gridItemBoxLeft: {
        margin: "auto !important",
        textAlign: 'left',
    },
    gridItemBoxRight: {
        margin: "auto !important",
        textAlign: 'right',
    },
    imgAlignTop: {
        padding: '0 !important',
        margin: 'unset !important',
        marginTop: '20px!important',
    },
    packageLink: {
        "&:hover": {
            color: theme.palette.primary.secondary
        },
    },
    inactiveStyle: {
        color: theme.palette.gray.secondary,
        textDecoration: "line-through",
        fontStyle: "italic",
    },
    bottomActionStyle: {
        // backgroundColor: theme.palette.primary.main + ' !important',
        // margin: "50px 20px 20px 20px !important",
        margin: '50px 0 20px',
        borderRadius: 5,
    },
    bottomActionBox: {
        // padding: "0px 16px 16px 16px",
        padding: 0,
        color: theme.palette.primary.secondary + ' !important',
        fontWeight: "bold !important",
        alignItems: 'center',
    },
    bottomActionCheckout: {
        display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'flex-end',
        flexDirection: 'column !important',
        alignItems: 'flex-end',
        padding: 0
    },
    subtotalStyle: {
        textAlign: 'right',
        // marginRight: 15
    },
    optionStyle: {
        backgroundColor: theme.palette.primary.background + ' !important',
        color: theme.palette.primary.secondary + ' !important',
        borderRadius: 20,
        padding: "5px 10px",
        width: "fit-content",
        fontSize: "0.8rem !important",
        margin: "5px !important",
    },
    checkboxStyle: {
        padding: '0 0 0 5px!important', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', margin: 'unset !important', marginTop: '46px !important',
    },
    bookingDateStyle: {
        color: theme.palette.primary.secondary + ' !important',
        padding: "5px 10px 5px 0px",
        fontSize: "1.0rem !important",
        margin: "5px 5px 5px 0px !important",
    }
}))