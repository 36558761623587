import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import _ from 'lodash';

import { Box, CircularProgress, Grid, Typography, Container, Pagination, PaginationItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl } from '@helpers/api';
import NewsCard from '@components/NewsCard';

const News = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [listing, setListing] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        setLoading(true);
        getUrl("/member-news", { page: page }).then(response => {
            setLoading(false);
            if (response.status) {
                setListing(response.data.listing.data);
                setTotal(response.data.listing.last_page);
            }
        }).catch(err => {
            setLoading(false);
            addToast(t('general.error'), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [page]);

    const handlePaginationChange = (event, value) => {
        setPage(value);
    }

    return (
        <>
            {/* <img alt='news banner' src={"/images/news/banner.jpg"} style={{ width: "100%" }} /> */}
            <Box className={classes.bannerBackground} />
            <Box className={classes.bgImageWhiteLeft}>
                <Container maxWidth="xl" style={{ padding: "2% 9%" }}>
                    <Box style={{ marginTop: "5%" }}>
                        <Typography variant="h2" className={classes.titleStyle} style={{ fontFamily: "PlayfairDisplayItalic" }}>{t('news.title1')}</Typography>
                        <Typography variant="h2" className={classes.titleStyle}>{t('news.title2')}</Typography>
                    </Box>
                </Container>
            </Box>
            <Box className={classes.bgImageWhiteRight}>
                <Container maxWidth="xl" style={{ padding: "2% 9%" }}>
                    <Box>
                        {
                            loading ?
                                <CircularProgress color="primary" style={{ margin: "5%" }} />
                                :
                                <Box style={{ marginLeft: "1%" }}>
                                    {
                                        _.size(listing) > 0
                                            ?
                                            <Grid container spacing={3}>
                                                {_.map(listing, news => {
                                                    return (
                                                        <Grid item xs={6} sm={4} style={{ marginBottom: "6%" }} key={news.id}>
                                                            <NewsCard news={news} readMore={true} newsDetails={true} />
                                                        </Grid>
                                                    )
                                                })}
                                            </Grid>
                                            :
                                            <Typography variant="h4" style={{ margin: "5%" }}>{t('news.noNews')}</Typography>
                                    }
                                </Box>
                        }
                    </Box>
                    {
                        total ?
                            <Box style={{ display: "flex", justifyContent: "center", alignSelf: "center", margin: "3%" }}>
                                <Pagination count={total} page={page} onChange={handlePaginationChange} color="secondary" size="large" variant="text"
                                    renderItem={(item) => (
                                        <PaginationItem
                                            sx={{ color: theme.palette.secondary.main }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Box>
                            : null
                    }
                </Container>
            </Box>
            
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        lineHeight: "1 !important",
        [theme.breakpoints.up('xs')]: {
            fontSize: "2.0rem !important",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "4.3rem !important",
        },
    },
    bgImageWhiteLeft: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat', 
        backgroundPosition: 'left top 100%', 
        backgroundSize: "30%",
    },
    bgImageWhiteRight: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -100% bottom -10%', 
            backgroundSize: "70%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right bottom -80%', 
            backgroundSize: "40%",
        },
    },
    bannerBackground: {
        backgroundImage: `url("/images/news/banner.jpg")`,
        backgroundSize: 'cover',
        backgroundPosition: '0% 27%',
        [theme.breakpoints.up('xs')]: {
            minHeight: "200px",
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: "400px",
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: "600px",
        },
    },
}));

export default News;