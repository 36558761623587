import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Link as RouterLink } from 'react-router-dom';

import StarIcon from '@mui/icons-material/Star';
import { Avatar, Box, Tab, Tabs, Button, Container, Divider, Grid, LinearProgress, FormControlLabel, Checkbox, Rating, TextField, MenuItem, InputAdornment, Typography, Link, ImageList, ImageListItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { BsStarFill, BsStarHalf, BsStar, BsExclamationCircleFill } from "react-icons/bs";
import { IoBusiness } from "react-icons/io5";
import { FiClock, FiMinus, FiPlus } from "react-icons/fi";
import { FiChevronRight, FiShare2 } from "react-icons/fi";
import { MdFavoriteBorder, MdAdd, MdWifi, MdBeachAccess, MdPool, MdWineBar, MdArrowDropDown } from 'react-icons/md';

import { updateCartTotal } from '@actions/generalActions';
import BackToPrevious from '@components/BackToPrevious';
import PackageList from '@components/PackageList';
import { getUrl, postUrl } from '@helpers/api';

import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import PropTypes from 'prop-types';

const country = [
    { value: '', label: "-------- Where are you from --------" },
    { value: 'MY', label: 'Malaysia' },
    { value: 'JP', label: 'Japan' },
    { value: 'KR', label: 'Korea' },
    { value: 'TH', label: 'Thailand' },
    { value: 'SH', label: 'Shanghai' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'RI', label: 'Rebel Island' },
    { value: 'PA', label: 'Paris' },
];

const Summary = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const { uuid, accessToken } = useSelector(state => state.general);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [state, setState] = useState({
        promo_code: '',
        first_name: '',
        last_name: '',
        email: '',
        country: '',
        nric: '',
        mobile: '',
        additional_info: '',
    });
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [packages, setPackages] = useState([]);

    const perPage = 4;

    useEffect(() => {
        setLoading(true);
        getUrl("/package-pagination", { page: page, per_page: perPage }).then(response => {
            setLoading(false);
            if (response.status) {
                setPackages(response.data.listing.data);
                setTotal(response.data.listing.last_page);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [page]);

    const handlePaginationChange = (event, value) => {
        setPage(value);
    }

    const handleChange = (newValue) => {
        setState(newValue);
    };

    return(
        <>
            <Container maxWidth="xl" style={{ padding: "2% 10%" }}>
                <Box style={{ paddingTop: 20 }}>
                    {/* Package Summary */}
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <div style={{ display: 'flex', direction: 'row'}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={5}>
                                            <img src={'../images/rooms_offers/room1.jpg'} width="100%" height={230} style={{ objectFit: 'cover', paddingRight: 20}} />
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <Typography className={classes.titleText} sx={{ fontFamily: 'PlayfairDisplayRegular' }}>Standard Suites Room</Typography>
                                            <Typography className={classes.contentText} style={{ display: 'flex', alignItems: 'center' }}><BsExclamationCircleFill style={{ marginRight: 10 }} color={theme.palette.primary.main} />Advance Purchase with Breakfast</Typography>
                                            
                                            <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                                            
                                            <Typography><b>ReLuxe De Mantanani</b> rewards you with the great rates.</Typography>
                                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                                <MdWifi size={25} color={theme.palette.primary.main} />
                                                <Typography style={{paddingLeft: 10, fontFamily: 'FuturaPTMedium'}}>Complimentary high-speed WiFi</Typography>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                                <MdBeachAccess size={25} color={theme.palette.primary.main} />
                                                <Typography style={{paddingLeft: 10, fontFamily: 'FuturaPTMedium'}}>Direct and private beach access</Typography>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                                <MdPool size={25} color={theme.palette.primary.main} />
                                                <Typography style={{paddingLeft: 10, fontFamily: 'FuturaPTMedium'}}>Private pools</Typography>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                                <MdWineBar size={25} color={theme.palette.primary.main} />
                                                <Typography style={{paddingLeft: 10, fontFamily: 'FuturaPTMedium'}}>Beach Bar</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                                <Divider style={{ margin: "40px 0px", borderBottomWidth: 0 }} />

                                <div style={{ display: 'flex'}}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={4}>
                                            <img src={'../images/rooms_offers/activity4.jpg'} width="100%" height={150} style={{ objectFit: 'cover', paddingRight: 20}} />
                                        </Grid>
                                        <Grid item xs={12} md={8}>
                                            <Typography className={classes.contentText}><u style={{fontFamily: 'FuturaPTMedium'}}>ReLuxe De Mantanani All-Inclusive Package</u></Typography>
                                            
                                            <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                                            
                                            <Typography>
                                                The most memorable stay imaginable, with VIP arrival, 
                                                full-board dining, and exploration of the breathtaking 
                                                underwater safari of Mantanani Island.
                                            </Typography>
                                            <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                                            <div style={{ display: 'flex', alignItems: 'center', color: theme.palette.primary.main }}>
                                                <BsStarFill />
                                                <BsStarFill />
                                                <BsStarFill />
                                                <BsStarHalf />
                                                <BsStar />
                                                <Typography variant="body1" style={{paddingLeft: 5, color: theme.palette.primary.main}}>3.5 (710 reviews)</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>

                                <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />

                                <div>
                                    <Typography variant='h6' style={{fontFamily: 'FuturaPTMedium'}}>Important Note !</Typography>
                                    <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                                    <Typography variant='h6'><u>"Memories are made of magic. Doesn't matter how unmem'rable they seen.</u></Typography>
                                    <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                                    <Typography variant='h6'>
                                        "Ordinary moments like a little laugh you shared, can bring a gleam of light
                                        in your bleakest darkest hour.
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box style={{ border: "1px solid" }}>
                                    <Box style={{ padding: "10px 30px" }}>
                                        <Typography variant="h6">{t('package.bookingDetails')}</Typography>
                                    </Box>
                                    <Divider style={{ borderColor: theme.palette.secondary.main }} />
                                    <Box style={{ padding: "20px 30px" }}>
                                        {
                                            _.map([
                                                { title: (t('package.checkIn') + ":"), content: "Sat 05 Nov 2022" },
                                                { title: (t('package.checkOut') + ":"), content: "Sat 05 Nov 2022" },
                                                { title: (t('package.nights') + ":"), content: "2 Nights" },
                                                { title: (t('package.for') + ":"), content: "2 Adults, 1 Room" },
                                            ], (info, key) => (
                                                <Box style={{ display: "block", paddingTop: key === 0 ? 0 : 20 }} key={key}>
                                                    <Typography variant="body" style={{ fontFamily: "FuturaPTMedium", textTransform: 'uppercase', display: "block" }}>{info.title}</Typography>
                                                    <Typography variant="h6">{info.content}</Typography>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                    <Divider style={{ borderColor: theme.palette.secondary.main }} />
                                    <Box style={{ padding: "25px 30px" }}>
                                        <Box style={{ display: "block", paddingBottom: 20 }}>
                                            <Typography variant="h6">Price Breakdown</Typography>
                                            <Typography variant="h6">7 Nights, 1 Room, 2 Adults</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>{t('package.room') + ":"}</Typography>
                                            <Typography variant="h6">MYR 2,932.94</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>{t('package.taxesAndFees') + ":"}</Typography>
                                            <Typography variant="h6">MYR 450.94</Typography>
                                        </Box>
                                        <Box style={{ lineHeight: "1", padding: "10px 0px 20px" }}>
                                            <Typography variant="caption">{t('package.taxesAndFeesNote')}</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy", color: theme.palette.primary.main }}>{t('package.totalPrice') + ":"}</Typography>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>{"MYR 2,932.94"}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                    {/* Promo Code */}
                    <div>
                        <Grid container spacing={2} style={{ paddingTop: 20 }}>
                            <Grid item xs={12} md={6}>
                                <Typography variant='h6' style={{fontFamily: "FuturaPTMedium", paddingBottom: 5 }}>Add a Promo Code</Typography>
                                <div className={ classes.quantityStyle }>
                                    <TextField
                                        sx={{ input: { fontSize: 20, textAlign: "left", color: theme.palette.primary.main, paddingLeft: 2} }}
                                        variant="standard"
                                        name="promo_code"
                                        placeholder='Example: RELUXE2023HNY'
                                        fullWidth
                                        value={state.promo_code}
                                        InputProps={{ disableUnderline: true,}}
                                        onChange={(event) => handleChange(event.target.value)}
                                    />
                                    <Button variant="outlined" color="primary" className={ classes.buttonStyle }>
                                        <Typography variant='h6' color="inherit" style={{fontFamily: "FuturaPTMedium", textTransform: 'uppercase'}}>APPLY</Typography>
                                    </Button>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'end', justifyContent: 'end' }}>
                                <Button variant="outlined" color="primary" className={ classes.buttonStyle2 }>
                                    <Typography variant='h6' color="inherit" style={{fontFamily: "FuturaPTMedium", textTransform: 'uppercase'}}>UPDATE CART</Typography>
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Container>
            
            {/* Guest Details */}
            <div style={{ backgroundColor: '#a89d83' }}>
                <Container maxWidth="xl" style={{ padding: "0.5% 10%", display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography variant='h4' style={{color: 'white', fontFamily: "FuturaPTMedium"}}>Guest Details</Typography>
                    <Button variant="containedPrimary" color="primary" style={{ display: 'flex', alignItems: 'center', paddingRight: 10 }}>
                        <MdAdd />
                        <Typography variant="h6" color="inherit">Add guest</Typography>
                    </Button>
                </Container>
            </div>

            <div>
                <Container maxWidth="xl" style={{ paddingTop: 30, padding: "2% 10%" }}>
                    <div>
                        <form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">First Name</Typography>
                                    <div className={ classes.guestDetailsStyle }>
                                        <TextField
                                            sx={{ input: { fontSize: 20, textAlign: "left", color: theme.palette.primary.main, paddingLeft: 2} }}
                                            variant="standard"
                                            name="first_name"
                                            placeholder='Madrigal'
                                            fullWidth
                                            value={state.first_name}
                                            InputProps={{ disableUnderline: true,}}
                                            onChange={(event) => handleChange(event.target.value)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">Last Name</Typography>
                                    <div className={ classes.guestDetailsStyle }>
                                        <TextField
                                            sx={{ input: { fontSize: 20, textAlign: "left", color: theme.palette.primary.main, paddingLeft: 2} }}
                                            variant="standard"
                                            name="last_name"
                                            placeholder='Mirabel'
                                            fullWidth
                                            value={state.last_name}
                                            InputProps={{ disableUnderline: true,}}
                                            onChange={(event) => handleChange(event.target.value)}
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">Email</Typography>
                                    <div className={ classes.guestDetailsStyle }>
                                        <TextField
                                            sx={{ input: { fontSize: 20, textAlign: "left", color: theme.palette.primary.main, paddingLeft: 2} }}
                                            variant="standard"
                                            name="email"
                                            placeholder='familyMadrigals@reluxe.com'
                                            fullWidth
                                            value={state.email}
                                            InputProps={{ disableUnderline: true,}}
                                            onChange={(event) => handleChange(event.target.value)}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">Country & Origin</Typography>
                                    <div className={ classes.guestDetailsStyle }>
                                        <TextField
                                            sx={{ input: { fontSize: 20, textAlign: "left", color: theme.palette.primary.main, paddingLeft: 2} }}
                                            variant="standard"
                                            name="country"
                                            select
                                            fullWidth
                                            defaultValue=""
                                            value={state.last_name}
                                            InputProps={{ disableUnderline: true,}}
                                            onChange={(event) => handleChange(event.target.value)}
                                        >
                                            {country.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                </Grid>
                            </Grid>

                            <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">Mykad ID or Passport ID</Typography>
                                    <div className={ classes.guestDetailsStyle }>
                                        <TextField
                                            sx={{ input: { fontSize: 20, textAlign: "left", color: theme.palette.primary.main, paddingLeft: 2} }}
                                            variant="standard"
                                            name="nric"
                                            fullWidth
                                            value={state.nric}
                                            InputProps={{ disableUnderline: true,}}
                                            onChange={(event) => handleChange(event.target.value)}
                                        />
                                    </div>

                                    <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />

                                    <Typography variant="h5">Contact Number</Typography>
                                    <div className={ classes.guestDetailsStyle }>
                                        <Typography style={{ paddingLeft: 10, paddingRight: 10, borderRight: `solid 2px ${theme.palette.primary.main}` }}>+6</Typography>
                                        <TextField
                                            sx={{ input: { fontSize: 20, textAlign: "left", color: theme.palette.primary.main, paddingLeft: 2} }}
                                            variant="standard"
                                            name="mobile"
                                            fullWidth
                                            placeholder='0123456789'
                                            value={state.mobile}
                                            InputProps={{ disableUnderline: true,}}
                                            onChange={(event) => handleChange(event.target.value)}
                                        />
                                    </div>
                                    <FormControlLabel control={<Checkbox />} label="Send SMS confirmation to this number" />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h5">Additional Information</Typography>
                                    <div className={ classes.guestDetailsStyle }>
                                        <TextField
                                            sx={{ input: { fontSize: 20, textAlign: "left", color: theme.palette.primary.main, paddingLeft: 2} }}
                                            variant="standard"
                                            name="additional_info"
                                            multiline
                                            rows={7}
                                            fullWidth
                                            value={state.additional_info}
                                            InputProps={{ disableUnderline: true,}}
                                            onChange={(event) => handleChange(event.target.value)}
                                        />
                                    </div>
                                </Grid>
                            </Grid>

                            <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />

                            <Grid container spacing={2}>
                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: "center"}}>
                                    <Typography>
                                        {"By selecting the button below, I agree to the "}
                                        <Link component={RouterLink} to={"#"} style={{color: theme.palette.secondary.main}}>ReLuxe's Booking</Link>
                                        {" and "}
                                        <Link component={RouterLink} to={"#"} style={{color: theme.palette.secondary.main}}>Refund Policy</Link>
                                        {". Read more here."}
                                    </Typography>
                                    <Button variant="outlined" color="primary" className={ classes.buttonStyle2 }>
                                        <Typography variant='h6' color="inherit" style={{fontFamily: "FuturaPTMedium", textTransform: 'uppercase'}}>PROCEED TO CHECK OUT</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>
            </div>

            <Container maxWidth="xl" style={{ padding: "2% 9%" }} className={classes.bgImageWhiteRight}>
                <Box>
                    {/* Recommended Packages */}
                    <div>
                        <Typography variant='h3' sx={{ fontFamily: 'PlayfairDisplayItalic' }}>Recommended Packages</Typography>
                        <Box className={classes.packageList}>
                            {
                                _.size(packages) > 0
                                    ?
                                    <Box style={{ marginTop: 40 }}>
                                        <PackageList 
                                            packageList={packages} 
                                            pagination={true} 
                                            swiper={false} 
                                            handlePaginationChange={handlePaginationChange} 
                                            page={page} 
                                            total={total}  
                                            showPrice={true} 
                                            showLink={true} 
                                        />
                                    </Box>
                                    :
                                    loading
                                        ?
                                        <Box style={{ marginTop: 20 }}>
                                            <LinearProgress size={20} />
                                        </Box>
                                        :
                                        <Box style={{ marginTop: 20, textAlign: "center" }}>
                                            <Typography variant="h6" style={{ padding: 50 }}>{t('general.noData')}</Typography>
                                        </Box>
                            }
                        </Box>
                    </div>
                </Box>
            </Container>
        </>
    );
}
export default Summary;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    swiperStyle: {
        // width: 200,
        width: '100%',
        height: 400
    },
    bgImageWhiteRight: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -50% top 2%', 
            backgroundSize: "60%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right top', 
            backgroundSize: "20%", 
        },
    },
    titleText: {
        color: theme.palette.gray.dtext,
        fontSize: '28px!important',
        fontWeight: 'bold!important',
    },
    priceText: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold!important',
        fontSize: '26px!important',
        // paddingBottom: '20px!important'
    },
    contentText: {
        color: theme.palette.gray.dtext,
        fontSize: '16px!important',
    },
    contentNote: {
        color: theme.palette.gray.dtext,
        fontSize: '16px!important',
        fontStyle: "italic",
        paddingLeft: 20
    },
    phNote: {
        color: theme.palette.gray.dtext,
        fontSize: '16px!important',
    },
    buttonOrange: {
        backgroundColor: theme.palette.button.orange + " !important",
        boxShadow: "1px 2px 7px 0 #ffa34c !important",
    },
    buttonPrimary: {
        backgroundColor: theme.palette.button.main + " !important",
        boxShadow: "1px 2px 5px 0 #ff7f4c57  !important",
    },
    buttonStyle: {
        textAlign: 'center',
        padding: "6px 10px !important",
        height: 50,
        [theme.breakpoints.up('xs')]: {
            minWidth: '150px !important',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '170px !important',
        },
    },
    buttonStyle2: {
        [theme.breakpoints.up('xs')]: {
            minWidth: '200px !important',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '280px !important',
        },
    },
    minusPlusIconStyle: {
        minWidth: 'unset !important',
        padding: 8
    },
    minusPlusTextStyle: {
        fontSize: 20,
        color: '#ad9e80'
    },
    quantityStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 50,
        borderLeft: 'solid 2px #ad9e80',
        borderTop: 'solid 2px #ad9e80',
        borderBottom: 'solid 2px #ad9e80',
    },
    guestDetailsStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        border: 'solid 2px #ad9e80',
    },
    quantityTitle: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    quantityGroup: {
        border: '1px solid',
        borderRadius: 8,
        padding: '8px 15px',
        borderColor: theme.palette.gray.light,
        marginTop: 8,
    },
    titleFont: {
        fontWeight: 'bold',
        [theme.breakpoints.up('xs')]: {
            fontSize: '16px!important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px!important',
        },
    },
    flexSbM: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    flexM: {
        display: 'flex',
        alignItems: 'center'
    },
    packageList: {
        [theme.breakpoints.up('xs')]: {
            marginTop: "20px !important",
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "40px !important",
        },
    },
}))