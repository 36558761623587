import * as actionType from '@types/userType';

const INITIAL_STATE = {
    addresses: [],
    email: "",
    ic: null,
    id: null,
    mobile: null,
    name: "",
    first_name: '',
    last_name: '',
    username: '',
    profile_picture: null,
    status: null,
    agency_slug: '',
    rank:'',
};

export default function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionType.UPDATE_USER_INFO:
            const { addresses, email, ic, id, mobile, name, first_name, last_name, username, profile_picture, status, agency,rank_display } = action.payload;
            return { ...state, addresses, email, ic, id, mobile, name, first_name, last_name, username, profile_picture, status, agency_slug: agency?agency.slug:'', rank:rank_display };
        case actionType.UPDATE_PROFILE:
            return { ...state, ic: action.payload.ic, mobile: action.payload.mobile, name: action.payload.name, first_name: action.payload.first_name, last_name: action.payload.last_name, profile_picture: action.payload.profile_picture };
        case actionType.UPDATE_ADDRESSES:
            return { ...state, addresses: action.payload };
        case actionType.RESET_PROFILE:
            return INITIAL_STATE;
        default:
            return state;
    }
}