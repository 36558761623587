import { getUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { Box, Container, LinearProgress, Typography } from '@mui/material';

import BackToPrevious from '@components/BackToPrevious';
import PackageList from '@components/PackageList';

const Wishlist = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [wishlists, setWishlists] = useState([]);

    useEffect(() => {
        setLoading(true);
        getUrl("/wishlist-list", { pagination: 1, page: page }).then(response => {
            setLoading(false);
            if (response.status) {
                setTotal(response.data.listing.last_page);
                setWishlists(response.data.listing.data);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [page]);

    const handlePaginationChange = (event, value) => {
        setPage(value);
    }

    return (
        <Box className={classes.paper}>
            <Container maxWidth="xl">
                <BackToPrevious />
                <Typography variant="h5" className={classes.titleStyle}>{t('dashboard.wishlist')}</Typography>
                {
                    _.size(wishlists) > 0
                        ?
                        <Box>
                            <PackageList packageList={wishlists} pagination={true} swiper={false} handlePaginationChange={handlePaginationChange} page={page} total={total} showPrice={true} showLink={true} popup={false} mobileSwiper={false} />
                        </Box>
                        :
                        loading
                            ?
                            <Box style={{ marginTop: 20 }}>
                                <LinearProgress size={20} />
                            </Box>
                            :
                            <Box style={{ marginTop: 20, textAlign: "center" }}>
                                <Typography variant="h6" style={{ padding: 50 }}>{t('dashboard.noWishlist')}</Typography>
                            </Box>
                }
            </Container>
        </Box>
    )
}

export default Wishlist;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    titleStyle: {
        fontWeight: "bold !important",
        color: theme.palette.primary.main,
        //marginLeft: "20px !important",
        // marginBottom: "20px !important"
    },
}))