import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Pagination, PaginationItem, useMediaQuery } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import PackageCard from './PackageCard';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import './css/styles.css';
// import required modules
import { Navigation } from "swiper";

const PackageList = (props) => {
    const { packageList, pagination, swiper, handlePaginationChange, page, total, showPrice, showLink, bookResortNow, toggleItem, popup, mobileSwiper=true } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const matchDownLg = useMediaQuery(theme.breakpoints.down('lg'));
    const [swiperComponent, setSwiperComponent] = useState(null);

    useEffect(() => {
        if(!swiper && matchDownLg && swiperComponent){
            swiperComponent.slideTo(0, 0, false);
        }
        // eslint-disable-next-line
    }, [page]);


    const paginationSection = () => (
        <Grid container spacing={2} className={classes.packageList}>
            {_.map(packageList, (packageItem, key) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={key} style={{ display: 'flex', alignItems: "flex-end"}} className={classes.packageCardGrid}>
                    <PackageCard packageItem={packageItem} showPrice={showPrice} showLink={showLink} bookResortNow={bookResortNow} toggleItem={toggleItem} popup={popup} />
                </Grid>
            ))}
            {
                pagination
                    ?
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        {selectPaginationPart()}
                    </Grid>
                    :
                    null
            }
        </Grid>
    )

    const mobilePaginationSection = () => (
        <Box>
            {swiperSection()}
            {pagination ?
                <Box style={{ textAlign: "center" }}>
                    {selectPaginationPart()}
                </Box>
                : null}
        </Box>
    )

    const selectPaginationPart = () => (
        <Box style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
            <Pagination count={total} page={page} onChange={handlePaginationChange} color="secondary" variant="text"
                renderItem={(item) => (
                    <PaginationItem
                        sx={{ color: theme.palette.secondary.main }}
                        {...item}
                    />
                )}
            />
        </Box>
    )

    const swiperSection = () => (
        <Swiper
            className={classes.swiperSpacing}
            spaceBetween={20}
            slidesPerGroup={1}
            loop={false}
            navigation
            modules={[Navigation]}
            breakpoints={{
                0: {
                    slidesPerView: 2.5,
                },
                900: {
                    slidesPerView: 3.5,
                },
                1200: {
                    slidesPerView: 4.5,
                },
            }}
            onSwiper={(swiper) => setSwiperComponent(swiper)}
        >
            {
                _.map(packageList, packageItem => {
                    return (
                        <SwiperSlide key={packageItem.id} style={{ display: 'flex', alignItems: "flex-end"}} className={classes.packageCardGrid}>
                            <PackageCard packageItem={packageItem} showPrice={showPrice} showLink={showLink} bookResortNow={bookResortNow} toggleItem={toggleItem} popup={popup} />
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    )

    return (
        <>
            {(_.size(packageList) > 0) && swiper ? swiperSection() : (matchDownLg&&mobileSwiper?mobilePaginationSection():paginationSection())}
        </>
    )
};
export default PackageList;

const useStyles = makeStyles((theme) => ({
    packageList: {
        // [theme.breakpoints.up('xs')]: {
        //     padding: "20px !important",
        // },
        // [theme.breakpoints.up('md')]: {
        //     padding: "0px 30px !important",
        // },
    },
    swiperSpacing: {
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: '20px',
        },
    },
    packageCardGrid: {
        [theme.breakpoints.up('xs')]: {
            height: 290,
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 10,
            height: 370,
        },
        [theme.breakpoints.up('lg')]: {
            height: 470,
        },
        [theme.breakpoints.up('xl')]: {
            height: 550,
        },
    }
}));