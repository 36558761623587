import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { Box, Button, Grid, InputLabel, Rating, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '@helpers/api';

const MODULE = "reviews";
const MODULE_SINGLE = "review";

export default function ReviewForm(props) {
    const { voucher, setReviewDialog, setRefreshData } = props;
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        voucher_id: voucher.id,
        anonymous: 0,
        content: voucher.review?voucher.review.content:'',
        rating: voucher.review?voucher.review.rating:'',
    });
    const [inputErrors, setInputErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const isMountedRef = useRef(null);
    const [refTypeList, setRefTypeList] = useState([]);
    const [ratingCriteria, setRatingCriteria] = useState([]);
    const [readOnly, setReadOnly] = useState(voucher.review?true:false);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/reviews/create`).then(response => {
            setLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    let { ref_type, rating_criteria } = response.data;
                    setRefTypeList(ref_type);
                    setRatingCriteria(rating_criteria);
                    if (_.size(rating_criteria) > 0) {
                        let tempRating = {};
                        _.map(rating_criteria, (typeCriteria, type) => {
                            if (type === 'package') {
                                _.map(typeCriteria, (criteria, cname) => {
                                    let value = _.find(state.rating, function (r, key) { return key === cname; });
                                    value = value ? value : 0;
                                    tempRating[cname] = parseFloat(value);
                                })
                            }
                        })
                        setState({ ...state, rating: tempRating });
                    } else {
                        setState({ ...state, rating: [] });
                    }
                } else {
                    addToast(response.message, { appearance: 'error' });
                }
            }
        }).catch(error => {
            setLoading(false);
            addToast(error.message, { appearance: 'error' });
        });
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [])

    const handleChange = ({ target }) => {
        let { name, value } = target;
        setState({ ...state, [name]: value });
    };

    const handleRatingChange = ({ target }) => {
        let { name, value } = target;
        setState({ ...state, rating: { ...state.rating, [name]: parseFloat(value) } });
    }

    const handleClose = () => {
        setReviewDialog(false);
        setState({
            voucher_id: voucher.id,
            anonymous: 0,
            content: '',
            rating: [],
        });
    }

    const submitData = () => {
        let postData = {
            voucher_id: state.voucher_id,
            anonymous: state.anonymous,
            content: state.content,
            rating: state.rating,
        };
        setLoading(true);
        setInputErrors();
        postUrl('/member-reviews', postData).then(response => {
            setLoading(false);
            let { status, data, errors, message } = response;
            if (!status) {
                setInputErrors(errors);
            } else {
                setRefreshData(true);
                handleClose();
            }
            addToast(message, { appearance: status ? 'success' : 'error' });
        }).catch(error => {
            setLoading(false);
            addToast(error.message, { appearance: 'error' });
        });
    }

    return (
        <>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.content`)} *`}
                            variant="outlined"
                            value={state.content}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.content ? inputErrors.content : ''}
                            error={inputErrors && inputErrors.content ? true : false}
                            name="content"
                            multiline
                            rows={3}
                            onChange={handleChange}
                            InputProps={{
                                readOnly: readOnly,
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <InputLabel>{`${t(`${MODULE}.rating`)} *`}</InputLabel>
                        {
                            _.map(state.rating, (value, name) => {
                                if (name !== 'average') {
                                    let displayText = _.find(ratingCriteria['package'], function (r, key) { return key === name; });
                                    if (displayText) {
                                        return (
                                            <Box style={{ display: "flex", alignItems: "center" }} key={name}>
                                                <Typography variant="body" style={{ marginRight: 10 }}>{i18n.language === 'cn' ? displayText.cn : displayText.en}</Typography>
                                                <Rating
                                                    name={name}
                                                    value={parseFloat(value)}
                                                    precision={0.5}
                                                    onChange={handleRatingChange}
                                                    style={{ color: theme.palette.primary.main }}
                                                    readOnly={readOnly}
                                                />
                                            </Box>
                                        )
                                    }
                                }
                            })
                        }
                    </Box>
                </Grid>
            </Grid>
            {
                inputErrors && inputErrors.voucher_id
                ?
                <Box style={{display: "flex", justifyContent: "center"}}>
                    <Typography variant="body1" style={{ color: theme.palette.indicator.red }}>{inputErrors.voucher_id[0]}</Typography>
                </Box>
                :
                null
            }
            {
                readOnly
                    ?
                    <Box style={{ paddingTop: "2%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button variant="contained" color="primary" onClick={handleClose}>{t('general.ok')}</Button>
                    </Box>
                    :
                    <Box style={{ paddingTop: "2%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button variant="contained" color="primary" onClick={() => submitData()}>{t('general.submit')}</Button>
                    </Box>
            }
        </>
    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150,
        borderRadius: 8,
    },
}));