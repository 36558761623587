import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';

import { Box, Container, Typography, Link, Hidden, TextField, Divider, Button, Grid } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { BiSearch, BiCalendar } from 'react-icons/bi';
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";

import { deleteUrl, getUrl } from '@helpers/api';

const Subscription = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [loading, setLoading] = useState(false);
    const [inputErrors, setInputErrors] = useState({});

    const [searchIdForEmail, setSearchIdForEmail] = useState({
        email: '',
    });
    const [successUnsubscribe, setSuccessUnsubscribe] = useState(false);

    const deleteSubscription = () => {
        setLoading(true);

        getUrl(`/subscribes/${searchIdForEmail.email}`).then(response => {
            if (response.status) {
                if (response.data !== null) {
                    deleteUrl(`/subscribes/${response.data}`).then(response => {
                        if (response.status) {
                            setInputErrors({});
                            setSearchIdForEmail({ ...searchIdForEmail, email: "", id: "" });
                            setSuccessUnsubscribe(true);
                        } else {
                            setInputErrors(response.errors);
                        }
        
                        if (response.message) {
                            addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                        }
                        setLoading(false);
                    }).catch(err => {
                        setLoading(false);
                        addToast(JSON.stringify(err), { appearance: 'error' });
                    })
                } else {
                    addToast(t('dashboard.emailNotFound'), { appearance: 'error' });
                }
            } else {
                setInputErrors(response.errors);
            }

            if (response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            addToast(JSON.stringify(error), { appearance: 'error' });
        });
    }

    const handleChange = ({ target }) => {
        setSearchIdForEmail({ ...searchIdForEmail, email: target.value });
    }

    const unsubscribeFormPage = () => {
        return (
            <>
                <Typography variant="h6">{t('dashboard.unsubscribeDesc')}</Typography>

                <Grid item xs={12}>
                    {/* Email */}
                    <TextField
                        margin="dense"
                        id="email"
                        label={t('user.email')}
                        type="email"
                        sx={{ width: '700px' }}
                        variant="outlined"
                        value={searchIdForEmail.email}
                        onChange={handleChange}
                    />
                    <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                    <Button variant="outlined" color="primary" className={ classes.buttonStyle } onClick={deleteSubscription}>
                        { t('general.submit') }
                    </Button>
                </Grid>
            </>
        )
    }

    const successUnsubscribePage = () => {
        return (
            <>
                <Typography variant="h6">{t('dashboard.successUnsubscribe')}</Typography>

                <Grid item xs={12}>
                    <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                    <Button variant="outlined" color="primary" className={ classes.buttonStyle } to="/" component={RouterLink}>
                        { t('general.goHome') }
                    </Button>
                </Grid>
            </>
        );
    }

    return (
        <Box padding={5}>
            <Container maxWidth="xl" align="center">
                <Grid>
                    <Typography variant="h3" style={{fontFamily: 'PlayfairDisplayItalic'}}>{t('title.unsubscribe') + " " + t('title.ourNewsletter')}</Typography>
                    <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                </Grid>
                <Grid>
                    { successUnsubscribe ? successUnsubscribePage() : unsubscribeFormPage() }
                </Grid>
            </Container>
        </Box>
    );
};
export default Subscription;

const useStyles = makeStyles((theme) => ({
    buttonStyle: {
        textAlign: 'center',
        padding: "6px 10px !important",
        border: 'solid 3px #ad9e80 !important',
        height: 50,

        [theme.breakpoints.up('xs')]: {
            minWidth: '150px !important',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: '170px !important',
        },
    },
    cardContainerStyle: {
        marginBottom: 25,
        border: `1px solid ${theme.palette.primary.lightMain}`,
    },
    formContainer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    cardContentRoot: {
        padding: theme.spacing(3),
        border: `1px solid ${theme.palette.primary.lightMain}`,
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        borderRadius: 15
    },
    avatarStyle: {
        width: '80px !important',
        height: '80px !important',
        textTransform: 'uppercase',
        margin: 0,
        backgroundColor: theme.palette.primary.main + '!important',
    },
    ads: {
        [theme.breakpoints.up('xs')]: {
            width: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '120%'
        },
        [theme.breakpoints.up('md')]: {
            width: '105%'
        },
    },
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important",
    },
    button: {
        [theme.breakpoints.up('md')]: {
            padding: '0px 7px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0px 20px'
        },
    }
}))