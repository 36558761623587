import _ from 'lodash';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { Autocomplete, Box, Button, Card, CardContent, CardMedia, Container, Fade, FormControl, Grid, Hidden, IconButton, InputAdornment, InputLabel, LinearProgress, Link, MenuItem, Paper, Popper, Rating, Select, TextField, Typography, Dialog, DialogContent } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import CustomizeCalendar from '@components/CustomizeCalendar';
import PackageList from '@components/PackageList';
import { getUrl } from '@helpers/api';
import { BiCalendar } from 'react-icons/bi';

const Activities = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const { defaultCurrency } = useSelector(state => state.general);
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [calanderPopup, setCalanderPopup] = useState(false);

    const [packages, setPackages] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [categoryList, setCategoryList] = useState({});
    const [locationList, setLocationList] = useState({});
    const [searchData, setSearchData] = useState({});

    let name = searchParams.get('name');
    let category_id = searchParams.get('category_id');
    let location_id = searchParams.get('location_id');
    let start = searchParams.get('start_date');
    let end = searchParams.get('end_date');
    const [searchField, setSearchField] = useState({
        name: name ? name : '',
        category_id: (category_id) ? category_id : 0,
        location_id: (location_id) ? location_id : 0,
        start_date: start ? start : '',
        end_date: end ? end : '',
    });

    const [dates, setDates] = useState({
        start: start ? new Date(start) : '',
        end: end ? new Date(end) : '',
    });
    const [dateOpen, setDateOpen] = useState(false);

    useEffect(() => {
        setLoading(true);
        getUrl("/member-packages", { ...searchField, page: page }).then(response => {
            setLoading(false);
            if (response.status) {
                setPackages(response.data.listing.data);
                setTotal(response.data.listing.last_page);
                setCategoryList(response.data.categories);
                setLocationList(response.data.locations);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [page, name, category_id, location_id, start, end]);

    useEffect(() => {
        let postData = {
            name: searchField.name,
            start_date: searchField.start_date ? Moment(searchField.start_date).format('YYYY-MM-DD') : '',
            end_date: searchField.end_date ? Moment(searchField.end_date).format('YYYY-MM-DD') : '',
            limit: 5
        };
        getUrl(`/member-packages`, postData).then(response => {
            if (response.status) {
                setSearchData(response.data.listing);
            }
        }).catch(err => {
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [searchField.name]);

    useEffect(() => {
        setSearchField({ ...searchField, start_date: dates.start ? Moment(dates.start).format('YYYY-MM-DD') : '', end_date: dates.end ? Moment(dates.end).format('YYYY-MM-DD') : '' });
        // eslint-disable-next-line
    }, [dates.start, dates.end]);

    const handlePaginationChange = (event, value) => {
        setPage(value);
    }

    const [valueTab, setValueTab] = useState(1);
    const handleChange = (event, newValue) => {
        setValueTab(newValue);
    };

    const handleSearchData = () => {
        setSearchParams(searchField);
        setPage(1);
    }
    const clearSearchData = () => {
        setSearchParams();
        setSearchField({
            name: '',
            category_id: 0,
            location_id: 0,
            start_date: '',
            end_date: '',
        });
        setPage(1);
    }

    const handleStartDateChange = (event) => {
        if (searchField.end_date) {
            setSearchField({ ...searchField, start_date: event.target.value })
        } else {
            setSearchField({ ...searchField, start_date: event.target.value, end_date: event.target.value })
        }
    }

    const handleDateOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setDateOpen(!dateOpen);
    }

    return (
        <>
            {/* <img alt='activities banner' src={"/images/activities/banner.jpg"} style={{ width: "100%" }} /> */}
            <Box className={classes.bannerBackground} />
            <Hidden mdDown>
                <Box className={classes.bgImageWhiteRight}>
                    <Container maxWidth="xl" style={{ padding: "2% 6%" }}>
                        <Box>
                            <Typography variant="h6" align="center" style={{ margin: "50px 0px 15px" }}>{t('activities.content1')}</Typography>
                            <Typography variant="h2" align="center" style={{ marginBottom: "15px" }}>{t('activities.content2')}</Typography>
                            <Box style={{ display: "flex", justifyContent: "center" }}>
                                <Typography variant="h6" align="center" style={{ maxWidth: "900px", lineHeight: '1.1' }}><Trans>{t('activities.content3')}</Trans></Typography>
                            </Box>
                            <Typography variant="h6" align="center" style={{ fontStyle: "Italic", marginTop: "20px" }}>{t('activities.content4')}</Typography>
                            {/* <Typography align="center" style={{ fontFamily: "PlayfairDisplayBold", fontSize: "1.9rem", marginTop: "45px" }}>{t('activities.content4')}</Typography> */}
                        </Box>
                    </Container>
                </Box>
            </Hidden>
            <Box className={classes.bgImageWhiteLeft}>
                <Container maxWidth="xl" style={{ padding: "2% 6%" }}>
                    <Box>
                        <Typography variant="h2" align="center" sx={{ margin: { xs: "5px 0px", sm: "30px 0px" } }} style={{ fontFamily: "PlayfairDisplayItalic" }}>{t('activities.chooseDestination')}</Typography>
                        {/* <Box style={{ display: "flex", justifyContent: "center" }}>
                            <Hidden mdDown>
                            {
                                _.map(categories, category => {
                                    return (
                                        <Typography variant="h6" style={{ display: "inline", margin: "0px 10px" }} key={category.id}>
                                            <Box style={{ display: "flex" }}>
                                                <Link component={RouterLink} to={`/packages?category_id=${category.id}`} underline="hover">
                                                    <Box style={{ margin: "3px 10px" }}>
                                                        {category.name}
                                                    </Box>
                                                </Link>
                                            </Box>
                                        </Typography>
                                    )
                                })
                            }
                            </Hidden>
                            <Hidden mdUp>
                                <Tabs
                                    value={valueTab}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    style={{ margin: 0 }}
                                >
                                    {
                                        _.map(categories, category => {
                                            return (
                                                <Tab key={category.id} value={category.id} label={category.name} component={RouterLink} to={`/packages?category_id=${category.id}`} style={{ textTransform: 'capitalize', minHeight: 10, paddingBottom: 3, fontWeight: (valueTab === category.id ? "bold" : 'normal'), color: (valueTab === category.id ? "#183f3d" : null), borderBottom: (valueTab === category.id ? "3px solid #183f3d" : "0px solid white") }} />
                                            )
                                        })
                                    }
                                </Tabs>
                            </Hidden>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8} sx={{ marginLeft: {xs: 5, md: 0}, marginRight: {xs: 5, md: 0} }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={6}>
                                            <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('package.name')}
                                                    value={searchField.name}
                                                    name="name"
                                                    onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                        <Hidden mdUp>
                                            <Grid item xs={12} md={6}>
                                                <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                                    <FormControl fullWidth>
                                                        <InputLabel>{t('package.category')}</InputLabel>
                                                        <Select
                                                            value={searchField.category_id}
                                                            label={t('package.category')}
                                                            name="category_id"
                                                            onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                                        >
                                                            <MenuItem value={0}>{t('general.pleaseSelect') + ' ' + t('package.category')}</MenuItem>
                                                            {_.map(categoryList, resultItem => {
                                                                return <MenuItem key={resultItem.id} value={resultItem.id}>{resultItem.name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Hidden>
                                        <Grid item xs={12} md={6}>
                                            <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                                <FormControl fullWidth>
                                                    <InputLabel>{t('package.location')}</InputLabel>
                                                    <Select
                                                        value={searchField.location_id}
                                                        label={t('package.location')}
                                                        name="location_id"
                                                        onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                                    >
                                                        <MenuItem value={0}>{t('general.pleaseSelect') + ' ' + t('package.location')}</MenuItem>
                                                        {_.map(locationList, resultItem => {
                                                            return <MenuItem key={resultItem.id} value={resultItem.id}>{resultItem.name}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </Grid>
                                        <Hidden mdDown>
                                            <Grid item xs={12} md={6}>
                                                <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                                    <FormControl fullWidth>
                                                        <InputLabel>{t('package.category')}</InputLabel>
                                                        <Select
                                                            value={searchField.category_id}
                                                            label={t('package.category')}
                                                            name="category_id"
                                                            onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                                        >
                                                            <MenuItem value={0}>{t('general.pleaseSelect') + ' ' + t('package.category')}</MenuItem>
                                                            {_.map(categoryList, resultItem => {
                                                                return <MenuItem key={resultItem.id} value={resultItem.id}>{resultItem.name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Hidden>
                                        <Grid item xs={12} md={3}>
                                            <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('package.startDate')}
                                                    value={searchField.start_date}
                                                    name="start_date"
                                                    type="date"
                                                    autoComplete="false"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={handleStartDateChange}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('package.endDate')}
                                                    value={searchField.end_date}
                                                    name="end_date"
                                                    type="date"
                                                    autoComplete="false"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ marginLeft: {xs: 5, md: 0}, marginRight: {xs: 5, md: 0} }} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12}>
                                            <Box component="form" noValidate sx={{ '& > :not(style)': { width: '100%' }, }} >
                                                <Button variant="contained" fullWidth onClick={handleSearchData}>{t('general.search')}</Button>
                                            </Box>
                                        </Grid>
                                        <Hidden mdDown>
                                            <Grid item xs={12}>
                                                <Box component="form" noValidate sx={{ '& > :not(style)': { width: '100%' }, }} >
                                                    <Button variant="contained" onClick={clearSearchData}>{t('general.clear')}</Button>
                                                </Box>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box> */}
                        <Box style={{ display: "flex", justifyContent: "center" }}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={5}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: -1, width: '100%' }, }} >
                                        <Autocomplete
                                            fullWidth
                                            freeSolo
                                            id="free-solo-2-demo"
                                            disableClearable
                                            renderTags={() => null}
                                            PaperComponent={(options) => {
                                                const { containerProps, children } = options;
                                                return (
                                                    <Paper {...containerProps} style={{ padding: 15 }}>
                                                        {children}
                                                        <Button variant="outlined" color="primary" fullWidth onMouseDown={() => {
                                                            navigate("/activities?name=" + searchField.name + (searchField.start_date ? ("&start_date=" + Moment(searchField.start_date).format('YYYY-MM-DD')) : '') + (searchField.end_date ? ("&end_date=" + Moment(searchField.end_date).format('YYYY-MM-DD')) : ''))
                                                        }}>
                                                            <Typography variant="h6" color="inherit" style={{ fontFamily: "FuturaPTMedium" }}>{t('homepage.loadMore')}</Typography>
                                                        </Button>
                                                    </Paper>
                                                )
                                            }}
                                            options={Object.values(searchData)}
                                            renderOption={(props, option, { selected }) => (
                                                <Link component={RouterLink} to={`/package/${option.id}`} style={{ textDecoration: "none", color: "inherit" }} key={option.id}>
                                                    <li {...props} style={{ height: 110 }}>
                                                        <Card sx={{ display: 'flex', flexDirection: 'row', boxShadow: 0, borderRadius: 0, backgroundColor: 'transparent', width: '100%', height: 100 }}>
                                                            <CardMedia
                                                                className={classes.classMediaStyle}
                                                                sx={{ width: "50%", height: 100 }}
                                                                image={(option.images && option.images[0]) ? option.images[0].file_name : '/images/emptyStatus/no_ticket.png'}
                                                                alt={'package\'s image'}
                                                            />
                                                            <CardContent sx={{ width: "100%", padding: { xs: "0% 3%" } }}>
                                                                <Typography variant="body2" style={{ fontFamily: "FuturaPTDemi", lineHeight: "1.1", minHeight: 10 }}>
                                                                    {option.name}
                                                                </Typography>
                                                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                                    <Rating
                                                                        value={option.average_rating}
                                                                        precision={0.1}
                                                                        readOnly
                                                                        size="small"
                                                                        style={{ color: theme.palette.primary.main, fontSize: "0.5rem" }}
                                                                    />
                                                                    {
                                                                        option.average_rating > 0
                                                                            ?
                                                                            <Typography variant="caption" color="primary" style={{ padding: 3 }}>
                                                                                {(option.average_rating).toFixed(2)}
                                                                            </Typography>
                                                                            :
                                                                            null
                                                                    }
                                                                    <Typography variant="caption" color="primary">
                                                                        ({t('package.reviewsCount', { count: option.total_reviews })})
                                                                    </Typography>
                                                                </Box>
                                                                {
                                                                    option.display_price.sell_price > 0 ?
                                                                        <Typography variant="caption">
                                                                            {t('activities.from')}
                                                                            <b style={{ fontSize: "0.75rem" }}>
                                                                                {
                                                                                    " " + defaultCurrency.symbol + " "
                                                                                    + (parseFloat(option.display_price.sell_price) * defaultCurrency.rate).toFixed(2)
                                                                                    + t('activities.perPax')
                                                                                }
                                                                            </b>
                                                                        </Typography>
                                                                        : null
                                                                }
                                                            </CardContent>
                                                        </Card>
                                                    </li>
                                                </Link>
                                            )}
                                            value={searchField.name}
                                            getOptionLabel={() => searchField.name}
                                            // getOptionLabel={(option) => option.name}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    label={t('package.searchActivities')}
                                                    value={searchField.name}
                                                    name="name"
                                                    onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        onKeyDown: (e) => {
                                                            if (e.key === 'Enter') {
                                                                e.stopPropagation();
                                                            }
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                        <FormControl fullWidth>
                                            <InputLabel>{t('package.location')}</InputLabel>
                                            <Select
                                                value={searchField.location_id}
                                                label={t('package.location')}
                                                name="location_id"
                                                onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                            >
                                                <MenuItem value={0}>{t('general.pleaseSelect') + ' ' + t('package.location')}</MenuItem>
                                                {_.map(locationList, resultItem => {
                                                    return <MenuItem key={resultItem.id} value={resultItem.id}>{resultItem.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, display: "flex" }, }} >
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={t('package.checkAvailability')}
                                            value={searchField.start_date}
                                            name="start_date"
                                            onChange={handleStartDateChange}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        {/* <IconButton onClick={(event) => handleDateOpen(event)}> */}
                                                        <IconButton onClick={() => setCalanderPopup(!calanderPopup)}>
                                                            <BiCalendar size="25px" />
                                                        </IconButton>
                                                        {/* <Popper id='transition-popper' open={dateOpen} anchorEl={anchorEl} transition placement="bottom-end">
                                                            {({ TransitionProps }) => (
                                                                <Fade {...TransitionProps} timeout={350}>
                                                                    <Box sx={{ p: 1, bgcolor: 'background.paper', boxShadow: '0 0 6px 0 #0002' }}>
                                                                        <CustomizeCalendar dates={dates} setDates={setDates} stateSetting={false} singleDate={true} setPopupOpen={setDateOpen} />
                                                                    </Box>
                                                                </Fade>
                                                            )}
                                                        </Popper> */}
                                                    </InputAdornment>
                                            }}
                                        />
                                        <Dialog
                                            open={calanderPopup}
                                            onClose={() => setCalanderPopup(false)}
                                            maxWidth={'md'}
                                        >
                                            <DialogContent>
                                                <CustomizeCalendar dates={dates} setDates={setDates} stateSetting={false} singleDate={true} setPopupOpen={setCalanderPopup} />
                                            </DialogContent>
                                        </Dialog>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={1}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, display: "flex", alignItems: "center" }, }} >
                                        <Button variant="contained" color="secondary" fullWidth onClick={handleSearchData} style={{ height: { xs: "4rem", sm: "3.75rem", md: "3.5rem" }, color: { xs: "green", sm: "red" } }}>{t('general.search')}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        {
                            _.size(packages) > 0
                                ?
                                <Box style={{ marginTop: 40 }}>
                                    <PackageList packageList={packages} pagination={true} swiper={false} handlePaginationChange={handlePaginationChange} page={page} total={total} showPrice={true} showLink={true} popup={false} mobileSwiper={false} />
                                </Box>
                                :
                                loading
                                    ?
                                    <Box style={{ marginTop: 20 }}>
                                        <LinearProgress size={20} />
                                    </Box>
                                    :
                                    <Box style={{ marginTop: 20, textAlign: "center" }}>
                                        <Typography variant="h6" style={{ padding: 50 }}>{t('general.noData')}</Typography>
                                    </Box>
                        }
                    </Box>
                </Container>
            </Box>

        </>
    )
};
export default Activities;

const useStyles = makeStyles((theme) => ({
    bgImageWhiteLeft: {
        backgroundImage: `url('../images/logo-light.png')`,
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -60% top 5%',
            backgroundSize: "55%",
        },
        [theme.breakpoints.up('md')]: {
            backgroundPosition: 'left bottom -23%',
            backgroundSize: "35%",
        },
    },
    bgImageWhiteRight: {
        backgroundImage: `url('../images/logo-light.png')`,
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -90% top',
            backgroundSize: "70%",
        },
        [theme.breakpoints.up('md')]: {
            backgroundPosition: 'right -10% top 3%',
            backgroundSize: "20%",
        },
    },
    bannerBackground: {
        backgroundSize: 'cover',
        backgroundImage: `url("/images/activities/banner.jpg")`,
        backgroundPosition: 'center',
        // [theme.breakpoints.up('xs')]: {
        //     backgroundImage: `linear-gradient(to bottom, transparent 75%, rgb(255, 255, 255)), url("/images/activities/banner.jpg")`,
        //     minHeight: "200px",
        // },
        [theme.breakpoints.up('xs')]: {
            minHeight: "200px",
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: "400px",
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: "600px",
        },
    },
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important",
        padding: "0px !important",
        margin: "0px !important",
    },
}));