import * as actionTypes from '@types/userType';
// import { requestUser, requestPastBalance } from '@/services/user';

export const updateUserState = (info) => ({
    type: actionTypes.UPDATE_USER_INFO,
    payload: info
});

export const updateProfile = (info) => ({
    type: actionTypes.UPDATE_PROFILE,
    payload: info
});

export const updateAddresses = (info) => ({
    type: actionTypes.UPDATE_ADDRESSES,
    payload: info
});

export const resetProfile = () => dispatch => {
    dispatch({ type: actionTypes.RESET_PROFILE });
}