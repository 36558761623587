import { putUrl } from '@helpers/api';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';

import { Box, Button, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField, Typography } from '@mui/material';
import BackdropLoading from '@components/BackdropLoading';

const OrderItemCard = (props) => {
    const { order, refreshData, setRefreshData } = props;
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [inputError, setInputError] = useState('');
    const { defaultCurrency } = useSelector(state => state.general);

    const [cancelOrderDialog, setCancelOrderDialog] = useState({
        open: false,
        order: [],
        remark: ''
    });

    const handleCancelOrderDialogOpen = (order) => {
        setCancelOrderDialog({
            open: true,
            order: order,
            remark: ''
        });
    }
    const handleCancelOrderDialogClose = () => {
        setCancelOrderDialog({
            open: false,
            order: [],
            remark: ''
        });
    }
    const handleCancelOrderDialogSave = () => {
        setLoading(true);
        setInputError('');
        putUrl(`/order/${cancelOrderDialog.order.id}`, { remark: cancelOrderDialog.remark }).then(response => {
            setLoading(false);
            if (response.status) {
                handleCancelOrderDialogClose();
                addToast(response.message, { appearance: 'success' });
                setRefreshData(!refreshData);
            } else {
                setInputError(response.message);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    return (
        <>
            {/* ITEM CARD */}
            <Grid container className={classes.itemContainer} style={{marginTop:20}}>
                <Grid item xs={3} md={3} lg={2}>
                    <CardMedia
                        component="img"
                        image={_.size(order.order_details[0].package.images) > 0 ? order.order_details[0].package.images[0].file_name : "../images/emptyStatus/no_image.png"}
                        sx={{ flex: 1, width: "80%", maxHeight: "100px" }}
                        alt={'package\'s image'}
                        className={classes.classMediaStyle}
                    />
                </Grid>
                <Grid item xs={7} md={8} lg={9}>
                    <Box style={{ padding: "5%" }}>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            <Typography className={classes.statusStyle}>{order.status_display[i18n.language] ? order.status_display[i18n.language] : order.status_display.en}</Typography>
                            {
                                order.status === 20
                                    ?
                                    <Button variant="outlined" size="small" style={{ marginLeft: 10, borderRadius: 20 }} onClick={() => handleCancelOrderDialogOpen(order)}>
                                        {t('order.cancelOrder')}
                                    </Button>
                                    : null
                            }
                        </Box>
                        <Typography variant="h6">{t('order.orderNo') + ": " + order.order_no}</Typography>
                        <Typography>{t('order.createdAt') + ": " + order.created_at}</Typography>
                        <Typography>{t('order.remark') + ": " + (order.remark ? order.remark : '-')}</Typography>
                        <Typography>{t('order.totalAmount') + ": " + defaultCurrency.symbol + " " + (order.total_amount * defaultCurrency.rate).toFixed(2)}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={2} md={1} className={classes.viewDetailStyle}>
                    <Link component={RouterLink} to={`/order/${order.id}`} underline="none">
                        <div style={{ width: "100%", height: "100%", position: "relative" }}>
                            <div className={classes.viewDetailText}>
                                <Typography color="#fff">{t('order.viewDetails')}</Typography>
                            </div>
                        </div>
                    </Link>
                </Grid>
            </Grid>
            {/* CANCEL ORDER DIALOG */}
            <Dialog open={cancelOrderDialog.open} onClose={handleCancelOrderDialogClose}>
                <DialogTitle>{t('order.cancelOrder') + ": " + cancelOrderDialog.order.order_no}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{t('order.cancelOrderReminder')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('order.cancelRemark')}
                                value={cancelOrderDialog.remark}
                                name="remark"
                                onChange={(event) => setCancelOrderDialog({ ...cancelOrderDialog, [event.target.name]: event.target.value })}
                            />
                        </Grid>
                        {
                            inputError ?
                                <Grid item xs={12}>
                                    <Typography variant="caption" style={{ color: theme.palette.indicator.red }}>{inputError}</Typography>
                                </Grid>
                                : null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleCancelOrderDialogClose}>{t('general.no')}</Button>
                    <Button variant="contained" color="primary" onClick={handleCancelOrderDialogSave}>{t('general.yes')}</Button>
                </DialogActions>
            </Dialog>
            {/* LOADING OVERLAY */}
            <BackdropLoading loading={loading} color='#fff'  zIndex={1302} />
        </>
    )
}

export default OrderItemCard;

const useStyles = makeStyles((theme) => ({
    itemContainer: {
        border: 'none',
        borderRadius: 10,
        borderColor: theme.palette.gray.secondary,
        overflow: 'hidden',
        boxShadow: '0px 1px 6px 3px #0000001f',
    },
    classMediaStyle: {
        borderRadius: 5,
        objectFit: "cover !important",
        margin: "10%",
    },
    viewDetailStyle: {
        backgroundColor: theme.palette.button.main + ' !important',
    },
    viewDetailText: {
        position: "absolute",
        textAlign: "center",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%) rotate(-90deg)",
        whiteSpace: "nowrap",
    },
    statusStyle: {
        color: theme.palette.primary.main + ' !important',
        fontStyle: "italic"
    },
}))