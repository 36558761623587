import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Link as RouterLink } from 'react-router-dom';

import { Typography, Box, Grid, Hidden, Container, Link } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

const Mantanani = (props) => {
    // const { image } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();

    const [hovered, setHovered] = useState({
        id: 1,
    });

    const handleMouseEnter = (event) => {
        for(var $i = 0; $i < images.length; $i++){
            if (images[$i] === images[event.target.id]) {
                setHovered({ ...hovered, id: $i});
            }
        }
    }

    const images = [
        {id: 0, img: "/images/mantanani/left.jpg", link: '/'},
        // {id: 1, img: "/images/mantanani/right.jpg", link: '/'},
    ];

    const mantananiDisplay = (
        <>
        {
            _.map(images, (result, key) => {
                return (
                    <Grid item xs={12} sm={(hovered.id === result.id) ? 4 : 1.5} component={RouterLink} to={result.link} id={result.id} key={key} onMouseEnter={handleMouseEnter} className={classes.centerImg} 
                          style={{
                            backgroundImage: `url(${result.img})`,
                            textDecoration: 'none', 
                            backgroundSize: 'cover',
                            marginLeft: (hovered.id !== 0) ? 20 : 0, 
                            marginRight: (hovered.id !== 2) ? 20 : 0,
                            transition: 'all .8s',
                        }}
                    >
                        <Grid style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', width: '100%', height: '100%', visibility: (hovered.id === result.id) ? 'visible' : 'hidden' }}>
                            <Grid display="flex" justifyContent="center" style={{ paddingTop: '30px', alignItems: 'center' }}>
                                <Grid item xs={1}>
                                    <div style={{ flex: 1, height: '1.8px', backgroundColor: `${theme.palette.white.main}` }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography component="h1" variant="h3" sx={{ paddingLeft: '10px', color: `${theme.palette.white.main}` }}>{t(`mantanani.title.${result.id}`)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid display="flex" justifyContent="center" style={{ alignItems: 'center', paddingTop: '10px' }}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={10}>
                                    <Typography component="h1" variant="subtitle1" sx={{ paddingLeft: '10px', color: `${theme.palette.white.main}`, lineHeight: '1.5' }}>{t(`mantanani.content.${result.id}`)}</Typography>
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }) 
        }
        </>
    )

    const mantananiDisplayLatest = (
        <>
        {
            _.map(images, (result, key) => {
                return (
                    <Grid item xs={12} sm={4} component={RouterLink} to={result.link} id={result.id} key={key} className={classes.centerImg} 
                          style={{
                            backgroundImage: `url(${result.img})`,
                            textDecoration: 'none', 
                            backgroundSize: 'cover',
                        }}
                    >
                        <Grid style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', width: '100%', height: '100%' }}>
                            <Grid display="flex" justifyContent="center" style={{ paddingTop: '30px', alignItems: 'center' }}>
                                <Grid item xs={1}>
                                    <div style={{ flex: 1, height: '1.8px', backgroundColor: `${theme.palette.white.main}` }} />
                                </Grid>
                                <Grid item xs={11}>
                                    <Typography component="h1" variant="h3" sx={{ paddingLeft: '10px', color: `${theme.palette.white.main}` }}>{t(`mantanani.title.${result.id}`)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid display="flex" justifyContent="center" style={{ alignItems: 'center', paddingTop: '10px' }}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={10}>
                                    <Typography component="h1" variant="subtitle1" sx={{ paddingLeft: '10px', color: `${theme.palette.white.main}`, lineHeight: '1.5' }}>{t(`mantanani.content.${result.id}`)}</Typography>
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }) 
        }
        </>
    )

    return (
        <>
            <Box className={classes.background}>
                <Container maxWidth="xl">
                    <Hidden mdDown>
                        <Grid container spacing={0} paddingY={8} display="flex" justifyContent="center">
                            {mantananiDisplayLatest}
                        </Grid>
                    </Hidden>

                    {/* <Grid container spacing={0} paddingY={8} display="flex" flexDirection="row" justifyContent="center">
                        {mantananiDisplay()}
                        
                        <Hidden smDown>
                            <Grid item xs={0} sm={4}>
                                <img alt='left' align="right" src={"/images/mantanani/left.jpg"} style={{ paddingRight: '15px' }} />
                            </Grid>
                        </Hidden>
                        <Grid item xs={0} sm={4} className={classes.centerImg}>
                            <div className={classes.description}>
                                <Grid display="flex" justifyContent="center" style={{ paddingTop: '30px', alignItems: 'center' }}>
                                    <Grid item xs={1}>
                                        <div style={{ flex: 1, height: '1.8px', backgroundColor: `${theme.palette.white.main}` }} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography component="h1" variant="h3" sx={{ paddingLeft: '10px', color: `${theme.palette.white.main}` }}>{t('mantanani.title')}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid display="flex" justifyContent="center" style={{ alignItems: 'center', paddingTop: '10px' }}>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={10}>
                                        <Typography component="h1" variant="subtitle1" sx={{ paddingLeft: '10px', color: `${theme.palette.white.main}`, lineHeight: '1.5' }}>{t('mantanani.content')}</Typography>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={0} sm={4}>
                                <img alt='right' align="left" src={"/images/mantanani/right.jpg"} style={{ paddingLeft: '15px' }} />
                            </Grid>
                        </Hidden>
                    </Grid> */}
                </Container>
            </Box>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: `url("/images/mantanani/background.jpg")`,
        backgroundSize: 'cover',

        // [theme.breakpoints.up('xs')]: {
        //     backgroundImage: `url("/images/mantanani/center.jpg")`,
        //     backgroundSize: 'cover',
        // },
        // [theme.breakpoints.up('sm')]: {
        //     backgroundImage: `url("/images/mantanani/background.jpg")`,
        //     backgroundSize: 'cover',
        // },
    },

    centerImg: {
        backgroundRepeat: 'no-repeat',
        height: 500,

        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'top',
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'center',
        },
    },
}))

export default Mantanani