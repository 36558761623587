import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import Moment from 'moment';
import { LOCAL_URL } from '@configs/config';

import { Box, Container, Typography, Link, Hidden, TextField, Divider, Button, Grid, Autocomplete, Rating, Paper, Card, CardMedia, CardContent, useMediaQuery, InputAdornment, Popper, Fade, Dialog, DialogContent } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { BiSearch, BiCalendar } from 'react-icons/bi';
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";

import { getUrl } from '@helpers/api';
import PackageList from '@components/PackageList';
import Mantanani from '@components/Mantanani';
import NewsCard from '@components/NewsCard';
import ReviewCard from '@components/ReviewCard';
import CustomizeCalendar from '@components/CustomizeCalendar';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-coverflow";
// import './css/styles.css';
// import required modules
import { Pagination as SwiperPagination, Navigation, Scrollbar, EffectCoverflow, Autoplay } from "swiper";

const Home = () => {
    const theme = useTheme();
    const classes = useStyles();
    let navigate = useNavigate();
    const { defaultCurrency } = useSelector(state => state.general);
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [calanderPopup, setCalanderPopup] = useState(false);

    const packagePerPage = 4;
    const [packageList, setPackageList] = useState({});
    const [newsList, setNewsList] = useState({});
    const [gallery, setGallery] = useState({});
    const [reviews, setReviews] = useState({});
    const [state, setState] = useState({
        package_name: "",
        booking_date: "",
    });
    const [advertisement, setAdvertisement]=useState({});
    const [collections , setCollections]=useState([]);
    const [searchData, setSearchData] = useState({});
    const [dates, setDates] = useState({
        start: '',
        end: '',
    });
    const [dateOpen, setDateOpen] = useState(false);

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        setLoading(true);
        getUrl(`/homepage`).then(response => {
            setLoading(false);
            if (response.status) {
                const { package_list, news_list, gallery, reviews, advertisement, collection } = response.data;
                setPackageList(package_list);
                setTotal(_.ceil(_.size(package_list) / packagePerPage));
                setNewsList(news_list);
                setGallery(gallery);
                setReviews(reviews);
                setAdvertisement(advertisement);
                // setCollections(collection);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if((dates.start === '' && dates.end === '') || (dates.start && dates.end)){
            let postData = {
                name: state.package_name,
                start_date: dates.start?Moment(dates.start).format('YYYY-MM-DD'):'',
                end_date: dates.end?Moment(dates.end).format('YYYY-MM-DD'):'',
                limit: 5
            };
            getUrl(`/member-packages`, postData).then(response => {
                if (response.status) {
                    setSearchData(response.data.listing);
                }
            }).catch(err => {
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
        // eslint-disable-next-line
    }, [state.package_name, dates.start, dates.end]);

    const handleDateOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setDateOpen(!dateOpen);
    }
    const handleEnterPress = (event) => {
        if(event.key === 'Enter'){
            window.location.replace("/activities?name=" + state.package_name + (dates.start?("&start_date=" + Moment(dates.start).format('YYYY-MM-DD')):'') + (dates.end?("&end_date=" + Moment(dates.end).format('YYYY-MM-DD')):''));
        }
    }
    const bannerSearchBar = (display) => (
        <Box style={{ backgroundImage: display === 'xs' ? `linear-gradient(to bottom, transparent 70%, rgb(255, 255, 255) 90%), url("/images/home/banner.jpg")` : `url("/images/home/banner.jpg")`, backgroundSize: 'cover' }}>
            <Container maxWidth="xl" style={{ padding: "2% 6%" }}>
                {/* title */}
                <Box>
                    <Typography variant="h1" className={classes.title1}><Trans>{t('homepage.title1Break')}</Trans></Typography>
                </Box>
                {/* search box */}
                <Box className={classes.searchBox}>
                    <Box className={`${classes.searchBarBox} ${classes.searchTextWidth}`} width="100%">
                        <Autocomplete
                            fullWidth
                            freeSolo
                            id="free-solo-2-demo"
                            disableClearable
                            renderTags={() => null}
                            PaperComponent={(options) => {
                                const { containerProps, children } = options;

                                return (
                                    <Paper {...containerProps} style={{ padding: 15 }}>
                                        {children}
                                        <Button variant="outlined" color="primary" fullWidth onMouseDown={() => {
                                            navigate("/activities?name=" + state.package_name + (dates.start?("&start_date=" + Moment(dates.start).format('YYYY-MM-DD')):'') + (dates.end?("&end_date=" + Moment(dates.end).format('YYYY-MM-DD')):''))
                                        }}>
                                            <Typography variant="h6" color="inherit" style={{ fontFamily: "FuturaPTMedium" }}>{t('homepage.loadMore')}</Typography>
                                        </Button>
                                    </Paper>
                                )
                            }}
                            options={Object.values(searchData)}
                            renderOption={(props, option, { selected }) => (
                                <Link component={RouterLink} to={`/package/${option.id}`} style={{ textDecoration: "none", color: "inherit" }} key={option.id}>
                                    <li {...props} style={{ height: 150 }}>
                                        <Card sx={{ display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, boxShadow: 0, borderRadius: 0, backgroundColor: 'transparent', width: '100%', height: {xs: 150, sm: 130} }}>
                                            <CardMedia
                                                className={classes.classMediaStyle}
                                                sx={{ width: {xs: "100%", sm: "50%"}, height: 200 }}
                                                image={(option.images && option.images[0]) ? option.images[0].file_name : '/images/emptyStatus/no_ticket.png'}
                                                alt={'package\'s image'}
                                            />
                                            <CardContent sx={{ width: "100%", padding: {xs: "3% 0%", sm: "0% 3%"} }}>
                                                <Typography variant={matchDownSm ? "body1" : "h5"} style={{ fontFamily: "FuturaPTDemi", lineHeight: "1.1", minHeight: 40 }}>
                                                    {option.name}
                                                </Typography>
                                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                                                    <Rating
                                                        value={option.average_rating}
                                                        precision={0.1}
                                                        readOnly
                                                        size="small"
                                                        style={{ color: theme.palette.primary.main }}
                                                    />
                                                    {
                                                        option.average_rating > 0
                                                            ?
                                                            <Typography variant="body2" color="primary" style={{ padding: 3 }}>
                                                                {(option.average_rating).toFixed(2)}
                                                            </Typography>
                                                            :
                                                            null
                                                    }
                                                    <Typography variant="caption" color="primary">
                                                        ({t('package.reviewsCount', { count: option.total_reviews })})
                                                    </Typography>
                                                </Box>
                                                {
                                                    option.display_price.sell_price > 0 ?
                                                        <Typography variant="h6">
                                                            {t('activities.from')}
                                                            <b>
                                                                {
                                                                    " " + defaultCurrency.symbol + " "
                                                                    + (parseFloat(option.display_price.sell_price) * defaultCurrency.rate).toFixed(2)
                                                                    + t('activities.perPax')
                                                                }
                                                            </b>
                                                        </Typography>
                                                        : null
                                                }
                                            </CardContent>
                                            {/* <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: "100%" }}>
                                                
                                            </Box> */}
                                        </Card>
                                    </li>
                                </Link>
                            )}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        classes: { input: classes.searchBarText },
                                        startAdornment: (
                                            <InputAdornment position="start" className={classes.searchBarBox} style={{ width: "10%" }}>
                                                <BiSearch className={classes.searchBarIcon} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="standard"
                                    margin="normal"
                                    placeholder={t('homepage.searchBarPlaceHolder')}
                                    name="search"
                                    color="white"
                                    focused
                                    fullWidth
                                    value={state.package_name}
                                    onChange={(event) => setState({ ...state, package_name: event.target.value })}
                                    inputProps={{
                                        ...params.inputProps,
                                        onKeyDown: (e) => {
                                            if (e.key === 'Enter') {
                                                e.stopPropagation();
                                            }
                                        },
                                    }}
                                />
                            )}
                            onKeyPress={(event) => handleEnterPress(event)}
                        />
                    </Box>
                    <Divider orientation="vertical" flexItem variant="middle" style={{ border: `0.5px solid ${theme.palette.primary.main}` }} />
                    <Box className={classes.searchBarBox} style={{ width: "20%" }}>
                        {/* <Button onClick={(event) => handleDateOpen(event)}> */}
                        <Button onClick={() => setCalanderPopup(!calanderPopup)}>
                            {
                                dates.start && dates.end
                                    ?
                                    <Box>
                                        <Typography className={classes.searchDateText}>{Moment(dates.start).format('YY-MMM-DD')}</Typography>
                                        <Typography className={classes.searchDateText}>{Moment(dates.end).format('YY-MMM-DD')}</Typography>
                                    </Box>
                                    :
                                    <BiCalendar className={classes.searchBarIcon} />
                            }
                        </Button>
                        {/* <Popper id='transition-popper' open={dateOpen} anchorEl={anchorEl} transition placement="bottom-end">
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                    <Box sx={{ p: 1, bgcolor: 'background.paper', boxShadow: '0 0 6px 0 #0002' }}>
                                        <CustomizeCalendar dates={dates} setDates={setDates} state={state} setState={setState} singleDate={false} setPopupOpen={setDateOpen} />
                                    </Box>
                                </Fade>
                            )}
                        </Popper> */}
                        <Dialog
                            open={calanderPopup}
                            onClose={() => setCalanderPopup(false)}
                            maxWidth={'md'}
                        >
                            <DialogContent>
                                <CustomizeCalendar dates={dates} setDates={setDates} state={state} setState={setState} singleDate={false} setPopupOpen={setCalanderPopup} />
                            </DialogContent>
                        </Dialog>
                    </Box>
                    <Box className={classes.searchBarBox} style={{ width: "15%", backgroundColor: theme.palette.secondary.main }}>
                        <Link component={RouterLink} to={"/activities?name=" + state.package_name + (dates.start?("&start_date=" + Moment(dates.start).format('YYYY-MM-DD')):'') + (dates.end?("&end_date=" + Moment(dates.end).format('YYYY-MM-DD')):'')} underline='none'>
                            <Button>
                                <BiSearch className={classes.searchBarIcon} style={{ color: "#fff" }} />
                            </Button>
                        </Link>
                    </Box>
                </Box>
                <Hidden mdUp>
                    <Box>
                        {rsvp()}
                    </Box>
                </Hidden>
            </Container>
        </Box>
    )

    const rsvpDetail = (adv) => (
        <Box style={{ padding: 0, height: "100%", backgroundImage: adv?.image?.file_name ? `url(${adv.image.file_name})` : '', backgroundSize: 'cover', backgroundColor: theme.palette.primary.main }}>
            <Container maxWidth="xl" style={{ padding: 0, display: "flex" }}>
                <Box className={classes.rsvpTextBox}>
                    <Typography className={classes.rsvpText} style={{ color: adv?.text_color ? adv?.text_color : '#ffffff', fontFamily: "PlayfairDisplayRegular" }}>{adv?.title}</Typography>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "95%" }}>
                        <Typography className={classes.rsvpDateText} style={{ color: adv?.text_color ? adv?.text_color : '#ffffff' }}>{adv?.subtitle}</Typography>
                        {
                            adv?.button_text !== null &&
                            <Link href={adv?.link ? adv.link : LOCAL_URL} underline="hover" className={classes.rsvpLink}>
                                <Typography className={classes.rsvpText} style={{ color: adv?.text_color ? adv?.text_color : '#ffffff', display: "flex", alignItems: "center", margin: "3px 10px" }} >
                                    {adv?.button_text} <HiArrowNarrowRight style={{ marginLeft: 5 }} />
                                </Typography>
                            </Link>
                        }
                    </Box>
                </Box>
            </Container>
        </Box>
    )

    const rsvp = () => (
        <>
            <Swiper className="mySwiper" loop={true} autoplay={{ delay: 6000, disableOnInteraction: false }} modules={[Autoplay]} style={{ padding: 0 }}>
            {
                _.size(advertisement) > 0 ?
                    _.map(advertisement, (adv, index) => {
                        return (
                            <SwiperSlide key={index} style={{ opacity: 1, height: "auto" }}>
                                {
                                    adv?.button_text !== null 
                                    ? rsvpDetail(adv)
                                    : 
                                    <Link href={adv?.link ? adv.link : LOCAL_URL} underline="hover" style={{ border: 'none' }}>
                                        {rsvpDetail(adv)}
                                    </Link>
                                }
                            </SwiperSlide>
                        )
                    })
                : null
            }
            </Swiper>
        </>
    )

    const planList = [
        { id: 0, img: "/images/home/plan1.jpg", title: t('homepage.topPackages'), url: "/", urlText: t('homepage.browse') },
        { id: 1, img: "/images/home/plan2.jpg", title: t('homepage.golfCourses'), url: "/", urlText: t('homepage.discover') },
        { id: 2, img: "/images/home/plan3.jpg", title: t('homepage.activities'), url: "/activities", urlText: t('homepage.join') },
        { id: 3, img: "/images/home/plan4.jpg", title: t('homepage.destinations'), url: "", urlText: t('homepage.comingSoon') },
    ];
    const planningCard = (listing) => (
        <Box style={{ marginBottom: 25 }}>
            <Hidden mdDown>
                <img alt={listing.title} src={listing.img} style={{ width: '100%' }} />
                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h4" style={{ fontFamily: "PlayfairDisplayRegular" }}>{listing.title}</Typography>
                    {
                        listing.url
                            ?
                            <Link component={RouterLink} to={listing.url} underline="hover" >
                                <Box style={{ display: "flex", alignItems: "center", margin: "3px 10px" }}>
                                    <Typography variant="h5" color="primary">{listing.urlText}</Typography>
                                    <HiArrowNarrowRight size={25} style={{ color: theme.palette.primary.main, marginLeft: 5 }} />
                                </Box>
                            </Link>
                            :
                            <Box style={{ display: "flex", alignItems: "center", margin: "3px 10px" }}>
                                <Typography variant="h5" color="primary">{listing.urlText}</Typography>
                            </Box>
                    }
                </Box>
            </Hidden>
            <Hidden mdUp>
                <Link component={RouterLink} to={listing.url} underline="none" >
                    <div style={{ position: 'relative', marginRight: 5 }}>
                        <Hidden smDown>
                            <div style={{ position: 'absolute', left: 0, width: "100%", height: 180, backgroundImage: 'linear-gradient(to bottom, transparent, rgb(0, 0, 0, 0.5))', backgroundSize: 'cover' }} />
                            <img alt={listing.title} src={listing.img} width="100%" height={180} style={{ objectFit: 'cover' }} /> 
                        </Hidden>
                        <Hidden smUp>
                            <div style={{ position: 'absolute', left: 0, width: "100%", height: 110, backgroundImage: 'linear-gradient(to bottom, transparent, rgb(0, 0, 0, 0.5))', backgroundSize: 'cover' }} />
                            <img alt={listing.title} src={listing.img} width="100%" height={110} style={{ objectFit: 'cover' }} /> 
                        </Hidden>
                        <Typography variant="h5" style={{ position: 'absolute', bottom: 10, left: 10, fontFamily: "PlayfairDisplayRegular", color: '#fff' }}>{listing.title}</Typography>
                    </div>
                </Link>
            </Hidden>
        </Box>
    )
    const startPlanning = () => (
        <Box className={ matchDownMd ? '' : classes.bgImageWhiteLeft }>
            <Container maxWidth="xl" style={{ padding: "2% 6%" }}>
                <Grid container className={classes.boxMT}>
                    <Grid item xs={12} md={3}>
                        <Hidden mdDown>
                            <Typography variant="h2" className={classes.titleStyle}><Trans>{t('homepage.startPlanningBreak')}</Trans></Typography>
                        </Hidden>
                        <Hidden mdUp>
                            <Typography variant="h2" className={classes.titleStyle}>{t('homepage.startPlanning')}</Typography>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container spacing={3}>
                            <Hidden mdDown>
                                <Grid item xs={12} sm={6} className={classes.planMT}>
                                    {
                                        _.map(_.slice(planList, 0, 2), (listing, key) => {
                                            return <Box key={key}>{planningCard(listing)}</Box>;
                                        })
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {
                                        _.map(_.slice(planList, 2, 4), (listing, key) => {
                                            return <Box key={key}>{planningCard(listing)}</Box>;
                                        })
                                    }
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Grid item xs={12} className={classes.planMT} style={{ display: 'flex' }}>
                                    {
                                        _.map(planList, (listing, key) => {
                                            return <Box key={key} style={{ width: "25%" }}>{planningCard(listing)}</Box>;
                                        })
                                    }
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )

    const handlePaginationChange = (event, value) => {
        setPage(value);
    }
    const packagePagination = () => (
        <Container maxWidth="xl" style={{ padding: "2% 6%" }}>
            <Box className={classes.boxMT} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box>
                    <Typography sx={{ display: {xs: 'inline', md: 'block'} }} variant="h2" className={classes.titleStyle} style={{ fontFamily: "PlayfairDisplayItalic" }}>{t('homepage.jumpstart')} </Typography>
                    <Typography sx={{ display: {xs: 'inline', md: 'block'} }} variant="h2" className={classes.titleStyle}>{t('homepage.yourDaytrip')}</Typography>
                </Box>
                <Box style={{ display: "flex", justifyContent: "end" }}>
                    <Link component={RouterLink} to="/activities" underline="hover">
                        <Box style={{ display: "flex", alignItems: "center", margin: "3px 5px" }}>
                            <Typography variant="h5" className={classes.seeAll}>{t('package.loadMorePackage')}</Typography>
                            <HiArrowNarrowRight size={15} />
                        </Box>
                    </Link>
                </Box>
            </Box>
            <Box className={classes.packageList}>
                <PackageList packageList={packageList} pagination={false} swiper={true} showPrice={true} showLink={true} popup={true} />
            </Box>
        </Container>
    )
    
    const collectionList = (listing) => (
        _.map(listing, item => {
            if ((item.start_date === null && item.end_date === null) || (Moment(Moment(new Date(), 'YYYY-MM-DD')).isSameOrAfter(Moment(item.start_date).format('YYYY-MM-DD')) && Moment(Moment(new Date(), 'YYYY-MM-DD')).isSameOrBefore(Moment(item.end_date).format('YYYY-MM-DD')))) {
                return (
                    <Container maxWidth="xl" style={{ padding: "2% 6%" }} key={item.id}>
                        <Box className={classes.boxMT} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Box>
                                <Typography sx={{ display: { xs: 'inline', md: 'block' } }} variant="h2" className={classes.titleStyle} style={{ fontFamily: "PlayfairDisplayItalic" }}>{i18n.language === 'cn' ? item.display_name['cn'] : item.display_name['en']}</Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "end" }}>
                                <Link component={RouterLink} to="/activities" underline="hover">
                                    <Box style={{ display: "flex", alignItems: "center", margin: "3px 5px" }}>
                                        <Typography variant="h5" className={classes.seeAll}>{t('package.loadMorePackage')}</Typography>
                                        <HiArrowNarrowRight size={15} />
                                    </Box>
                                </Link>
                            </Box>
                        </Box>
                        <Box className={classes.packageList}>
                            <PackageList packageList={packageList} pagination={false} swiper={true} showPrice={true} showLink={true} popup={true} />
                        </Box>
                    </Container>
                )
            } else {
                return null;
            }
        })
    )

    const seeOurResorts = () => (
        <Box className={classes.bgImageWhiteRight}>
            <Container maxWidth="xl" style={{ padding: "2% 6%" }} >
                <Grid container spacing={2} className={classes.boxMT}>
                    <Grid item xs={6} sm={6}>
                        <img alt="resorts image" src="/images/home/resorts1.jpg" style={{ width: '87%' }} />
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ display: "flex", alignItems: 'center' }} >
                        <Box style={{ marginBottom: 30 }}>
                            <Typography variant="h2" className={classes.titleStyle} style={{ fontFamily: "PlayfairDisplayItalic" }}>{t('homepage.sleep')}</Typography>
                            <Typography variant="h2" className={classes.titleStyle}>{t('homepage.onTheSea')}</Typography>
                            <Box className={classes.resortML}>
                                <Typography variant="h5" sx={{ fontSize: {xs: 10, sm: 15, md: 25 }, fontFamily: {xs: "FuturaPTBook", md: "FuturaPTMedium"} }}>{t('homepage.resortsText')}</Typography>
                                <Link component={RouterLink} to="/our-resorts" underline="none">
                                    <Button variant="contained" color="secondary" className={classes.resortButton}>{t('homepage.seeOurResorts')}</Button>
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )

    const newsSwiper = () => {
        if (_.size(newsList) > 0) {
            return (
                <Container maxWidth="xl" style={{ padding: "2% 6%" }}>
                    <Box className={classes.boxMT} style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <Box>
                            <Typography variant="h2" className={classes.titleStyle} style={{ fontFamily: "PlayfairDisplayItalic" }}>{t('news.title1')} </Typography>
                            <Typography variant="h2" className={classes.titleStyle}>{t('news.title2')}</Typography>
                        </Box>
                        <Link component={RouterLink} to="/news" underline="hover">
                            <Box style={{ display: "flex", alignItems: "center", margin: "3px 10px" }}>
                                <Typography variant="h5" className={classes.seeAll}>{t('news.seeAll')}</Typography>
                                <HiArrowNarrowRight size={15} />
                            </Box>
                        </Link>
                    </Box>
                    <Swiper
                        className={classes.swiperSpacing}
                        spaceBetween={20}
                        slidesPerGroup={1}
                        loop={false}
                        navigation
                        modules={[Navigation]}
                        breakpoints={{
                            0: {
                                slidesPerView: 2.5,
                            },
                            900: {
                                slidesPerView: 3.5,
                            },
                        }}
                    >
                        {
                            _.map(newsList, news => {
                                return (
                                    <SwiperSlide style={{ lineHeight: 0 }} key={news.id}>
                                        <NewsCard news={news} readMore={false} newsDetails={false} />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </Container>
            )
        }
    }

    const gallerySwiper = () => {
        if (_.size(gallery) > 0) {
            return (
                <Container maxWidth="xl" style={{ padding: "2%" }}>
                    <Box className={classes.boxMT} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0% 0% 0% 6%" }}>
                        <Box>
                            <Typography variant="h2" className={classes.titleStyle} style={{ fontFamily: "PlayfairDisplayItalic" }}>{t('homepage.galleryTitle')}</Typography>
                            <Typography variant="h5">{t('homepage.gallerySubtitle')}</Typography>
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            {/* <HiArrowNarrowLeft size={15} color={theme.palette.primary.main} /> */}
                            <Typography variant="h5" className={classes.slideToSeeMore}>{t('homepage.slideToSeeMore')}</Typography>
                            <HiArrowNarrowRight size={15} color={theme.palette.primary.main} />
                        </Box>
                    </Box>
                    {/* <Box className={classes.boxMT} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        <HiArrowNarrowLeft size={25} color={theme.palette.primary.main} />
                        <Typography variant="h5" className={classes.slideToSeeMore}>{t('homepage.slideToSeeMore')}</Typography>
                        <HiArrowNarrowRight size={25} color={theme.palette.primary.main} />
                    </Box> */}
                    <Swiper
                        className={classes.swiperSpacing}
                        spaceBetween={10}
                        slidesPerGroup={1}
                        loop={false}
                        scrollbar={{
                            hide: true,
                        }}
                        modules={[Scrollbar]}
                        breakpoints={{
                            300: {
                                slidesPerView: 3,
                            },
                            600: {
                                slidesPerView: 4,
                            },
                            900: {
                                slidesPerView: 5,
                            },
                        }}
                    >
                        {
                            _.map(gallery, (image, key) => {
                                // let randomMargin = _.random(1, 10);

                                return (
                                    <SwiperSlide style={{ lineHeight: 0, display: "flex", alignSelf: "center" }} key={image.id}>
                                        <Box className={classes.galleryImage} sx={{ width: '100%' }} style={{ 
                                            display: "flex", 
                                            alignSelf: "center",
                                            backgroundImage: `url(${image.file_name})`,
                                            aspectRatio: '1/1'
                                        }} />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </Container>
            )
        }
    }

    const reviewsSwiper = () => {
        if (_.size(reviews) > 0) {
            return (
                <Container maxWidth="xl" style={{ padding: "2% 6%" }}>
                    <Box className={classes.boxMT} style={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                        <Box>
                            <Typography variant="h2" className={classes.titleStyle} style={{ fontFamily: "PlayfairDisplayItalic" }}>{t('homepage.review')}</Typography>
                            <Typography variant="h5">{t('homepage.reviewCaption')}</Typography>
                        </Box>
                    </Box>
                    <Swiper
                        className={classes.reviewSwiperSpacing}
                        spaceBetween={20}
                        slidesPerGroup={1}
                        loop={false}
                        pagination
                        modules={[EffectCoverflow, SwiperPagination]}
                        effect={"coverflow"}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 20,
                            modifier: 1,
                            slideShadows: false,
                            scale: 0.8
                        }}
                        breakpoints={{
                            300: {
                                slidesPerView: 3,
                            },
                            600: {
                                slidesPerView: 3,
                            },
                            900: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {
                            _.map(reviews, review => {
                                return (
                                    <SwiperSlide style={{ lineHeight: 0 }} key={review.id}>
                                        <ReviewCard review={review} detail={false} />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </Container>
            )
        }
    }

    return (
        <>
            <Hidden mdUp>
                {bannerSearchBar('xs')}
            </Hidden>
            <Hidden mdDown>
                {bannerSearchBar('sm')}
                {rsvp()}
            </Hidden>
            {startPlanning()}
            { _.size(packageList) > 0 && packagePagination()}
            { _.size(collections) > 0 && collectionList(collections)}
            <Mantanani />
            {seeOurResorts()}
            {newsSwiper()}
            {gallerySwiper()}
            {reviewsSwiper()}
        </>
    )
};
export default Home;

const useStyles = makeStyles((theme) => ({
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important",
        padding: "0px !important",
        margin: "0px !important",
    },
    bgImageWhiteLeft: {
        backgroundImage: `url('../images/logo-light.png')`,
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'left -20% top 50%',
            backgroundSize: "40%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'left -2% top 50%',
            backgroundSize: "30%",
        },
    },
    bgImageWhiteRight: {
        backgroundImage: `url('../images/logo-light.png')`,
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -50% top -5%', 
            backgroundSize: "80%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right 5% top 5%',
            backgroundSize: "30%",
        },
    },
    titleStyle: {
        lineHeight: "1 !important",
        [theme.breakpoints.up('xs')]: {
            fontSize: "2.0rem !important",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "4.5rem !important",
        },
    },
    boxMT: {
        [theme.breakpoints.up('xs')]: {
            marginTop: "0px !important",
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: "30px !important",
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "40px !important",
        },
    },
    swiperSpacing: {
        [theme.breakpoints.up('xs')]: {
            padding: '35px 10px 20px 10px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '60px 10px 50px 10px',
        },
    },
    reviewSwiperSpacing: {
        [theme.breakpoints.up('xs')]: {
            padding: '50px 0px 50px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '50px 0px 50px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '60px 20px 50px',
        },
    },
    // bannerSearchBar
    title1: {
        color: "#fff !important",
        lineHeight: "0.9 !important",
        [theme.breakpoints.up('xs')]: {
            marginTop: "55px !important",
            fontSize: "2.9rem !important",
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: "115px !important",
            fontSize: "3.0rem !important",
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "190px !important",
            fontSize: "3.4rem !important",
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: "175px !important",
            fontSize: "5.4rem !important",
        },
    },
    searchBox: {
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "center",
        width: "99%",
        [theme.breakpoints.up('xs')]: {
            height: "37px",
            margin: "10px 0px 20px !important",
        },
        [theme.breakpoints.up('sm')]: {
            height: "47px",
            margin: "20px 0px 30px !important",
        },
        [theme.breakpoints.up('md')]: {
            height: "57px",
            margin: "35px 5px 60px !important",
        },
        [theme.breakpoints.up('lg')]: {
            height: "67px",
            margin: "35px 5px 60px !important",
        },
    },
    searchBarBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    searchBarIcon: {
        color: theme.palette.primary.main,
        [theme.breakpoints.up('xs')]: {
            fontSize: "15px !important",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "30px !important",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "40px !important",
        },
    },
    searchBarText: {
        color: theme.palette.secondary.main + ' !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: "0.75rem !important"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.65rem !important"
        },
    },
    searchDateText: {
        color: theme.palette.secondary.main + ' !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: "0.75rem !important"
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.1rem !important"
        },
    },
    searchTextWidth:{
        [theme.breakpoints.up('xs')]: {
            width: "70%",
        },
        [theme.breakpoints.up('md')]: {
            width: "55%",
        },
    },
    // rsvp
    rsvpTextBox: {
        [theme.breakpoints.up('xs')]: {
            width: "80% !important",
            padding: "3% 0% 3% 3% !important",
        },
        [theme.breakpoints.up('sm')]: {
            width: "60% !important",
            padding: "3% 0% 3% 6% !important",
        },
        [theme.breakpoints.up('md')]: {
            width: "60% !important",
            padding: "3% 0% 3% 6% !important",
        },
    },
    rsvpImageBox: {
        [theme.breakpoints.up('xs')]: {
            width: "0% !important",
        },
        [theme.breakpoints.up('sm')]: {
            width: "45% !important",
        },
        [theme.breakpoints.up('md')]: {
            width: "40% !important",
        },
        boxShadow: "14px 0 20px 0 " + theme.palette.primary.main + " inset",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    },
    rsvpText: {
        [theme.breakpoints.up('sm')]: {
            fontSize: "1rem !important",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.5rem !important",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "2rem !important",
        },
    },
    rsvpDateText: {
        [theme.breakpoints.up('sm')]: {
            fontSize: "0.8rem !important",
        },
        [theme.breakpoints.up('md')]: {
            fontSize: "1.2rem !important",
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: "1.6rem !important",
        },
    },
    rsvpLink: {
        "&:hover": {
            borderColor: "#fff !important"
        }
    },
    // startPlanning
    planMT: {
        [theme.breakpoints.up('xs')]: {
            marginTop: "25px !important"
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: "40px !important"
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "175px !important"
        },
    },
    // packages
    packageList: {
        [theme.breakpoints.up('xs')]: {
            marginTop: "5px !important",
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "20px !important",
        },
    },
    // ourResorts
    resortButton: {
        fontFamily: "PlayfairDisplayRegular !important",
        textTransform: "none !important",
        [theme.breakpoints.up('xs')]: {
            marginTop: "20px !important",
            fontSize: "0.75rem !important",
            padding: "6px 16px !important",
        },
        [theme.breakpoints.up('md')]: {
            marginTop: "40px !important",
            fontSize: "1.5rem !important",
            padding: "6px 41px !important",
        },
    },
    resortML: {
        marginTop: "20px !important",
    },
    // news
    seeAll: {
        color: theme.palette.primary.main + " !important",
        marginRight: "15px !important",
    },
    // gallery
    slideToSeeMore: {
        color: theme.palette.primary.main + " !important",
        marginRight: "5px !important",
        marginLeft: "5px !important",
    },
    galleryImage: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
}));