import React from 'react';
import { Typography, Link } from '@mui/material';
import { DEFAULT_EMAIL } from '@configs/config';

const ReturnRefundPolicy = () => {
    return (
        <div style={{ textAlign: 'justify' }}>
            <Typography style={{ textDecoration: "underline", fontFamily: 'FuturaPTBold' }}>Return & Refund Policy</Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            This Return & Refund Policy applies to all purchases made on our platform, including resort bookings, travel packages, and related services. We understand that travel plans can change, and we strive to provide our customers with a fair and flexible refund policy.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            Your purchase does not automatically guarantee a reservation. Your purchase will be confirmed via email within 1 to 2 business days. The policy will take effect once you submit your reservation. We recommend each customer read and review the policy and consider purchasing travel insurance.
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Cancellation Policy</Typography>
            <Typography style={{ lineHeight: 1.5 }}>
            The cancellation policy applies for all reservations unless the tour has specific rates or fees. Please carefully review all the policies applicable to your tour before booking. Please know that ReLuxe will always adhere to individual tour policies when applicable. Please note that cancellation fees are calculated based on the date we receive the Cancellation/Refund Form.
            </Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
                <b>**Single-Day Tours/Activities</b>: Please note that once you have made a booking for a Single Day Tour/Activity, cancellations are not permitted, and refunds will not be issued. We kindly ask that you carefully consider your decision before making a reservation. However, in the event of tour/activity cancellation due to bad weather conditions, natural disaster, or cancellation by ReLuxe, a full refund of the payment made will be issued, except for credit card charges and currency differences.
            </Typography>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Conditions Of The Travel</Typography>
            <br />
            <ol style={{ listStyleType: 'disc', paddingLeft: 20, lineHeight: 1.5 }}>
                <li>You will have to strictly follow the travel program. There shall be no refund if you fail to join the group at the commencement of the tour, join the group later, or leave the group before the tour ends. It shall be noted that for all purposes, it shall be your responsibility to reach the place of commencement of the tour and register with the representative of ReLuxe at the appointed place, date, and time.</li>
                <li>In the case where you along with his family are compelled to discontinue the tour due to any reason whatsoever including illness, death, or loss of any travel documents, no claim shall be entertained for a refund of unutilized services. Even if you are unable to reach the place of commencement of the tour due to any reason whatsoever including loss of baggage or loss of travel documents, your booking shall be treated as a "no show" on the tour and 100% cancellation charges will be levied. </li>
                <li>If you avail of pre-tour services or part thereof, or the air tickets (if booked under any offer) but fail to join the group for the main tour at the appointed place, or cancel the tour after using the air tickets or pre-tour arrangements or part thereof, it shall be treated as "no show" and there will be no refund whatsoever for the unutilized pre-tour or main tour services. </li>
                <li>ReLuxe reserves the right to withdraw the tour membership if any of your behavior is deemed likely to affect the smooth operation of the tour or adversely affect the enjoyment or safety of other passengers and ReLuxe shall be under no liability to any such person. </li>
                <li>The immunities provided under this contract shall be available to ReLuxe employees, directors, and managers, including, Employees and Agents but not to the Independent Contractors selected by ReLuxe. </li>
                <li>Each of these conditions shall be severable from the other and if any provision is invalid, illegal, or unenforceable, the remaining provisions shall nevertheless have full force and effect. No liability on the part of ReLuxe arising in any way out of the Contract in respect of any tour, holiday, or excursion facilities shall exceed the total amount paid or agreed to be paid for the tour holiday, and shall in no case include any consequential, incidental loss or additional expense whatsoever. </li>
                <li>It is a condition of booking that you take out travel insurance - You may procure travel insurance (optional) at your own cost. </li>
                <li>The prices quoted in our website/brochure have been calculated at the rate prevailing at the time of publishing/printing. ReLuxe reserves the right to amend the prices published in this brochure in case of fuel costs, special/high season charges levied by the suppliers, or a hike in the airline/rail charges before the date of departure and to surcharge accordingly. You must pay for all such increases in price in full before departure. </li>
                <li>If no suit/action is brought against ReLuxe within 7 days of the last day of the tour, ReLuxe shall be discharged from all liabilities under/or arising out of this contract and You shall be deemed to have relinquished/abandoned all his rights under or arising from this contract. </li>
                <li>In case of publication of any travel scheme offering any discount or benefit by ReLuxe, we shall have the sole right to withdraw such a scheme or discount at any time unless a specific assurance of the contrary is published. </li>
            </ol>

            <br />
            <Typography style={{ fontFamily: 'FuturaPTBold' }}>Steps for Cancellation/Refund:</Typography>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            To cancel or request a refund for your booking, please follow these steps:
            </Typography>
            <br />
            <ol style={{ listStyleType: 'numeric', paddingLeft: 20, lineHeight: 1.5 }}>
                <li>Send an email to ReLuxe customer service at <Link href={`mailto:${DEFAULT_EMAIL}`}>{DEFAULT_EMAIL}</Link> and include your booking details.</li>
                <li>Our customer service team will respond to your email with a Cancellation/Refund form. Please complete the form with your booking details, the reason for cancellation/refund, and payment information, and send it back to us via email. </li>
                <li>After receiving your completed form, our customer service team will process your request and provide you with an update on the status of your cancellation/refund. You will be notified whether your request has been approved or denied, and if approved when you can expect to receive your refund.</li>
                <li>If your request is approved, you will receive your refund via the same payment method used to make the original booking. Please note that the timeframe for receiving the refund may vary depending on the payment method and processing times. </li>
            </ol>
            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            Please keep in mind that Return & Refund Policy may differ depending on the type of booking and the terms and conditions agreed upon at the time of booking. We recommend reviewing our Return & Refund Policy before making a booking with ReLuxe to avoid any confusion or unexpected fees.
            </Typography>

            <br />
            <Typography style={{ lineHeight: 1.5 }}>
            We will process your cancellation/refund request within 1 to 7 business days. We will NOT acknowledge any verbal / over-the-phone request or voicemail. Verbal speaking with our customer service representatives without email will not guarantee that your cancellation has been processed or acknowledged. All cancellation/refund requests on email <Link href={`mailto:${DEFAULT_EMAIL}`}>{DEFAULT_EMAIL}</Link> are deemed final.
            </Typography>
        </div>
    )
}

export default ReturnRefundPolicy;