import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Link as RouterLink } from 'react-router-dom';

import StarIcon from '@mui/icons-material/Star';
import { Avatar, Box, Tab, Tabs, Button, Container, Divider, Grid, LinearProgress, Rating, TextField, InputAdornment, Typography, Link, ImageList, ImageListItem, Hidden } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { BsStarFill, BsStarHalf, BsStar } from "react-icons/bs";
import { IoBusiness } from "react-icons/io5";
import { FiClock, FiMinus, FiPlus } from "react-icons/fi";
import { FiChevronRight, FiShare2 } from "react-icons/fi";
import { MdFavoriteBorder, MdWifi, MdBeachAccess, MdPool, MdWineBar, MdArrowDropDown } from 'react-icons/md';

import { updateCartTotal } from '@actions/generalActions';
import BackToPrevious from '@components/BackToPrevious';
import PackageList from '@components/PackageList';
import { getUrl, postUrl } from '@helpers/api';

import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

import PropTypes from 'prop-types';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { 
    Calendar, CalendarDefaultTheme, CalendarControls, CalendarPrevButton,
    CalendarNextButton, CalendarMonths, CalendarMonth, CalendarMonthName, CalendarWeek,
    CalendarDays
} from '@uselessdev/datepicker';

const Detail = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const { uuid, accessToken } = useSelector(state => state.general);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [value, setValue] = useState(0);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [packages, setPackages] = useState([]);
    const [data, setData] = useState(null);
    const [visible, setVisible] = useState(false);
    const [state, setState] = useState({
        quantity: 1,
    });
    const [dates, setDates] = useState({
        start: new Date("2022-11-13"),
        end: new Date("2022-11-20"),
    });

    const MONTHS = 2;
    const perPage = 4;

    useEffect(() => {
        setLoading(true);
        getUrl("/package-pagination", { page: page }).then(response => {
            setLoading(false);
            if (response.status) {
                setPackages(response.data.listing.data);
                setTotal(response.data.listing.last_page);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [page]);

    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }
      
    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };
      
    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function srcset(image, size, rows = 1, cols = 1) {
        return {
          src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
          srcSet: `${image}?w=${size * cols}&h=${
            size * rows
          }&fit=crop&auto=format&dpr=2 2x`,
        };
    };

    const handleQuantityChange = (type, quantity) => {
        let newQuantity = parseInt(quantity);
        if (type === 'quantity') {
            setState({ ...state, quantity: newQuantity });
        } else if (type === 'add') {
            setState({ ...state, quantity: state.quantity + newQuantity });
        } else if (type === 'deduct') {
            setState({ ...state, quantity: (state.quantity - newQuantity) > 0 ? (state.quantity - newQuantity) : 1 });
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlePaginationChange = (event, value) => {
        setPage(value);
    };

    var prevDate = dates;

    const handleSelectDate = (dates) => {
        console.log("prevStart => " + prevDate.start);
        console.log("prevEnd => " + prevDate.end);

        console.log("currStart => " + dates.start);
        console.log("currEnd => " + dates.end);
        
        if ((dates.start === prevDate.start) && (prevDate.end !== '')) {
            dates.start = dates.end;
            dates.end = '';
            console.log('miaow function');
        } 
        
        if (prevDate.start !== '' && prevDate.end === '') {
            dates.end = (dates.start === prevDate.start ? dates.end : dates.start);
            dates.start = prevDate.start;
            console.log('nani function');
        }

        if (new Date(dates.end).getTime() < new Date(dates.start).getTime()) {
            dates.start = dates.end;
            dates.end = '';
            console.log('restart function');
        }

        setDates(dates);
        prevDate = dates;

        console.log("setStart => " + dates.start);
        console.log("setEnd => " + dates.end);
        console.log("---------------------------------------------");
    };
    const handleClearDate = () => setDates({
        start: '',
        end: '',
    });

    const calculateNumOfNights = (dates) => {
        var timeDiff = 0;
        var numberOfNights = 0;

        if (dates.start !== '' && dates.end !== '') {
            timeDiff = Math.abs(dates.end.getTime() - dates.start.getTime());
            numberOfNights = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
        }

        return numberOfNights;
    };

    const toggleItem = (packageItem = null) => {
        setData(packageItem);
        setVisible(packageItem !== null);
    };

    const monthsList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const daysList = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const itemData = [
        {
            img: '../images/resort/resort-1.jpg',
            title: 'Resort 1',
        },
        {
            img: '../images/resort/resort-3.jpg',
            title: 'Resort 2',
            cols: 2,
        },
        {
            img: '../images/resort/resort-2.jpg',
            title: 'Resort 3',
            cols: 2,
        },
        {
            img: '../images/resort/resort-6.jpg',
            title: 'Resort 4',
        },
    ];

    const tabItemData = [
        {
            image: '../images/resort/resort-8.jpg',
            title: 'Waterfront Enjoyment',
            subtitle: 'Standard Rooms',
            descr: 'Wake up to ocean views. Enjoy the breathtaking sunset while strolling at the beach. Cool off in the private pool while overlooking the ocean.',
            lists: {
                0: 'Direct and private beach access',
                1: '30 sqm of indoor living space',
                2: 'Complimentary high-speed WiFi',
                3: 'Private pool',
            },
            price: 'From MYR 200/pax',
            button: 'VIEW ALL ROOMS',
        },
        {
            image: '../images/resort/resort-6.jpg',
            title: 'Waterfront Enjoyment',
            subtitle: 'Standard Suites',
            descr: 'Wake up to ocean views. Enjoy the breathtaking sunset while strolling at the beach. Cool off in the private pool while overlooking the ocean.',
            lists: {
                0: 'Direct and private beach access',
                1: '30 sqm of indoor living space',
                2: 'Complimentary high-speed WiFi',
                3: 'Private pool',
            },
            price: 'From MYR 280/pax',
            button: 'VIEW ALL SUITES',
        },
        {
            image: '../images/resort/resort-3.jpg',
            title: 'Waterfront Enjoyment',
            subtitle: 'Standard Villas',
            descr: 'Wake up to ocean views. Enjoy the breathtaking sunset while strolling at the beach. Cool off in the private pool while overlooking the ocean.',
            lists: {
                0: 'Direct and private beach access',
                1: '30 sqm of indoor living space',
                2: 'Complimentary high-speed WiFi',
                3: 'Private pool',
            },
            price: 'From MYR 310/pax',
            button: 'VIEW ALL VILLAS',
        }
    ];

    return (
        <>
            <Container maxWidth="xl" style={{ padding: "2% 10%" }}>
                <Box className={classes.paper}>
                    {/* Title Resort */}
                    <Grid container style={{ paddingBottom: 10 }}>
                        <Grid item xs={12} md={9}>
                            <Typography variant="h3">ReLuxe De Mantanani Resort</Typography>
                        </Grid>
                        <Grid item xs={12} md={3} style={{ display: 'flex', alignItems: 'end' }}>
                            <Button style={{ alignItems: 'center', paddingRight: 10 }}>
                                <FiShare2 />
                                <Typography variant="h6" style={{color: theme.palette.primary.main, paddingLeft: 5}}>Share</Typography>
                            </Button>
                            <Button style={{ alignItems: 'center', paddingRight: 10 }}>
                                <MdFavoriteBorder />
                                <Typography variant="h6" style={{color: theme.palette.primary.main, paddingLeft: 5}}>Save</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container style={{ display: 'flex', alignItems: 'center', paddingBottom: 10 }}>
                        <Grid item xs={12} md={3.5}>
                            <Typography variant="body1" style={{paddingRight: 10}}>Your Maldives in Borneo experience.</Typography>
                        </Grid>
                        <Grid item xs={12} md={8} style={{ display: 'flex', alignItems: 'center', color: theme.palette.primary.main }}>
                            <BsStarFill />
                            <BsStarFill />
                            <BsStarFill />
                            <BsStarHalf />
                            <BsStar />
                            <Typography variant="body1" style={{paddingLeft: 5, color: theme.palette.primary.main}}>3.5 (226 reviews)</Typography>
                        </Grid>
                    </Grid>

                    {/* Display Images */}
                    <div>
                        <ImageList variant="quilted" cols={3} rowHeight={250} gap={10}>
                            {itemData.map((item) => (
                                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                                    <img
                                        {...srcset(item.img, 250, item.rows, item.cols)}
                                        alt={item.title}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </div>

                    {/* Highlight */}
                    <div style={{ display: 'flex', alignItems: 'left', paddingBottom: 30, paddingTop: 20 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={2.4}>
                                <Typography style={{ color: theme.palette.primary.main }}>Must-See</Typography>
                            </Grid>
                            <Grid item xs={2.4}>
                                <Typography style={{ color: theme.palette.primary.main }}>Water Adventure</Typography>
                            </Grid>
                            <Grid item xs={2.4}>
                                <Typography style={{ color: theme.palette.primary.main }}>Tropical Tours</Typography>
                            </Grid>
                            <Grid item xs={2.4}>
                                <Typography style={{ color: theme.palette.primary.main }}>Outdoor Sports</Typography>
                            </Grid>
                            <Grid item xs={2.4}>
                                <Typography style={{ color: theme.palette.primary.main }}>Fun & Culture</Typography>
                            </Grid>
                        </Grid>
                    </div>

                    {/* Description */}
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <Typography style={{ fontFamily: "PlayfairDisplayBold" }} className={classes.titleText}>ReLuxe De Mantanami Resort</Typography>
                                <Typography style={{ fontFamily: "FuturaPTDemi" }} className={classes.contentText}>4 guests • 2 bedrooms • 2 beds • 2 baths</Typography>
                                
                                <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                                
                                <Typography variant="h6"><u style={{fontFamily: "FuturaPTDemi"}}>Quick Insight</u></Typography>
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                    <img src={'../images/logo.png'} width={20} />
                                    <Typography variant="body1" style={{paddingLeft: 10, fontFamily: "FuturaPTDemi"}}>A private island with beach pool villas</Typography>
                                </div>
                                <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                    <img src={'../images/logo.png'} width={20} />
                                    <Typography variant="body1" style={{paddingLeft: 10, fontFamily: "FuturaPTDemi"}}>Reached by all-inclusive boat transfer from Kota Belud, Sabah</Typography>
                                </div>
                                <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                    <img src={'../images/logo.png'} width={20} />
                                    <Typography variant="body1" style={{paddingLeft: 10, fontFamily: "FuturaPTDemi"}}>World-class diving, sailing, seawalking, and water sports</Typography>
                                </div>
                                <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                    <img src={'../images/logo.png'} width={20} />
                                    <Typography variant="body1" style={{paddingLeft: 10, fontFamily: "FuturaPTDemi"}}>Dolphin and dugong watching</Typography>
                                </div>
                                <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                    <img src={'../images/logo.png'} width={20} />
                                    <Typography variant="body1" style={{paddingLeft: 10, fontFamily: "FuturaPTDemi"}}>Island hopping</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box style={{ border: "1px solid" }}>
                                    <Box style={{ padding: "10px 30px" }}>
                                        <Typography variant="h6">{t('package.bookingDetails')}</Typography>
                                    </Box>
                                    <Divider style={{ borderColor: theme.palette.secondary.main }} />
                                    <Box style={{ padding: "20px 30px" }}>
                                        {
                                            _.map([
                                                { title: (t('package.checkIn') + ":"), content: dates.start !== '' ? (daysList[dates.start.getDay()] + " " + dates.start.getDate() + " " + monthsList[dates.start.getMonth()] + " " + dates.start.getFullYear()) : '-' },
                                                { title: (t('package.checkOut') + ":"), content: dates.end !== '' ? (daysList[dates.end.getDay()] + " " + dates.end.getDate() + " " + monthsList[dates.end.getMonth()] + " " + dates.end.getFullYear()) : '-' },
                                                { title: (t('package.nights') + ":"), content: (calculateNumOfNights(dates) + " Nights") },
                                                { title: (t('package.for') + ":"), content: (state.quantity + " Adults, 1 Room") },
                                            ], (info, key) => (
                                                <Box style={{ display: "block", paddingTop: key === 0 ? 0 : 20 }} key={key}>
                                                    <Typography variant="body" style={{ fontFamily: "FuturaPTMedium", textTransform: 'uppercase', display: "block" }}>{info.title}</Typography>
                                                    <Typography variant="h6">{info.content}</Typography>
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                    <Divider style={{ borderColor: theme.palette.secondary.main }} />
                                    <Box style={{ padding: "25px 30px" }}>
                                        <Box style={{ display: "block", paddingBottom: 20 }}>
                                            <Typography variant="h6">Price Breakdown</Typography>
                                            <Typography variant="h6">{calculateNumOfNights(dates) + " Nights, 1 Room, " + state.quantity + " Adults"}</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>{t('package.room') + ":"}</Typography>
                                            <Typography variant="h6">MYR 2,932.94</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>{t('package.taxesAndFees') + ":"}</Typography>
                                            <Typography variant="h6">MYR 450.94</Typography>
                                        </Box>
                                        <Box style={{ lineHeight: "1", padding: "10px 0px 20px" }}>
                                            <Typography variant="caption">{t('package.taxesAndFeesNote')}</Typography>
                                        </Box>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy", color: theme.palette.primary.main }}>{t('package.totalPrice') + ":"}</Typography>
                                            <Typography variant="h6" style={{ fontFamily: "FuturaPTHeavy" }}>{"MYR 2,932.94"}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>

                    {/* Number of Guests */}
                    <div>
                        <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center', paddingTop: 30, paddingBottom: 30}}>
                            <Grid item xs={12} sm={7}>
                                <Typography className={classes.titleText} style={{color: theme.palette.primary.main}}><u>{ calculateNumOfNights(dates) + " nights in ReLuxe De Mantanani Resort" }</u></Typography>
                                <Typography className={classes.titleText} style={{color: theme.palette.primary.main}}>
                                    {
                                        dates.start !== '' && dates.end !== '' 
                                        ? (
                                            monthsList[dates.start.getMonth()] + " " + dates.start.getDate() + ", " + dates.start.getFullYear() 
                                            + " - " +
                                            monthsList[dates.end.getMonth()] + " " + dates.end.getDate() + ", " + dates.end.getFullYear()
                                        )
                                        : 'MM DD, YYYY - MM DD, YYYY'
                                        
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5} style={{ display: 'flex', justifyContent: 'end'}}>
                                <div className={classes.quantityStyle} style={{ padding: '5px 0' }}>
                                    <Typography variant="h6" style={{ paddingLeft: 20, color: theme.palette.primary.main }}>Guest</Typography>
                                    <div style={{ display: 'flex', width: 120 }}>
                                        <Button className={classes.minusPlusIconStyle} onClick={() => handleQuantityChange('deduct', 1)}>
                                            <FiMinus className={classes.minusPlusTextStyle} />
                                        </Button>
                                        <TextField
                                            sx={{ input: { textAlign: "center", color: theme.palette.primary.main } }}
                                            variant="standard"
                                            name="quantity"
                                            value={state.quantity}
                                            InputProps={{ disableUnderline: true,}}
                                            onChange={(event) => handleQuantityChange('quantity', event.target.value)}
                                        />
                                        <Button className={classes.minusPlusIconStyle} onClick={() => handleQuantityChange('add', 1)}>
                                            <FiPlus className={classes.minusPlusTextStyle} />
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    {/* Calendar */}
                    <Grid container>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center'}}>
                            <ChakraProvider theme={calendarTheme}>
                                <Calendar value={dates} months={MONTHS} onSelectDate={handleSelectDate}>
                                    <CalendarControls>
                                        <CalendarPrevButton />
                                        <CalendarNextButton />
                                    </CalendarControls>

                                    <CalendarMonths>
                                        <Hidden mdDown>
                                        {
                                            [...Array(MONTHS).keys()].map(month => (
                                                <CalendarMonth month={month} key={month}>
                                                    <CalendarMonthName />
                                                    <CalendarWeek />
                                                    <CalendarDays />
                                                </CalendarMonth>
                                            ))
                                        }
                                        </Hidden>
                                        <Hidden mdUp>
                                        {
                                            [...Array(1).keys()].map(month => (
                                                <CalendarMonth month={month} key={month}>
                                                    <CalendarMonthName />
                                                    <CalendarWeek />
                                                    <CalendarDays />
                                                </CalendarMonth>
                                            ))
                                        }
                                        </Hidden>
                                    </CalendarMonths>
                                </Calendar>
                            </ChakraProvider>
                        </Grid>
                        <Grid item xs={10} style={{ display: 'flex', justifyContent: 'end'}}>
                            <Button onClick={handleClearDate}>
                                <Typography variant="h6" style={{color: theme.palette.primary.main}}><u>Clear Date</u></Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Container maxWidth="xl" style={{ padding: "2% 10%" }} className={classes.bgImageWhiteRight}>
                <Box>
                    {/* Description */}
                    <Grid container>
                        <Grid item>
                            <Typography variant="h5"><u style={{fontFamily: "FuturaPTDemi"}}>What this place offers</u></Typography>
                            <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <MdWifi size={30} color={theme.palette.primary.main} />
                                <Typography variant="h6" style={{paddingLeft: 10, fontFamily: "FuturaPTDemi"}}>Complimentary high-speed WiFi</Typography>
                            </div>
                            <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <MdBeachAccess size={30} color={theme.palette.primary.main} />
                                <Typography variant="h6" style={{paddingLeft: 10, fontFamily: "FuturaPTDemi"}}>Direct and private beach access</Typography>
                            </div>
                            <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <MdPool size={30} color={theme.palette.primary.main} />
                                <Typography variant="h6" style={{paddingLeft: 10, fontFamily: "FuturaPTDemi"}}>Private pools</Typography>
                            </div>
                            <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <MdWineBar size={30} color={theme.palette.primary.main} />
                                <Typography variant="h6" style={{paddingLeft: 10, fontFamily: "FuturaPTDemi"}}>Beach Bar</Typography>
                            </div>
                            <Divider style={{ margin: "10px 0px", borderBottomWidth: 0 }} />
                            <Typography>
                                ReLuxe De Mantanami Island Resorts boasts of crystal clear blue waters filled with a myriad of marine
                                life and white sandy beaches fringed by coconut palm trees. And with beach cillas dotting our island,
                                cast away in luxury ReLuxe style. Set sail in search of dugongs and dolphins. Dive into some of the
                                world's best reefs. Laze on deserted stretches of beach as some of the previous marine species stop
                                by at the beach.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
                    
            <Container maxWidth="xl" style={{ padding: "2% 10%" }} className={classes.bgImageWhiteLeft}>
                <Box>
                    {/* Tabs on Rooms, Suites, Villa */}
                    <div style={{ paddingBottom: 30 }}>
                        <Box>
                            <Hidden smUp>
                                <Tabs value={value} variant="scrollable" scrollButtons allowScrollButtonsMobile onChange={handleChange} aria-label="basic tabs example" style={{ position: 'relative', top: 22 }}>
                                    <Tab label="Rooms" icon={value == 0 ? <MdArrowDropDown size={40} /> : null} iconPosition={value == 0 ? "bottom": null} style={{ fontSize: 20, textTransform: 'capitalize', marginLeft: '10px', width: 150, height: (value == 0 ? 60 : 50), backgroundColor: theme.palette.primary.main, color: 'white', boxShadow: '0px 0px 5px #2d2d2d'}} {...a11yProps(0)}/>
                                    <Tab label="Suites" icon={value == 1 ? <MdArrowDropDown size={40} /> : null} iconPosition={value == 1 ? "bottom": null} style={{ fontSize: 20, textTransform: 'capitalize', marginLeft: '10px', width: 150, height: (value == 1 ? 60 : 50), backgroundColor: theme.palette.primary.main, color: 'white', boxShadow: '0px 0px 5px #2d2d2d'}} {...a11yProps(1)} />
                                    <Tab label="Villa" icon={value == 2 ? <MdArrowDropDown size={40} /> : null} iconPosition={value == 2 ? "bottom": null} style={{ fontSize: 20, textTransform: 'capitalize', marginLeft: '10px', width: 150, height: (value == 2 ? 60 : 50), backgroundColor: theme.palette.primary.main, color: 'white', boxShadow: '0px 0px 5px #2d2d2d'}} {...a11yProps(2)} />
                                </Tabs>
                            </Hidden>
                            <Hidden smDown>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ position: 'relative', top: 22 }}>
                                    <Tab label="Rooms" icon={value == 0 ? <MdArrowDropDown size={40} /> : null} iconPosition={value == 0 ? "bottom": null} style={{ fontSize: 20, textTransform: 'capitalize', marginLeft: '10px', width: 150, height: (value == 0 ? 60 : 50), backgroundColor: theme.palette.primary.main, color: 'white', boxShadow: '0px 0px 5px #2d2d2d'}} {...a11yProps(0)}/>
                                    <Tab label="Suites" icon={value == 1 ? <MdArrowDropDown size={40} /> : null} iconPosition={value == 1 ? "bottom": null} style={{ fontSize: 20, textTransform: 'capitalize', marginLeft: '10px', width: 150, height: (value == 1 ? 60 : 50), backgroundColor: theme.palette.primary.main, color: 'white', boxShadow: '0px 0px 5px #2d2d2d'}} {...a11yProps(1)} />
                                    <Tab label="Villa" icon={value == 2 ? <MdArrowDropDown size={40} /> : null} iconPosition={value == 2 ? "bottom": null} style={{ fontSize: 20, textTransform: 'capitalize', marginLeft: '10px', width: 150, height: (value == 2 ? 60 : 50), backgroundColor: theme.palette.primary.main, color: 'white', boxShadow: '0px 0px 5px #2d2d2d'}} {...a11yProps(2)} />
                                </Tabs>
                            </Hidden>
                            
                            {
                                _.map(tabItemData, (detail, key) => {
                                    return (
                                        <TabPanel value={value} index={key} key={key} style={{backgroundColor: theme.palette.primary.main}}>
                                            <Grid container>
                                                <Hidden mdUp>
                                                    <Grid item xs={12} md={7}>
                                                        <img src={detail.image} alt="resort" />
                                                    </Grid>
                                                </Hidden>
                                                <Hidden mdDown>
                                                    <Grid item xs={12} md={7} style={{ backgroundImage: `url(${detail.image})`, backgroundSize: "300px 300px", backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}>
                                                    </Grid>
                                                </Hidden>
                                                <Grid item xs={12} md={5} style={{ padding: 20 }}>
                                                    <Typography variant="h5" sx={{ fontFamily: 'PlayfairDisplayRegular' }} style={{color: "white"}}>{detail.title}</Typography>
                                                    <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                                                    <Typography variant="h5" style={{color: "white"}}><u>{detail.subtitle}</u></Typography>
                                                    <Typography sx={{ fontFamily: 'FuturaPTBook' }} style={{color: "white"}}>{detail.descr}</Typography>
                                                    <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                                                    {
                                                        _.map(detail.lists, (listDetail, key) => {
                                                            return (
                                                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                                                    <img src={'../images/logo.png'} width={20} style={{filter: 'brightness(0) invert(1)'}} key={key} />
                                                                    <Typography sx={{ fontFamily: 'FuturaPTBook' }} style={{paddingLeft: 10, color: "white"}}>{listDetail}</Typography>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    <Divider style={{ margin: "20px 0px", borderBottomWidth: 0 }} />
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
                                                        <Typography className={classes.priceText} sx={{ fontFamily: 'FuturaPTBook' }}>{detail.price}</Typography>
                                                        <Button variant="outlinedWhite" color="primary" style={{ textTransform: 'uppercase' }}>{detail.button}</Button>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>
                                    )
                                })
                            }
                        </Box>
                    </div>

                    {/* More Resorts */}
                    <div style={{ paddingBottom: 30 }}>
                        <Typography variant="h2" className={classes.titleStyle} style={{ fontFamily: "PlayfairDisplayItalic", paddingBottom: 30 }}>Explore more resorts</Typography>
                        <div>
                            <Box className={classes.packageList}>
                            {
                                _.size(packages) > 0
                                    ?
                                    <Box className={classes.packageList}>
                                        <PackageList 
                                            packageList={packages} 
                                            pagination={true} 
                                            swiper={false} 
                                            handlePaginationChange={handlePaginationChange} 
                                            page={page} 
                                            total={total} 
                                            showPrice={false} 
                                            showLink={false} 
                                            bookResortNow={true} 
                                            toggleItem={toggleItem} 
                                        />
                                    </Box>
                                    :
                                    loading
                                        ?
                                        <Box style={{ marginTop: 20 }}>
                                            <LinearProgress size={20} />
                                        </Box>
                                        :
                                        <Box style={{ marginTop: 20, textAlign: "center" }}>
                                            <Typography variant="h6" style={{ padding: 50 }}>{t('general.noData')}</Typography>
                                        </Box>
                            }
                            </Box>
                        </div>
                    </div>
                </Box>
            </Container>
        </>
    );
}
export default Detail;

const calendarTheme = extendTheme(CalendarDefaultTheme, {
    components: {
      Calendar: {
        parts: ['calendar'],
  
        baseStyle: {
          calendar: {
            border: 'none',
            rounded: 'none',
            shadow: 'none',
          },
        },
      },

      CalendarMonth: {
        parts: ['weekday', 'days'],
        
        baseStyle: {
            weekday:{
                color: 'black',
                bgColor: '#D6CFBF',
            },
            days:{ color: '#574f41' },
        },
      },

      CalendarDay: {
        variants: {
            selected: {
                bgColor: 'black',
                rounded: 'xl',
                _hover: { bgColor: '#574f41'},
            },
            range: {
                bgColor: '#574f41',
                _hover: { bgColor: 'black'},
            },
        }
      },
  
      CalendarControl: {
        parts: ['button'],
  
        baseStyle: {
          button: {
            h: 6,
            px: 2,
            rounded: 'none',
            fontSize: 'md',
            color: 'black',
            bgColor: 'white',
            _hover: { bgColor: 'grey.100' },
            _focus: { outline: 'none' },
          },
        },
      }
    },
});

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    swiperStyle: {
        // width: 200,
        width: '100%',
        height: 400
    },
    bgImageWhiteLeft: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'left top', 
            backgroundSize: "40%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'left top 20%', 
            backgroundSize: "25%", 
        },
    },
    bgImageWhiteRight: {
        backgroundImage: `url('../images/logo-light.png')`, 
        backgroundRepeat: 'no-repeat', 
        [theme.breakpoints.up('xs')]: {
            backgroundPosition: 'right -40% top', 
            backgroundSize: "40%",
        },
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'right top', 
            backgroundSize: "20%",
        },
    },
    titleText: {
        color: theme.palette.gray.dtext,
        fontSize: '28px!important',
        fontWeight: 'bold!important',
    },
    priceText: {
        color: theme.palette.secondary.main,
        fontWeight: 'bold!important',
        fontSize: '20px!important',
        // paddingBottom: '20px!important'
    },
    contentText: {
        color: theme.palette.gray.dtext,
        fontSize: '16px!important',
    },
    contentNote: {
        color: theme.palette.gray.dtext,
        fontSize: '16px!important',
        fontStyle: "italic",
        paddingLeft: 20
    },
    phNote: {
        color: theme.palette.gray.dtext,
        fontSize: '16px!important',
    },
    buttonOrange: {
        backgroundColor: theme.palette.button.orange + " !important",
        boxShadow: "1px 2px 7px 0 #ffa34c !important",
    },
    buttonPrimary: {
        backgroundColor: theme.palette.button.main + " !important",
        boxShadow: "1px 2px 5px 0 #ff7f4c57  !important",
    },
    buttonStyle: {
        //borderRadius: "8px !important",
        textAlign: 'center',
        padding: "6px 10px !important",
        //color: '#fff !important',
        minWidth: '220px !important',
        border: 'solid 3px #fff !important',
        //boxShadow: '3px 3px 6px 0 ' + theme.palette.shadow.main,
    },
    minusPlusIconStyle: {
        //backgroundColor: theme.palette.gray.superLight + " !important",
        //boxShadow: '2px 2px 6px 0 ' + theme.palette.gray.light + " !important",
        //borderRadius: '5px !important',
        minWidth: 'unset !important',
        padding: 8
    },
    minusPlusTextStyle: {
        fontSize: 20,
        color: '#ad9e80'
    },
    quantityStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: 300,
        height: 60,
        border: 'solid 3px #ad9e80'
    },
    quantityTitle: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    quantityGroup: {
        border: '1px solid',
        borderRadius: 8,
        padding: '8px 15px',
        borderColor: theme.palette.gray.light,
        marginTop: 8,
    },
    titleFont: {
        fontWeight: 'bold',
        [theme.breakpoints.up('xs')]: {
            fontSize: '16px!important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px!important',
        },
    },
    flexSbM: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    flexM: {
        display: 'flex',
        alignItems: 'center'
    },
    packageList: {
        marginTop: "10px !important",
    },
}))