import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger';

import combinedReducers from '@/reducers';

import { ENV } from './config';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['general', 'auth', 'user', 'wallet'],
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);
const logger = createLogger();

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: ENV === 'dev' ? [thunk, logger] : [thunk]
});

let persistor = persistStore(store);
export { store, persistor };