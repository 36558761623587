import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { HiChevronDoubleUp } from "react-icons/hi";

const ScrollToTop = () => {
    const { pathname } = useLocation();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300) {
            setVisible(true);
        }
        else if (scrolled <= 300) {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    window.addEventListener('scroll', toggleVisible);

    if(!(window.location.href).includes('/package/') || (window.location.href).includes('/package/summary')){
        return (
            <HiChevronDoubleUp onClick={scrollToTop} size="50" style={{ display: visible ? 'inline' : 'none', right: 50, zIndex: 99, bottom: 50, position: 'fixed', cursor: 'pointer' }} />
        );
    }
}

export default ScrollToTop; 
