import React from 'react';

import { Box } from '@mui/material/';

import AccountComponent from '@components/AccountComponent';
import BackToPrevious from '@components/BackToPrevious';

const MobileAccountSidebar = () => {
    return (
        <Box style={{ padding: "30px" }}>
            <BackToPrevious />
            <AccountComponent />
        </Box>
    );
};

export default MobileAccountSidebar;