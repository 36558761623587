import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Moment from 'moment';

import { Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Link, Radio, RadioGroup, TextField, Typography, Collapse } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const BookingInfo = (props) => {
    const { carts, bookingInfo, setBookingInfo, loading, inputErrors, setActiveStep, tourType, placeOrder, setPolicyChecked, policyChecked, registerInfo, setRegisterInfo, emergencyContact, setEmergencyContact, guestAddOnInfo, setGuestAddOnInfo } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { name, email, mobile, ic } = useSelector(state => state.user);
    const { accessToken } = useSelector(state => state.general);
    const [emergencyOpen, setEmergencyOpen] = useState(false);

    const handleBookingInfoChange = (cartId, key, target) => {
        let newBookingInfo = bookingInfo;
        newBookingInfo[cartId][key]['checked'] = false;
        newBookingInfo[cartId][key][target.name] = target.value;
        setBookingInfo({ ...newBookingInfo });
    }

    const handleDateSelect = (cartId, key, value) => {
        let date = '';
        if(value){
            date = Moment([value.$y, value.$M, value.$D]).format('YYYY-MM-DD');
        }
        let newBookingInfo = bookingInfo;
        newBookingInfo[cartId][key]['checked'] = false;
        newBookingInfo[cartId][key]['user_dob'] = date;
        setBookingInfo({ ...newBookingInfo });
    }

    const handleCheckboxChange = (cartId, key, target) => {
        let newBookingInfo = bookingInfo;
        if (target.checked) {
            newBookingInfo[cartId][key] = {
                checked: true,
                user_name: name ? name : '',
                user_email: email ? email : '',
                user_mobile: mobile ? mobile : '',
                user_ic: ic ? ic : '',
                user_dob: '',
                user_gender: '',
            };
        } else {
            newBookingInfo[cartId][key] = {
                checked: false,
                user_name: '',
                user_email: '',
                user_mobile: '',
                user_ic: '',
                user_dob: '',
                user_gender: '',
            };
        }
        setBookingInfo({ ...newBookingInfo });
    }

    const handleCheckboxAcceptChange = (target) => {
        if (target.checked) {
            setPolicyChecked(true);
        } else {
            setPolicyChecked(false);
        }
    }

    const bookingInfoBox = (cartItem) => {
        return (
            <Box key={cartItem.id}>
                <Typography variant="body1" className={classes.optionStyle}>{cartItem.package_option.name}</Typography>
                {
                    _.map(bookingInfo[cartItem.id], (userInfo, key) => {
                        return guestDetail(cartItem.id, key, userInfo)
                    })
                }
            </Box>
        )
    }

    const guestDetail = (cartId, key, userInfo) => {
        return (
            <Grid container key={key}>
                <Grid item xs={12}>
                    <Box style={{ display: "flex", alignItems: "center", marginTop: 10, paddingLeft: 10 }}>
                        <Typography variant="body1">
                            {t('voucher.guestDetail') + " " + (key + 1)}
                        </Typography>
                        {
                            accessToken && (key === 0)
                                ?
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={userInfo.checked}
                                            onChange={(event) => handleCheckboxChange(cartId, key, event.target)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            size="small"
                                        />
                                    }
                                    label={t('checkout.sameWithAcc')}
                                    style={{ marginLeft: 5 }}
                                />
                                :
                                null
                        }
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        label={t('user.name') + '*'}
                        value={userInfo.user_name}
                        name="user_name"
                        onChange={(event) => handleBookingInfoChange(cartId, key, event.target)}
                        error={inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_name'] ? true : false}
                        helperText={(inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_name']) || ''}
                        style={{ padding: "0px 5px" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        label={t('user.email')}
                        value={userInfo.user_email}
                        name="user_email"
                        onChange={(event) => handleBookingInfoChange(cartId, key, event.target)}
                        error={inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_email'] ? true : false}
                        helperText={(inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_email']) || ''}
                        style={{ padding: "0px 5px" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        label={t('user.mobile')}
                        value={userInfo.user_mobile}
                        name="user_mobile"
                        onChange={(event) => handleBookingInfoChange(cartId, key, event.target)}
                        error={inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_mobile'] ? true : false}
                        helperText={(inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_mobile']) || ''}
                        style={{ padding: "0px 5px" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        variant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth
                        label={t('user.ic') + '*'}
                        value={userInfo.user_ic}
                        name="user_ic"
                        onChange={(event) => handleBookingInfoChange(cartId, key, event.target)}
                        error={inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_ic'] ? true : false}
                        helperText={(inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_ic']) || ''}
                        style={{ padding: "0px 5px" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label={t('user.dob') + '*'}
                            value={userInfo.user_dob}
                            onChange={(newValue) => handleDateSelect(cartId, key, newValue)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    error={inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_dob'] ? true : false}
                                    helperText={(inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_dob']) || ''}
                                    style={{ padding: "0px 5px" }}
                                    />
                                }
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl style={{ padding: "0px 5px" }}>
                        <Box style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <FormLabel style={{ marginRight: 20 }}>{t('user.gender') + '*'}</FormLabel>
                            <RadioGroup row name="user_gender" value={userInfo.user_gender} onChange={(event) => handleBookingInfoChange(cartId, key, event.target)}>
                                <FormControlLabel value={'male'} control={<Radio size="small" />} label={t('user.male')} />
                                <FormControlLabel value={'female'} control={<Radio size="small" />} label={t('user.female')} />
                            </RadioGroup>
                        </Box>
                        {
                            inputErrors && inputErrors['booking_info.' + cartId + '.' + key + '.user_gender']
                                ?
                                <Typography variant="caption" color="error">{inputErrors['booking_info.' + cartId + '.' + key + '.user_gender']}</Typography>
                                :
                                null
                        }
                    </FormControl>
                </Grid>
            </Grid>
        )
    }

    const agreementCheckBox = () => {
        return (
            <Box xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={policyChecked}
                            onChange={(event) => handleCheckboxAcceptChange(event.target)}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                    label={
                        <div>
                            <p style={{ margin: "10px 0px", textDecoration: "underline" }}>{t('voucher.agreementTitle')}</p>
                            <span>{t('voucher.agreement1')}</span>
                            <Link component={RouterLink} to="/policy?type=termcondition" target="_blank">{t('voucher.termscondition')}</Link>
                            <span>{t('voucher.agreement2')}</span>
                            <Link component={RouterLink} to="/policy?type=privacypolicy" target="_blank">{t('voucher.privacypolicy')}</Link>
                            <span>.</span>
                            {
                                !accessToken
                                    ?
                                    <>
                                        <p style={{ margin: "10px 0px" }}>{t('voucher.agreement3')}</p>
                                        <p style={{ margin: "10px 0px" }}>{t('voucher.agreement4')}</p>
                                    </>
                                    :
                                    null
                            }
                        </div>
                    }
                    style={{ marginLeft: 10 }}
                />
            </Box>
        )
    }

    const contactDetailsBox = () => {
        return (
            <Box style={{ marginTop: "3%" }}>
                <Typography variant="h5" style={{ marginBottom: 10, fontWeight: "bold" }}>{t('checkout.contactDetails')}</Typography>
                <Box className={classes.bookingBox}>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                size="small"
                                fullWidth
                                label={t('user.firstName') + '*'}
                                value={registerInfo.register_first_name}
                                name="register_first_name"
                                onChange={(event) => setRegisterInfo({ ...registerInfo, register_first_name: event.target.value })}
                                error={inputErrors && (inputErrors.register_first_name || inputErrors.name) ? true : false}
                                helperText={(inputErrors && (inputErrors.register_first_name || inputErrors.name)) || ''}
                                style={{ padding: "0px 5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                size="small"
                                fullWidth
                                label={t('user.lastName') + '*'}
                                value={registerInfo.register_last_name}
                                name="register_last_name"
                                onChange={(event) => setRegisterInfo({ ...registerInfo, register_last_name: event.target.value })}
                                error={inputErrors && (inputErrors.register_last_name || inputErrors.name) ? true : false}
                                helperText={(inputErrors && (inputErrors.register_last_name || inputErrors.name)) || ''}
                                style={{ padding: "0px 5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                size="small"
                                fullWidth
                                label={t('user.username') + '*'}
                                value={registerInfo.register_username}
                                name="register_username"
                                onChange={(event) => setRegisterInfo({ ...registerInfo, register_username: event.target.value })}
                                error={inputErrors && inputErrors.register_username ? true : false}
                                helperText={(inputErrors && inputErrors.register_username) || ''}
                                style={{ padding: "0px 5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                size="small"
                                fullWidth
                                label={t('user.email') + '*'}
                                value={registerInfo.register_email}
                                name="register_email"
                                onChange={(event) => setRegisterInfo({ ...registerInfo, register_email: event.target.value })}
                                error={inputErrors && (inputErrors.register_email || inputErrors.email) ? true : false}
                                helperText={(inputErrors && (inputErrors.register_email || inputErrors.email)) || ''}
                                style={{ padding: "0px 5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                size="small"
                                fullWidth
                                label={t('user.mobile') + '*'}
                                value={registerInfo.register_mobile}
                                name="register_mobile"
                                onChange={(event) => setRegisterInfo({ ...registerInfo, register_mobile: event.target.value })}
                                error={inputErrors && (inputErrors.register_mobile || inputErrors.mobile) ? true : false}
                                helperText={(inputErrors && (inputErrors.register_mobile || inputErrors.mobile)) || ''}
                                style={{ padding: "0px 5px" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                size="small"
                                fullWidth
                                label={t('user.ic')}
                                value={registerInfo.register_ic}
                                name="register_ic"
                                onChange={(event) => setRegisterInfo({ ...registerInfo, register_ic: event.target.value })}
                                error={inputErrors && inputErrors.register_ic ? true : false}
                                helperText={(inputErrors && inputErrors.register_ic) || ''}
                                style={{ padding: "0px 5px" }}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label={t('user.dob') + '*'}
                                    value={registerInfo.register_dob}
                                    onChange={(newValue) => setRegisterInfo({ ...registerInfo, register_dob: newValue?Moment([newValue.$y, newValue.$M, newValue.$D]).format('YYYY-MM-DD'):'' })}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            margin="dense"
                                            size="small"
                                            fullWidth
                                            error={inputErrors && inputErrors.register_dob ? true : false}
                                            helperText={(inputErrors && inputErrors.register_dob) || ''}
                                            style={{ padding: "0px 5px" }}
                                        />
                                    }
                                />
                            </LocalizationProvider>
                        </Grid> */}
                    </Grid>
                </Box>
            </Box>
        )
    }

    const emergencyContactBox = () => {
        return (
            <Box style={{ marginTop: "3%", marginBottom: 10 }}>
                <Box style={{ display: "flex", alignItems: "center", marginBottom: 10 }} onClick={() => setEmergencyOpen(!emergencyOpen)}>
                    <Typography variant="h5" style={{ fontWeight: "bold" }}>{t('checkout.emergencyContact')}</Typography>
                    {emergencyOpen ? <MdExpandLess size={30} /> : <MdExpandMore size={30} />}
                </Box>
                <Collapse in={emergencyOpen} timeout="auto" unmountOnExit>
                    <Box className={classes.bookingBox}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    label={t('user.name')}
                                    value={emergencyContact.emergency_name}
                                    name="emergency_name"
                                    onChange={(event) => setEmergencyContact({ ...emergencyContact, emergency_name: event.target.value })}
                                    error={inputErrors && inputErrors.emergency_name ? true : false}
                                    helperText={(inputErrors && inputErrors.emergency_name) || ''}
                                    style={{ padding: "0px 5px" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    label={t('checkout.relationship')}
                                    value={emergencyContact.emergency_relationship}
                                    name="relationship"
                                    onChange={(event) => setEmergencyContact({ ...emergencyContact, emergency_relationship: event.target.value })}
                                    error={inputErrors && inputErrors.emergency_relationship ? true : false}
                                    helperText={(inputErrors && inputErrors.emergency_relationship) || ''}
                                    style={{ padding: "0px 5px" }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth
                                    label={t('user.mobile')}
                                    value={emergencyContact.emergency_mobile}
                                    name="emergency_mobile"
                                    onChange={(event) => setEmergencyContact({ ...emergencyContact, emergency_mobile: event.target.value })}
                                    error={inputErrors && inputErrors.emergency_mobile ? true : false}
                                    helperText={(inputErrors && inputErrors.emergency_mobile) || ''}
                                    style={{ padding: "0px 5px" }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
            </Box>
        )
    }

    const bottomActionRow = () => {
        return (
            <Box className={classes.bottomActionStyle}>
                <Grid container spacing={2} className={classes.bottomActionBox}>
                    <Grid item xs={12} className={classes.gridItemBoxRight}>
                        {/* <Button variant="contained" onClick={() => setActiveStep(1)} disabled={(!loading && _.size(carts) > 0) ? false : true}>
                            {t('general.previousStep')}
                        </Button> */}
                        <Button variant="contained" onClick={placeOrder} disabled={(!loading && _.size(carts) > 0) ? false : true}>
                            {t('checkout.placeOrder')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const handleGuestInfoChange = (cartId, key, target) => {
        let newGuestAddOnInfo = guestAddOnInfo;
        newGuestAddOnInfo[cartId][key] = target.value;
        setGuestAddOnInfo({ ...guestAddOnInfo });
    }

    return (
        <Box>
            <Typography variant="h5" style={{ marginBottom: 10, fontWeight: "bold" }}>{t('checkout.bookingInfo')}</Typography>
            {
                _.size(carts) > 0 && tourType
                    ?
                    <>
                        {
                            _.map(carts, (agencyCartList, agencyId) => (
                                _.map(agencyCartList.data, (data, package_id) => (
                                    _.map(data, (groupItem, booking_date) => {
                                        if (groupItem.selected) {
                                            return (
                                                <Box key={booking_date}>
                                                    <Box className={classes.bookingBox}>
                                                        <Typography variant="h6"><b>{groupItem.package.name}</b></Typography>
                                                        {
                                                            _.map(groupItem.items, mainOption => {
                                                                // main option
                                                                if (mainOption.status && mainOption.package.type === 'tour') {
                                                                    return bookingInfoBox(mainOption)
                                                                }
                                                            })
                                                        }
                                                        {
                                                            _.map(groupItem.add_on, (add_on, key) => {
                                                                return (
                                                                    <Box key={key}>
                                                                        <Typography variant="h6"><b>{t('checkout.addOn') + ": " + add_on.display_name}</b></Typography>
                                                                        {
                                                                            _.map(add_on.items, addOnOption => {
                                                                                // add on option
                                                                                if (addOnOption.status && addOnOption.package.type === 'tour') {
                                                                                    return (
                                                                                        <Box>
                                                                                            <Typography variant="body1" className={classes.optionStyle}>{addOnOption.package_option.name}</Typography>
                                                                                            {
                                                                                                _.map(guestAddOnInfo[addOnOption.id], (guestInfo, key) => {
                                                                                                    return (
                                                                                                        <Box>
                                                                                                            <Typography variant="body1">
                                                                                                                {t('voucher.guestDetail') + " " + (key + 1)}
                                                                                                            </Typography>
                                                                                                            <RadioGroup row name="booking_guest" value={guestAddOnInfo[addOnOption.id][key]} onChange={(event) => handleGuestInfoChange(addOnOption.id, key, event.target)}>
                                                                                                                {_.map(groupItem.items, (mainOption, cart_key) => (
                                                                                                                    mainOption.status && (mainOption.package.type === 'tour') && _.map(bookingInfo[mainOption.id], (userInfo, guest_key) => {
                                                                                                                        if (userInfo.user_name) {
                                                                                                                            return (
                                                                                                                                <FormControlLabel value={mainOption.id + '-' + guest_key} control={<Radio size="small" />} label={userInfo.user_name} />
                                                                                                                            )
                                                                                                                        }
                                                                                                                    })
                                                                                                                ))}
                                                                                                            </RadioGroup>
                                                                                                            {
                                                                                                                inputErrors && (inputErrors['booking_info.' + addOnOption.id + '.' + key + '.user_name'] || inputErrors['booking_info.' + addOnOption.id + '.' + key + '.user_dob'])
                                                                                                                    ?
                                                                                                                    <Typography variant="caption" color="error">
                                                                                                                        {inputErrors['booking_info.' + addOnOption.id + '.' + key + '.user_name']}
                                                                                                                        {inputErrors['booking_info.' + addOnOption.id + '.' + key + '.user_dob']}
                                                                                                                    </Typography>
                                                                                                                    :
                                                                                                                    null
                                                                                                            }
                                                                                                        </Box>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Box>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </Box>
                                                                )
                                                            })
                                                        }
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                    })
                                ))
                            ))
                        }
                    </>
                    :
                    <Box className={classes.noBookingBox}>
                        {t('checkout.noTourPackagenoBookingDetail')}
                    </Box>
            }
            {!accessToken?contactDetailsBox():null}
            {emergencyContactBox()}
            {agreementCheckBox()}
            {bottomActionRow()}
        </Box>
    )
}

export default BookingInfo;

const useStyles = makeStyles((theme) => ({
    gridItemBoxRight: {
        margin: "auto !important",
        textAlign: 'right',
    },
    bottomActionStyle: {
        // backgroundColor: theme.palette.primary.secondary + ' !important',
        margin: "50px 0px 20px 0px !important",
        borderRadius: 5,
    },
    bottomActionBox: {
        padding: "0px 16px 16px 16px",
        color: theme.palette.primary.main + ' !important',
        fontWeight: "bold !important",
    },
    optionStyle: {
        color: '#fff !important',
        backgroundColor: theme.palette.primary.main + ' !important',
        borderRadius: 20,
        padding: "5px 10px",
        width: "fit-content",
        fontSize: "0.8rem !important",
        marginTop: "10px !important",
        // marginLeft: "10px !important",
    },
    packageOptionName: {
        display: "flex",
        padding: 10,
        borderRadius: 10,
        backgroundColor: theme.palette.primary.main + ' !important',
        marginBottom: "10px !important",
        width: "fit-content"
    },
    bookingBox: {
        padding: 20,
        // border: "2px solid " + theme.palette.primary.main + ' !important',
        borderRadius: 10,
        marginBottom: "10px !important",
        boxShadow: '0 0 11px 0 #00000029',
    },
    noBookingBox: {
        padding: 20,
        // border: "2px solid " + theme.palette.primary.main + ' !important',
        borderRadius: 10,
        marginTop: "20px !important",
        textAlign: "center",
        boxShadow: '0 0 11px 0 #00000029',
    },
    bookingDate: {
        fontSize: 18
    }
}))