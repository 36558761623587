import { getUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box, Card, CardContent, CardMedia, Grid, LinearProgress, Typography, Link, Container } from '@mui/material';
import { FiHome, FiMail, FiUser } from "react-icons/fi";

import BackToPrevious from '@components/BackToPrevious';
import AccountSidebar from '@components/AccountSidebar';
import OrderPrint from './OrderPrint';

const OrderDetail = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);

    let { id } = useParams();
    const [order, setOrder] = useState({});
    const [subTotal, setSubTotal] = useState(0);
    const [agentDiscountAmount, setAgentDiscountAmount] = useState(0);
    const [codeDiscountAmount, setCodeDiscountAmount] = useState(0);
    const { defaultCurrency } = useSelector(state => state.general);
    const [displayOrder, setDisplayOrder] = useState({});

    useEffect(() => {
        setLoading(true);
        getUrl(`/order/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setOrder(response.data.order);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, []);

    const customerInfo = () => {
        return (
            <Box style={{ marginTop: 20 }}>
                <Card>
                    <CardContent>
                        <Typography>
                            <FiUser style={{ marginRight: 5 }} />
                            {order.order_customer.name + (order.order_customer.mobile ? (" | " + order.order_customer.mobile) : '')}
                        </Typography>
                        <Typography>
                            <FiMail style={{ marginRight: 5 }} />
                            {order.order_customer.email}
                        </Typography>
                        {/* <Typography>
                            <FiHome style={{ marginRight: 5 }} />
                            {order.order_customer.full_address}
                        </Typography> */}
                    </CardContent>
                </Card>
            </Box>
        )
    }

    useEffect(() => {
        calculateTotal(order);
        rearrangeOrderDetail();
        // eslint-disable-next-line
    }, [order]);

    const rearrangeOrderDetail = () => {
        let rearrangeOrderDetail = {};
        _.map(order.order_details, (orderDetail, dkey) => {
            if (orderDetail.status) {
                let main_package_id = orderDetail.package_id;
                let main_package = orderDetail.package;
                if (orderDetail.add_on_id) {
                    let main_detail = _.find(order.order_details, { id: parseInt(orderDetail.add_on_id) });
                    main_package_id = main_detail.package_id;
                    main_package = main_detail.package;
                }
                if (!(rearrangeOrderDetail[main_package_id])) {
                    rearrangeOrderDetail[main_package_id] = {};
                }
                if (!(rearrangeOrderDetail[main_package_id][orderDetail.booking_date])) {
                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date] = { items: {}, add_on: {}, package: main_package };
                }
                if (orderDetail.add_on_id) {
                    if (!(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id])) {
                        rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id] = { items: {}, package: orderDetail.package, display_name: orderDetail.package_name };
                    }
                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id]['items'][_.size(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id]['items'])] = orderDetail;
                } else {
                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['items'][_.size(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['items'])] = orderDetail;
                }
            }
        })
        setDisplayOrder(rearrangeOrderDetail);
    }

    const calculateTotal = (order) => {
        let _subTotal = 0;
        let _agent_discount = 0;
        let _code_discount = 0;
        _.map(order.order_details, order_detail => {
            if (order_detail.status) {
                _subTotal += parseFloat(order_detail.quantity * order_detail.price.sell);
                _code_discount += parseFloat(order_detail.quantity * (order_detail.price.sell - order_detail.price.adjust));
                _agent_discount += parseFloat(order_detail.quantity * (order_detail.price.adjust - order_detail.price.discount));
            }
        })
        setSubTotal(_subTotal);
        setAgentDiscountAmount(_agent_discount);
        setCodeDiscountAmount(parseFloat(_code_discount).toFixed(2));
    }

    const orderItem = () => {
        return (
            <Box className={classes.cartContainer}>
                <Box className={classes.cartAgencyBox}>
                    <Typography className={classes.cartAgencyTitle}>{t('order.orderItems')}</Typography>
                </Box>
                <Box>
                    {
                        _.map(displayOrder, (data, packageId) => (
                            _.map(data, (groupItem, booking_date) => {
                                return cartItemBox(groupItem, booking_date);
                            })
                        ))
                    }
                </Box>
                <Box style={{ margin: 20 }}>
                    <Typography>{t('checkout.remark') + ': ' + (order.remark ? order.remark : '-')}</Typography>
                    <Typography>{t('checkout.subtotal') + ': ' + defaultCurrency.symbol + ' ' + (parseFloat(subTotal * defaultCurrency.rate).toFixed(2))}</Typography>
                    {
                        parseFloat(codeDiscountAmount) !== 0 &&
                        <Typography>
                            {
                                t('checkout.discountCodeAmount') + ': ' +
                                (codeDiscountAmount < 0 ? '' : '-')
                                +
                                " " + defaultCurrency.symbol + " "
                                + (parseFloat(codeDiscountAmount > 0 ? codeDiscountAmount : -codeDiscountAmount) * defaultCurrency.rate).toFixed(2)
                            }
                        </Typography>
                    }
                    {
                        agentDiscountAmount !== 0 &&
                        <Typography>{t('checkout.agentDiscount') + ': ' + defaultCurrency.symbol + ' ' + (-parseFloat(agentDiscountAmount * defaultCurrency.rate).toFixed(2))}</Typography>
                    }
                </Box>
            </Box>
        )
    }

    const cartItemBox = (groupItem, booking_date) => {
        return (
            <Grid container spacing={2} className={classes.packageBox}>
                <Grid item xs={12} md={2} className={`head ${classes.gridItemBoxCenter} ${classes.imgAlignTop}`} >
                    <CardMedia
                        component="img"
                        image={_.size(groupItem.package.images) > 0 ? groupItem.package.images[0]?.file_name : "../images/emptyStatus/no_image.png"}
                        sx={{ flex: 1, width: "100%", padding: 0 }}
                        alt={'package\'s image'}
                        className={classes.imgStyle}
                    />
                </Grid>
                <Grid item xs={12} md={10} className={classes.gridItemBoxLeft} style={{ paddingLeft: 10 }}>
                    <Link component={RouterLink} to={`/package/${groupItem.package.id}`} underline="none" className={classes.packageLink}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>{groupItem.package.name}</Typography>
                    </Link>
                    {
                        booking_date
                            ?
                            <Typography className={classes.bookingDateStyle}>{booking_date}</Typography>
                            :
                            null
                    }
                    {
                        _.map(groupItem.items, mainOption => {
                            return (
                                <Box key={mainOption.id}>
                                    {optionBox(mainOption)}
                                </Box>
                            )
                        })
                    }
                    <Box>
                        {
                            (_.size(groupItem.add_on) > 0) &&
                            <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20 }}>
                                {t('checkout.addOn') + ": "}
                            </Typography>
                        }
                        {
                            _.map(groupItem.add_on, (add_on, key) => {
                                if (_.size(add_on.items) > 0) {
                                    return (
                                        <Box key={key}>
                                            <Typography variant="body1" style={{ fontWeight: "bold" }}>{add_on.display_name}</Typography>
                                            {_.map(add_on.items, addOnOption => {
                                                return (
                                                    <Box key={addOnOption.id}>
                                                        {optionBox(addOnOption)}
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    )
                                }
                            })
                        }
                    </Box>
                </Grid>
            </Grid>
        )
    }

    const optionBox = (option) => {
        return (
            <Box>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={6}>
                        <Typography variant="body1">{option.package_option_name}</Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Box style={{ display: "flex" }}>
                            <Typography variant="body1" align="center" stle={{ marginRight: 5 }}>x{option.quantity}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{ margin: "auto !important", display: "flex", justifyContent: 'flex-end', alignItems: "center" }}>
                            {
                                option.price['retail'] > 0 && (option.price['retail'] - option.price['sell']) > 0
                                    ?
                                    <Typography variant="caption" style={{ textDecoration: "line-through" }}>
                                        {
                                            " " + defaultCurrency.symbol + " "
                                            + parseFloat((option.price['retail'] * defaultCurrency.rate) * option.quantity).toFixed(2)
                                        }
                                    </Typography>
                                    :
                                    null
                            }
                            <Typography variant="body1" style={{ marginLeft: 10 }}>
                                {
                                    " " + defaultCurrency.symbol + " "
                                    + parseFloat((option.price['sell'] * defaultCurrency.rate) * option.quantity).toFixed(2)
                                }
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={1.5} className={classes.gridItemBoxRight}>
                    {
                        order.status === 50 &&
                            <OrderPrint label={t('general.print')} data={order} orderDetail={option} defaultCurrency={defaultCurrency} />
                        }
                    </Grid>
                </Grid>
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2" style={{ marginRight: 5 }}>{t('voucher.code') + ": "}</Typography>
                    {_.map(option.vouchers, voucher => {
                        return (
                            <Link key={voucher.id} component={RouterLink} to={`/vouchers?code=${voucher.code}`} underline="none" style={{ marginRight: 10 }}>
                                <Typography variant="body2" className={classes.voucherLink}>{voucher.code}</Typography>
                            </Link>
                        )
                    })}
                </Box>
            </Box>
        )
    }

    return (
        <Box className={classes.paper}>
            <Container maxWidth="xl">
                <AccountSidebar>
                    {/* <BackToPrevious /> */}
                    {
                        _.size(order) > 0
                            ?
                            <Box style={{ marginLeft: 0 }}>
                                <Typography variant="h5" className={classes.titleStyle}>{t('order.orderNo') + ": " + order.order_no}</Typography>
                                <Typography>{order.status_display[i18n.language] ? order.status_display[i18n.language] : order.status_display.en}</Typography>
                                <Typography>{t('order.createdAt') + ": " + order.created_at}</Typography>
                                {order.order_customer ? customerInfo() : null}
                                {orderItem()}
                                <Box style={{ margin: 20, textAlign: "right" }}>
                                    <Typography variant="h6">{t('order.totalAmount') + ': ' + defaultCurrency.symbol + ' ' + parseFloat(order.total_amount * defaultCurrency.rate).toFixed(2)}</Typography>
                                </Box>
                            </Box>
                            :
                            loading
                                ?
                                <Box style={{ marginTop: 20 }}>
                                    <LinearProgress size={20} />
                                </Box>
                                :
                                null
                    }

                </AccountSidebar>
            </Container>
        </Box>
    )
}

export default OrderDetail;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
    },
    titleStyle: {
        fontWeight: "bold !important",
        color: theme.palette.primary.main,
        marginTop: "10px !important",
    },
    packageBox: {
        padding: "16px",
        borderBottom: "solid 1px " + theme.palette.primary.secondary,
    },
    imgStyle: {
        objectFit: "contain !important",
        maxHeight: "150px",
    },
    gridItemBoxCenter: {
        margin: "auto !important",
        textAlign: 'center',
    },
    gridItemBoxLeft: {
        margin: "auto !important",
        textAlign: 'left',
    },
    gridItemBoxRight: {
        margin: "auto !important",
        textAlign: 'right',
    },
    cartContainer: {
        border: 'none',
        borderRadius: 20,
        borderColor: theme.palette.gray.secondary,
        overflow: 'hidden',
        boxShadow: '0px 5px 6px 0 #0000001f',
        marginTop: 15,
        marginBottom: 15,
    },
    cartAgencyBox: {
        backgroundColor: theme.palette.secondary.main + ' !important',
    },
    cartAgencyTitle: {
        color: '#fff !important',
        fontWeight: "bold !important",
        padding: "16px"
    },
    optionStyle: {
        backgroundColor: theme.palette.primary.background + ' !important',
        color: theme.palette.primary.secondary + ' !important',
        borderRadius: 20,
        padding: "5px 10px",
        width: "fit-content",
        fontSize: "0.8rem !important",
    },
    voucherLink: {
        color: theme.palette.primary.main + " !important",
        "&:hover": {
            color: theme.palette.primary.background + " !important"
        },
    },
}))