export const ENV = "liv";
export const APP_NAME = "ReLuxe";
export const COMPANY_FULLNAME = "ReLuxe De Vacation Sdn Bhd";
export const DEFAULT_LANGUAGE = "en";
export const DEFAULT_CURRENCY = {"code": "MYR", "name": "Ringgit Malaysia|马币", "symbol": "RM", "rate": "1.00", "status": "1"};
export const DEFAULT_EMAIL = "booking@reluxeofficial.com";
export const DEFAULT_PHONE_NUMBER = '019 808 6933 ';

const BACKEND_URL_LIST = {
    liv: `https://api.reluxeofficial.com`,
    stage: `https://ticketingapi.mib88.com`,
    dev: 'http://127.0.0.1:8000',
};

const LOCAL_URL_LIST = {
    liv: `https://www.reluxeofficial.com`,
    stage: `https://ticketing.mib88.com`,
    dev: `http://localhost:3000`,
};

const ADMIN_URL_LIST = {
    liv: `https://backend.reluxeofficial.com`,
    stage: `https://ticketingbackend.mib88.com`,
    dev: `http://localhost:3001`,
};

export const BACKEND_URL = BACKEND_URL_LIST[ENV];
export const LOCAL_URL = LOCAL_URL_LIST[ENV];
export const ADMIN_URL = ADMIN_URL_LIST[ENV];
export const API_URL = `${BACKEND_URL}/api/`;

export const TIMEOUT = 8000;

export const GOOGLE_CLIENT_ID = "343802386132-033igsun11bb7pdh4e92cukm1dslfgh3.apps.googleusercontent.com";

export const BANK_NAME = 'CIMB';
export const BANK_ACC_NAME = 'Reluxe De Mantanani Sdn Bhd';
export const BANK_ACC_NO = '8010909015';
