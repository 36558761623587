import { getUrl, postUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';

import { Box, Button, CardMedia, Divider, Grid, Typography, Dialog, DialogTitle, DialogActions, DialogContent, TextField } from '@mui/material';
import { RiShoppingBag3Line } from "react-icons/ri";

import { BANK_NAME, BANK_ACC_NAME, BANK_ACC_NO } from "@configs/config"

const CartItemCard = (props) => {
    const { orders, purchase_no, setLoading, agentDiscount } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const { defaultCurrency } = useSelector(state => state.general);

    const [sumTotal, setSumTotal] = useState(0);
    const [receiptDialog, setReceiptDialog] = useState({
        open: false,
        receipt: '',
        errMsg: '',
    });
    const [paymentDoneDialog, setPaymentDoneDialog] = useState({
        open: false,
        msg: "",
    });
    const [displayOrder, setDisplayOrder] = useState({});

    useEffect(() => {
        calculateTotal(orders);
        rearrangeOrderDetail();
        // eslint-disable-next-line
    }, [orders]);

    const rearrangeOrderDetail = () => {
        let rearrangeOrder = {};
        if (_.size(orders) > 0) {
            {
                _.map(orders, (order, okey) => {
                    let rearrangeOrderDetail = {};
                    _.map(order.order_details, (orderDetail, dkey) => {
                        if (orderDetail.status) {
                            let main_package_id = orderDetail.package_id;
                            let main_package = orderDetail.package;
                            if (orderDetail.add_on_id) {
                                let main_detail = _.find(order.order_details, { id: parseInt(orderDetail.add_on_id) });
                                main_package_id = main_detail.package_id;
                                main_package = main_detail.package;
                            }
                            if (!(rearrangeOrderDetail[main_package_id])) {
                                rearrangeOrderDetail[main_package_id] = {};
                            }
                            if (!(rearrangeOrderDetail[main_package_id][orderDetail.booking_date])) {
                                rearrangeOrderDetail[main_package_id][orderDetail.booking_date] = { items: {}, add_on: {}, package: main_package };
                            }
                            if (orderDetail.add_on_id) {
                                if (!(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id])) {
                                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id] = { items: {}, package: orderDetail.package, display_name: orderDetail.package_name };
                                }
                                rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id]['items'][_.size(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id]['items'])] = orderDetail;
                            } else {
                                rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['items'][_.size(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['items'])] = orderDetail;
                            }
                        }
                    })
                    rearrangeOrder[order.id] = rearrangeOrderDetail;
                })
            }
        }
        setDisplayOrder(rearrangeOrder);
    }

    const calculateTotal = (orders) => {
        let _subTotal = 0;
        let _discountTotal = 0;
        _.map(orders, (order, okey) => {
            _.map(order.order_details, (order_detail, dkey) => {
                if (order_detail.status) {
                    _subTotal += parseFloat(order_detail.quantity * order_detail.price.adjust);
                    if (order_detail.price.adjust > 0 && (order_detail.price.adjust - order_detail.price.final) > 0) {
                        _discountTotal += parseFloat((parseFloat(order_detail.price.adjust) - parseFloat(order_detail.price.final)) * order_detail.quantity);
                    }
                }
            })
        })
        let sumTotal = parseFloat(_subTotal) - parseFloat(_discountTotal);
        setSumTotal(sumTotal);
    }

    const revenueMonsterPayment = () => {
        setLoading(true);
        // insert order payment
        postUrl(`payments/${encodeURIComponent(purchase_no)}`, {site: "member"}).then(response => {
            if (response.status) {
                // get revenue monster link
                getUrl(`payments/${encodeURIComponent(purchase_no)}/revenuemonster`).then(response2 => {
                    setLoading(false);
                    if (response2.status) {
                        // redirect to payment page
                        if (response2.data.code === 'SUCCESS' && response2.data.item.url) {
                            window.location.href = response2.data.item.url;
                        }
                    } else {
                        addToast(response2.message, { appearance: 'error' });
                    }
                }).catch(err => {
                    setLoading(false);
                    addToast(JSON.stringify(err), { appearance: 'error' });
                })

                //local make payment success
                // getUrl(`payments/${encodeURIComponent(purchase_no)}/success`).then(response2 => {
                //     setLoading(false);
                //     if (response2 === 1) {
                //         navigate(`/revenuemonster/${encodeURIComponent(purchase_no)}/callback`, { replace: true });
                //     } else {
                //         addToast(response2.message, { appearance: 'error' });
                //     }
                // }).catch(err => {
                //     setLoading(false);
                //     addToast(JSON.stringify(err), { appearance: 'error' });
                // })

            } else {
                setLoading(false);
                addToast(response.message, { appearance: 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const displayBookingPeriod = (booking_date, duration_period) => {
        let displayText = booking_date;
        if (duration_period > 1) {
            let endDate = new Date(booking_date);
            endDate.setDate(endDate.getDate() + (duration_period - 1));
            displayText = displayText + ' - ' + Moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()]).format('YYYY-MM-DD');
        }
        return displayText;
    }

    const handleUploadReceiptOpen = () => {
        setReceiptDialog({
            open: true,
            receipt: '',
            errMsg: '',
        });
    }
    const handleUploadReceiptClose = () => {
        setReceiptDialog({
            open: false,
            receipt: '',
            errMsg: '',
        });
    }
    const uploadReceiptDialog = () => {
        return (
            <Dialog
                open={receiptDialog.open}
                onClose={handleUploadReceiptClose}
            >
                <DialogTitle>{t('checkout.uploadReceipt')}</DialogTitle>
                <DialogContent>
                    <Typography>{t('checkout.bankName') + ": " + BANK_NAME}</Typography>
                    <Typography>{t('checkout.accountName') + ": " + BANK_ACC_NAME}</Typography>
                    <Typography>{t('checkout.accountNo') + ": " + BANK_ACC_NO}</Typography>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            variant="outlined"
                            type="file"
                            InputLabelProps={{ shrink: true }}
                            onChange={({ target }) => setReceiptDialog({ ...receiptDialog, receipt: target.files[0] })}
                        />
                    </Box>
                    {
                        receiptDialog.errMsg
                            ?
                            <Typography variant="caption" color="error">{receiptDialog.errMsg}</Typography>
                            :
                            null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUploadReceiptClose} variant="outlined" color="primary">{t('general.cancel')}</Button>
                    <Button onClick={uploadReceipt} variant="contained" color="primary">{t('general.upload')}</Button>
                </DialogActions>
            </Dialog>
        )
    }
    const paymentDoneDialogBox = () => {
        return (
            <Dialog
                open={paymentDoneDialog.open}
                onClose={() => navigate(`/orders`, { replace: true })}
            >
                <DialogTitle>{paymentDoneDialog.msg}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => navigate(`/orders`, { replace: true })} variant="contained" color="secondary">{t('general.ok')}</Button>
                </DialogActions>
            </Dialog>
        )
    }
    const uploadReceipt = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("_method", "post");
        formData.append("receipt", receiptDialog.receipt);
        formData.append("site", "member");
        postUrl(`payments/${encodeURIComponent(purchase_no)}/upload_receipt`, formData).then((response) => {
            setLoading(false);
            if (response.status) {
                handleUploadReceiptClose();
                setPaymentDoneDialog({
                    open: true,
                    msg: response.message,
                });
            } else {
                setReceiptDialog({ ...receiptDialog, errMsg: response.message });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        });
    }

    const optionBox = (option) => {
        return (
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={6}>
                    <Typography className={option.status ? '' : classes.inactiveStyle}>{option.package_option_name}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body2" align="center">x{option.quantity}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <div style={{ margin: "auto !important", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        {
                            option.price['retail'] > 0 && (option.price['retail'] - option.price['sell']) > 0
                                ?
                                <Typography variant="caption" style={{ textDecoration: "line-through" }}>
                                    {
                                        " " + defaultCurrency.symbol + " "
                                        + parseFloat((option.price['retail'] * defaultCurrency.rate) * option.quantity).toFixed(2)
                                    }
                                </Typography>
                                :
                                null
                        }
                        <Typography variant="body1" style={{ marginLeft: 10 }}>
                            {
                                " " + defaultCurrency.symbol + " "
                                + parseFloat((option.price['sell'] * defaultCurrency.rate) * option.quantity).toFixed(2)
                            }
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }

    const bottomActionRow = () => {
        return (
            <Box className={classes.bottomActionStyle}>
                {/* <Grid container spacing={2} className={classes.bottomActionBox}>
                    <Grid item xs={6} sm={8} className={classes.gridItemBoxRight}>
                        <Typography>{t('checkout.totalSum') + ': '}</Typography>
                        <Typography className={classes.finalPriceStyle}>{'RM ' + parseFloat(sumTotal).toFixed(2)}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={4} className={classes.gridItemBoxRight}>
                        <Button variant="contained" onClick={revenueMonsterPayment} >
                            {t('checkout.payNow')}
                        </Button>
                    </Grid>
                </Grid> */}
                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <div style={{ textAlign: 'right', marginRight: 15 }}>
                        <Typography>{t('checkout.totalSum')}</Typography>
                        <Typography className={classes.finalPriceStyle}>
                            {
                                " " + defaultCurrency.symbol + " "
                                + parseFloat(sumTotal * defaultCurrency.rate).toFixed(2)
                            }
                        </Typography>
                    </div>
                    <Button style={{ margin: 5 }} variant="contained" onClick={revenueMonsterPayment} disabled>
                        {t('checkout.payNow')}
                    </Button>
                    <Button style={{ margin: 5 }} variant="contained" onClick={handleUploadReceiptOpen}>
                        {t('checkout.uploadReceipt')}
                    </Button>
                </div>
            </Box>
        )
    }

    const orderDisplayBox = () => {
        return (
            <Box>
                {
                    _.map(orders, (order, okey) => {
                        let orderDetail = displayOrder[order.id];
                        let sellTotal = 0;
                        let adjustTotal = 0;
                        let discountTotal = 0;
                        return (
                            <Box className={classes.cartContainer} key={okey}>
                                <Box className={classes.cartAgencyBox}>
                                    <Typography className={classes.cartAgencyTitle} style={{ display: 'flex', alignItems: 'center', lineHeight: 1.0 }}>
                                        <RiShoppingBag3Line style={{ marginRight: 5, fontSize: 18 }} />{order.order_no}</Typography>
                                </Box>
                                <Box>
                                    {
                                        _.map(orderDetail, (data, packageId) => (
                                            _.map(data, (groupItem, booking_date) => {
                                                return (
                                                    <Grid container spacing={2} className={classes.packageBox}>
                                                        <Grid item xs={12} md={2} className={`head ${classes.gridItemBoxCenter} ${classes.imgAlignTop}`} >
                                                            <CardMedia
                                                                component="img"
                                                                image={_.size(groupItem.package.images) > 0 ? groupItem.package.images[0]?.file_name : "../images/emptyStatus/no_image.png"}
                                                                sx={{ flex: 1, width: "100%", padding: 0 }}
                                                                alt={'package\'s image'}
                                                                className={classes.imgStyle}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={10} className={classes.gridItemBoxLeft} style={{ paddingLeft: 10 }}>
                                                            <Typography variant="h6" style={{ fontWeight: "bold" }}>{groupItem.package.name}</Typography>
                                                            {
                                                                booking_date
                                                                    ?
                                                                    <Typography className={classes.bookingDateStyle}>{displayBookingPeriod(booking_date, 1)}</Typography>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                _.map(groupItem.items, mainOption => {
                                                                    sellTotal += mainOption.price['sell'] * mainOption.quantity;
                                                                    adjustTotal += mainOption.price['adjust'] * mainOption.quantity;
                                                                    discountTotal += mainOption.price['discount'] * mainOption.quantity;
                                                                    return (
                                                                        <Box key={mainOption.id}>
                                                                            {optionBox(mainOption)}
                                                                        </Box>
                                                                    )
                                                                })
                                                            }
                                                            <Box>
                                                                {
                                                                    (_.size(groupItem.add_on) > 0) &&
                                                                    <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20 }}>
                                                                        {t('checkout.addOn') + ": "}
                                                                    </Typography>
                                                                }
                                                                {
                                                                    _.map(groupItem.add_on, (add_on, key) => {
                                                                        if (_.size(add_on.items) > 0) {
                                                                            return (
                                                                                <Box key={key}>
                                                                                    <Typography variant="body1" style={{ fontWeight: "bold" }}>{add_on.display_name}</Typography>
                                                                                    {_.map(add_on.items, addOnOption => {
                                                                                        sellTotal += addOnOption.price['sell'] * addOnOption.quantity;
                                                                                        adjustTotal += addOnOption.price['adjust'] * addOnOption.quantity;
                                                                                        discountTotal += addOnOption.price['discount'] * addOnOption.quantity;
                                                                                        return (
                                                                                            <Box key={addOnOption.id}>
                                                                                                {optionBox(addOnOption)}
                                                                                            </Box>
                                                                                        )
                                                                                    })}
                                                                                </Box>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        ))
                                    }
                                </Box>
                                <Box style={{ padding: "20px" }}>
                                    <Typography>{t('checkout.remark') + ': ' + (order.remark ? order.remark : '-')}</Typography>
                                    {
                                        parseFloat(sellTotal) != parseFloat(order.total_amount)
                                            ?
                                            <Typography>{t('checkout.subtotal') + ': ' + defaultCurrency.symbol + ' ' + parseFloat(sellTotal * defaultCurrency.rate).toFixed(2)}</Typography>
                                            :
                                            null
                                    }
                                    {
                                        parseFloat(sellTotal).toFixed(2) != parseFloat(adjustTotal).toFixed(2)
                                            ?
                                            <Typography>
                                                {
                                                    t('checkout.discountCodeAmount') + ': ' +
                                                    ((sellTotal - adjustTotal) < 0 ? '' : '-')
                                                    +
                                                    " " + defaultCurrency.symbol + " "
                                                    + (parseFloat((sellTotal - adjustTotal) > 0 ? (sellTotal - adjustTotal) : -(sellTotal - adjustTotal)) * defaultCurrency.rate).toFixed(2)
                                                }
                                            </Typography>
                                            :
                                            null
                                    }
                                    {
                                        agentDiscount.member_agent && parseFloat(adjustTotal) != parseFloat(discountTotal)
                                            ?
                                            <Typography>{t('checkout.agentDiscount') + ': ' + defaultCurrency.symbol + ' ' + parseFloat((parseFloat(adjustTotal - discountTotal) - parseFloat(order.total_amount)) * defaultCurrency.rate).toFixed(2)}</Typography>
                                            :
                                            null
                                    }
                                    <Typography>{t('checkout.orderTotal') + ': ' + defaultCurrency.symbol + ' ' + parseFloat(order.total_amount * defaultCurrency.rate).toFixed(2)}</Typography>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    return (
        <Box className={classes.outlineBox}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>{t('checkout.checkoutItem')}</Typography>
            {
                _.size(orders) > 0 ? orderDisplayBox()
                    :
                    <Box style={{ marginTop: 20, textAlign: "center" }}>
                        <Typography variant="body2" style={{ marginBottom: 10 }}>{t('checkout.invalidPayment')}</Typography>
                    </Box>
            }
            {bottomActionRow()}
            {uploadReceiptDialog()}
            {paymentDoneDialogBox()}
        </Box>
    )
}

export default CartItemCard;

const useStyles = makeStyles((theme) => ({
    cartContainer: {
        border: 'none',
        borderRadius: 20,
        borderColor: theme.palette.gray.secondary,
        overflow: 'hidden',
        boxShadow: '0px 5px 6px 0 #0000001f',
        marginBottom: 15
    },
    cartAgencyBox: {
        backgroundColor: theme.palette.gray.superLight + ' !important',
    },
    cartAgencyTitle: {
        // color: theme.palette.primary.main + ' !important',
        fontWeight: "bold !important",
        padding: "16px"
    },
    packageBox: {
        padding: "16px",
        borderBottom: "solid 1px " + theme.palette.primary.secondary,
    },
    imgStyle: {
        objectFit: "contain !important",
        maxHeight: "150px",
    },
    gridItemBoxCenter: {
        margin: "auto !important",
        textAlign: 'center',
    },
    gridItemBoxLeft: {
        margin: "auto !important",
        textAlign: 'left',
    },
    gridItemBoxRight: {
        margin: "auto !important",
        textAlign: 'right',
    },
    bottomActionStyle: {
        // backgroundColor: theme.palette.primary.secondary + ' !important',
        margin: "50px 0px 20px 0px !important",
        borderRadius: 5,
    },
    bottomActionBox: {
        padding: "0px 16px 16px 16px",
        color: theme.palette.primary.main + ' !important',
        fontWeight: "bold !important",
    },
    finalPriceStyle: {
        // color: theme.palette.primary.main,
        fontWeight: "bold !important",
        fontSize: "1.2rem !important",
        lineHeight: '1.0 !important'
    },
    outlineBox: {
        [theme.breakpoints.up('xs')]: {
            margin: "20px 0px 20px 0px !important"
        },
        [theme.breakpoints.up('md')]: {
            margin: "20px !important"
        },
    },
    paymentLogo: {
        objectFit: "contain !important"
    },
    paymentMethodBox: {
        padding: "10px !important",
        height: 70,
        backgroundColor: '#fff !important',
        border: '1px solid #d0d0d0  !important'
    },
    optionStyle: {
        backgroundColor: theme.palette.primary.background + ' !important',
        color: theme.palette.primary.secondary + ' !important',
        borderRadius: 20,
        padding: "5px 10px",
        width: "fit-content",
        fontSize: "0.8rem !important",
    },
    bookingDateStyle: {
        color: theme.palette.primary.secondary + ' !important',
        padding: "5px 10px 5px 0px",
        fontSize: "1.0rem !important",
        margin: "5px 5px 5px 0px !important",
    }
}))