import React from 'react';
import { useTranslation } from 'react-i18next';

import { Step, StepLabel, Stepper } from '@mui/material';

const CartStepper = (props) => {
    const { activeStep } = props;
    const { t } = useTranslation();

    return (
        <Stepper activeStep={activeStep} alternativeLabel>
            <Step>
                <StepLabel>{t('checkout.stepper.cart')}</StepLabel>
            </Step>
            <Step>
                <StepLabel>{t('checkout.stepper.checkout')}</StepLabel>
            </Step>
            <Step>
                <StepLabel>{t('checkout.stepper.submit')}</StepLabel>
            </Step>
        </Stepper>
    )
}

export default CartStepper;